import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

const WelcomeMessage = memo(({ currentUser, t }) => (
  <>
    <Row>
      <Col offset={2}>
        <div style={{ color: 'white', fontSize: 18 }}>
          <h3 style={{ color: 'white', marginBottom: 4 }}>
            {t('password_email.hello')} {currentUser?.name}!
          </h3>
        </div>
      </Col>
    </Row>
    <Row>
      <Col offset={2}>
        <div style={{ color: 'white', fontSize: 15 }}>{t('what_can_help')}</div>
      </Col>
    </Row>
  </>
));

WelcomeMessage.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string
  }),
  t: PropTypes.func.isRequired
};

export default WelcomeMessage;
