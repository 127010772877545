/**
 * Calculates parent activity with no links.
 * @param {Object} activityData - The parent activity object.
 * @param {Map<number, Object>} calculations - Map of calculated activities.
 * @param {Map<number, Object>} singleParents - Map containing parent activities and their children.
 * @param {string} direction - Calculation direction ('forward' or 'backward').
 * @returns {Object|null} Calculation result for the parent activity, or null if not calculable.
 */
export function parentWithNoLinks(params) {
  const { activityData, calculations, singleParents, direction } = params;
  if (hasActivityBeenCalculated(activityData.id, calculations)) return null;

  const childrenIds = getChildrenIds(activityData.id, singleParents);
  const childrenCalculations = getCalculatedChildren(childrenIds, calculations);

  if (childrenCalculations.length === 0) return null;

  const parentCalculation = computeParentCalculation(
    childrenCalculations,
    direction
  );

  return parentCalculation;
}

/**
 * Checks if the activity has already been calculated.
 * @param {number} activityId - The ID of the activity.
 * @param {Map<number, Object>} calculations - Map of calculated activities.
 * @returns {boolean} True if the activity has been calculated, false otherwise.
 */
function hasActivityBeenCalculated(activityId, calculations) {
  return calculations.has(activityId);
}

/**
 * Retrieves the IDs of the child activities for a given parent activity ID.
 * @param {number} parentId - The ID of the parent activity.
 * @param {Map<number, Object>} singleParents - Map containing parent activities and their children.
 * @returns {Array<number>} Array of child activity IDs.
 */
function getChildrenIds(parentId, singleParents) {
  const parent = singleParents.get(parentId);
  return parent ? parent.childrens : [];
}

/**
 * Collects calculations of child activities that have been calculated.
 * @param {Array<number>} childrenIds - Array of child activity IDs.
 * @param {Map<number, Object>} calculations - Map of calculated activities.
 * @returns {Array<Object>} Array of child activity calculations.
 */
function getCalculatedChildren(childrenIds, calculations) {
  return childrenIds
    .filter((childId) => calculations.has(childId))
    .map((childId) => calculations.get(childId));
}

/**
 * Computes the calculation for the parent activity based on its children's calculations.
 * @param {Array<Object>} childrenCalculations - Array of child activity calculations.
 * @param {string} direction - Calculation direction ('forward' or 'backward').
 * @returns {Object} The calculated values for the parent activity.
 */
function computeParentCalculation(childrenCalculations, direction) {
  if (direction === 'forward') {
    const earliestStart = calculateEarliestDate(childrenCalculations, 'es');
    const latestFinish = calculateLatestDate(childrenCalculations, 'ef');

    return { es: earliestStart, ef: latestFinish };
  } else {
    const latestStart = calculateEarliestDate(childrenCalculations, 'ls');
    const latestFinish = calculateLatestDate(childrenCalculations, 'lf');

    return { ls: latestStart, lf: latestFinish };
  }
}

/**
 * Calculates the earliest date from an array of calculations based on the specified property.
 * @param {Array<Object>} calculationsArray - Array of calculations.
 * @param {string} property - The property to find the earliest date for.
 * @returns {Date} The earliest date.
 */
function calculateEarliestDate(calculationsArray, property) {
  return calculationsArray.reduce((earliest, current) => {
    return current[property] < earliest[property] ? current : earliest;
  })[property];
}

/**
 * Calculates the latest date from an array of calculations based on the specified property.
 * @param {Array<Object>} calculationsArray - Array of calculations.
 * @param {string} property - The property to find the latest date for.
 * @returns {Date} The latest date.
 */
function calculateLatestDate(calculationsArray, property) {
  return calculationsArray.reduce((latest, current) => {
    return current[property] > latest[property] ? current : latest;
  })[property];
}
