import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import CloseButton from './components/CloseButton.js';
import WelcomeMessage from './components/WelcomeMessage.js';
import { backgroundHeader, proplannerHeaderIcon } from './assets';

const Header = memo(({ currentUser, onClose, t }) => (
  <>
    <Row>
      <img src={backgroundHeader} width="100%" alt="background" />
    </Row>
    <div style={{ position: 'relative', top: -127 }}>
      <Row>
        <Col span={5} offset={2}>
          <img src={proplannerHeaderIcon} width={34} alt="header icon" />
        </Col>
        <Col offset={14} span={2}>
          <CloseButton onClick={onClose} />
        </Col>
      </Row>
      <WelcomeMessage currentUser={currentUser} t={t} />
    </div>
  </>
));

Header.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default Header;
