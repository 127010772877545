import CalculateForwardParentsWithLinks from '../parents-calculations/forward';
import CalculateBackwardParentsLinks from '../parents-calculations/backward';
/**
 * Calculates the parent activity when it has links.
 * @param {Object} params - The parameters object.
 * @param {Object} params.parent - The parent activity object.
 * @param {String} params.linkProperty - The link property (e.g., '$source' or '$target').
 * @param {String} params.linkDirection - The link direction (e.g., 'source' or 'target').
 * @param {Map<number, Object>} params.calculations - Map of calculated activities.
 * @param {Object} params.gantt - The Gantt instance.
 * @param {String} params.direction - Calculation direction ('forward' or 'backward').
 * @returns {Object} The calculation result for the parent activity.
 */
export function parentWithLinks(params) {
  const {
    parent,
    linkProperty,
    linkDirection,
    calculations,
    gantt,
    direction
  } = params;

  const linksArray = parent[linkProperty];
  const parentData = extractParentData(parent);

  let calculation = null;

  if (direction === 'forward') {
    const linksWithActivityData = getLinksWithActivityDataForward(
      linksArray,
      linkDirection,
      calculations,
      gantt
    );

    calculation = new CalculateForwardParentsWithLinks(
      linksWithActivityData,
      parent,
      gantt
    );

    calculation = calculation.calculate();
  } else {
    const linksWithActivityData = getLinksWithActivityDataBackward(
      linksArray,
      linkDirection,
      calculations,
      gantt
    );

    const backwardCalculation = new CalculateBackwardParentsLinks({
      linksWithActivityData,
      activity: parent,
      parentData,
      gantt
    });

    calculation = backwardCalculation.calculate();
  }

  return calculation;
}

/**
 * Extracts necessary data from the parent activity.
 * @param {Object} parent - The parent activity object.
 * @returns {Object} The extracted parent data.
 */
function extractParentData(parent) {
  return {
    duration: parent.duration,
    calendarId: parent.calendar_id
  };
}

/**
 * Retrieves links with activity data for forward calculation.
 * @param {Array<number>} linksArray - Array of link IDs.
 * @param {String} linkDirection - The link direction ('source' or 'target').
 * @param {Map<number, Object>} calculations - Map of calculated activities.
 * @param {Object} gantt - The Gantt instance.
 * @returns {Array<Object>} Array of linked activity data.
 */
function getLinksWithActivityDataForward(
  linksArray,
  linkDirection,
  calculations,
  gantt
) {
  if (linksArray.length === 0) {
    return [];
  }

  return linksArray
    .map((linkId) => {
      const linkData = gantt.getLink(linkId);
      if (!linkData) {
        console.warn(`Link not found: ${linkId}`);
        return null;
      }
      const linkedActivityId = Number(linkData[linkDirection]);
      const calculationsOfLinkedActivity = calculations.get(linkedActivityId);
      if (!calculationsOfLinkedActivity) {
        console.warn(
          `Calculations not found for activity: ${linkedActivityId}`
        );
        return null;
      }
      return {
        es: calculationsOfLinkedActivity.es,
        ef: calculationsOfLinkedActivity.ef,
        linkLag: linkData.lag,
        linkType: linkData.type
      };
    })
    .filter(Boolean);
}

/**
 * Retrieves links with activity data for backward calculation.
 * @param {Array<number>} linksArray - Array of link IDs.
 * @param {String} linkDirection - The link direction ('source' or 'target').
 * @param {Map<number, Object>} calculations - Map of calculated activities.
 * @param {Object} gantt - The Gantt instance.
 * @returns {Array<Object>} Array of linked activity data.
 */
function getLinksWithActivityDataBackward(
  linksArray,
  linkDirection,
  calculations,
  gantt
) {
  if (linksArray.length === 0) {
    return [];
  }

  return linksArray
    .map((linkId) => {
      const linkData = gantt.getLink(linkId);
      if (!linkData) {
        console.warn(`Link not found: ${linkId}`);
        return null;
      }
      const linkedActivityId = Number(linkData[linkDirection]);
      const calculationsOfLinkedActivity = calculations.get(linkedActivityId);
      if (!calculationsOfLinkedActivity) {
        return null;
      }
      const linkedTask = gantt.getTask(linkedActivityId);
      if (!linkedTask) {
        return null;
      }
      return {
        ls: calculationsOfLinkedActivity.ls,
        lf: calculationsOfLinkedActivity.lf,
        linkLag: linkData.lag || 0,
        linkType: linkData.type,
        progress: linkedTask.progress,
        type: linkedTask.type
      };
    })
    .filter(Boolean);
}
