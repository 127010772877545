import { trackingEvent } from '../../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../../analytics/utils';
/**
 * Tracks an event when an activity fails validation.
 *
 * @param {string} activity_id - The ID of the activity.
 * @param {string} activity_name - The name of the activity.
 * @param {Array<Object>} invalid_attributes - List of invalid attributes with error details.
 */
export const trackingEventValidateActivity = ({
  activity_id,
  activity_name,
  invalid_attributes
}) => {
  trackingEvent(
    'activity_save_validation_error',
    {
      ...getBasicAmplitudEventProperties(),
      invalid_attributes,
      activity_id,
      activity_name
    },
    AMPLITUDE_SERVICE
  );
};
