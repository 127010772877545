import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

const ResourceLink = memo(({ url, icon, label }) => (
  <Col
    span={8}
    className="single-other-help"
    onClick={() => window.open(url, '_blank')}>
    <Row>
      <Col>
        <img src={icon} width={30} alt={label} />
      </Col>
    </Row>
    <Row>
      <Col className="other-resources-label">{label}</Col>
    </Row>
  </Col>
));

ResourceLink.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default ResourceLink;
