import React from 'react';
import { useDependenciesLinkFloat } from '../DependenciesLinkFloat/useGetLinkFreeFloat';
import { CheckCircleFillIcon } from '../../../../../../../icons/Icons';

const DependenciesLinkDriving = ({ activity, activityLink, direction }) => {
  const dependenciesLinkFloat = useDependenciesLinkFloat(
    activity,
    activityLink,
    direction
  );

  if (dependenciesLinkFloat !== 0) return null;

  return (
    <span>
      <CheckCircleFillIcon color="#FFF" />
    </span>
  );
};

export default DependenciesLinkDriving;
