const byFirstLevel = (activity) => {
  if (!activity) return false;
  return activity['$level'] === 1;
};

const filterByParentType = (activity) => activity.type === 'project';
const avoidSubproject = (activity) => activity['$level'] !== 0;
const byTaskType = (activity) => activity.type === 'task';
const byTaskTypeAndMilestone = (activity) =>
  activity.type === 'task' || activity.type === 'milestone';

export default {
  byFirstLevel,
  filterByParentType,
  avoidSubproject,
  byTaskType,
  byTaskTypeAndMilestone
};
