import { log } from '../../../../../monitor/monitor';
import filters from '../../helpers/filters';

/**
 * Identifies parents with and without links and updates the corresponding sets.
 * @param {Object} params - The parameters object.
 * @param {Object} params.gantt - The Gantt instance.
 * @param {string} params.direction - The calculation direction ('forward' or 'backward').
 * @param {string} params.linkProperty - The link property name ('$target' or '$source').
 * @returns {Object} An object containing:
 *   - parentsWithFullProgress: Set of parent activity IDs with full progress.
 *   - pendingParentsWithNoLinks: Set of parent activity IDs with no links.
 *   - pendingParentsWithLinks: Set of parent activity IDs with links.
 */
export function identifyParentsWithAndWithoutLinks({
  gantt,
  direction,
  linkProperty
}) {
  try {
    const allParentActivities = getAllParentActivities(gantt);
    const parentsWithFullProgress = new Set();
    const pendingParentsWithNoLinks = new Set();
    const pendingParentsWithLinks = new Set();

    if (direction === 'backward') {
      const parentsWithFullProgressArray =
        getParentsWithFullProgress(allParentActivities);
      parentsWithFullProgressArray.forEach((activity) => {
        parentsWithFullProgress.add(activity.id);
      });
    }

    const parentsWithNoLinks = getParentsWithNoLinks(
      allParentActivities,
      direction,
      linkProperty
    );

    const difference = getDifference(allParentActivities, parentsWithNoLinks);

    parentsWithNoLinks.forEach((activity) => {
      pendingParentsWithNoLinks.add(activity.id);
    });

    difference.forEach((activity) => {
      pendingParentsWithLinks.add(activity.id);
    });

    return {
      parentsWithFullProgress,
      pendingParentsWithNoLinks,
      pendingParentsWithLinks
    };
  } catch (e) {
    log('Critical Path', 'Error in identifyParentsWithAndWithoutLinks');
    throw e;
  }
}

/**
 * Retrieves all parent activities.
 * @param {Object} gantt - The Gantt instance.
 * @returns {Array} An array of parent activities.
 */
export function getAllParentActivities(gantt) {
  return gantt
    .getTaskByTime()
    .filter(filters.filterByParentType)
    .filter(filters.avoidSubproject);
}

/**
 * Retrieves parents with no links based on direction and link property.
 * @param {Array} activities - Array of activities.
 * @param {string} direction - The calculation direction ('forward' or 'backward').
 * @param {string} linkProperty - The link property name ('$target' or '$source').
 * @returns {Array} An array of parent activities with no links.
 */
export function getParentsWithNoLinks(activities, direction, linkProperty) {
  return activities.filter((activity) => {
    const links = activity[linkProperty];
    if (!Array.isArray(links)) {
      return false;
    }
    const hasLinks = links.length > 0;
    const constraintType = activity.constraint_type || '';
    const isNotFnlt = constraintType !== 'fnlt';

    if (direction === 'forward') {
      const isNotSnet = constraintType !== 'snet';
      return !hasLinks && isNotFnlt && isNotSnet;
    } else {
      return !hasLinks && isNotFnlt;
    }
  });
}

/**
 * Retrieves the difference between all activities and excluded activities.
 * @param {Array} allActivities - Array of all activities.
 * @param {Array} excludedActivities - Array of activities to exclude.
 * @returns {Array} An array of activities not in excludedActivities.
 */
export function getDifference(allActivities, excludedActivities) {
  const excludedIds = new Set(
    excludedActivities.map((activity) => activity.id)
  );
  return allActivities.filter((activity) => {
    return !excludedIds.has(activity.id);
  });
}

/**
 * Retrieves parents with full progress (100%).
 * @param {Array} activities - Array of activities.
 * @returns {Array} An array of parent activities with full progress.
 */
export function getParentsWithFullProgress(activities) {
  return activities.filter((activity) => {
    const progress = Number(activity.progress);
    if (Number.isNaN(progress)) {
      return false;
    }
    return progress === 100;
  });
}
