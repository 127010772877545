import { log } from '../../../../../monitor/monitor';
import calculators from '../calculators';
import { CONSTRAINT_TYPES } from '../../constants/index';

class CalculationOfParent {
  constructor(params) {
    this.activity = params.activity;
    this.direction = params.direction;
    this.linkProperty = params.direction === 'forward' ? '$target' : '$source';
    this.linkDirection = params.direction === 'forward' ? 'source' : 'target';
    this.calculationsOfParentThatImpactChildrens =
      params.calculationsOfParentThatImpactChildrens;
    this.calculations = params.calculations;
    this.calculationOfParentsFromLinks = params.calculationOfParentsFromLinks;
    this.singleParents = params.singleParents;
    this.parentCalculationFromChildren = params.parentCalculationFromChildren;
    this.gantt = params.gantt;
  }

  handleParentWithLinks() {
    try {
      const allLinkedTasks = this.getArrayOfIdOfLinkedActivities(
        this.activity[this.linkProperty]
      );

      if (this.direction === 'backward') {
        const restrictions = [CONSTRAINT_TYPES.FNLT];

        if (this.shouldCalculateParentWithLinksBackward(restrictions)) {
          this.calculateAndStoreParentWithLinks(this.activity);
          return;
        }

        if (this.shouldSetDatesToParentsWithLinks(restrictions)) {
          this.setDatesToParentsWithLinksBackward();
          return;
        }
      }

      const areAllLinksCalculated = this.areAllLinksCalculated(allLinkedTasks);

      if (
        areAllLinksCalculated &&
        this.calculationsOfParentThatImpactChildrens.has(this.activity.id)
      ) {
        if (this.direction === 'forward') {
          this.setDatesToParentsWithLinks();
        }
        if (this.direction === 'backward') {
          this.setDatesToParentsWithLinksBackward();
        }
        return;
      }

      this.calculateAndStoreParentWithLinks(this.activity);
    } catch (e) {
      log('Critical Path', 'Error in handleParentWithLinks');
      throw e;
    }
  }

  shouldCalculateParentWithLinksBackward(restrictions) {
    const constraintTypeMatches = restrictions.includes(
      this.activity.constraint_type
    );
    const parentNotCalculatedFromLinks =
      !this.calculationOfParentsFromLinks.has(this.activity.id);

    return constraintTypeMatches && parentNotCalculatedFromLinks;
  }

  calculateAndStoreParentWithLinks(activity) {
    const calculation = calculators.parentWithLinks({
      parent: activity,
      linkProperty: this.linkProperty,
      linkDirection: this.linkDirection,
      gantt: this.gantt,
      calculations: this.calculations,
      direction: this.direction
    });

    this.calculationsOfParentThatImpactChildrens.set(activity.id, calculation);
    this.calculationOfParentsFromLinks.add(activity.id);
  }

  areAllLinksCalculated(allLinkedTasks) {
    return allLinkedTasks.every((activityId) => {
      return this.calculations.has(activityId);
    });
  }

  shouldSetDatesToParentsWithLinks(restrictions) {
    const constraintTypeMatches = restrictions.includes(
      this.activity.constraint_type
    );
    const parentCalculatedFromLinks = this.calculationOfParentsFromLinks.has(
      this.activity.id
    );

    return constraintTypeMatches && parentCalculatedFromLinks;
  }

  handleParentWithNoLinks() {
    const values = calculators.parentWithNoLinks({
      activityData: this.activity,
      gantt: this.gantt,
      singleParents: this.singleParents,
      calculations: this.calculations,
      direction: this.direction
    });

    if (values) {
      this.calculations.set(this.activity.id, values);
    }
  }

  setDatesToParentsWithLinks() {
    if (!this.activity) {
      throw new Error(`Error trying to get parent ${this.activity}`);
    }
    let childrens = this.singleParents.get(this.activity.id)?.childrens;

    let arrayOfDatesOfAllTheChildrens = Array.from(this.calculations).filter(
      (activity) => {
        return childrens.includes(activity[0]);
      }
    );
    let arrayOfEsAndEf = arrayOfDatesOfAllTheChildrens.map((activity) => {
      return activity[1];
    });

    const { minEs, maxEf } = arrayOfEsAndEf.reduce(
      (acc, { es, ef }) => ({
        minEs: new Date(Math.min(acc.minEs.getTime(), new Date(es).getTime())),
        maxEf: new Date(Math.max(acc.maxEf.getTime(), new Date(ef).getTime()))
      }),
      {
        minEs: new Date(arrayOfEsAndEf[0].es),
        maxEf: new Date(arrayOfEsAndEf[0].ef)
      }
    );

    this.calculations.set(this.activity.id, {
      es: minEs,
      ef: maxEf
    });
    this.parentCalculationFromChildren.add(this.activity.id);
  }

  setDatesToParentsWithLinksBackward() {
    if (!this.activity) {
      throw new Error(`Error trying to get parent ${this.activity}`);
    }
    let childrens = this.singleParents.get(this.activity.id)?.childrens;

    let arrayOfDatesOfAllTheChildrens = Array.from(this.calculations).filter(
      (activity) => {
        return childrens.includes(activity[0]);
      }
    );

    let arrayOfEsAndEf = arrayOfDatesOfAllTheChildrens.map((activity) => {
      return activity[1];
    });

    const { minLs, maxLf } = arrayOfEsAndEf.reduce(
      (acc, { ls, lf }) => ({
        minLs: new Date(Math.min(acc.minLs.getTime(), new Date(ls).getTime())),
        maxLf: new Date(Math.max(acc.maxLf.getTime(), new Date(lf).getTime()))
      }),
      {
        minLs: new Date(arrayOfEsAndEf[0].ls),
        maxLf: new Date(arrayOfEsAndEf[0].lf)
      }
    );

    this.calculations.set(this.activity.id, {
      ls: minLs,
      lf: maxLf
    });
  }

  getArrayOfIdOfLinkedActivities(links, direction = false) {
    return links.map((link) => {
      const linkData = this.gantt.getLink(link);
      const linkedActivity =
        linkData[direction ? direction : this.linkDirection];
      return Number(linkedActivity);
    });
  }
}

export default CalculationOfParent;
