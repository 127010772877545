// Import necessary modules and helpers
import { getLinkNameByCode } from '../../constants/index';
import { addDurationToDate } from '../../helpers/addDurationToDate';
import { getNextWorkingHour } from '../../helpers/getNextWorkingHour';
import { getMaxEarlyStartSet } from '../../forward-path/helpers/getMaxEarlyStartSet';
import {
  mutateDate,
  calculateRestriction,
  calculateRestrictionSnet,
  mutateTheDateToFutureOrPastInBaseRestriction
} from './helpers';

class CalculateForwardParentsWithLinks {
  /**
   * Initializes the calculator with linked activities, the current activity, and the gantt instance.
   * @param {Array<Object>} linkedActivitiesData - Array of linked activities data.
   * @param {Object} activity - The current activity object.
   * @param {Object} gantt - The gantt instance.
   */
  constructor(linkedActivitiesData, activity, gantt) {
    this.linkedActivitiesData = linkedActivitiesData;
    this.activity = activity;
    this.gantt = gantt;

    this.activityCalendar = this.gantt.getCalendar(this.activity.calendar_id);
    if (!this.activityCalendar) {
      throw new Error(
        `Error trying to get calendar with id: ${this.activity.calendar_id}`
      );
    }
  }

  /**
   * Main method to perform the calculation.
   * @returns {Object} The calculation result.
   */
  calculate() {
    const calculationsFromLinks = this.calculateLinks();

    const maxEfFromLinks = getMaxEarlyStartSet(calculationsFromLinks);

    const constraintType = this.activity.constraint_type;

    if (constraintType === 'snet') {
      return this.handleSNETConstraint(maxEfFromLinks);
    }

    if (constraintType === 'fnlt') {
      return this.handleFNLTConstraint(maxEfFromLinks);
    }

    return maxEfFromLinks;
  }

  /**
   * Calculates the dates based on the linked activities.
   * @returns {Array<Object>} Array of calculation results from links.
   */
  calculateLinks() {
    const calculations = [];

    for (const linkedActivity of this.linkedActivitiesData) {
      const linkTypeCode = linkedActivity.linkType;
      const linkType = getLinkNameByCode(linkTypeCode);
      const lag = linkedActivity.linkLag;
      const predecessorEs = linkedActivity.es;
      const predecessorEf = linkedActivity.ef;

      let calculation = null;

      if (linkType === 'ss') {
        calculation = this.calculateSSLink({ lag, predecessorEs });
      } else if (linkType === 'ff') {
        calculation = this.calculateFFLink({ lag, predecessorEf });
      } else if (linkType === 'fs') {
        calculation = this.calculateFSLink({ lag, predecessorEf });
      } else if (linkType === 'sf') {
        calculation = this.calculateSFLink({
          lag,
          predecessorEs,
          predecessorEf
        });
      } else {
        continue;
      }

      if (calculation) {
        calculations.push(calculation);
      }
    }

    return calculations;
  }

  /**
   * Handles the 'Start No Earlier Than' (SNET) constraint.
   * @param {Object|null} maxEfFromLinks - The maximum early finish from links.
   * @returns {Object} The calculation result considering the constraint.
   */
  handleSNETConstraint(maxEfFromLinks) {
    const restriction = calculateRestrictionSnet({
      duration: this.activity.duration,
      constraintDate: this.activity.constraint_date,
      activityCalendar: this.activityCalendar,
      activity: this.activity
    });

    if (!maxEfFromLinks) {
      return restriction;
    }

    return maxEfFromLinks.ef > restriction.ef ? maxEfFromLinks : restriction;
  }

  /**
   * Handles the 'Finish No Later Than' (FNLT) constraint.
   * @param {Object|null} maxEfFromLinks - The maximum early finish from links.
   * @returns {Object} The calculation result considering the constraint.
   */
  handleFNLTConstraint(maxEfFromLinks) {
    const restriction = calculateRestriction({
      duration: -this.activity.duration,
      constraintDate: this.activity.constraint_date,
      activityCalendar: this.activityCalendar,
      activity: this.activity
    });

    if (!maxEfFromLinks) {
      return restriction;
    }

    return maxEfFromLinks.ef > restriction.ef ? restriction : maxEfFromLinks;
  }

  /**
   * Calculates dates for 'Start to Start' (SS) link type.
   * @param {Object} params - Parameters containing lag and predecessor early start.
   * @returns {Object} The calculation result.
   */
  calculateSSLink({ lag, predecessorEs }) {
    let esLinked = mutateDate({
      linkType: 'ss',
      lag,
      predecessorTime: predecessorEs,
      activityCalendar: this.activityCalendar,
      activity: this.activity
    });

    let es = addDurationToDate(
      this.activityCalendar,
      esLinked,
      lag,
      this.activity
    );

    if (lag > 0) {
      es = mutateTheDateToFutureOrPastInBaseRestriction(
        es,
        'ss',
        this.activityCalendar,
        this.activity
      );
    }

    const ef = addDurationToDate(
      this.activityCalendar,
      es,
      this.activity.duration,
      this.activity
    );

    return { es, ef };
  }

  /**
   * Calculates dates for 'Finish to Finish' (FF) link type.
   * @param {Object} params - Parameters containing lag and predecessor early finish.
   * @returns {Object} The calculation result.
   */
  calculateFFLink({ lag, predecessorEf }) {
    let efLinked = mutateDate({
      linkType: 'ff',
      lag,
      predecessorTime: predecessorEf,
      activityCalendar: this.activityCalendar,
      activity: this.activity
    });

    const ef = addDurationToDate(
      this.activityCalendar,
      efLinked,
      lag,
      this.activity
    );

    let es = addDurationToDate(
      this.activityCalendar,
      ef,
      -this.activity.duration,
      this.activity
    );

    if (lag > 0) {
      es = mutateTheDateToFutureOrPastInBaseRestriction(
        es,
        'ff',
        this.activityCalendar,
        this.activity
      );
    }

    return { es, ef };
  }

  /**
   * Calculates dates for 'Finish to Start' (FS) link type.
   * @param {Object} params - Parameters containing lag and predecessor early finish.
   * @returns {Object} The calculation result.
   */
  calculateFSLink({ lag, predecessorEf }) {
    let efLinked = predecessorEf;

    if (lag === 0) {
      efLinked = getNextWorkingHour({
        dateBaseToCalculate: predecessorEf,
        activityCalendar: this.activityCalendar,
        direction: 'future',
        activity: this.activity
      });
    }

    const es = addDurationToDate(
      this.activityCalendar,
      efLinked,
      lag,
      this.activity
    );

    const ef = addDurationToDate(
      this.activityCalendar,
      es,
      this.activity.duration,
      this.activity
    );

    return { es, ef };
  }

  /**
   * Calculates dates for 'Start to Finish' (SF) link type.
   * @param {Object} params - Parameters containing lag, predecessor early start, and finish.
   * @returns {Object} The calculation result.
   */
  calculateSFLink({ lag, predecessorEs }) {
    const ef = addDurationToDate(
      this.activityCalendar,
      predecessorEs,
      lag,
      this.activity
    );

    let es = addDurationToDate(
      this.activityCalendar,
      ef,
      -this.activity.duration,
      this.activity
    );

    if (lag > 0) {
      es = mutateTheDateToFutureOrPastInBaseRestriction(
        es,
        'sf',
        this.activityCalendar,
        this.activity
      );
    }

    return { es, ef };
  }
}

export default CalculateForwardParentsWithLinks;
