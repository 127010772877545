/* eslint-disable prefer-const */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import logo from '../../assets/img/logo.png';
import {
  Menu,
  Dropdown,
  Button,
  Avatar,
  Badge,
  Drawer,
  Tooltip,
  message,
  Icon
} from 'antd';
import { WarningOutlined, DownloadOutlined } from '@ant-design/icons';
import { useHistory, withRouter } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { sectorService, notificationService } from '../../services';
import {
  addToLocalStorage,
  dynamicSort,
  getTodayWeekWithPday,
  getTypeNotification
} from '../../utils';
import { calendarActions } from '../../redux/actions/calendarActions';
import { stateActions } from '../../redux/actions/stateActions';
import './Toolbar.css';
import './Toolbar.scss';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';
/** Import lodash functions */
import moment from 'moment';

/** React-query */
import { useQuery } from 'react-query';
import { getAnalitycsByProject } from '../../views/weeklyPlan/weeklyPlan.helper';

/** Redux implementation */
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import * as projectActions from '../../redux/slices/projectSlice';
import useConfigurationPermissions from '../../hooks/useConfigurationPermissions';
import { withTranslation } from 'react-i18next';

/** custom hooks */
import { useProgressIndicatorProject } from '../../hooks/useProgressIndicatorProject';
import { usePpcIndicatorProject } from '../../hooks/usePpcIndicatorProject';
import { usePcrIndicatorProject } from '../../hooks/usePcrIndicatorProject';

/** Icons */
import userIcon from '../../assets/img/user.svg';
import handshake from '../../assets/img/handshake.svg';
import closeweekIcon from '../../assets/img/closeweek.svg';
import closecncIcon from '../../assets/img/mrequest.svg';
import useUnsavedElementsAlerter from '../../hooks/useUnsavedElementsAlerter';
import ProcoreImg from '../../assets/img/procore-variant.png';

import {
  EditStageIcon,
  DuplicateStageIcon,
  SaveAsTemplateStageIcon,
  DeleteStageIcon,
  DangerousNotificationIcon,
  ArrowDropdownIcon,
  MakeVisibleScheduleIcon,
  HideScheduleIcon
} from '../../icons';
import RenameStage from './RenameStage';
import DuplicateStage from './DuplicateStage';
import DeleteStage from './DeleteStage';
import SetAsCurrentStage from './SetAsCurrentStage';
import SetVisibilityOfSchedule from './VisibilitySchedule';

import { notifyMessageCustom } from '../../utils';
import { EditPartyComp } from './DuplicateStage/icons';

import { resetPropertiesAnalytics, trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';

import { allSectorsId } from '../../redux/selectors/lookahead';
import {
  getSignedUser,
  setCurrentProject,
  setCurrentSector
} from '../../utils/userUtils';
import CompanyPopover from '../CompanyPopover/CompanyPopover';
import { getCompaniesLocal } from '../../views/selectCompany/companyOptions/companyOptions.helpers';

import './Toolbar.scss';
import PopoverUniselectSystem from '../DesignSystem/PopoverUniselectSystem';
import TruncatedTextSystem from '../DesignSystem/TruncatedTextSystem';
import { roleTypes } from '../../constants/role.constants';
import { Colors } from '../../constants/colors.constants';
import { removeProjectAndSector } from '../../utils/projectUtils';
import { isFeatureFlagActive } from '../../analytics/implements/amplitude';
import HelpCenter from './HelpCenter';

export const TestIds = {
  TOOLBAR_CONTAINER: 'TOOLBAR_CONTAINER'
};

/** Permissions */
const {
  SUPERADMIN,
  ADMIN,
  PROJECTLEADER,
  PLANNER,
  LASTPLANNER,
  CLIENT,
  MANAGER,
  SUBTRADE,
  SUPERINTENDENT
} = roleTypes;

const usersAccessHandlerSetCurrentOfSchedules = [
  SUPERADMIN,
  ADMIN,
  PROJECTLEADER
];
const usersAccessHandlerVisibilityOfSchedules = [
  SUPERADMIN,
  ADMIN,
  PROJECTLEADER,
  PLANNER
];
export const usersAccessOnlyToVisibleSchedules = [
  LASTPLANNER,
  CLIENT,
  MANAGER,
  SUBTRADE,
  SUPERINTENDENT
];

const userConfigPermissions = useConfigurationPermissions();
const configPermissionsValues = Object.values(userConfigPermissions);
const accessConfig = configPermissionsValues.some((value) =>
  ['AC', 'V'].includes(value)
);

const { PRIMARY, GRAY_1 } = Colors;

function Toolbar({
  selects = {},
  fixed = false,
  t = () => {},
  autoSetSchedule = true
}) {
  const greetUser = isFeatureFlagActive('saludar-usuarios');
  const dispatch = useDispatch();
  const [loggedUser, setLoggedUser] = useState(getSignedUser());
  const userFromLocalStorageData = localStorage.getItem('user');
  const userFromLocalStorage = userFromLocalStorageData
    ? JSON.parse(userFromLocalStorageData)
    : null;

  /** Redux instances */
  const projectState = useSelector((state) => state.projectState);
  const lookaheadState = useSelector((state) => state.lookaheadState);
  const selectedProjectSectors = useSelector(
    (state) => allSectorsId(state),
    shallowEqual
  );
  const projectSelected = projectState.projectSelected;
  const sectorViewNotification = projectState.sectorViewNotification;
  const update = useSelector((state) => state.userState);
  const [currentCompany, setCurrentCompany] = useState({});
  const { jsx, callback } = useUnsavedElementsAlerter(t);

  // hooks
  const selectedProject = projectState.projectSelected;
  const selectedSector = projectState.sectorSelected;
  const [currentSectors, setCurrentSectors] = useState();

  // hook for sectors (select)
  const [logoUser, setLogoUser] = useState(); // image user
  const { width, height } = useWindowDimensions();
  const [fixedState] = useState(fixed); /** hook for handle fixed toolbar */
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [notificationsNoRead, setNotificationsNoRead] = useState(0);
  const [stateDropdownNotification, setStateDropdownNotification] = useState({
    visible: false
  });
  const [pageNotifications, setPageNotifications] = useState(0);
  const [allRowsNotifications, setAllRowsNotifications] = useState(0);
  const pathname = window.location.pathname;
  const userLang = navigator.language || navigator.userLanguage;
  let lenguaje = 'es';
  if (userLang.indexOf('es') !== -1) {
    lenguaje = 'es';
    moment.locale('es');
  } else {
    lenguaje = 'en';
    moment.locale('en');
  }

  const [showModalRename, setShowModalRename] = useState(false);
  const [showModalDuplicate, setShowModalDuplicate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalSetCurrentStage, setShowModalSetCurrentStage] =
    useState(false);
  const [showModalVisibilitySchedule, setShowModalVisibilitySchedule] =
    useState(false);

  const popoverProjectRef = useRef(null);
  const popoverScheduleRef = useRef(null);

  const selectedProjectLabel = projectState.allProjects.find(
    (selected) => selected.id === selectedProject
  )?.name;

  const selectedSectorLabel = projectState.allSectors.find(
    (selected) => selected.id === selectedSector
  )?.name;

  useEffect(() => {
    let isMounted = true;

    const loadNotifications = async (pagination = false) => {
      if (!isMounted) return;

      if (pagination) {
        await setPageNotifications(0);
        await setAllRowsNotifications(0);
      }

      const resp = userFromLocalStorage
        ? await notificationService.getByUser(
            userFromLocalStorage.id,
            pageNotifications
          )
        : false;
      const ret = resp ? resp.notifications : false;

      if (!isMounted) return;

      if (Array.isArray(ret)) {
        let existSomeNewStageDup = false;
        let newSectorName;
        const asyncMapping = ret.map(async (notif) => {
          const data = JSON.parse(notif.data);
          if (data?.wasNotified === false) {
            if (
              notif.module === 'stage' &&
              notif.type_notification === 'duplicate_sector' &&
              data.wasNotified === false
            ) {
              existSomeNewStageDup = true;
              newSectorName = data.sector;
              data.wasNotified = true;
              notif.data = JSON.stringify(data);
              await notificationService.update(notif);
            }
          }
        });
        await Promise.all(asyncMapping);

        if (isMounted) {
          setNotifications(ret);
          setPageNotifications(resp.page);
          setAllRowsNotifications(resp.rows);
          setNotificationsNoRead(resp.noread);
        }
      }
    };

    loadNotifications();

    return () => {
      isMounted = false;
    };
  }, [
    update?.update,
    pathname,
    projectState?.projectSelected,
    userFromLocalStorage?.companyId
  ]);

  const { role, name, lastname } = userFromLocalStorage || {};
  useEffect(() => {
    const company = getCompaniesLocal();
    setCurrentCompany(company);
  }, []);

  useEffect(() => {
    loadSectors();
  }, [selectedProject]);

  useEffect(() => {
    if (!autoSetSchedule) {
      return;
    }

    if (!projectState.allSectors.some((el) => el.id === selectedSector)) {
      if (projectState.allSectors[0]) {
        const selectedSector = sectorToSelectInToolbar(
          projectState?.allSectors
        );
        changeSector(selectedSector);
      }
    }
  }, [projectState.allSectors]);

  useEffect(() => {
    if (projectState.props.changeAvatar) {
      callUrlAvatar();
    }
  }, [projectState.props.changeAvatar]);

  useEffect(() => {
    setCurrentSectors(projectState.allSectors);
  }, [selectedProjectSectors]);

  const calculateNotificationsNoRad = () => {
    const noRead = notifications.reduce((ac, cu) => {
      /** if current notification isnt read, then count variable noRead */
      if (!cu.is_read && cu.is_visible) ac += 1;
      return ac;
    }, 0);
    setNotificationsNoRead(noRead);
  };

  const handleVisibleChange = (flag) => {
    setStateDropdownNotification({ visible: flag });
  };

  /** calculate notifications no read */
  // useEffect(() => {
  //     calculateNotificationsNoRad()
  // }, [notifications])

  useEffect(() => {
    if (currentSectors && currentSectors[0]) {
      const sectorExists = currentSectors.some(
        (el) => el.id === projectState.sectorSelected
      );
      if (!sectorExists) {
        dispatch(projectActions.setSector(null));
      } else {
        changeSector(projectState.sectorSelected);
      }
    }
  }, [currentSectors]);

  /** clean calendar state */
  useEffect(() => {
    dispatch(calendarActions.setCalendar(null));
    dispatch(calendarActions.setCalendarForm(null));
  }, [projectState.projectSelected, projectState.sectorSelected]);

  const updateCacheIndicatorsForProject = (dataAnalitycs, indicators) => {
    /** update progress indicator */
    const { progressLabel } = indicators.indicatorProject;

    /** update ppc indicator */
    const { ppcLabel } = indicators.indicatorPpcProject;

    /** update pcr indicator */
    const { pcrLabel } = indicators.indicatorPcrProject;

    /** update indicator project */
    if (dataAnalitycs) {
      addToLocalStorage('projectsPpc', 'ppc', ppcLabel, dataAnalitycs.projects);
      addToLocalStorage(
        'projectsProgress',
        'progress',
        progressLabel,
        dataAnalitycs.projects
      );
      addToLocalStorage('projectsPcr', 'pcr', pcrLabel, dataAnalitycs.projects);
    }
  };

  useEffect(() => {
    if (selectedProject === null) {
      dispatch(projectActions.setSector(null));
    }
  }, [selectedProject]);

  const callUrlAvatar = async () => {
    setLogoUser(null);
    await timeout(3500);
    let user = JSON.parse(localStorage.getItem('user'));
    setLogoUser(user.image !== undefined ? user.image : null);
  };

  const timeout = (delay) =>
    new Promise((resolve) => setTimeout(resolve, delay));

  const getSectorsByProject = async (idProject) => {
    try {
      const resp = await sectorService.getSectorsByProject(idProject);
      const sectors = resp ? resp.sectors : [];
      const filterSectors = sectors.filter((e) => e.status === true);
      dispatch(projectActions.setAllSectors(filterSectors));
    } catch (error) {
      console.error('Error getting sectors by project:', error);
    }
  };

  const getSectorsByProjectV2 = async (idProject) => {
    try {
      const resp = await sectorService.getSectorsByProject(idProject);
      const sectors = resp ? resp.sectors : [];
      const filterSectors = sectors.filter((e) => e.status === true);
      dispatch(projectActions.setAllSectors(filterSectors));

      return filterSectors;
    } catch (error) {
      console.error('Error getting sectors by project:', error);
    }
  };

  const loadSectors = useCallback(async () => {
    try {
      const sectors = await getSectorsByProject(selectedProject);
      if (sectors) {
        dispatch(
          projectActions.setAllSectors(sectors.filter((e) => e.status === true))
        );
      }
    } catch (error) {
      console.error('Error loading sectors:', error);
    }
  }, [selectedProject, getSectorsByProject]);

  const loadNotifications = async (pagination = false) => {
    if (pagination) {
      await setPageNotifications(0);
      await setAllRowsNotifications(0);
    }
    let user = JSON.parse(localStorage.getItem('user'));
    const resp = user
      ? await notificationService.getByUser(user.id, pageNotifications)
      : false;
    const ret = resp ? resp.notifications : false;

    if (Array.isArray(ret)) {
      let existSomeNewStageDup = false;
      let newSectorName;
      const asyncMapping = ret.map(async (notif) => {
        const data = JSON.parse(notif.data);
        if (data?.wasNotified === false) {
          if (
            notif.module === 'stage' &&
            notif.type_notification === 'duplicate_sector' &&
            data.wasNotified === false
          ) {
            existSomeNewStageDup = true;
            newSectorName = data.sector;
            data.wasNotified = true;
            notif.data = JSON.stringify(data);
            await notificationService.update(notif);
          }
        }
      });
      await Promise.all(asyncMapping);
      let description = t('notifications.duplicate_sector_success_msg').replace(
        '__str__',
        newSectorName
      );
      if (existSomeNewStageDup && !sectorViewNotification) {
        notifyMessageCustom({
          type: getTypeNotification(EditPartyComp, 'withTitleIcon'),
          title: t('notifications.duplicate_sector_finished'),
          description
        });
        loadSectors();
      }
    }

    setNotifications(ret);
    setPageNotifications(resp.page);
    setAllRowsNotifications(resp.rows);
    setNotificationsNoRead(resp.noread);
  };

  let rightSideStyle = {
    paddingRight: '12px',
    paddingLeft: '12px',
    left: '-30px'
  };
  if (width > 1600) {
    rightSideStyle.left = '-' + Math.round(width * 0.015 + 20) + 'px';
  } else if (width < 1300) {
    rightSideStyle.left = '-' + Math.round(width * 0.07) + 'px';
    rightSideStyle.paddingRight = '0px';
    rightSideStyle.paddingLeft = '0px';
  }

  /**
   * This function receives an action wish must be stopped if there is unsaved elements in the masterplan, if user accepts, then the wishaction is fired as a callback function
   * @param {*} wishAction Normal JS function which represents the action stopped to check if there is unsaved elements
   * @returns null avoiding function being fired
   */
  const validateMPMovementsAlert = (wishAction) => {
    const forceReturn = callback && callback(wishAction);
    if (forceReturn) return;
    wishAction();
  };

  // redirects
  const logout = (e) => {
    const wishAction = (_) => {
      e.preventDefault();
      dispatch(stateActions.clearAllStoreData());
      localStorage.clear();
      sessionStorage.clear();
      resetPropertiesAnalytics();
      history.push('/login');
    };
    validateMPMovementsAlert(wishAction);
  };

  const goToHome = (e) => {
    const wishAction = (_) => {
      e.preventDefault();
      removeProjectAndSector();
      history.push('/projects');
    };
    validateMPMovementsAlert(wishAction);
  };

  const goToSettings = (e) => {
    const wishAction = (_) => {
      e.preventDefault();
      history.push('/settings/user');
    };
    validateMPMovementsAlert(wishAction);
  };

  const goToIntegration = (e) => {
    e.preventDefault();
    history.push('/settings/plugins');
  };

  const changeProjectandSector = async (projectId, sectorId = null) => {
    const wishAction = async (_) => {
      const project = projectState.allProjects.find((p) => p.id === projectId);

      if (!project) {
        return;
      }
      setCurrentProject(project);
      dispatch(projectActions.setProject(projectId));
      const sectors = await getSectorsByProject(projectId);

      setTimeout(() => {
        if (sectors) {
          let sectorToSelect = sectorToSelectInToolbar(sectors);
          if (sectorId) {
            sectorToSelect = sectorId;
          }

          const sector = sectors.find((sector) => sector.id === sectorToSelect);

          if (!sectors) {
            return;
          }

          setCurrentSector(sector);
          projectState.sectorSelected = sectorToSelect;
          dispatch(projectActions.setAllSectors(sectors));
          dispatch(projectActions.setSector(sectorToSelect));
          setCurrentSectors(sectors);
        }
        dispatch(
          projectActions.setProps({
            ...projectState.props,
            loadSectors: !projectState.props.loadSectors
          })
        );
        EventEmitter.emit('HIDE_PLANIFICATION');
        history.push('/masterplan');
      }, 500);
    };
    validateMPMovementsAlert(wishAction);
  };

  const changeProjectandSectorV2 = async (projectId, sectorId = null) => {
    const wishAction = async (_) => {
      /** Redux event emitting function to set selected project from project actions */
      dispatch(projectActions.setProject(projectId));
      const sectors = await getSectorsByProjectV2(projectId);

      if (!sectors.some((sector) => sector.id === sectorId)) {
        notifyMessageCustom({
          type: getTypeNotification(
            <DangerousNotificationIcon color="#e50101" />,
            'withTitleIcon'
          ),
          title: t('notifications.mr_deleted')
        });

        return;
      }

      setTimeout(() => {
        if (sectors) {
          let sectorToSelect = sectorToSelectInToolbar(sectors);
          if (sectorId) {
            sectorToSelect = sectorId;
          }
          projectState.sectorSelected = sectorToSelect;
          dispatch(projectActions.setAllSectors(sectors));
          dispatch(projectActions.setSector(sectorToSelect));
          setCurrentSectors(sectors);
        }
        dispatch(
          projectActions.setProps({
            ...projectState.props,
            loadSectors: !projectState.props.loadSectors
          })
        );
        EventEmitter.emit('HIDE_PLANIFICATION');
        history.push('/masterplan');
      }, 500);
    };
    validateMPMovementsAlert(wishAction);
  };

  const sectorToSelectInToolbar = (sectors) => {
    return sectors.find((sector) => sector?.set_current === true)?.id;
  };
  const changeProject = async (e) => {
    const wishAction = async (_) => {
      const project = projectState.allProjects.find((p) => p.id === e);
      setCurrentProject(project);
      dispatch(projectActions.setProject(e));
      const sectors = await getSectorsByProject(e);

      if (sectors && sectors[0]) {
        const sector = sectors.find((sector) => sector?.set_current === true);
        setCurrentSector(sector);
        dispatch(projectActions.setAllSectors(sectors));
        const sectorToSelect = sectorToSelectInToolbar(sectors);
        dispatch(projectActions.setSector(sectorToSelect));
      }
      dispatch(
        projectActions.setProps({
          ...projectState.props,
          loadSectors: !projectState.props.loadSectors
        })
      );
      EventEmitter.emit('HIDE_PLANIFICATION');
    };
    validateMPMovementsAlert(wishAction);

    handleClosePopoverProject();
  };

  const [drawer, setDrawer] = useState({ visible: false });
  const openHelpCenter = () => {
    const url = window.location.href;
    let module;
    if (url.includes('ganttchart')) {
      module = 'ganttchart';
    } else if (url.includes('resources') && url.includes('gantt')) {
      module = 'gantt_resources';
    } else if (url.includes('lookahead') && url.includes('planification')) {
      module = 'lookahead_planification';
    } else if (url.includes('lookahead') && url.includes('resources')) {
      module = 'lookahead_resources';
    } else if (url.includes('lookahead') && url.includes('constraints')) {
      module = 'lookahead_constraints';
    } else if (url.includes('weeklyplan') && url.includes('planning')) {
      module = 'weekly_planning';
    } else if (url.includes('weeklyplan') && url.includes('commintments')) {
      module = 'weekly_planning';
    } else if (url.includes('weeklyplan') && url.includes('cncs')) {
      module = 'weekly_cncs';
    } else if (url.includes('analytics')) {
      module = 'analytics';
    } else if (url.includes('settings/user')) {
      module = 'settings_user';
    } else if (url.includes('settings/project')) {
      module = 'settings_project';
    } else if (url.includes('settings/company')) {
      module = 'settings_company';
    } else if (url.includes('projects')) {
      module = 'projects_home';
    } else if (url.includes('tutorials')) {
      module = 'projects_home';
    } else if (url.includes('taktplanning') && url.includes('units')) {
      module = 'takt_units';
    } else if (url.includes('taktplanning') && url.includes('assign')) {
      module = 'takt_linking';
    }

    if (module) {
      setDrawer({ visible: true, data: { module } });
    }
  };

  const changeSector = (e, onToolbar = false) => {
    const wishAction = async () => {
      dispatch(projectActions.setSector(e));
      EventEmitter.emit('HIDE_PLANIFICATION');
    };

    if (!autoSetSchedule) {
      wishAction();
      history.push('/masterplan');
      return;
    }

    if (onToolbar) {
      return validateMPMovementsAlert(wishAction);
    }
    wishAction();
  };

  const menu = (
    <Menu>
      {accessConfig ? (
        <Menu.Item>
          <div onClick={goToSettings}>{t('master_plan.config_label')}</div>
        </Menu.Item>
      ) : (
        <Menu.Item>
          <div onClick={goToIntegration}>{t('master_plan.config_label')}</div>
        </Menu.Item>
      )}

      <Menu.Divider />
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={logout}>
          {t('singout_label')}
        </a>
      </Menu.Item>
    </Menu>
  );

  const handleMarkAsRead = (notification) => {
    /** update no reads */
    if (
      !projectSelected &&
      notification.type_notification !== 'report_analytics' &&
      notification.type_notification !== 'mrequest'
    ) {
      message.info(
        t('lang') === 'es'
          ? 'Debes seleccionar un proyecto primero para acceder al link'
          : 'You must select a project first to access the link'
      );
      return;
    }
    if (!notification.is_read) {
      setNotificationsNoRead(parseInt(notificationsNoRead) - 1);
    }

    /** update notification */
    const newNotification = {
      ...notification,
      is_read: true
    };
    notificationService.update(newNotification);

    /** update notifications state */
    const newNotifications = notifications;
    const findIndex = newNotifications.findIndex(
      (el) => el.id === notification.id
    );
    newNotifications[findIndex] = newNotification;
    setNotifications(newNotifications);
  };

  const handleMarkAllAsRead = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const resp = await notificationService.readAll(user.id);

    setNotificationsNoRead(0);
    if (resp.notifications && resp.notifications.length) {
      setNotifications(resp.notifications);
    }
  };

  const handleOnClickNotification = (e, el) => {
    const dataJson = JSON.parse(el.data);
    if (dataJson.type_notification === 'duplicate_sector') {
      const projectId = dataJson.target_project_id;
      const sectorId = dataJson.newSector;
      if (projectId && sectorId) {
        changeProjectandSector(projectId, sectorId);
      }
    }

    if (el.type_notification === 'mrequest') {
      const projectId = el.projectId;
      const sectorId = el.sectorId;
      if (projectId && sectorId) {
        changeProjectandSectorV2(projectId, sectorId);
      }
    }
  };

  const menuNotifications = (
    <Menu
      className="menu__notifications"
      style={{
        maxHeight: parseInt(window.innerHeight) - 100,
        position: 'relative',
        left: 70,
        top: 18
      }}>
      {parseInt(notificationsNoRead) > 0 ? (
        <Menu.Item>
          <div
            className="notification__row"
            onClick={() => handleMarkAllAsRead()}>
            <div className="notification-more">
              {t('lang') === 'es' ? 'Leer todas' : 'Read all'}
            </div>
          </div>
        </Menu.Item>
      ) : null}
      {notifications.length ? (
        notifications.map((el, index) => {
          const data = JSON.parse(el.data);
          const ids_params =
            el.module === 'constraints' || el.module === 'procore'
              ? data.constraints.join('-')
              : null;
          const allowed_modules_notif = [
            'constraints',
            'tasks',
            'procore',
            'weekcommit',
            'closeweek',
            'mrequest',
            'closecnc',
            'stage'
          ];
          const title = allowed_modules_notif.includes(el.module)
            ? `${data.project} - ${data.sector}`
            : 'Outbuild';
          /** format create data */
          let datetoShow = moment(el.createdAt)
            .format('DD MMM YY')
            .replace(/\./g, '');
          let datetoShowHours = moment(el.createdAt)
            .format('DD MMM YY HH:mm')
            .replace(/\./g, '');
          /** title case to create data */
          datetoShow = datetoShow
            .split(' ')
            .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
            .join(' ');
          datetoShowHours = datetoShowHours
            .split(' ')
            .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
            .join(' ');

          if (data.link && el.module === 'mrequest') {
            data.link = `${window.location.origin}/${data.link.split('/').slice(3).join('/')}`;
          }

          const getLinkNotif = () =>
            !projectSelected && el.type_notification !== 'report_analytics'
              ? null
              : el.module === 'constraints' || el.module === 'procore'
                ? `/lookahead/constraints/${el.projectId}/${el.sectorId}/${ids_params}`
                : el.module === 'tasks'
                  ? `/lookahead/planification/${el.projectId}/${el.sectorId}`
                  : data.link;

          return (
            <Menu.Item key={index} className={!el.is_read ? 'no-read' : 'read'}>
              <a
                target="_blank"
                href={el.module == 'mrequest' ? null : getLinkNotif()}
                onClick={(e) => handleOnClickNotification(e, el)}>
                <div
                  onClick={() => handleMarkAsRead(el)}
                  className="notification__row">
                  <div className="notification-icon">
                    {el.type_notification === 'report_analytics' && (
                      <DownloadOutlined className="constraint-icon" />
                    )}
                    {el.type_notification === 'expire' && (
                      <WarningOutlined className="constraint-icon" />
                    )}
                    {(el.type_notification === 'assign_responsible' ||
                      el.type_notification === 'unassign_responsible' ||
                      el.type_notification === 'assign_report' ||
                      el.type_notification === 'mrequest') && (
                      <img
                        src={data.user.image ? data.user.image : userIcon}
                        className="rounded-img-user"
                        height="30px"
                        width="30px"
                      />
                    )}
                    {el.type_notification === 'weekcommit' && (
                      <img src={handshake} height="30px" width="30px" />
                    )}
                    {el.type_notification === 'closeweek' && (
                      <img src={closeweekIcon} height="30px" width="30px" />
                    )}
                    {el.type_notification === 'closecnc' && (
                      <img src={closecncIcon} height="30px" width="30px" />
                    )}
                    {el.type_notification === 'duplicate_sector' && (
                      <DuplicateStageIcon
                        color={PRIMARY}
                        className="duplicate-stage-icon"
                      />
                    )}
                    {el.module === 'procore' && (
                      <img
                        src={ProcoreImg}
                        className="rounded-img-user"
                        height="30px"
                        width="30px"
                      />
                    )}
                  </div>
                  <div className="notification-description">
                    <span className="desc-title">
                      {title.length > 45 ? (
                        <Tooltip placement="left" title={title}>
                          {title.substring(0, 45) + '...'}
                        </Tooltip>
                      ) : (
                        title
                      )}
                    </span>
                    {el.type_notification === 'expire' && (
                      <div className="desc-notif">
                        <span className="have">{t('notifications.have')}</span>
                        <span className="due">
                          {' ' + data.title.length}{' '}
                          {data.title.length > 1
                            ? t('notifications.expires')
                            : t('notifications.expire')}
                        </span>
                      </div>
                    )}
                    {el.type_notification === 'new_constraint' &&
                      el.module === 'procore' && (
                        <div className="desc-notif">
                          <span className="have">
                            {t('notifications.have')}
                          </span>
                          <span className="due">
                            {' ' + data.title.length}{' '}
                            {data.title.length > 1
                              ? t('notifications.new_procore_constraint')
                              : t('notifications.new_procore_constraint')}
                          </span>
                        </div>
                      )}
                    {el.type_notification === 'duplicate_sector' &&
                      el.module === 'stage' && (
                        <div className="desc-notif">
                          <span className="have">
                            {t('notifications.duplicate_sector_success')}
                          </span>
                        </div>
                      )}
                    {(el.type_notification === 'assign_responsible' ||
                      el.type_notification === 'unassign_responsible' ||
                      el.type_notification === 'assign_report' ||
                      el.type_notification === 'mrequest') && (
                      <div className="desc-notif">
                        <span className="due">
                          {data.user.name && data.user.lastname
                            ? data.user.name + ' ' + data.user.lastname
                            : data.user.email}
                        </span>
                        {el.type_notification === 'assign_responsible' &&
                          el.module === 'constraints' && (
                            <span className="have">
                              {' ' + t('notif_responsible')}
                            </span>
                          )}
                        {el.type_notification === 'assign_responsible' &&
                          el.module === 'tasks' && (
                            <span className="have">
                              {' ' + t('notif_task')}
                            </span>
                          )}
                        {el.type_notification === 'unassign_responsible' && (
                          <span className="have">
                            {' ' + t('notif_unresponsible')}
                          </span>
                        )}
                        {el.type_notification === 'assign_report' && (
                          <span className="have">
                            {' ' + t('notif_report')}
                          </span>
                        )}
                        {el.type_notification === 'mrequest' && (
                          <span className="have">
                            {t('lang') === 'es'
                              ? ' ha generado una solicitud de modificación a la actividad'
                              : ' sent a Modification Request for an activity in the Masterplan'}
                          </span>
                        )}
                        <br />
                        <u className="have">
                          {data.title.length > 45 ? (
                            <Tooltip placement="left" title={data.title}>
                              {data.title.substring(0, 45) + '...'}
                            </Tooltip>
                          ) : (
                            data.title
                          )}
                        </u>
                      </div>
                    )}
                    {el.type_notification === 'report_analytics' && (
                      <div className="desc-notif">
                        <span className="have">
                          {t('lang') === 'es'
                            ? 'Tu reporte está listo. Click aquí para descargarlo'
                            : 'Your report is ready. Click here to download it'}
                        </span>
                      </div>
                    )}
                    {el.type_notification === 'weekcommit' && (
                      <div className="desc-notif">
                        <span className="have">
                          {t('lang') === 'es'
                            ? 'Se ha comprometido un nuevo plan semanal'
                            : 'A new weekly plan has been committed'}
                        </span>
                      </div>
                    )}
                    {el.type_notification === 'closeweek' && (
                      <div className="desc-notif">
                        <span className="have">
                          {t('lang') === 'es'
                            ? `Se ha cerrado la semana ${data.week}. Analiza si hay tareas sin cumplir`
                            : `Week ${data.week} has been closed. Check for any incomplete tasks`}
                        </span>
                      </div>
                    )}
                    {el.type_notification === 'closecnc' && (
                      <div className="desc-notif">
                        <span className="have">
                          {t('lang') === 'es' && data.count === 1
                            ? `Tienes ${data.count} compromiso sin cumplir sin CNC`
                            : t('lang') === 'es' && data.count > 1
                              ? `Tienes ${data.count} compromisos sin cumplir sin CNC`
                              : t('lang') !== 'es' && data.count === 1
                                ? `You have ${data.count} unfulfilled commitment without RFV`
                                : `You have ${data.count} unfulfilled commitments without RFV`}
                        </span>
                      </div>
                    )}
                    <span className="date">
                      {el.type_notification === 'expire'
                        ? datetoShow
                        : datetoShowHours}
                    </span>
                  </div>
                </div>
              </a>
            </Menu.Item>
          );
        })
      ) : (
        <Menu.Item>
          <div className="notification__row">
            <div className="notification-nodata">
              {t('notifications.nodata')}
            </div>
          </div>
        </Menu.Item>
      )}
      {notifications.length < allRowsNotifications ? (
        <Menu.Item>
          <div
            className="notification__row"
            onClick={() => loadNotifications()}>
            <div className="notification-more">{t('notifications.more')}</div>
          </div>
        </Menu.Item>
      ) : parseInt(notificationsNoRead) > 0 ? (
        <Menu.Item>
          <div
            className="notification__row"
            onClick={() => handleMarkAllAsRead()}>
            <div className="notification-more">
              {t('lang') === 'es' ? 'Leer todas' : 'Read all'}
            </div>
          </div>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  const closeHelpCenter = () => {
    setDrawer({ visible: false });
  };

  const handleClickRenameStage = (sectorId, sectorName) => {
    dispatch(
      projectActions.setSectorSelectedRename({
        id: sectorId,
        name: sectorName
      })
    );
    setShowModalRename(true);
  };

  const handleClickDuplicateStage = (sectorId, sectorName) => {
    dispatch(
      projectActions.setSectorSelectedRename({
        id: sectorId,
        name: sectorName
      })
    );
    setShowModalDuplicate(true);
  };

  const handleClickDeleteStage = (sectorId, sectorName) => {
    dispatch(
      projectActions.setSectorSelectedRename({
        id: sectorId,
        name: sectorName
      })
    );
    setShowModalDelete(true);
  };

  const handleClickSetAsCurrentStage = (sectorId, sectorName) => {
    dispatch(
      projectActions.setSectorSelectedRename({
        id: sectorId,
        name: sectorName
      })
    );
    setShowModalSetCurrentStage(true);
  };

  const handleClickVisibilitySchedule = (sectorId, sectorName, visible) => {
    dispatch(
      projectActions.setSectorSelectedRename({
        id: sectorId,
        name: sectorName,
        visible: visible
      })
    );
    setShowModalVisibilitySchedule(true);
  };

  const optionsProjectsPopover = () => {
    return projectState.allProjects
      .filter(
        (project) =>
          project.stage !== 'archived' || project.id === selectedProject
      )
      .sort(dynamicSort('name'))
      .map((project) => ({
        key: project.id,
        name: project.name,
        active: selectedProject === project.id,
        icon: null,
        disabled: false,
        onClick: () => changeProject(project.id),
        subitems: [],
        group: []
      }));
  };

  const optionsSchedulesPopover = () => {
    const currentScheduleGroup = () =>
      projectState.allSectors
        .filter(({ set_current }) => set_current)
        .map(({ id, name, set_current, visible }) => ({
          key: id,
          name: name,
          active: selectedSector === id,
          icon: null,
          disabled: false,
          onClick: () => {
            changeSector(id, true);
            handleClosePopoverSchedule();
          },
          subitems: moreOptionsSchedulePopover(id, name, set_current, visible)
        }));

    const otherSchedulesGroup = () => {
      let filteredSectors = projectState.allSectors.filter(
        ({ set_current }) => !set_current
      );

      const haveAccessOnlyVisibleSchedules =
        usersAccessOnlyToVisibleSchedules.includes(role);

      if (haveAccessOnlyVisibleSchedules) {
        filteredSectors = filteredSectors.filter(({ visible }) => visible);
      }

      return filteredSectors.map(({ id, name, set_current, visible }) => ({
        key: id,
        name: name,
        active: selectedSector === id,
        icon: visible ? null : <HideScheduleIcon color={GRAY_1} />,
        disabled: false,
        onClick: () => {
          changeSector(id, true);
          handleClosePopoverSchedule();
        },
        subitems: moreOptionsSchedulePopover(id, name, set_current, visible)
      }));
    };

    const currentSchedule = [
      {
        key: 1,
        name: t('schedule_toolbar_group_options.current_schedule'),
        active: false,
        icon: null,
        disabled: false,
        onClick: null,
        subitems: [],
        group: currentScheduleGroup().sort(dynamicSort('name'))
      }
    ];

    const otherSchedules = [
      {
        key: 2,
        name: t('schedule_toolbar_group_options.other_schedules'),
        active: false,
        icon: null,
        disabled: false,
        onClick: null,
        subitems: [],
        group: otherSchedulesGroup().sort(dynamicSort('name'))
      }
    ];

    return otherSchedulesGroup().length > 0
      ? [...currentSchedule, ...otherSchedules]
      : currentSchedule;
  };

  const moreOptionsSchedulePopover = (
    sectorId,
    sectorName,
    setCurrent,
    visible
  ) => {
    const haveAccessHandlerVisibilityOfSchedules =
      usersAccessHandlerVisibilityOfSchedules.includes(role);

    let haveAccessHandlerSetCurrentSchedules =
      usersAccessHandlerSetCurrentOfSchedules.includes(role);

    let options = [
      {
        key: 'rename',
        name: t('stage_options_popover_rename'),
        active: false,
        icon: <EditStageIcon color={PRIMARY} />,
        disabled: false,
        onClick: () => handleClickRenameStage(sectorId, sectorName)
      },
      {
        key: 'duplicate',
        name: t('stage_options_popover_duplicate'),
        active: false,
        icon: <DuplicateStageIcon color={PRIMARY} />,
        disabled: false,
        onClick: () => handleClickDuplicateStage(sectorId, sectorName)
      }
    ];

    if (haveAccessHandlerVisibilityOfSchedules) {
      options.push({
        key: 'visibility',
        name: visible
          ? t('stage_options_popover_hide')
          : t('stage_options_popover_make_visible'),
        active: false,
        icon: visible ? (
          <HideScheduleIcon color={PRIMARY} />
        ) : (
          <MakeVisibleScheduleIcon color={PRIMARY} />
        ),
        disabled: setCurrent,
        onClick: () =>
          handleClickVisibilitySchedule(sectorId, sectorName, visible),
        informationIcon: setCurrent,
        tooltipIformation: t('visibility_of_schedule.info')
      });
    }

    if (haveAccessHandlerSetCurrentSchedules) {
      options.push(
        {
          key: 'set_as_current',
          name: t('stage_options_popover_set_as_current'),
          active: false,
          icon: <SaveAsTemplateStageIcon color={PRIMARY} />,
          disabled: setCurrent || !visible,
          onClick: () => handleClickSetAsCurrentStage(sectorId, sectorName),
          informationIcon: !visible,
          tooltipIformation: t('set_as_current.info')
        },
        {
          key: 'separation',
          divider: true
        },
        {
          key: 'delete',
          name: t('stage_options_popover_delete'),
          active: false,
          icon: <DeleteStageIcon color={PRIMARY} />,
          informationIcon: setCurrent,
          tooltipIformation: t('delete_stage.info'),
          disabled: setCurrent,
          onClick: () => handleClickDeleteStage(sectorId, sectorName)
        }
      );
    }

    return options;
  };

  const trackingInitSearchPopover = (eventSource) => {
    trackingEvent(
      'search_box_focused',
      {
        event_source: eventSource
      },
      AMPLITUDE_SERVICE
    );
  };

  const trackingCleanSearchPopover = (searchText, eventSource) => {
    trackingEvent(
      'search_box_cleared',
      {
        search_text: searchText,
        event_source: eventSource
      },
      AMPLITUDE_SERVICE
    );
  };

  const trackingUsedSearchPopoverProject = (
    searchText,
    resultsCount = 0,
    itemKey
  ) => {
    trackingEvent(
      'project_selection',
      {
        search_text: searchText,
        event_source: 'header_dropdown_projects',
        results_count: resultsCount,
        project_id: itemKey,
        project_name: projectState.allProjects.find((p) => p.id === itemKey)
          ?.name,
        company_name: currentCompany?.name,
        company_id: currentCompany?.id
      },
      AMPLITUDE_SERVICE
    );
  };

  const trackingUsedSearchPopoverSchedule = (
    searchText,
    resultsCount = 0,
    itemKey
  ) => {
    trackingEvent(
      'schedule_selection',
      {
        search_text: searchText,
        event_source: 'header_dropdown_schedules',
        results_count: resultsCount,
        schedule_name: projectState.allSectors.find(
          (selected) => selected.id === itemKey
        )?.name,
        schedule_id: itemKey,
        company_name: currentCompany?.name,
        company_id: currentCompany?.id
      },
      AMPLITUDE_SERVICE
    );
  };

  const trackingShowEmptyStatePopover = (searchText, eventSource) => {
    trackingEvent(
      'search_box_no_results',
      {
        search_text: searchText,
        event_source: eventSource
      },
      AMPLITUDE_SERVICE
    );
  };

  const handleClosePopoverProject = () => {
    if (popoverProjectRef.current) {
      popoverProjectRef.current.tooltip.state.visible = false;
    }
  };

  const handleClosePopoverSchedule = () => {
    if (popoverScheduleRef.current) {
      popoverScheduleRef.current.tooltip.state.visible = false;
    }
  };

  return (
    <div
      data-testid={TestIds.TOOLBAR_CONTAINER}
      id="toolbar_container"
      className={
        fixedState
          ? 'toolbar-container fixedToolbar toolbar__container'
          : 'toolbar-container toolbar__container'
      }>
      <div className="companyContainer">
        <div className="logoToolbar">
          <a onClick={goToHome}>
            <img src={logo} alt="Logo" className="logo-style" />
          </a>
        </div>
        <div className="selectors">
          {selects?.projects && <CompanyPopover t={t} />}
          <div
            style={{
              display: selects && selects.projects ? 'inline-block' : 'none'
            }}>
            <PopoverUniselectSystem
              itemReference={popoverProjectRef}
              trigger="click"
              width={250}
              countItemsShow={10}
              theme="dark"
              placement="bottomLeft"
              onFocusSearchFunction={() =>
                trackingInitSearchPopover('header_dropdown_projects')
              }
              onCleanSearchFunction={(searchText) =>
                trackingCleanSearchPopover(
                  searchText,
                  'header_dropdown_projects'
                )
              }
              onUsedSearchFunction={(searchText, resultsCount, itemKey) =>
                trackingUsedSearchPopoverProject(
                  searchText,
                  resultsCount,
                  itemKey
                )
              }
              onShowEmptyStateFunction={(searchText) =>
                trackingShowEmptyStatePopover(
                  searchText,
                  'header_dropdown_projects'
                )
              }
              items={optionsProjectsPopover()}>
              <div className="popover-select-project">
                <div className="popover-select-project__label">
                  <TruncatedTextSystem
                    color={'#FFF'}
                    name={selectedProjectLabel}
                    maxWidth={131}
                    placement="bottomLeft">
                    <span>{selectedProjectLabel}</span>
                  </TruncatedTextSystem>
                </div>
                <div className="popover-select-project__icon">
                  <ArrowDropdownIcon color="#FFFFFF" />
                </div>
              </div>
            </PopoverUniselectSystem>
          </div>
          <div
            style={{
              display: selects && selects.stages ? 'inline-block' : 'none'
            }}>
            <PopoverUniselectSystem
              itemReference={popoverScheduleRef}
              trigger="click"
              width={300}
              countItemsShow={10}
              theme="dark"
              placement="bottomLeft"
              onFocusSearchFunction={() =>
                trackingInitSearchPopover('header_dropdown_schedules')
              }
              onCleanSearchFunction={(searchText) =>
                trackingCleanSearchPopover(
                  searchText,
                  'header_dropdown_schedules'
                )
              }
              onUsedSearchFunction={(searchText, resultsCount, itemKey) =>
                trackingUsedSearchPopoverSchedule(
                  searchText,
                  resultsCount,
                  itemKey
                )
              }
              onShowEmptyStateFunction={(searchText) =>
                trackingShowEmptyStatePopover(
                  searchText,
                  'header_dropdown_schedules'
                )
              }
              items={optionsSchedulesPopover()}>
              <div className="popover-select-schedule">
                <div className="popover-select-schedule__label">
                  <TruncatedTextSystem
                    color={'#FFF'}
                    name={selectedSectorLabel}
                    maxWidth={131}
                    placement="bottomLeft">
                    <span>{selectedSectorLabel}</span>
                  </TruncatedTextSystem>
                </div>
                <div className="popover-select-schedule__icon">
                  <ArrowDropdownIcon color="#FFFFFF" />
                </div>
              </div>
            </PopoverUniselectSystem>
          </div>
        </div>
      </div>

      <div className="toolbar__account">
        <span className="titles">
          <span className="user__name">
            {greetUser ? 'Hello' : ''} {loggedUser?.name ? loggedUser.name : ''}{' '}
            {loggedUser?.lastname ? loggedUser.lastname : ''}
          </span>
          <span className="user__role">
            {loggedUser?.role ? t(`positions.${role}`) : ''}{' '}
          </span>
        </span>

        <Dropdown overlay={menu} bodyStyle={{ padding: 0 }}>
          <Avatar
            className="avatar-btn link-account"
            shape="circle"
            size={45}
            icon="user"
            src={logoUser || null}
          />
        </Dropdown>

        <div className="div-notification">
          <Dropdown
            overlay={menuNotifications}
            placement="bottomRight"
            overlayClassName="notification-dropdown"
            arrow
            trigger={['click']}
            onVisibleChange={handleVisibleChange}
            visible={stateDropdownNotification.visible}>
            <Tooltip title={t('notifications.title')}>
              <Badge
                count={notificationsNoRead}
                size="small"
                className="notification-count">
                <Button className="notification-btn" size="small">
                  <Icon type="bell" theme="filled" />
                </Button>
              </Badge>
            </Tooltip>
          </Dropdown>
        </div>

        <Tooltip title={t('help_center_tooltip')}>
          <span className="help-center-icon" onClick={() => openHelpCenter()}>
            {/* <img src={helpCenterIcon} width={35} /> */}
            <Button className="notification-btn" size="small">
              <Icon type="question-circle" theme="filled" />
            </Button>
          </span>
        </Tooltip>

        <span className="help-center">
          <Drawer
            width={350}
            className="help-center"
            placement="right"
            closable={false}
            onClose={closeHelpCenter}
            visible={drawer.visible}>
            <HelpCenter
              height={height}
              currentUser={JSON.parse(localStorage.getItem('user'))}
              drawer={drawer}
              onClose={closeHelpCenter}
              t={t}
            />
          </Drawer>
        </span>
      </div>
      {jsx}

      <RenameStage show={showModalRename} setShow={setShowModalRename} t={t} />
      <DuplicateStage
        show={showModalDuplicate}
        setShow={setShowModalDuplicate}
        t={t}
      />
      <SetVisibilityOfSchedule
        show={showModalVisibilitySchedule}
        setShow={setShowModalVisibilitySchedule}
        t={t}
      />
      <SetAsCurrentStage
        show={showModalSetCurrentStage}
        setShow={setShowModalSetCurrentStage}
        t={t}
      />
      <DeleteStage show={showModalDelete} setShow={setShowModalDelete} t={t} />
    </div>
  );
}

export default withTranslation()(withRouter(Toolbar));
