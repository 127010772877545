import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { activityService } from '../../../services/activity.service';
import { calendarService } from '../../../services/calendar.service';
import { projectService } from '../../../services/project.service';
import { subContractService } from '../../../services/subcontract.service';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  PDFViewer
} from '@react-pdf/renderer';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

/** Function to clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';
import priority_normal from '../../../assets/img/PR-Blue-50x50.png';
import priority_baja from '../../../assets/img/PR-Gray-50x50.png';
import priority_alta from '../../../assets/img/PR-Yellow-50x50.png';
import priority_maxima from '../../../assets/img/PR-Red-50x50.png';
import logo from '../../../assets/img/iso.png';
import child_arrow from '../../../assets/img/child-arrow.png';
import { ganttAPI } from '../../../utils/customGanttPlugin';
import { getCompanyTextColor } from '../../../utils';
import { styles } from './style.js';
import DownloadReportContainer from '../DownloadReportContainer/DownloadReportContainer.js';
import { getCurrentCompany } from '../../../utils/userUtils.js';

function WeekReport(props) {
  const params = useParams();
  const { t } = props;
  const week_state = props.week;
  const start_date_url = props.start;
  const planificationDay = props.planificationday;
  const year_init = props.year;
  const sectorId = props.sectorId;
  const projectId = props.projectId;
  const projectState = useSelector((state) => state.projectState);
  const [activities, setActivities] = useState([]);
  const [pages, setPages] = useState([]);
  const [resource, setResource] = useState([]);
  const [project, setProject] = useState(null);
  const [sector, setSector] = useState(null);
  const [subContract, setSubContract] = useState([]);
  const [commitmentstate, setCommitmentstate] = useState(
    t('exportable.Tentative')
  );
  const [data, setData] = useState([]);
  const activity_temp = {};
  // cambie a rango con dia de plafinicacion
  // const [init_date , setInit_date] = useState(null);
  // const [end_date , setEnd_date] = useState(null);
  const init_date = moment(start_date_url);
  const end_date = init_date.clone().add(6, 'day');
  const [loadpdf, setLoadpdf] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const currentCompany = getCurrentCompany();
  let pagina = 0;
  const EMPTY_PLAN_ENDOWMENT = 0;

  const loadCalendars = (calendars) => {
    /** This method load calendars to Gantt API and also creates a custom version to use custom calculate duration */
    const customVersionCalendars = ganttAPI.loadCalendars(calendars);
  };

  useEffect(() => {
    if (calendars.length) {
      loadCalendars(calendars);
    }
  }, [calendars]);

  const head = [
    [
      'ID',
      'Name',
      'Country',
      'Country',
      'Country',
      'Country',
      'Country',
      'Country',
      'Country',
      'Country'
    ]
  ];
  const body = [
    [1, 'Shaw', 'Tanzania'],
    [2, 'Nelson', 'Kazakhstan'],
    [3, 'Garcia', 'Madagascar']
  ];

  const headerData = [
    {
      title: 'exportable.Tasks',
      styleView: styles.table_header_texto,
      styleText: styles.table_header_title_column_name
    },
    { title: 'exportable.Priority' },
    { title: 'exportable.Responsible' },
    { title: 'exportable.Subtrade' },
    { title: 'exportable.Progress' },
    { title: 'exportable.Commitment' }
  ];

  const daysOfWeek = Array.from({ length: 7 }, (_, i) => i);

  const weekHeaderPerDay = daysOfWeek.map((day) => (
    <View style={styles.table_header_days}>
      <Text style={styles.table_header_title_column}>
        {init_date
          .clone()
          .locale(t('lang'))
          .add('days', day)
          .format('dd DD')
          .replace(/^./, (match) => match.toUpperCase())}
      </Text>
    </View>
  ));

  const weekRowPerDay = (task, activity, committedColor, unCommittedColor) => {
    return daysOfWeek.map((day) => (
      <View
        key={day}
        style={
          validateDay(task, activity, day)
            ? [styles.table_task_days, committedColor]
            : [styles.table_task_days, unCommittedColor]
        }>
        <Text
          style={[
            styles.table_task_title_name,
            { color: getCompanyTextColor(committedColor.backgroundColor) }
          ]}>
          {validateDay(task, activity, day)
            ? task.plan_endowment > EMPTY_PLAN_ENDOWMENT && task.plan_endowment
            : ''}
        </Text>
      </View>
    ));
  };

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf'
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf'
  });

  useEffect(() => {
    /* let projectpre = await projectService.show(projectState.projectSelected);
        if(projectpre.project.planification_day == 0){
            //setPlanificationDay(-1)
            //init_date = moment().lang("en").year("2020").week(week_state).startOf('isoweek').add('days', (-1));
            /end_date = init_date.clone().add(6, 'day');
        }else{
            setPlanificationDay(projectpre.project.planification_day -1)
            init_date = moment().lang("en").year("2020").week(week_state).startOf('isoweek').add('days', (projectpre.project.planification_day -1));
            end_date = init_date.clone().add(6, 'day');
        } */

    setTimeout(() => {
      getData();
    }, 1000);
  }, []);

  async function getData() {
    const sector_data = sectorId || projectState.sectorSelected;
    const project_data = projectId || projectState.projectSelected;
    const config = {
      end: end_date.format('YYYY/MM/DD'),
      ignore_dates: true,
      sector_id: sector_data,
      start: init_date.format('YYYY/MM/DD'),
      week: parseInt(week_state, 10),
      year: parseInt(year_init, 10)
    };
    const data = await activityService.getLookaheadweekcommitWeek(config);

    const calendars = await calendarService.showBySector(sector_data);
    const project = await projectService.show(project_data);
    const sectorFind = projectState.allSectors.find((e) => e.id == sector_data);
    const subcontractsGet = await subContractService.getByProject(project_data);
    setSubContract(subcontractsGet.subcontracts);
    setCalendars(calendars.calendar);
    setProject(project.project);
    setSector(sectorFind);
    const activitys_array = [];
    const activitys = [];
    let task_total = 0;

    data.activities
      .sort((a, b) => parseInt(a.correlative_id) - parseInt(b.correlative_id))
      .map((atis) => {
        let validate_push = false;
        const temp_actis = { ...atis };
        // let activity = atis;
        const tasks = [];
        // let newArray = atis.tasks.filter(a => a.lean_status == "Will" || a.lean_status == "Committed");
        const calendar = ganttAPI.getTaskCalendar(atis.calendarId);
        atis.tasks.map((task) => {
          const task_start_date = task.taskcommitments[0].start_date
            ? task.taskcommitments[0].start_date
            : task.start_date;
          const task_end_date = task.taskcommitments[0].end_date
            ? task.taskcommitments[0].end_date
            : task.end_date;

          // inicio >= init day
          if (task.taskcommitments.length > 0) {
            task.start_date = task_start_date;
            task.end_date = task_end_date;
          }
          if (task.taskcommitments.length > 0) {
            task_total++;
            // init_date = moment().lang("en").year("2020").week(week_state).startOf('isoweek').add('days', (planificationDay));
            // end_date =  init_date.clone().add(6, 'day')

            const init_date_v = moment(start_date_url);
            // let end_date_v =  init_date_v.clone().add(6, 'day')
            const start_validate = init_date.isBetween(
              moment(task_start_date),
              moment(task_end_date),
              'days',
              false
            );
            const end_validate = end_date.isBetween(
              moment(task_start_date),
              moment(task.taskcommitments[0].end_date),
              'days',
              false
            );

            let validate_week_range_count = 0;
            let validate_week_range = false;
            while (validate_week_range_count < 7) {
              if (!validate_week_range) {
                // init_date_v.add('days', (validate_week_range_count)
                validate_week_range = init_date_v
                  .clone()
                  .add('days', validate_week_range_count)
                  .isBetween(
                    moment(task_start_date).subtract(1, 'd'),
                    moment(task.taskcommitments[0].end_date).add(1, 'd'),
                    'days',
                    false
                  );
              }
              validate_week_range_count++;
            }

            let validate_week_calendar_count = 1;
            let validate_week_calendar = false;
            while (validate_week_calendar_count < 8) {
              const copyOfDate = new Date(
                init_date
                  .clone()
                  .add('days', validate_week_calendar_count)
                  .format('YYYY/MM/DD H:mm')
              );

              // Funcion mal utilizada deberia ser {date, unit: 'day'}
              if (
                calendar.isWorkTime({
                  date: copyOfDate,
                  unit: 'day'
                })
              ) {
                validate_week_calendar = true;
              }
              validate_week_calendar_count++;
            }

            validate_week_calendar = true;

            if (validate_week_range && validate_week_calendar) {
              validate_push = true;
              tasks.push(task);
            }
          }
          if (task.lean_status == 'Committed') {
            const status = t('exportable.Committed');
            setCommitmentstate(status);
          }
        });
        temp_actis.tasks = tasks;
        if (validate_push) {
          activitys.push(temp_actis);
        }
      });
    const array_paginator = [];
    let paginator = 0;
    let count_activities = 0;
    let count_tasks = 0;
    let total = 0;
    let page_paginator = [];
    let activitiesAndTasksHeight = 0;
    const exportableHeight = 650;
    function lastTaskAllActivities(a, b) {
      if (a.length != b.length) {
        return false;
      }
      let result = false;

      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false;
        }
        result = true;
      }
      return result;
    }
    function taskEvaluation(task, atis, i, j) {
      const nActivities = activitys.length - 1;
      const nTasks = atis.tasks.length - 1;
      if (task.children.length > 0) {
        paginator += task.children.length;
        const nChildrenTask = task.children.length - 1;
        task.children.map((subtask, k) => {
          count_tasks += 1;
          subtask.level_subtask = 1;
          page_paginator.push(subtask);
          activitiesAndTasksHeight = count_tasks * 22 + count_activities * 27;
          if (activitiesAndTasksHeight > exportableHeight) {
            paginator = 0;
            activitiesAndTasksHeight = 0;
            count_activities = 0;
            count_tasks = 0;
            array_paginator.push(page_paginator);
            page_paginator = [];
          }
          if (subtask.children.length > 0) {
            paginator += task.children.length;
            const nChildrenSubtask = subtask.children.length - 1;
            subtask.children.map((subtask, l) => {
              count_tasks += 1;
              subtask.level_subtask = 2;
              page_paginator.push(subtask);
              activitiesAndTasksHeight =
                count_tasks * 22 + count_activities * 27;
              if (activitiesAndTasksHeight > exportableHeight) {
                paginator = 0;
                activitiesAndTasksHeight = 0;
                count_activities = 0;
                count_tasks = 0;
                array_paginator.push(page_paginator);
                page_paginator = [];
              }
              if (subtask.children.length > 0) {
                paginator += task.children.length;
                const nChildrenSubtask2 = subtask.children.length - 1;
                subtask.children.map((subtask, m) => {
                  count_tasks += 1;
                  subtask.level_subtask = 3;
                  page_paginator.push(subtask);
                  activitiesAndTasksHeight =
                    count_tasks * 22 + count_activities * 27;
                  if (activitiesAndTasksHeight > exportableHeight) {
                    paginator = 0;
                    activitiesAndTasksHeight = 0;
                    count_activities = 0;
                    count_tasks = 0;
                    array_paginator.push(page_paginator);
                    page_paginator = [];
                  }
                  if (subtask.children.length > 0) {
                    paginator += task.children.length;
                    const nChildrenSubtask3 = subtask.children.length - 1;
                    subtask.children.map((subtask, n) => {
                      count_tasks += 1;
                      subtask.level_subtask = 4;
                      page_paginator.push(subtask);
                      activitiesAndTasksHeight =
                        count_tasks * 22 + count_activities * 27;
                      if (activitiesAndTasksHeight > exportableHeight) {
                        paginator = 0;
                        activitiesAndTasksHeight = 0;
                        count_activities = 0;
                        count_tasks = 0;
                        array_paginator.push(page_paginator);
                        page_paginator = [];
                      }
                      if (subtask.children.length > 0) {
                        paginator += task.children.length;
                        const nChildrenSubtask4 = subtask.children.length - 1;
                        subtask.children.map((subtask, o) => {
                          count_tasks += 1;
                          subtask.level_subtask = 5;
                          page_paginator.push(subtask);
                          activitiesAndTasksHeight =
                            count_tasks * 22 + count_activities * 27;
                          if (activitiesAndTasksHeight > exportableHeight) {
                            paginator = 0;
                            activitiesAndTasksHeight = 0;
                            count_activities = 0;
                            count_tasks = 0;
                            array_paginator.push(page_paginator);
                            page_paginator = [];
                          } else if (
                            lastTaskAllActivities(
                              [i, j, k, l, m, n, o],
                              [
                                nActivities,
                                nTasks,
                                nChildrenTask,
                                nChildrenSubtask,
                                nChildrenSubtask2,
                                nChildrenSubtask3,
                                nChildrenSubtask4
                              ]
                            )
                          ) {
                            paginator = 0;
                            array_paginator.push(page_paginator);
                            page_paginator = [];
                          }
                        });
                      } else if (
                        lastTaskAllActivities(
                          [i, j, k, l, m, n],
                          [
                            nActivities,
                            nTasks,
                            nChildrenTask,
                            nChildrenSubtask,
                            nChildrenSubtask2,
                            nChildrenSubtask3
                          ]
                        )
                      ) {
                        paginator = 0;
                        array_paginator.push(page_paginator);
                        page_paginator = [];
                      }
                    });
                  } else if (
                    lastTaskAllActivities(
                      [i, j, k, l, m],
                      [
                        nActivities,
                        nTasks,
                        nChildrenTask,
                        nChildrenSubtask,
                        nChildrenSubtask2
                      ]
                    )
                  ) {
                    paginator = 0;
                    array_paginator.push(page_paginator);
                    page_paginator = [];
                  }
                });
              } else if (
                lastTaskAllActivities(
                  [i, j, k, l],
                  [nActivities, nTasks, nChildrenTask, nChildrenSubtask]
                )
              ) {
                paginator = 0;
                array_paginator.push(page_paginator);
                page_paginator = [];
              }
            });
          } else if (
            lastTaskAllActivities(
              [i, j, k],
              [nActivities, nTasks, nChildrenTask]
            )
          ) {
            paginator = 0;
            array_paginator.push(page_paginator);
            page_paginator = [];
          }
        });
      } else {
        if (activitiesAndTasksHeight > exportableHeight) {
          paginator = 0;
          activitiesAndTasksHeight = 0;
          count_activities = 0;
          count_tasks = 0;
          array_paginator.push(page_paginator);
          page_paginator = [];
        } else if (lastTaskAllActivities([i, j], [nActivities, nTasks])) {
          paginator = 0;
          array_paginator.push(page_paginator);
          page_paginator = [];
        }
      }
    }

    activitys.map((atis, i) => {
      paginator += 1;
      count_activities += 1;
      const nActivities = activitys.length - 1;
      const nTasks = atis.tasks.length - 1;
      page_paginator.push(atis);
      activitiesAndTasksHeight = count_tasks * 22 + count_activities * 27;
      if (activitiesAndTasksHeight > exportableHeight) {
        paginator = 0;
        activitiesAndTasksHeight = 0;
        count_activities = 0;
        count_tasks = 0;
        array_paginator.push(page_paginator);
        page_paginator = [];
        atis.tasks.map((task, j) => {
          count_tasks += 1;
          paginator += 1;
          page_paginator.push(task);
          activitiesAndTasksHeight = count_tasks * 22 + count_activities * 27;
          if (activitiesAndTasksHeight > exportableHeight) {
            paginator = 0;
            activitiesAndTasksHeight = 0;
            count_activities = 0;
            count_tasks = 0;
            array_paginator.push(page_paginator);
            page_paginator = [];
          } else if (lastTaskAllActivities([i, j], [nActivities, nTasks])) {
            paginator = 0;
            array_paginator.push(page_paginator);
            page_paginator = [];
          }
          taskEvaluation(task, atis, i, j);
        });
        total += 1;
      } else {
        atis.tasks.map((task, j) => {
          count_tasks += 1;
          page_paginator.push(task);
          activitiesAndTasksHeight = count_tasks * 22 + count_activities * 27;
          taskEvaluation(task, atis, i, j);
        });
        total += 1;
      }
    });
    setPages(array_paginator);
    activitys_array.push(activitys);
    setActivities(activitys_array);
    setLoadpdf(true);
  }

  if (loadpdf) {
    return (
      <DownloadReportContainer
        t={t}
        pdfDocument={
          <Document>
            {pages.map((page, i) => (
              <Page
                size="letter"
                style={styles.page}
                orientation="landscape"
                key={i}>
                <View style={{ ...styles.section_header }}>
                  <View style={{ flexDirection: 'row' }}>
                    {currentCompany?.image && (
                      <Image
                        style={{ ...styles.image_logo }}
                        src={{
                          uri: currentCompany.image,
                          method: 'GET',
                          headers: { 'Cache-Control': 'no-cache' },
                          body: ''
                        }}
                        allowDangerousPaths={true}
                      />
                    )}
                    <View style={{ ...styles.section_text_header }}>
                      <Text style={styles.title}>
                        {t('exportable.Weekly_Plan')}
                        <Text
                          style={
                            commitmentstate == 'Comprometida'
                              ? styles.title_lean_state_com
                              : styles.title_lean_state
                          }>
                          {' '}
                          {commitmentstate}
                        </Text>
                      </Text>
                      <Text style={styles.title_name}>
                        {project ? project.name : ''} /{' '}
                        <Text style={styles.title_name}>
                          {sector ? sector.name : ''}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  <View style={{ ...styles.section_text_header }}>
                    <Text style={styles.title_head_week}>
                      {t('exportable.Week')}{' '}
                      {year_init == 2021 ? week_state : week_state}
                    </Text>
                    <Text style={styles.subtitle_head_week}>
                      {init_date.locale(t('lang')).format('DD MMM') +
                        ' - ' +
                        end_date.locale(t('lang')).format('DD MMM')}
                    </Text>
                  </View>
                </View>
                <View style={styles.table}>
                  {getHeaderTablePdf()}
                  {page.map((page_content, j) => (
                    <View key={j}>
                      {page_content.activities ? (
                        <View style={{ ...styles.section_activity }}>
                          <Text style={styles.section_activity_title_name}>
                            {page_content.activityRoute +
                              ' > ' +
                              page_content.name}
                          </Text>
                        </View>
                      ) : page_content.children.length > 0 &&
                        page_content.parent_id === null ? (
                        recursiveChild(page_content, activity_temp, 0)
                      ) : page_content.parent_id === null &&
                        page_content.children.length == 0 ? (
                        getTaskTable(page_content, activity_temp, 0)
                      ) : page_content.parent_id > 0 &&
                        page_content.children.length > 0 &&
                        page_content.level_subtask < 5 ? (
                        recursiveChild(
                          page_content,
                          activity_temp,
                          page_content.level_subtask * 4
                        )
                      ) : page_content.parent_id > 0 &&
                        page_content.children.length > 0 &&
                        page_content.level_subtask == 5 ? (
                        getTaskTable(
                          page_content,
                          activity_temp,
                          page_content.level_subtask * 4
                        )
                      ) : (
                        recursiveChild(
                          page_content,
                          activity_temp,
                          page_content.level_subtask * 4
                        )
                      )}

                      {/*
                                                      activity.tasks.map((task) => {
                                                          if (task.children.length > 0) {
                                                              return recursiveChild(task, activity, 0);
                                                          // return
                                                          // return recursiveChild(task,activity);
                                                          } else {
                                                              return getTaskTable(task, activity, 0);
                                                          }
                                                      }) */}
                    </View>
                  ))}
                </View>
                <View style={styles.pagination}>
                  <Text style={styles.pagination_text_count}>
                    {t('exportable.Page') + ' '}
                    {++pagina}
                  </Text>
                </View>
              </Page>
            ))}
          </Document>
        }
        info={{ module: 'week' }}
      />
    );
  }
  return null;

  function recursiveChild(task, activity, padding) {
    // if (task.showWeeklyPlan) {

    // }
    if (task.children.length == 0) {
      return getTaskTable(task, activity, padding);
    }
    const tem_padding = padding + 4;
    return getTaskTable(task, activity, padding, true);
  }

  function getHeaderTablePdf() {
    return (
      <View style={styles.section_header_pdf}>
        {headerData.map((item) => (
          <View key={item.title} style={item.styleView || styles.table_header}>
            <Text style={item.styleText || styles.table_header_title_column}>
              {t(item.title)}
            </Text>
          </View>
        ))}
        {weekHeaderPerDay}
      </View>
    );
  }

  function getActivityTable(activity, k) {
    return (
      <View style={styles.section_activity} key={k}>
        <Text style={styles.section_activity_title_name}>
          {activity.activityRoute + '->' + activity.name}
        </Text>
      </View>
    );
  }

  function getTaskTableParent(task, activity, parent) {
    /* if (!task.showWeeklyPlan) {
            return null
        } */
    const image = '';
    const responsables = task.responsables
      .map((responsables) => responsables.name)
      .join(', ');
    let newArray = [];
    if (task.materialId) {
      newArray = resource.filter((r) => r.id == task.materialId);
    }
    return (
      <View style={styles.section_task_parent}>
        <View style={styles.table_task_texto_parent}>
          <Image
            style={{
              width: '6px',
              height: '6px',
              // backgroundColor: "#61C74F",
              // marginTop:"18px",
              marginLeft: isNaN(parent) ? '0px' : parent + 'px',
              // marginLeft:"15px",
              display: parent != 0 ? '' : 'none'
            }}
            source={child_arrow}></Image>
          <Text
            style={{
              height: 'auto',
              fontSize: 7,
              fontFamily: 'Roboto',
              // marginLeft: parent+"px",
              fontStyle: 'normal',
              fontWeight: '1200',
              color: '#000000'
            }}>
            {task.name?.length > 31
              ? `${task.name?.substr(0, parent != 0 ? 30 - parent : 28)}...`
              : task.name}
          </Text>
        </View>
        <View style={styles.table_task_priority}>
          <Image style={styles.image} source={getPriorityimg(task.priority)} />
        </View>
        <View style={styles.table_task_subcontract}>
          <Text style={styles.table_task_title_name}>
            {responsables.length > 0
              ? responsables.length > 14
                ? `${responsables.substr(0, 14)} ...`
                : responsables
              : ' -'}
          </Text>
        </View>
        <View style={styles.table_task_subcontract}></View>
        <View style={styles.table_task_priority}>
          <Text style={styles.table_task_title_name}>
            {task.progress.toFixed(2)}%
          </Text>
        </View>
        <View style={styles.table_task_commitment}>
          <Text style={styles.table_task_title_name}>
            {task.taskcommitments.length > 0
              ? task.taskcommitments[0].commitment_percentaje.toFixed(2) + '%'
              : ' -'}
          </Text>
        </View>
        <View style={styles.table_task_resource}>
          <Text style={styles.table_task_title_name}>
            {newArray.length > 0 ? newArray[0].name : ' -'}
          </Text>
        </View>
        <View style={styles.table_task_real}>
          <Text style={styles.table_task_title_name}>
            {parseFloat((task.progress * task.total_quantity) / 100).toFixed(2)}
          </Text>
        </View>
        <View style={styles.table_task_resource}></View>

        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days}></View>
        <View style={styles.table_task_days_parent}></View>
      </View>
    );
  }

  function getTaskTable(task, activity, parent, isParent) {
    /* if (!task.showWeeklyPlan) {
            return null
        } */
    const image = '';
    const responsables = task.responsables
      .map((responsables) => responsables.name)
      .join(', ');
    let newArray = [];
    if (task.materialId) {
      newArray = resource.filter((r) => r.id == task.materialId);
    }
    let sub = '';
    let sub_ = false;
    if (subContract) sub_ = subContract.find((e) => e.id == task.subcontractId);
    if (sub_) {
      sub = sub_.name;
    }

    const defaultParentColor = '#E1E1E1';
    const defaultDayOffColor = '#FFFFFF';
    const defaultCommittedColor = '#F59D04';
    const defaultSubcontractColor = '#FFFFFF';
    const dayOffColor = isParent ? defaultParentColor : defaultDayOffColor;
    const withoutSubcontractColor = isParent
      ? defaultParentColor
      : defaultSubcontractColor;
    const colorThreshold = 30;

    const isParentTask = (isParent) => ({
      backgroundColor: isParent ? defaultParentColor : defaultDayOffColor
    });
    const unCommittedColor = {
      backgroundColor: dayOffColor
    };
    const committedColor = {
      backgroundColor: sub_?.color ?? defaultCommittedColor
    };
    const subcontractColor = {
      backgroundColor: sub_?.color || withoutSubcontractColor,
      color: `${getCompanyTextColor(sub_?.color || withoutSubcontractColor)}`
    };

    return (
      <View
        style={{
          ...styles.section_task,
          ...isParentTask(isParent)
        }}>
        <View
          style={{
            ...styles.table_task_column_name,
            ...isParentTask(isParent)
          }}>
          <Image
            style={{
              width: '6px',
              height: '6px',
              // backgroundColor: "#61C74F",
              // marginTop:"18px",
              marginLeft: isNaN(parent) ? '0px' : parent + 'px',
              // marginLeft:"15px",
              display: parent != 0 ? '' : 'none'
            }}
            source={child_arrow}></Image>
          <Text
            style={{
              height: 'auto',
              width: '100%',
              fontSize: 7,
              fontFamily: 'Roboto',
              paddingLeft: '5px',
              // marginLeft: parent+"px",
              fontStyle: 'normal',
              color: '#121212'
            }}>
            {task.name?.length > 31
              ? `${task.name?.substr(0, parent != 0 ? 30 - parent : 28)}...`
              : task.name}
          </Text>
        </View>
        <View style={styles.table_task_column}>
          <Image style={styles.image} source={getPriorityimg(task.priority)} />
        </View>
        <View style={styles.table_task_column}>
          <Text style={styles.table_task_title_name}>
            {responsables.length > 0
              ? responsables.length > 14
                ? `${responsables.substr(0, 14)} ...`
                : responsables
              : ' -'}
          </Text>
        </View>
        <View style={[styles.table_task_column, subcontractColor]}>
          <Text
            style={[
              styles.table_task_title_name__subcontract,
              { color: getCompanyTextColor(subcontractColor.backgroundColor) }
            ]}>
            {sub.length > 0
              ? sub.length > 10
                ? `${sub.substr(0, 8)} ...`
                : sub
              : ' -'}
          </Text>
        </View>
        <View style={styles.table_task_column}>
          <Text style={styles.table_task_title_name}>
            {parseFloat(task.progress).toFixed(2)}%
          </Text>
        </View>
        <View style={styles.table_task_column}>
          <Text style={styles.table_task_title_name}>
            {task.taskcommitments.length > 0
              ? parseFloat(
                  task.taskcommitments[0].commitment_percentaje
                ).toFixed(2) + '%'
              : ' -'}
          </Text>
        </View>
        {weekRowPerDay(task, activity, committedColor, unCommittedColor)}
      </View>
    );
  }

  function validateDay(task, activity, day) {
    const calendar = ganttAPI.getTaskCalendar(activity.calendarId);
    const validate_date = moment(start_date_url).set('hour', '9');
    const copyOfDate = new Date(
      validate_date.add('days', day).format('YYYY/MM/DD H:mm')
    );
    const validate_day = validate_date.isBetween(
      moment(task.taskcommitments[0].start_date).subtract(1, 'd'),
      moment(task.taskcommitments[0].end_date).add(1, 'd'),
      'days',
      false
    );
    if (validate_day) {
      // Funcion mal utilizada deberia ser {date, unit: 'day'}
      return calendar.isWorkTime({ date: copyOfDate, unit: 'day' });
    }
    return false;
  }

  function getPriorityimg(name) {
    let image = '';
    if (name == 'Urgent') {
      image = priority_maxima;
    }
    if (name == 'High') {
      image = priority_alta;
    }
    if (name == 'Normal') {
      image = priority_normal;
    }
    if (name == 'High') {
      image = priority_maxima;
    }
    if (name == 'Low') {
      image = priority_baja;
    }
    return image;
  }

  function calculeRango() {}
}

export default withTranslation()(WeekReport);
