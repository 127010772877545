import moment from 'moment';
import { getLinkNameByCode } from '../../constants/index';
import { addDurationToDate } from '../../helpers/addDurationToDate';
import { getNextWorkingHour } from '../../helpers/getNextWorkingHour';

class CalculateBackwardParentsLinks {
  constructor(calculationObject) {
    this.linkedActivitiesData = calculationObject.linksWithActivityData;
    this.activity = calculationObject.activity;
    this.gantt = calculationObject.gantt;
  }

  calculate() {
    this.activityCalendar = this.gantt.getCalendar(this.activity.calendar_id);
    if (!this.activityCalendar) {
      throw new Error(
        `Error trying to get calendar with id: ${this.activity.calendar_id}`
      );
    }

    const calculationsFromLinks = [];

    for (let linkedActivity of this.linkedActivitiesData) {
      const linkType = getLinkNameByCode(linkedActivity.linkType);

      if (
        linkedActivity.type === 'project' &&
        Number(linkedActivity.progress) === 100
      ) {
        continue;
      }

      if (
        linkedActivity.type === 'task' &&
        Number(linkedActivity.progress) > 0
      ) {
        continue;
      }

      const lag = linkedActivity.linkLag;
      let sucessorLs = linkedActivity.ls;
      let sucessorLf = linkedActivity.lf;
      if (linkType === 'ss') {
        const valuesToDoCalculation = {
          lag,
          workTime: sucessorLs,
          lsDataCalculation: -lag,
          lfDataCalculation: this.activity.duration
        };
        let { ls, lf } = this.calculateSSLink(valuesToDoCalculation);
        calculationsFromLinks.push({ ls, lf });
      }

      if (linkType === 'ff') {
        const valuesToDoCalculation = {
          lag,
          workTime: sucessorLf,
          lsDataCalculation: -this.activity.duration,
          lfDataCalculation: -lag
        };

        let { ls, lf } = this.calculateFFLink(valuesToDoCalculation);

        calculationsFromLinks.push({ ls, lf });
      }

      if (linkType === 'fs') {
        const valuesToDoCalculation = {
          lag,
          workTime: sucessorLs,
          lsDataCalculation: -this.activity.duration,
          lfDataCalculation: -lag
        };

        let { ls, lf } = this.calculateFsLink(valuesToDoCalculation);

        calculationsFromLinks.push({ ls, lf });
      }

      if (linkType === 'sf') {
        const valuesToDoCalculation = {
          lag,
          workTime: sucessorLf,
          lsDataCalculation: -lag,
          lfDataCalculation: this.activity.duration
        };

        let { ls, lf } = this.calculateSFLink(valuesToDoCalculation);

        calculationsFromLinks.push({ ls, lf });
      }
    }

    if (this.activity.constraint_type === 'fnlt') {
      const maxLfFromLinks = this.getMinLfFromLinks(calculationsFromLinks);

      let restriction = this.calculateSfRestrictionDates();

      if (!maxLfFromLinks) {
        return restriction;
      }
      return this.getMinLfFromLinks([restriction, maxLfFromLinks]);
    } else {
      return this.getMinLfFromLinks(calculationsFromLinks);
    }
  }

  calculateFFLink({ lag, workTime, lsDataCalculation, lfDataCalculation }) {
    let lfPred = null;
    let lsPred = null;

    if (lag === 0) {
      lfPred = addDurationToDate(
        this.activityCalendar,
        workTime,
        lfDataCalculation,
        this.activity
      );
      lsPred = addDurationToDate(
        this.activityCalendar,
        lfPred,
        lsDataCalculation,
        this.activity
      );
      return { ls: lsPred, lf: lfPred };
    }

    let lfSucessor = workTime;

    if (lag > 0) {
      lfSucessor = this.getLastWorkingHour(
        lfSucessor,
        this.activityCalendar,
        'past'
      );
    }
    lfPred = addDurationToDate(
      this.activityCalendar,
      lfSucessor,
      lfDataCalculation,
      this.activity
    );
    lsPred = addDurationToDate(
      this.activityCalendar,
      lfPred,
      lsDataCalculation,
      this.activity
    );
    return { ls: lsPred, lf: lfPred };
  }

  calculateFsLink({
    lag,
    workTime,
    lsDataCalculation,
    lfDataCalculation,
    isMilestone = false
  }) {
    let lfPred = null;
    let lsPred = null;

    if (lag === 0) {
      const lsSucessor = this.getLastWorkingHour(
        workTime,
        this.activityCalendar,
        'past'
      );

      lfPred = addDurationToDate(
        this.activityCalendar,
        lsSucessor,
        lfDataCalculation,
        this.activity
      );

      lfPred = this.getLastWorkingHour(lfPred, this.activityCalendar, 'past');

      lsPred = addDurationToDate(
        this.activityCalendar,
        lfPred,
        lsDataCalculation,
        this.activity
      );

      return { ls: lsPred, lf: lfPred };
    }

    let lsSucessor = workTime;

    if (!isMilestone) {
      lsSucessor = this.getLastWorkingHour(workTime, this.activityCalendar);
    }

    lfPred = addDurationToDate(
      this.activityCalendar,
      lsSucessor,
      lfDataCalculation,
      this.activity
    );

    lsPred = addDurationToDate(
      this.activityCalendar,
      lfPred,
      lsDataCalculation,
      this.activity
    );

    if (!isMilestone) {
      lfPred = this.getLastWorkingHour(lfPred, this.activityCalendar, 'past');
    }

    return { ls: lsPred, lf: lfPred };
  }

  calculateSFLink({ lag, workTime, lsDataCalculation, lfDataCalculation }) {
    let lfPred = null;
    let lsPred = null;
    let lfSucessor = workTime;

    lsPred = addDurationToDate(
      this.activityCalendar,
      lfSucessor,
      lsDataCalculation,
      this.activity
    );

    lfPred = addDurationToDate(
      this.activityCalendar,
      lsPred,
      lfDataCalculation,
      this.activity
    );

    return { ls: lsPred, lf: lfPred };
  }

  calculateSSLink({
    lag,
    workTime,
    lsDataCalculation,
    lfDataCalculation,
    isMilestone = false
  }) {
    let lfPred = null;
    let lsPred = null;

    if (lag === 0) {
      lsPred = addDurationToDate(
        this.activityCalendar,
        workTime,
        lsDataCalculation,
        this.activity
      );

      lfPred = addDurationToDate(
        this.activityCalendar,
        lsPred,
        lfDataCalculation,
        this.activity
      );

      return { ls: lsPred, lf: lfPred };
    }
    let lsSucessor = workTime;
    if (lag < 0 && !isMilestone) {
      lsSucessor = this.getLastWorkingHour(
        lsSucessor,
        this.activityCalendar,
        'future'
      );
    }

    lsPred = addDurationToDate(
      this.activityCalendar,
      lsSucessor,
      lsDataCalculation,
      this.activity
    );
    lfPred = addDurationToDate(
      this.activityCalendar,
      lsPred,
      lfDataCalculation,
      this.activity
    );
    return { ls: lsPred, lf: lfPred };
  }

  getMinLfFromLinks(allLinksCalculations = []) {
    if (!allLinksCalculations.length) {
      return false;
    }
    return allLinksCalculations.reduce((min, activity) =>
      activity.lf < min.lf ? activity : min
    );
  }

  calculateSfRestrictionDates() {
    const lfRestriction = this.activity.constraint_date;
    const duration = this.activity.duration;
    const lsRestriction = addDurationToDate(
      this.activityCalendar,
      lfRestriction,
      -duration,
      this.activity
    );

    return { ls: lsRestriction, lf: lfRestriction };
  }

  getLastWorkingHour(date, activityCalendar, dir = 'past') {
    let resetedDate = moment(date).clone();

    if (this.isActivityInInitHour(activityCalendar, date) && dir === 'past') {
      resetedDate = resetedDate.hours(0).minutes(0).seconds(0).milliseconds(0);
    }

    let newDate = getNextWorkingHour({
      dateBaseToCalculate: new Date(resetedDate),
      direction: dir,
      activityCalendar: activityCalendar,
      activity: this.activity
    });
    return newDate;
  }

  /**
   * Determines if a given date corresponds to the initial work hour of an activity as defined in the activity calendar.
   * This function retrieves the first work interval from the activity calendar and compares the hour portion
   * of the input date to the start hour of the work interval.
   * @param {object} activityCalendar - The calendar object containing work hours and scheduling information for the activity.
   * @param {Date} date - The date to check against the activity's start hour.
   * @returns {boolean} True if the hour of the input date matches the initial work hour of the activity; otherwise, false.
   */
  isActivityInInitHour(activityCalendar, date) {
    let shifts = activityCalendar.getWorkHours(new Date(date));
    let firstWorkInterval = shifts[0];

    if (!firstWorkInterval) {
      return false;
    }

    if (!firstWorkInterval.includes('-')) return false;

    let workStartHour = firstWorkInterval.split('-')[0];

    if (!workStartHour.includes(':')) return false;

    let hoursMinutes = workStartHour.split(':');

    if (hoursMinutes.length < 2) return false;
    workStartHour = parseInt(hoursMinutes[0], 10);

    if (moment.isMoment(date)) {
      return date.hours() === workStartHour;
    }
    return date.getHours() === workStartHour;
  }
}

export default CalculateBackwardParentsLinks;
