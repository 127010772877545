/* eslint-disable quote-props */
import { base } from './base';
import axios from 'axios';
class AuthService {
  constructor() {
    const loggedUser = localStorage.getItem('authToken');
    this.loggedUser = loggedUser;
    this.getHttp();
  }

  async getHttp(dataInit) {
    let dataBasic =
      'cHJvcGxhbm5lcjpiWGx0WVhOMFpYSnJaWGx0ZVcxaGMzUmxjbXRsZVcxNWJXRnpkR1Z5YTJWNU1USXpORFUyTnpnNU1UQmhZbU5rWldabmFHbHE=';
    if (dataInit) {
      dataBasic = Buffer.from(dataInit.user + ':' + dataInit.password).toString(
        'base64'
      );
    }

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + dataBasic,
      'WWW-Authenticate': 'Bearer ' + this.loggedUser
    };
    const http = axios.create({ baseURL: base.api, headers: headers });
    this.http = http;
  }

  async index(data) {
    if (data?.user) {
      data.user = data.user.toLowerCase();
    }
    this.getHttp(data);
    const res = await this.http.post('auth', data).catch((error) => {
      console.log(error.response);
      return error.response;
    });
    return res;
  }

  async indexSSO(publicToken) {
    this.getHttp(publicToken);
    const { data } = await this.http
      .post('auth/sso/login', publicToken)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return data;
  }

  async createSession(data) {
    this.getHttp(data);
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      this.http.defaults.headers['WWW-Authenticate'] = `Bearer ${authToken}`;
    }
    const res = await this.http.post('auth/createSession', data);
    return res;
  }

  async alreadyExist(data) {
    this.getHttp(data);
    const res = await this.http
      .post('auth/alreadyExist', data)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return res;
  }

  async isTokenExpired(data) {
    this.getHttp(data);
    const res = await this.http
      .post('auth/isTokenExpired', data)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return res;
  }

  async checkBackendAccess() {
    try {
      await this.http.get('auth/healthCheck');
      return true;
    } catch (error) {
      return false;
    }
  }

  async reInviteUser(data) {
    if (data?.user) {
      data.user = data.user.toLowerCase();
    }
    this.getHttp(data);
    const res = await this.http
      .post('auth/reInviteUser', data)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return res;
  }
}

export const authService = new AuthService();
