import { store } from '../../../../redux/store';
import { earlyAccessCriticalPath } from '../../../../utils/earlyAccessCriticalPath';
import {
  startRecalculating,
  completeCalculation
} from '../../../../redux/slices/ganttSlice';

const criticalPathRefresh = () => {
  const gantt = window.to_use_react_gantt;
  const activity = gantt.getTaskByIndex(0);
  if (!activity) return;
  if (earlyAccessCriticalPath()) {
    window.loader.show();
    store.dispatch(startRecalculating());
    gantt.calculateAsyncCriticalPath().then(() => {
      gantt.refreshData();
      window.loader.hide();
      store.dispatch(completeCalculation());
    });
    return;
  }

  activity.freeSlack = gantt.getFreeSlack(activity);
  activity.totalSlack = gantt.getTotalSlack(activity);
};

export { criticalPathRefresh };
