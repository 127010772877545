import { identifySecondLevelActivities } from './secondLevelActivities';
import { identifyInitialActivities } from './initialActivities';
import { identifyParentsWithAndWithoutLinks } from './identifyParentsWithAndWithoutLinks';
import { identifyForbiddenActivities } from './identifyForbiddenActivities';

export default {
  identifySecondLevelActivities,
  identifyInitialActivities,
  identifyParentsWithAndWithoutLinks,
  identifyForbiddenActivities
};
