import React from 'react';
import icon_acces from '../assets/img/modules/ccloudmin.png';
import moment from 'moment';

/** Import IconComponent */
import IconComponent from './icon';
import { isFeatureFlagActive } from '../analytics/implements/amplitude';
import { getSessionTokenData } from '../utils/userUtils';

const userLang = navigator.language || navigator.userLanguage;

const isAfterSeptemberFirst = (companyState) => {
  const baseDate = '07-07-2021';
  const formatedDate = moment(companyState.createdAt);
  return moment(formatedDate).isAfter(baseDate);
};

// var userLang = navigator.language || navigator.userLanguage;
export const menu = (t, companyState, userLang) => {
  const isTaktPlanningMenuActive = isFeatureFlagActive('takt-planning-menu');
  const userSessionStorage = getSessionTokenData();

  let newMenu =
    companyState?.id === 29 || companyState?.id === 31 // ARMAS, INGEVEC
      ? [
          {
            available: true,
            id: '0',
            title: t('modules.masterplan'),
            icon: 'pic-right',
            routeName: 'masterplan',
            go: (history) => {
              history.push('/masterplan');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          },
          {
            available: true,
            id: '1',
            title: t('modules.lookahead'),
            icon: 'pic-right',
            routeName: 'lookahead',
            go: (history) => {
              history.push('/lookahead/planification');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          },
          {
            available: true,
            id: '2',
            title: t('modules.weeklyplan'),
            icon: 'pic-right',
            routeName: 'weeklyplan',
            go: (history) => {
              history.push('/weeklyplan/commintments');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          },
          {
            available: true,
            id: '3',
            title: t('modules.taktplanning'),
            icon: 'pic-right',
            routeName: 'taktplanning',
            go: (history) => {
              history.push('/taktplanning/units');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          },
          {
            available: true,
            id: '4',
            title: t('modules.productivity'),
            icon: 'pic-right',
            routeName: 'productivity',
            go: (history) => {
              history.push('/productivity/assign');
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          },
          /* {
          available: false,
          id: '5',
          title: t('modules.budget'),
          icon: 'pic-right',
          routeName: 'budget',
          go: (history) => {
              history.push('/budget')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
          /* {
          available: false,
          id: '6',
          title: t('modules.documents'),
          icon: 'pic-right',
          routeName: 'documents',
          go: (history) => {
              history.push('/documents')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      },
      {
          available: false,
          id: '7',
          title: t('modules.bim'),
          icon: 'pic-right',
          routeName: 'bim',
          go: (history) => {
              history.push('/bim')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
          {
            available: true,
            id: '8',
            title: t('modules.analytics'),
            icon: 'bar-chart',
            routeName: 'analytics',
            go: (history) => {
              history.push(
                userSessionStorage?.companyId === '23'
                  ? '/analytics-v2'
                  : '/analytics'
              );
            },
            svgIcon: (routeName) => <IconComponent name={routeName} />
          }
          /* {
          available: false,
          id: '9',
          title: t('modules.reports'),
          icon: 'pic-right',
          routeName: 'reports',
          go: (history) => {
              history.push('/reports')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
          /* {
          available: true,
          id: '10',
          title: "Calidad Cloud",
          icon: 'pic-right',
          routeName: 'cloud',
          go: (history) => {
              history.push('/ccloud_banner')
          },
          //filter: grayscale(100%);
          svgIcon: (routeName) => <img style={{ filter: "grayscale(100%)" ,width: "36px", height: "26px", marginTop: "-25px", marginLeft:" -5px"}} src={icon_acces} width="25px"></img>
      } */
        ]
      : userLang.includes('es')
        ? [
            {
              available: true,
              id: '0',
              title: t('modules.masterplan'),
              icon: 'pic-right',
              routeName: 'masterplan',
              go: (history) => {
                history.push('/masterplan');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            {
              available: true,
              id: '1',
              title: t('modules.lookahead'),
              icon: 'pic-right',
              routeName: 'lookahead',
              go: (history) => {
                history.push('/lookahead/planification');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            {
              available: true,
              id: '2',
              title: t('modules.weeklyplan'),
              icon: 'pic-right',
              routeName: 'weeklyplan',
              go: (history) => {
                history.push('/weeklyplan/commintments');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            /*{
              available: true,
              id: '3',
              title: t('modules.taktplanning'),
              icon: 'pic-right',
              routeName: 'taktplanning',
              go: (history) => {
                history.push('/taktplanning/units');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },*/
            /* {
          available: !userLang.includes('en'),
          id: '4',
          title: t('modules.productivity'),
          icon: 'pic-right',
          routeName: 'productivity',
          go: (history) => {
              history.push('/productivity/assign')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
            /* {
          available: false,
          id: '5',
          title: t('modules.budget'),
          icon: 'pic-right',
          routeName: 'budget',
          go: (history) => {
              history.push('/budget')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
            /* {
          available: false,
          id: '6',
          title: t('modules.documents'),
          icon: 'pic-right',
          routeName: 'documents',
          go: (history) => {
              history.push('/documents')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      },
      {
          available: false,
          id: '7',
          title: t('modules.bim'),
          icon: 'pic-right',
          routeName: 'bim',
          go: (history) => {
              history.push('/bim')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
            {
              available: true,
              id: '8',
              title: t('modules.analytics'),
              icon: 'bar-chart',
              routeName: 'analytics',
              go: (history) => {
                history.push(
                  userSessionStorage?.companyId === '23'
                    ? '/analytics-v2'
                    : '/analytics'
                );
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            }
            /* {
          available: false,
          id: '9',
          title: t('modules.reports'),
          icon: 'pic-right',
          routeName: 'reports',
          go: (history) => {
              history.push('/reports')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
            /* {
          available: true,
          id: '10',
          title: "Calidad Cloud",
          icon: 'pic-right',
          routeName: 'cloud',
          go: (history) => {
              history.push('/ccloud_banner')
          },
          //filter: grayscale(100%);
          svgIcon: (routeName) => <img style={{ filter: "grayscale(100%)" ,width: "36px", height: "26px", marginTop: "-25px", marginLeft:" -5px"}} src={icon_acces} width="25px"></img>
      } */
          ]
        : [
            {
              available: true,
              id: '0',
              title: t('modules.masterplan'),
              icon: 'pic-right',
              routeName: 'masterplan',
              go: (history) => {
                history.push('/masterplan');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            {
              available: true,
              id: '1',
              title: t('modules.lookahead'),
              icon: 'pic-right',
              routeName: 'lookahead',
              go: (history) => {
                history.push('/lookahead/planification');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            {
              available: true,
              id: '2',
              title: t('modules.weeklyplan'),
              icon: 'pic-right',
              routeName: 'weeklyplan',
              go: (history) => {
                history.push('/weeklyplan/commintments');
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            },
            /* {
          available: !userLang.includes('en'),
          id: '4',
          title: t('modules.productivity'),
          icon: 'pic-right',
          routeName: 'productivity',
          go: (history) => {
              history.push('/productivity/assign')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
            /* {
          available: false,
          id: '5',
          title: t('modules.budget'),
          icon: 'pic-right',
          routeName: 'budget',
          go: (history) => {
              history.push('/budget')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
            /* {
          available: false,
          id: '6',
          title: t('modules.documents'),
          icon: 'pic-right',
          routeName: 'documents',
          go: (history) => {
              history.push('/documents')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      },
      {
          available: false,
          id: '7',
          title: t('modules.bim'),
          icon: 'pic-right',
          routeName: 'bim',
          go: (history) => {
              history.push('/bim')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
            {
              available: true,
              id: '8',
              title: t('modules.analytics'),
              icon: 'bar-chart',
              routeName: 'analytics',
              go: (history) => {
                history.push(
                  userSessionStorage?.companyId === '23'
                    ? '/analytics-v2'
                    : '/analytics'
                );
              },
              svgIcon: (routeName) => <IconComponent name={routeName} />
            }
            /* {
          available: false,
          id: '9',
          title: t('modules.reports'),
          icon: 'pic-right',
          routeName: 'reports',
          go: (history) => {
              history.push('/reports')
          },
          svgIcon: (routeName) => <IconComponent name={routeName} />
      }, */
            /* {
          available: true,
          id: '10',
          title: "Calidad Cloud",
          icon: 'pic-right',
          routeName: 'cloud',
          go: (history) => {
              history.push('/ccloud_banner')
          },
          //filter: grayscale(100%);
          svgIcon: (routeName) => <img style={{ filter: "grayscale(100%)" ,width: "36px", height: "26px", marginTop: "-25px", marginLeft:" -5px"}} src={icon_acces} width="25px"></img>
      } */
          ];

  const taktPlanningMenu = [
    {
      available: true,
      id: '9',
      title: t('modules.taktplanning'),
      icon: 'pic-right',
      routeName: 'taktplanning',
      go: (history) => {
        history.push('/taktplanning/units');
      },
      svgIcon: (routeName) => <IconComponent name={routeName} />
    },
    {
      available: true,
      id: '10',
      title: t('modules.takt_analytics'),
      icon: 'bar-chart',
      routeName: 'extraDashboard',
      go: (history) => {
        history.push('/extraDashboard');
      },
      svgIcon: (routeName) => <IconComponent name={routeName} />
    }
  ];

  if (isTaktPlanningMenuActive) {
    newMenu = [...newMenu, ...taktPlanningMenu];
  }

  return newMenu;
};
