import React, { useState, useEffect } from 'react';
import { workerService } from '../services/serviceworker.service';

/**
 * This function creates browser context side reference to communicate with background context side reference to deal with heavy data
 * @param {*} setParentWorker React set state function to keep reference updated
 * @param {*} otherStates React states which must be updated at worker background context
 */
export const useWorker = (otherStates = []) => {
  const [reactWorker, setReactWorker] = useState(null);

  useEffect(() => {
    const worker = navigator?.serviceWorker?.controller;
    if (!worker) return;
    workerService.setWorker(worker);

    setReactWorker(navigator.serviceWorker);
  }, [otherStates]);
  return reactWorker;
};
