import { PAPER_FORMAT } from '../constants';

/**
 * Hides any currently open Ant Design dropdown by simulating a click event.
 */
export const hideOpenDropdown = () => {
  const dropdownOpen = document.querySelector('.ant-dropdown-open');
  if (dropdownOpen) {
    dropdownOpen.click();
  }
};

/**
 * Gets the label for the specified page format by finding it in the PAPER_FORMAT array and
 * using a translation function to return the formatted label.
 *
 * @param {Function} t - Translation function.
 * @param {string} pageFormat - Key of the page format to retrieve.
 * @returns {string} Translated label for the page format.
 */
export const getLabelPageFormat = ({ t, pageFormat }) => {
  const pageFormatLabel = PAPER_FORMAT.find(([key]) => key === pageFormat)[1];
  return t(pageFormatLabel);
};

/**
 * Gets the start of the day (00:00:00) for a given date.
 *
 * @param {Date|string} date - The date to get the start of day for.
 * @returns {Date} A new Date object set to the start of the specified day.
 */
const getStartOfDay = (date) => {
  if (!date) {
    throw new Error('Date parameter is required');
  }

  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
};

/**
 * Checks if a given date is not today.
 *
 * @param {Date|string} date - The date to compare with today's date.
 * @returns {boolean} True if the date is not today; otherwise, false.
 */
export const isDateNotToday = (date) => {
  const today = getStartOfDay(new Date());
  const selectedDate = getStartOfDay(new Date(date));
  return today.getTime() !== selectedDate.getTime();
};
