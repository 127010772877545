import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { ICONS } from './assets';
import ResourceRow from './components/ResourceRow';
import { useResourceUrls } from './hooks/useResourceUrls';
import './styles.scss';

const ITEMS_PER_ROW = 2;
const CONTAINER_HEIGHT_OFFSET = 467;

const ResourcesSection = memo(({ height, t }) => {
  const { getResources } = useResourceUrls(t);

  const resourceRows = useMemo(() => {
    const resources = getResources().map((resource) => ({
      ...resource,
      label: t(resource.labelKey)
    }));

    return resources.reduce((rows, resource, index) => {
      const rowIndex = Math.floor(index / ITEMS_PER_ROW);

      if (!rows[rowIndex]) {
        rows[rowIndex] = [];
      }

      rows[rowIndex].push(resource);
      return rows;
    }, []);
  }, [getResources, t]);

  return (
    <div
      className="resources-section__container"
      style={{ height: height - CONTAINER_HEIGHT_OFFSET }}>
      <Row>
        <Col offset={2}>
          <div className="resources-section__title">
            {t('more_resources_help')} 🚀
          </div>
        </Col>
      </Row>

      {resourceRows.map((rowResources, index) => (
        <ResourceRow key={index} resources={rowResources} icons={ICONS} />
      ))}
    </div>
  );
});

ResourcesSection.propTypes = {
  height: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

ResourcesSection.displayName = 'ResourcesSection';

export default ResourcesSection;
