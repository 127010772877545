import { log } from '../../../../../monitor/monitor';
/**
 * Identifies forbidden activities linked to parents with links.
 * @param {Object} params - The parameters object.
 * @param {Set<number>} params.pendingParentsWithLinks - Set of activity IDs that are pending parents with links.
 * @param {Object} params.gantt - The Gantt instance.
 * @param {string} params.direction - The calculation direction ('forward' or 'backward').
 * @returns {Set<number>} A set of forbidden activity IDs linked to parents with links.
 */
export function identifyForbiddenActivities({
  pendingParentsWithLinks,
  gantt,
  direction
}) {
  try {
    const forbiddenActivities = new Set();

    pendingParentsWithLinks.forEach((activityId) => {
      const activity = gantt.getTask(activityId);
      const linkProperty = getLinkProperty(direction);
      const linkDirection = getLinkDirection(direction);

      const activityLinks = activity[linkProperty];

      if (!Array.isArray(activityLinks)) {
        return;
      }

      const linkedActivities = getArrayOfLinkedActivities(
        gantt,
        activityLinks,
        linkDirection
      );

      linkedActivities.forEach((linkedActivityId) => {
        forbiddenActivities.add(linkedActivityId);
      });
    });

    return forbiddenActivities;
  } catch (e) {
    log(
      'Critical Path',
      'Error in identifyForbiddenActivitiesLinkedToParentsWithLinks'
    );
    throw e;
  }
}

/**
 * Determines the link property based on the direction.
 * @param {string} direction - The calculation direction ('forward' or 'backward').
 * @returns {string} The link property ('$source' or '$target').
 */
function getLinkProperty(direction) {
  return direction === 'forward' ? '$source' : '$target';
}

/**
 * Determines the link direction based on the calculation direction.
 * @param {string} direction - The calculation direction ('forward' or 'backward').
 * @returns {string} The link direction ('target' or 'source').
 */
function getLinkDirection(direction) {
  return direction === 'forward' ? 'target' : 'source';
}

function getArrayOfLinkedActivities(gantt, links, direction) {
  return links.map((link) => {
    const linkData = gantt.getLink(link);
    const linkedActivity = linkData[direction];
    return Number(linkedActivity);
  });
}
