import { log } from '../../../../monitor/monitor';
import CriticalPathHelpers from '../base/generic-calculations';
import { CONSTRAINT_TYPES } from '../constants/index';
import LinksConstraintCalculator from './calculators/LinksConstraintCalculator';
import calculateRestriction from './helpers/restrictionCalculation';
import { addDurationToDate } from '../helpers';
import ActivityCalculator from '../base/getActivitiesToCalculate';
import calculateRestrictionAsForward from '../forward-path/helpers/restrictionCalculation';

class BackwardPath extends CriticalPathHelpers {
  constructor(ganttInstance, forwardPathCalculations, forwardConstraintsMap) {
    super(ganttInstance, 'backward');
    this.gantt = ganttInstance;
    this.chainStartActivities = new Set();
    this.secondLevelActivities = new Set();
    this.pendingParentsWithLinks = new Set();
    this.pendingParentsWithNoLinks = new Set();
    this.structureOfParents = new Map();
    this.singleParents = new Map();
    this.calculationsOfParentThatImpactChildrens = new Map();
    this.activitiesWaitingForCalculation = new Set();
    this.calculations = new Map();
    this.mapOfRelationsWithActivitiesIds = new Map();
    this.activitiesWaitingForCalculation = new Set();
    this.forwardPathCalculations = forwardPathCalculations;
    this.forwardConstraintsMap = forwardConstraintsMap;
    this.constraintMap = new Map();
    this.minFromLinks = new Map();
    this.activitiesStartChainButChildrenOfLinkedParents = new Set();
    this.calculationOfParentsFromLinks = new Set();
    this.parentCalculationFromChildren = new Set();
    this.parentsWithFullProgress = new Set();
    this.forbiddenActivitiesLinkedToParentsWithLinks = new Set();
    this.identifyInitialActivities();
    this.initActivitiesWaitingForCalculation();
  }

  async calculate() {
    this.identifyParentsWithAndWithoutLinks();
    this.identifyForbiddenActivitiesLinkedToParentsWithLinks();
    this.initializeActivitiesWithFullProgress();
    this.calculateStartAndFinishOfActivitiesThatStartChain();
    this.identifySecondLevelActivities();
    this.calculateSecondLevelActivities();
    this.identifyThirdLevelActivities();
    this.recursiveCalculateLfAndLs();
  }
  /**
   * Recursively calculates the latest finish (LF) and latest start (LS) times for a set of activities.
   *
   * This function processes activities that can be calculated based on their predecessors and updates
   * the remaining pending activities until all calculations are complete. It recursively calls itself
   * until there are no more pending activities to be calculated.
   *
   * @param {Set<string|number>} [activitiesPending=this.activitiesWaitingForCalculation] - A set of activity IDs that are pending calculation.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming this.activitiesWaitingForCalculation is a Set containing activity IDs [1, 2, 3]
   * recursiveCalculateLfAndLs();
   * // This will recursively calculate LF and LS for all activities that can be calculated
   * // based on their predecessors and update the pending activities set until all are calculated.
   *
   * @throws {Error} If an error occurs during the process.
   */
  recursiveCalculateLfAndLs(
    activitiesPending = this.activitiesWaitingForCalculation,
    attempt = 0
  ) {
    if (attempt >= 25000) {
      log('Critical Path', 'Error in recursiveCalculateLfAndLs');
      throw new Error(
        'Error in recursiveCalculateLfAndLs: Maximum attempts reached'
      );
    }

    if (activitiesPending.size === 0) return;
    const activityCalculator = new ActivityCalculator({
      gantt: this.gantt,
      direction: this.direction,
      pendingParentsWithNoLinks: this.pendingParentsWithNoLinks,
      pendingParentsWithLinks: this.pendingParentsWithLinks,
      calculationOfParentsFromLinks: this.calculationOfParentsFromLinks,
      singleParents: this.singleParents,
      calculations: this.calculations,
      activitiesStartChainButChildrenOfLinkedParents:
        this.activitiesStartChainButChildrenOfLinkedParents,
      linkProperty: this.linkProperty,
      linkDirection: this.linkDirection
    });

    const { activitiesThatCanBeCalculated, pendingActivities } =
      activityCalculator.checkActivitiesThatNowCanBeCalculated(
        activitiesPending
      );
    this.calculateStartAndFinishTimes(activitiesThatCanBeCalculated);

    activitiesThatCanBeCalculated.forEach((activity) => {
      if (
        this.calculationOfParentsFromLinks.has(activity) &&
        !this.calculations.has(activity)
      ) {
        pendingActivities.add(activity);
      }
      this.activitiesWaitingForCalculation.delete(activity);
      if (this.calculations.has(activity)) {
        pendingActivities.delete(activity);
      }
    });

    if (pendingActivities.size > 0) {
      this.recursiveCalculateLfAndLs(pendingActivities, attempt + 1);
    }
  }

  setDatesToParentsWithLinks(parentData) {
    if (!parentData) {
      throw new Error(`Error trying to get parent with id: ${parentData}`);
    }
    let childrens = this.singleParents.get(parentData.id).childrens;

    let arrayOfDatesOfAllTheChildrens = Array.from(this.calculations).filter(
      (activity) => {
        return childrens.includes(activity[0]);
      }
    );

    let arrayOfEsAndEf = arrayOfDatesOfAllTheChildrens.map((activity) => {
      return activity[1];
    });

    const { minLs, maxLf } = arrayOfEsAndEf.reduce(
      (acc, { ls, lf }) => ({
        minLs: new Date(Math.min(acc.minLs.getTime(), new Date(ls).getTime())),
        maxLf: new Date(Math.max(acc.maxLf.getTime(), new Date(lf).getTime()))
      }),
      {
        minLs: new Date(arrayOfEsAndEf[0].ls),
        maxLf: new Date(arrayOfEsAndEf[0].lf)
      }
    );

    this.calculations.set(parentData.id, {
      ls: minLs,
      lf: maxLf
    });
  }
  /**
   * Calculates the latest start (LS) and latest finish (LF) times for a list of activities.
   *
   * This function processes each activity in the `activitiesToCalculate` list, retrieves the necessary
   * information, and calculates the LS and LF times based on the activity's progress and constraint type.
   * It handles various constraints and updates the calculations accordingly.
   *
   * @param {Array<string|number>} activitiesToCalculate - A list of activity IDs for which to calculate LS and LF times.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activitiesToCalculate is an array containing activity IDs [1, 2, 3]
   * calculateStartAndFinishTimes([1, 2, 3]);
   * // This will calculate the LS and LF times for the activities and update the internal calculations map.
   *
   * @throws {Error} If an error occurs during the process.
   */
  calculateStartAndFinishTimes(activitiesToCalculate) {
    activitiesToCalculate.forEach((activityId) => {
      let activityReference = this.gantt.getTask(activityId);

      if (!activityReference) {
        throw new Error(`Activity with id ${activityId} not found`);
      }
      let linksWithSucessors = activityReference.$source;
      let constraintType =
        activityReference.constraint_type || CONSTRAINT_TYPES.ASAP;

      const progress = Number(activityReference.progress);

      if (this.activitiesStartChainButChildrenOfLinkedParents.has(activityId)) {
        if (
          this.calculationsOfParentThatImpactChildrens.has(
            Number(activityReference.parent)
          ) &&
          this.calculationsOfParentThatImpactChildrens.get(
            Number(activityReference.parent)
          ) === false
        ) {
          let { ls, lf } = this.calculateStartAndFinishOfChainOrigin(
            activityReference,
            this.direction
          );

          this.setCalculations(activityReference, ls, lf);
          return;
        }

        return this.doCalculationForActivitiesThatStartLinkButHisParentIsLinked(
          activityReference
        );
      }

      if (activityReference.type === 'project') {
        return this.doCalculationForParentType(activityReference);
      }

      if (progress === 100) {
        this.setDatesForActivityWithFullProgress(activityReference);
        return;
      }

      if (
        this.allLinkedActivitiesHasProgress(
          linksWithSucessors,
          activityReference
        )
      ) {
        this.setWhenAllLinkedActivitiesHasProgress(activityReference);
        return;
      }

      if (progress > 0 && progress < 100) {
        if (
          constraintType === CONSTRAINT_TYPES.MSO ||
          constraintType === CONSTRAINT_TYPES.MFO
        ) {
          this.setWhenActivitiesHasProgressButLessThanOneHundred(
            activityReference,
            constraintType
          );
          return;
        }
      }

      if (
        constraintType === CONSTRAINT_TYPES.MSO ||
        constraintType === CONSTRAINT_TYPES.MFO
      ) {
        this.recalculateInBaseRestriction(activityReference, constraintType);

        return;
      }

      let { ls, lf, minFromLinks } = this.calculateLfandLsFromLinks(
        linksWithSucessors,
        activityReference,
        constraintType,
        this.parentsWithFullProgress,
        this.calculationsOfParentThatImpactChildrens
      );

      if (
        constraintType === CONSTRAINT_TYPES.SNLT ||
        constraintType === CONSTRAINT_TYPES.FNLT
      ) {
        ({ ls, lf } = this.getTheLessLateInBasEsEfConstraint(
          activityReference.id,
          {
            ls,
            lf
          },
          constraintType
        ));
      }

      this.setCalculations(activityReference, ls, lf);
      this.minFromLinks.set(activityReference.id, {
        ls: minFromLinks.ls,
        lf: minFromLinks.lf
      });
    });
  }

  /**
   * Determines the least late time for an activity based on ES and EF constraints.
   *
   * This function compares the latest finish (LF) time from links with the earliest finish (EF) constraint
   * for the activity. If the LF from links is less than the EF constraint, it returns the LF from links.
   * Otherwise, it returns the ES and EF constraints.
   *
   * @param {string|number} activity - The ID of the activity.
   * @param {Object} lfFromLinks - An object containing LS (latest start) and LF (latest finish) times from links.
   * @param {Date} lfFromLinks.ls - The latest start time from links.
   * @param {Date} lfFromLinks.lf - The latest finish time from links.
   * @returns {Object} An object containing the least late LS and LF times.
   * @property {Date} ls - The least late latest start time.
   * @property {Date} lf - The least late latest finish time.
   *
   * @example
   * // Assuming forwardConstraintsMap contains the ES and EF constraints for the activity
   * const result = getTheLessLateInBasEsEfConstraint(1, { ls: new Date(), lf: new Date() });
   * console.log()
 // { ls: Date, lf: Date }
   *
   * @throws {Error} If an error occurs during the process.
   */
  getTheLessLateInBasEsEfConstraint(activity, lfFromLinks, constraintType) {
    let esEfFromConstraint = this.forwardConstraintsMap.get(activity);
    if (!esEfFromConstraint) {
      const activityReference = this.gantt.getTask(activity);
      const activityCalendar = this.gantt.getCalendar(
        activityReference.calendar_id
      );
      esEfFromConstraint = calculateRestrictionAsForward(
        activityReference,
        constraintType,
        activityCalendar
      );
    }

    if (lfFromLinks.lf < esEfFromConstraint.ef) {
      return lfFromLinks;
    }

    return { ls: esEfFromConstraint.es, lf: esEfFromConstraint.ef };
  }
  /**
   * Checks whether all activities linked to the given set of activities have progress greater than 0.
   *
   * This function iterates over the linked activities, retrieves their data, and checks if all of them have progress greater than 0.
   * If any linked activity has 0 progress, the function returns false. If all linked activities have progress, it returns true.
   *
   * @param {Array<string|number>} linkedActivities - An array of linked activity IDs.
   * @returns {boolean} True if all linked activities have progress greater than 0, otherwise false.
   *
   * @example
   * // Assuming linkedActivities is an array containing linked activity IDs [1, 2, 3]
   * const allHaveProgress = allLinkedActivitiesHasProgress([1, 2, 3]);
   * console.log()
 // true or false based on the progress of the linked activities
   *
   * @throws {Error} If an error occurs during the process, such as missing link data or activity data.
   */
  allLinkedActivitiesHasProgress(linkedActivities) {
    try {
      let allLinkedHasProgress = true;

      for (let link of linkedActivities) {
        const linkData = this.gantt.getLink(link);
        if (!linkData) {
          continue;
        }

        const activityData = this.gantt.getTask(linkData.target);
        if (!activityData) {
          continue;
        }
        if (activityData.type === 'project') {
          allLinkedHasProgress = false;
        }
        if (activityData.progress === 0) {
          allLinkedHasProgress = false;
        }
      }

      return allLinkedHasProgress;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  /**
   * Sets the calculations for an activity that has 100% progress.
   *
   * This function uses the activity's start and end dates to set the calculations for the activity.
   * It is assumed that the activity has 100% progress.
   *
   * @param {Object} activity - The activity object containing the details of the activity.
   * @param {Date} activity.start_date - The start date of the activity.
   * @param {Date} activity.end_date - The end date of the activity.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activity is an object with start_date and end_date properties
   * setDatesForActivityWithFullProgress({
   *   start_date: new Date('2023-01-01'),
   *   end_date: new Date('2023-01-10')
   * });
   * // This will set the calculations for the activity using its start and end dates.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  setDatesForActivityWithFullProgress(activity) {
    this.setCalculations(activity, activity.start_date, activity.end_date);
  }
  /**
   * Sets the calculations for an activity when all its linked activities have progress.
   *
   * This function calculates the latest start (LS) and latest finish (LF) times for the activity using a backward direction,
   * and sets these values in the internal calculations.
   *
   * @param {Object} activity - The activity object containing the details of the activity.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activity is an object with necessary properties for calculation
   * setWhenAllLinkedActivitiesHasProgress(activity);
   * // This will calculate LS and LF for the activity and set these values in the internal calculations.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  setWhenAllLinkedActivitiesHasProgress(activity) {
    const parent = Number(activity.parent);

    let { ls, lf } = this.calculateStartAndFinishOfChainOrigin(
      activity,
      'backward'
    );
    if (this.calculationsOfParentThatImpactChildrens.has(parent)) {
      ls = activity.start_date;

      lf = Math.min(
        lf,
        this.calculationsOfParentThatImpactChildrens.get(parent).lf
      );
    }

    this.setCalculations(activity, ls, lf);
    return;
  }
  /**
   * Sets the calculations for an activity with progress greater than 0% but less than 100%.
   *
   * This function handles activities based on their constraint type when the progress is between 0% and 100%.
   * It sets the calculations accordingly using the activity's start and end dates, or the constraint dates.
   *
   * @param {Object} activity - The activity object containing the details of the activity.
   * @param {string} constraintType - The constraint type of the activity, such as 'mso' or 'mfo'.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activity is an object with start_date, end_date, and constraint_date properties, and constraintType is 'mso'
   * setWhenActivitiesHasProgressButLessThanOneHundred(activity, 'mso');
   * // This will set the calculations for the activity using its start and end dates.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  setWhenActivitiesHasProgressButLessThanOneHundred(activity, constraintType) {
    if (constraintType === CONSTRAINT_TYPES.MSO) {
      this.setCalculations(activity, activity.start_date, activity.end_date);
      return;
    }

    if (constraintType === CONSTRAINT_TYPES.MFO) {
      const minDateBetween = Math.min(
        activity.constraint_date,
        this.projectDates.end_date
      );
      const maxBetween = Math.max(minDateBetween, activity.end_date);
      this.setCalculations(activity, activity.start_date, maxBetween);
      return;
    }
  }
  /**
   * Sets the latest start (LS) and latest finish (LF) times for a given activity in the internal calculations map.
   *
   * This function stores the LS and LF times, along with the activity's text, in the internal `calculations` map
   * using the activity's ID as the key.
   *
   * @param {Object} activity - The activity object containing the details of the activity.
   * @param {Date} ls - The latest start time for the activity.
   * @param {Date} lf - The latest finish time for the activity.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activity is an object with id and text properties, and ls and lf are Date objects
   * setCalculations(activity, new Date('2023-01-01'), new Date('2023-01-10'));
   * // This will store the LS and LF times in the calculations map for the given activity.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  setCalculations(activity, ls, lf) {
    this.calculations.set(activity.id, {
      ls,
      lf,
      text: activity.text
    });
  }
  /**
   * Calculates the latest start (LS) and latest finish (LF) times for an activity based on its links with successors and the given constraint type.
   *
   * This function creates a new instance of the `LinksConstraintCalculator` class with the provided parameters and calls its `calculate` method
   * to determine the LS and LF times for the activity.
   *
   * @param {Array<string|number>} linksWithSucessors - An array of links to successor activities.
   * @param {Object} activityReference - The activity object for which to calculate LS and LF times.
   * @param {string} constraintType - The constraint type of the activity.
   * @returns {Object} An object containing the calculated latest start (LS) and latest finish (LF) times.
   * @property {Date} ls - The calculated latest start time.
   * @property {Date} lf - The calculated latest finish time.
   *
   * @example
   * // Assuming linksWithSucessors is an array of link IDs, activityReference is an activity object,
   * // and constraintType is a string representing the constraint type
   * const result = calculateLfandLsFromLinks([1, 2, 3], activityReference, 'mso');
   * console.log()
 // { ls: Date, lf: Date }
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculateLfandLsFromLinks(
    linksWithSucessors,
    activityReference,
    constraintType,
    parentsWithFullProgress,
    parentsThatImpactChildrens
  ) {
    return new LinksConstraintCalculator({
      links: linksWithSucessors,
      activity: activityReference,
      calculatedActivitiesMap: this.calculations,
      calculatedForwardActivitiesMap: this.forwardPathCalculations,
      constraint: constraintType,
      parentsWithFullProgress,
      parentsThatImpactChildrens,
      ganttInstance: this.gantt
    }).calculate();
  }
  /**
   * Recalculates the latest start (LS) and latest finish (LF) times for an activity based on its constraint type.
   *
   * This function fetches the activity's calendar, calculates the restriction based on the constraint type,
   * updates the constraint map, and sets the new calculations for the activity.
   *
   * @param {Object} activity - The activity object containing the details of the activity.
   * @param {string} constraintType - The constraint type of the activity.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activity is an object with calendar_id property, and constraintType is 'mso'
   * recalculateInBaseRestriction(activity, 'mso');
   * // This will recalculate the LS and LF times for the activity based on the constraint type and update the internal calculations.
   *
   * @throws {Error} If an error occurs during the calculation process, such as missing calendar data.
   */
  recalculateInBaseRestriction(activity, constraintType) {
    try {
      const activityCalendar = this.gantt.getCalendar(activity.calendar_id);
      if (!activityCalendar) {
        throw new Error(
          `Error trying to get calendar with id: ${activity.calendar_id}`
        );
      }
      let restriction = calculateRestriction(
        activityCalendar,
        activity,
        constraintType
      );
      this.constraintMap.set(activity, restriction);
      this.setCalculations(activity, restriction.ls, restriction.lf);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  cleanAllActivityParentsFromLinks(links) {
    //Temporary code for parent activities
    return links
      .map((link) => {
        let sourceId = this.gantt.getLink(link).source;
        let targetId = this.gantt.getLink(link).target;
        if (
          !this.gantt.getTask(sourceId) ||
          this.gantt.getTask(sourceId)?.type === 'project'
        )
          return;
        if (
          !this.gantt.getTask(targetId) ||
          this.gantt.getTask(targetId)?.type === 'project'
        )
          return;

        return link;
      })
      .filter(Boolean);
  }

  doCalculationForActivitiesThatStartLinkButHisParentIsLinked(activity) {
    const parent = Number(activity.parent);
    const parentLinkCalcualation =
      this.calculationsOfParentThatImpactChildrens.get(parent);

    let ls = 0;
    let lf = 0;
    if (
      [
        CONSTRAINT_TYPES.ASAP,
        CONSTRAINT_TYPES.ALAP,
        CONSTRAINT_TYPES.SNET,
        CONSTRAINT_TYPES.FNET
      ].includes(activity.constraint_type)
    ) {
      if (Number(activity.progress) > 0) {
        const endDateOfProject = this.projectDates.end_date;
        lf = Math.min(endDateOfProject, parentLinkCalcualation.lf);
        ls = activity.start_date;
      } else {
        lf = parentLinkCalcualation.lf;
        ls = addDurationToDate(
          this.gantt.getCalendar(activity.calendar_id),
          lf,
          -activity.duration,
          activity
        );
      }
    }

    if ([CONSTRAINT_TYPES.SNLT].includes(activity.constraint_type)) {
      const constraintDate = activity.constraint_date;
      const parentLinkCalcualation =
        this.calculationsOfParentThatImpactChildrens.get(parent);

      const lsToComparison = addDurationToDate(
        this.gantt.getCalendar(activity.calendar_id),
        parentLinkCalcualation.lf,
        -activity.duration,
        activity
      );

      ls = new Date(
        Math.min(constraintDate.getTime(), lsToComparison.getTime())
      );
      lf = addDurationToDate(
        this.gantt.getCalendar(activity.calendar_id),
        ls,
        activity.duration,
        activity
      );
    }
    if ([CONSTRAINT_TYPES.FNLT].includes(activity.constraint_type)) {
      const constraintDate = activity.constraint_date;
      const parentLinkCalcualation =
        this.calculationsOfParentThatImpactChildrens.get(parent);

      ls = new Date(
        Math.min(constraintDate.getTime(), parentLinkCalcualation.lf.getTime())
      );
      lf = addDurationToDate(
        this.gantt.getCalendar(activity.calendar_id),
        ls,
        activity.duration,
        activity
      );
    }

    if ([CONSTRAINT_TYPES.MSO].includes(activity.constraint_type)) {
      ls = activity.constraint_date;
      lf = addDurationToDate(
        this.gantt.getCalendar(activity.calendar_id),
        ls,
        activity.duration,
        activity
      );
    }

    if ([CONSTRAINT_TYPES.MFO].includes(activity.constraint_type)) {
      lf = activity.constraint_date;
      ls = addDurationToDate(
        this.gantt.getCalendar(activity.calendar_id),
        lf,
        -activity.duration,
        activity
      );
    }

    const endDateOfTheProject = this.projectDates.end_date;
    const allCalculations = [
      {
        ls,
        lf
      }
    ];
    allCalculations.push({
      ls: addDurationToDate(
        this.gantt.getCalendar(activity.calendar_id),
        endDateOfTheProject,
        -activity.duration,
        this.activity
      ),
      lf: endDateOfTheProject
    });
    const minDates = this.getMinLfFromLinks(allCalculations);
    this.calculations.set(activity.id, {
      ls: minDates.ls,
      lf: minDates.lf,
      text: activity.text
    });
  }

  getMinLfFromLinks(allLinksCalculations = []) {
    if (!allLinksCalculations.length) {
      return false;
    }
    return allLinksCalculations.reduce((min, activity) =>
      activity.lf < min.lf ? activity : min
    );
  }
}

export default BackwardPath;
