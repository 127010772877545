import React from 'react';
import { Image } from '@react-pdf/renderer';
import child_arrow from '../../../assets/img/child-arrow.png';
import moment from 'moment';

export const NUMWEEKSPERPAGE = 4;
export const DAYSPERWEEK = 7;

export const formatText = (text, maxLength) => {
  if (!maxLength) {
    return text || '';
  }
  if (text.length > maxLength) {
    return `${text.substr(0, maxLength - 3)} ...`;
  }

  return text || '';
};

export const arrowParentTask = (parent) => (
  <Image
    style={{
      width: '6px',
      height: '6px',
      marginLeft: isNaN(parent) ? '0px' : parent + 'px',
      display: parent == 0 || isNaN(parent) ? 'none' : ''
    }}
    source={child_arrow}
  />
);

export const calculateDate = (baseDate, pageIndex, day) => {
  const WORK_DAY_START_HOUR = 9;
  return moment(baseDate)
    .add(pageIndex * NUMWEEKSPERPAGE * DAYSPERWEEK, 'days')
    .lang('es')
    .set('hour', WORK_DAY_START_HOUR)
    .add('days', day);
};

export const convertToJsDate = (momentDate) => {
  if (!momentDate || !momentDate.isValid()) {
    throw new Error('Invalid moment date provided');
  }
  return new Date(momentDate.format('YYYY/MM/DD H:mm'));
};

export const getCalendarForActivity = (ganttAPI, activity) => {
  if (!ganttAPI) {
    throw new Error('ganttAPI is requested');
  }
  if (!activity) {
    throw new Error('Activity is requested');
  }
  if (!activity.calendarId) {
    throw new Error('Activity has no calendar ID');
  }
  const calendar = ganttAPI.getTaskCalendar(activity.calendarId);
  if (!calendar) {
    throw new Error(`Calendar not found for ID: ${activity.calendarId}`);
  }
  return calendar;
};
