import { TaskType } from '../../../../../../constants/taskType.constants';
import {
  HIDE_COLUMNS_SCHEDULE,
  HIDE_COLUMNS_LOOKAHEAD,
  SHOW_COLUMNS_SCHEDULE,
  SHOW_COLUMNS_LOOKAHEAD,
  ALL_CONTENT
} from '../constants';
import { getColumnWidthZoom } from './configPages';
import { calculateGanttVisibleRangePercentage } from './formateDate';

/**
 * Retrieves the Gantt chart instance based on the schedule setting.
 *
 * @param {boolean} isSchedule - Indicates whether to return the schedule Gantt chart instance.
 * @returns {Object} The Gantt chart instance for either schedule or lookahead based on the input.
 */
export const getGanttToExport = (isSchedule = true) => {
  const {
    to_use_react_gantt: ganttSchedule,
    ganttVisualization: ganttLookahead
  } = window;

  if (isSchedule) {
    return ganttSchedule;
  }

  return ganttLookahead;
};

/**
 * Retrieves the current zoom level of the Gantt chart.
 *
 * @param {Object} gantt - The Gantt chart instance used to get the zoom level.
 * @returns {number} The current zoom level of the Gantt chart.
 */
export const getZoomLevel = (gantt) => {
  const zoomLevel = gantt.ext.zoom.getCurrentLevel();
  return zoomLevel;
};

/**
 * Updates task properties to prepare them for PDF export.
 *
 * @param {Object} gantt - The Gantt chart instance used to retrieve and update tasks.
 * @returns {void}
 */
export const updateAllTaskForExportPDF = (gantt) => {
  const { PROJECT, MAIN } = TaskType;
  const tasks = gantt.getTaskByTime();

  tasks.forEach((task) => {
    const { $index, $expanded_branch, should_be_showed, hide, type } = task;
    const isProject = type === PROJECT || type === MAIN;
    const isVisible = should_be_showed && $expanded_branch && !hide;
    const barWidth = gantt.getTaskPosition(task);

    task.isProjectInExport = isProject;
    task.indexInExport = $index;
    task.visibleInExport = isVisible;
    task.barWidthExport = barWidth.width;
  });
};

/**
 * Shows or hides specified columns in the Gantt chart.
 *
 * @param {Object} gantt - The Gantt chart instance used to access and modify columns.
 * @param {boolean} isHide - Whether to hide (`true`) or show (`false`) the columns.
 * @param {string[]} columns - An array of column names to be shown or hidden.
 *
 * @returns {void}
 */
const showOrHideGanttColumns = ({ gantt, isHide, columns = [] }) => {
  columns.forEach((columnName) => {
    const column = gantt.getGridColumn(columnName);
    if (column) {
      gantt.getGridColumn(columnName).hide = isHide;
    }
  });
};

/**
 * Determines which columns to show or hide based on the schedule setting.
 *
 * @param {boolean} isSchedule - Whether the Gantt chart is in schedule mode (`true`) or lookahead mode (`false`).
 *
 * @returns {Object} An object containing arrays of column names to hide and to show.
 * @returns {string[]} return.hideColummns - The columns to be hidden.
 * @returns {string[]} return.showColummns - The columns to be shown.
 */
const showAndHideGanttColumns = (isSchedule) => {
  const hideColummns = isSchedule
    ? HIDE_COLUMNS_SCHEDULE
    : HIDE_COLUMNS_LOOKAHEAD;
  const showColummns = isSchedule
    ? SHOW_COLUMNS_SCHEDULE
    : SHOW_COLUMNS_LOOKAHEAD;

  return { hideColummns, showColummns };
};

/**
 * Configures the Gantt chart before generating the PDF.
 *
 * @param {Object} gantt - The Gantt chart instance to be configured.
 * @param {boolean} isSchedule - Whether the Gantt chart is in schedule mode.
 *
 * @returns {void}
 */
export const setupGanttBeforePDFGeneration = ({ gantt, isSchedule }) => {
  gantt.unselectTask();
  gantt.detachMultiDragSelect();
  gantt.render();

  updateAllTaskForExportPDF(gantt);

  const { hideColummns, showColummns } = showAndHideGanttColumns(isSchedule);

  showOrHideGanttColumns({ gantt, isHide: true, columns: hideColummns });
  showOrHideGanttColumns({ gantt, isHide: false, columns: showColummns });
};

/**
 * Restores the Gantt chart configuration after generating the PDF.
 *
 * @param {Object} gantt - The Gantt chart instance to be restored.
 * @param {boolean} isSchedule - Whether the Gantt chart was in schedule mode.
 * @param {number} resetZoomLevel - The zoom level to reset to after PDF generation.
 *
 * @returns {void}
 */
export const setupGanttAfterPDFGeneration = ({ gantt, isSchedule }) => {
  const { hideColummns, showColummns } = showAndHideGanttColumns(isSchedule);

  showOrHideGanttColumns({ gantt, isHide: false, columns: hideColummns });
  showOrHideGanttColumns({ gantt, isHide: true, columns: showColummns });

  gantt.fixScaleDateHeader();
  gantt.adjustGridWidth();
  gantt.render();
};

/**
 * Validates if there are any tasks in the Gantt chart that are not visible based on their properties.
 *
 * @param {Object} gantt - The Gantt chart instance to be validated.
 * @returns {Object|undefined} The first task that is not visible, or `undefined` if all tasks are visible.
 */
export const validateFilteredGantt = (gantt) => {
  const tasks = gantt.getTaskByTime();

  return tasks.find((task) => {
    const {
      should_be_showed: shouldBeShowed,
      $expanded_branch: expandedBranch,
      hide
    } = task;
    const isVisible = shouldBeShowed && expandedBranch && !hide;
    return !isVisible;
  });
};

/**
 * Calculates the width of the Gantt table, adjusting for content format.
 *
 * @param {Object} gantt - The Gantt chart instance.
 * @param {boolean} isAllContent - Whether to include all content in the width calculation.
 *
 * @returns {number} The calculated width of the Gantt table.
 */
export const calculateGanttTableWidth = ({ gantt, isAllContent }) => {
  const ADD_WIDTH_ALL_CONTENT = 1.25;
  const ADD_WIDTH_DEFAULT = 1;

  const ganttTablewidth = gantt.$grid.scrollWidth;
  const addWidthByFormat = isAllContent
    ? ADD_WIDTH_ALL_CONTENT
    : ADD_WIDTH_DEFAULT;

  return ganttTablewidth * addWidthByFormat;
};

/**
 * Retrieves the width of the last column in the Gantt chart.
 *
 * @returns {number} The width of the last column in pixels. If no columns are found, returns a default width of 50 pixels.
 */
export const getCurrentColumnWidthGanttChart = () => {
  const GANTT_COLUMNS = 'gantt_scale_cell';
  const WIDTH_DEFAULT = 50;
  const ganttColumns = document.getElementsByClassName(GANTT_COLUMNS);
  const ganttColumnsCount = ganttColumns.length;

  if (!ganttColumnsCount) {
    return WIDTH_DEFAULT;
  }

  const lastColumnWidth = ganttColumns[ganttColumnsCount - 1].style.width;
  const columnWidth = parseFloat(lastColumnWidth);
  return columnWidth;
};

/**
 * Ensures the width of the Gantt chart is not less than the minimum required width.
 *
 * @param {number} width - The current width of the Gantt chart.
 * @returns {number} The width, adjusted to be at least the minimum required width (200 pixels).
 */
const minGanttWidth = (width) => {
  const MIN_TASK_WIDTH = 200;

  if (width < MIN_TASK_WIDTH) {
    return MIN_TASK_WIDTH;
  }
  return width;
};

/**
 * Calculates the width of the Gantt chart, adjusting for the visible range and column width.
 *
 * @param {Object} gantt - The Gantt chart instance.
 * @param {boolean} isAllContent - Flag indicating if all content should be considered.
 * @returns {number} The calculated width of the Gantt chart, adjusted for the visible range and minimum width constraints.
 */
export const calculateGanttChartWidth = ({ gantt, isAllContent }) => {
  const ganttChartWidth = gantt.$task_bg.scrollWidth;

  const columnWidth = getCurrentColumnWidthGanttChart();
  const columnWidthByZoom = getColumnWidthZoom({ gantt, isAllContent });
  const countColumns = ganttChartWidth / columnWidth;
  const adjustedGanttWidth = countColumns * columnWidthByZoom;

  const percentage = calculateGanttVisibleRangePercentage(gantt);
  const percentageGanttWidth = percentage * adjustedGanttWidth;
  return minGanttWidth(percentageGanttWidth);
};

/**
 * Calculates the total width of the Gantt chart by summing the table width and chart width.
 *
 * @param {Object} options - Options for calculating width.
 * @param {Object} gantt - The Gantt chart instance.
 * @param {boolean} isAllContent - Flag to determine if all content should be considered.
 * @returns {number} The total width of the Gantt chart.
 */
export const calculateGanttTotalWidth = (props) => {
  const ganttTableWidth = calculateGanttTableWidth(props);
  const ganttChartWidth = calculateGanttChartWidth(props);
  return ganttTableWidth + ganttChartWidth;
};

/**
 * Calculates the total height of the Gantt chart by summing the heights of the task background and grid.
 *
 * @param {Object} gantt - The Gantt chart instance.
 * @returns {number} The total height of the Gantt chart.
 */
export const calculateGanttTotalHeigth = (gantt) => {
  const totalHeight = gantt.$task_bg.offsetHeight + gantt.$grid.offsetHeight;
  return totalHeight;
};
