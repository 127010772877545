const earlyAccessCriticalPath = () => {
  return true;
  if (window.localStorage.getItem('user')) {
    return ['302', '299', '713', '1236'].includes(
      JSON.parse(window.localStorage.getItem('user')).companyId
    );
  }
  return false;
};
export { earlyAccessCriticalPath };
