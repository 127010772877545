import React from 'react';
import CheckSystem from '../../../../DesignSystem/CheckSystem';
import { ALL_CONTENT } from '../../../../../assets/gantt/ext/Export/ExportPDFGantt/constants';

export default function FitOnePage({
  t,
  formatExport,
  isFitOnePage,
  setIsFitOnePage
}) {
  return (
    <>
      <CheckSystem
        checked={isFitOnePage}
        withLabel={true}
        label={t('export_pdf_modal.fit_one_page')}
        onClickFunction={(value) => setIsFitOnePage(value)}
        disabled={formatExport === ALL_CONTENT}
      />
    </>
  );
}
