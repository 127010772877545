import { isValidDate } from './isValidDate';
/**
 * Adds a specified duration to a given date based on the activity's calendar.
 * @param {Object} activityCalendar - The calendar object associated with the activity.
 * @param {Date} date - The starting date for the calculation.
 * @param {number} duration - The duration to add to the date, in hours.
 * @param {Object} activity - The activity object associated with the date calculation.
 * @returns {Date} - The calculated end date after adding the duration.
 */

function addDurationToDate(activityCalendar, date, duration, activity) {
  if (!isValidDate(new Date(date))) {
    throw new Error(
      `Invalid date argument for calculateEndDate method. Date: ${date}, Activity: ${JSON.stringify(activity)}`
    );
  }

  const dateToCalculate = date;
  return activityCalendar.calculateEndDate({
    start_date: new Date(dateToCalculate),
    duration: duration,
    unit: 'hour',
    task: activity
  });
}

export { addDurationToDate };
