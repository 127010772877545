import {
  delete_old_cache,
  workerService
} from '../services/serviceworker.service';

import * as Sentry from '@sentry/browser';
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

/**
 * This function register by now only one service worker
 * @param {*} config configuration for registering workers at browser
 * @returns Null if there is some CDN problem
 */
export function register(config) {
  return;
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/index.worker.js`;
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

/**
 * This function register a service worker and adds to adds a map for reactWorkers object
 * @param {*} swUrl valid url for a service worker in workers public folder
 * @param {*} config config comming from register command at index.js
 * @param {*} workerName worker name which is going to be mapped
 */
function registerValidSW(swUrl, config, workerName = 'default') {
  return;
  navigator.serviceWorker
    .register(swUrl, {
      scope: '/'
    })
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            window.location.reload();
            if (navigator.serviceWorker.controller) {
              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      Sentry.captureMessage(error.message, 'warning');
    });
}

/**
 * This functions fetchs an URL to get a service worker, and check if it exist and if it is valid
 * @param {*} swUrl url for a valid service worker
 * @param {*} config configuration given through register method
 * @param {*} workerName worker name to be mapped at reactWorkers object
 */
function checkValidServiceWorker(swUrl, config, workerName = '') {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' }
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config, workerName);
      }
    })
    .catch((e) => {
      Sentry.captureMessage(e.message, 'warning');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        Sentry.captureMessage(error.message, 'warning');
      });
  }
}

window.updateCacheAndWorkers = () => {
  unregister();
  setTimeout(() => {
    if (!navigator.serviceWorker.controller) {
      Sentry.captureMessage('No navigator service worker', 'warning');
      return;
    }
    workerService.callAction(delete_old_cache, [], () => {
      Sentry.captureMessage('cant delete cache', 'warning');
    });
  }, 1000);
};
unregister();
