import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Outbuild from '../../../../assets/img/proplanner.svg';
import InputSystem from '../../../../components/DesignSystem/InputSystem';
import { userService, authService, projectService } from '../../../../services';
import { base } from '../../../../services/base';
import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import './index.scss';
import { httpCodes } from '../../../../constants/httpCodes';
import {
  redirectSelectCompany,
  setSessionForFreeTrial
} from '../../../login/login.helpers';

const INPUT_TYPE_TEXT = 'text';
const INPUT_TYPE_PASSWORD = 'password';
const INPUT_TYPE_PASSWORD2 = 'password2';
const REGEX_PASSWORD = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

const DEFAULT_DATA_FORM = {
  first_name: '',
  last_name: '',
  password: '',
  password2: ''
};

const QuarterScreenA = (props) => {
  const { t, userData } = props;

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [showErrorPassword, setShowErrorPassword] = useState(false);
  const [showErrorPassword2, setShowErrorPassword2] = useState(false);
  const [dataForm, setDataForm] = useState(DEFAULT_DATA_FORM);

  useEffect(() => {
    trackingEvent('create_user_form_viewed', null, AMPLITUDE_SERVICE);
  }, []);

  const handleInputChange = (event) => {
    setDataForm({
      ...dataForm,
      [event.target.name]: event.target.value
    });
  };

  const handleOnBlur = (type) => {
    if (type === INPUT_TYPE_PASSWORD) {
      setShowErrorPassword(!REGEX_PASSWORD.test(dataForm.password));
    }
    if (type === INPUT_TYPE_PASSWORD2) {
      if (dataForm.password !== '' && dataForm.password2 !== '') {
        setShowErrorPassword2(dataForm.password !== dataForm.password2);
      }
    }
  };

  const isNotValidFormatEmail = () =>
    dataForm.first_name === '' ||
    dataForm.last_name === '' ||
    !REGEX_PASSWORD.test(dataForm.password) ||
    !REGEX_PASSWORD.test(dataForm.password2) ||
    dataForm.password !== dataForm.password2;

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingButton(true);

    const data = {
      id: userData.user.id,
      first_name: dataForm.first_name,
      last_name: dataForm.last_name,
      email: userData.user.email.toLowerCase(),
      password: dataForm.password,
      company_id: userData.company.id,
      flow_free_trial_company_detected: true,
      flow_create_new_company: false,
      role: 'projectleader',
      isFreeTrial: true
    };

    try {
      if (!isNotValidFormatEmail()) {
        const responseUser = await userService.updateUserFreeTrial(data);
        if (responseUser.status === 200) {
          const dataProject = {
            userId: userData.user.id,
            name: dataForm.first_name,
            companyId: userData.company.id,
            isFreeTrial: true
          };

          const dataAuth = {
            user: userData.user.email,
            password: dataForm.password
          };

          const responseAuth = await authService.index(dataAuth);
          if (responseAuth.status !== httpCodes.OK) return;

          setSessionForFreeTrial(responseAuth);

          const responseDuplicateProject =
            await projectService.duplicateProject(dataProject);

          if (responseDuplicateProject.status === 200) {
            trackingEvent(
              'existing_company_signup_form_filled',
              {
                action_taken: 'account_created',
                company_existence_checked: true
              },
              AMPLITUDE_SERVICE
            );

            const dataAssign = {
              projectId: responseDuplicateProject.data.projectId,
              email: userData.user.email.toLowerCase(),
              isFreeTrial: true
            };

            await projectService.assignProjectFreeTrialSuperAdmin(dataAssign);

            if (responseAuth.status === httpCodes.OK)
              redirectSelectCompany(responseAuth, props.history);
          }
        }
      }
    } catch (err) {
      localStorage.removeItem('signed');
      localStorage.removeItem('firsTimeLogin');
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
      console.log(`Error in SignUpConfirmation - onSubmit(), ${err}`);
    }
  };

  return (
    <div className="signupconfirmation__quarter-screen-a">
      <div className="signupconfirmation__quarter-screen-a__logo">
        <img src={Outbuild} alt="Logo Outbuild" />
      </div>
      <div className="signupconfirmation__quarter-screen-a__body">
        <div className="body__title">
          <h6>{t('signup.case_a.quarter_screen_title')}</h6>
        </div>
        <form onSubmit={onSubmit} className="body__form">
          <div className="form__inputs">
            {InputSystem({
              label: t('signup.case_a.quarter_screen_first_name_label'),
              size: 'large',
              onChange: handleInputChange,
              onBlur: () => handleOnBlur(INPUT_TYPE_TEXT),
              name: 'first_name',
              autoComplete: 'off'
            })}
          </div>
          <div className="form__inputs">
            {InputSystem({
              label: t('signup.case_a.quarter_screen_last_name_label'),
              size: 'large',
              onChange: handleInputChange,
              onBlur: () => handleOnBlur(INPUT_TYPE_TEXT),
              name: 'last_name',
              autoComplete: 'off'
            })}
          </div>
          <div className="form__password">
            {InputSystem({
              label: t('signup.case_a.quarter_screen_password_label'),
              size: 'large',
              hint: t('signup.case_a.quarter_screen_password_hint'),
              type: 'password',
              onChange: handleInputChange,
              onBlur: () => handleOnBlur(INPUT_TYPE_PASSWORD),
              name: 'password',
              autoComplete: 'off',
              isError: showErrorPassword
            })}
          </div>
          <div className="form__password2">
            {InputSystem({
              label: t('signup.case_a.quarter_screen_password2_label'),
              size: 'large',
              type: 'password',
              onChange: handleInputChange,
              onBlur: () => handleOnBlur(INPUT_TYPE_PASSWORD2),
              name: 'password2',
              autoComplete: 'off',
              hint: showErrorPassword2
                ? t('signup.case_a.quarter_screen_password2_hint')
                : '',
              isError: showErrorPassword2
            })}
          </div>
          <div className="form__button">
            <button
              type="submit"
              disabled={isNotValidFormatEmail() || isLoadingButton}>
              {isLoadingButton ? (
                <LoadingOutlined />
              ) : (
                t('signup.case_a.quarter_screen_button_text')
              )}
            </button>
          </div>
        </form>
        <div className="body__hint">
          <span>
            {t('signup.case_a.quarter_screen_text')}{' '}
            <a
              href="https://www.outbuild.com/terms-and-conditions"
              target="_blank">
              {t('signup.case_a.quarter_screen_link_text')}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuarterScreenA;
