import LinksRulesCalculationsMethods from '../base/LinksRulesCalculationsMethods';
import { getMaxEarlyStartSet } from '../helpers/getMaxEarlyStartSet';
import CriticalPathHelpers from '../../base/generic-calculations';
import { addDurationToDate } from '../../helpers/index';
class LinksConstraintCalculator extends LinksRulesCalculationsMethods {
  constructor(calculationObject) {
    super(calculationObject);
  }
  /**
   * Calculates the earliest start dates for all links and returns the maximum early start set.
   *
   * This function processes each link in the `links` array, retrieves the necessary link data, and calculates
   * the earliest start dates using the `calculateLink` method. It then returns the maximum early start set
   * by calling `getMaxEarlyStartSet`.
   *
   * @returns {Object} The maximum early start set calculated from all links.
   *
   * @example
   * // Assuming links is an array of link IDs [1, 2, 3]
   * // and gantt.getLink(id) returns link data for each ID
   * const result = calculate();
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculate() {
    if (this.links.length === 0) {
      return new CriticalPathHelpers(
        this.gantt,
        'forward'
      ).calculateStartAndFinishOfChainOrigin(this.referenceActivity);
    }
    const allLinksCalculations = this.links.map((link) => {
      const linkData = this.gantt.getLink(link);
      if (!linkData) {
        throw new Error(`Link data not found with id ${link}`);
      }
      return this.calculateLink(linkData);
    });

    const parent = Number(this.referenceActivity.parent);
    if (this.parentsWithLinks.has(parent)) {
      let activityCalendar = this.gantt.getCalendar(
        this.referenceActivity.calendar_id
      );
      const es = this.parentsCalculations.get(parent).es;

      const ef = addDurationToDate(
        activityCalendar,
        es,
        this.referenceActivity.duration,
        this.referenceActivity
      );

      allLinksCalculations.push({ es, ef });
    }

    return getMaxEarlyStartSet(allLinksCalculations);
  }
  /**
   * Calculates the link based on its type.
   *
   * This function determines the appropriate calculation method for the link based on its type and executes it.
   * The link type is mapped to specific calculation methods: Finish-to-Start (FS), Start-to-Start (SS),
   * Finish-to-Finish (FF), and Start-to-Finish (SF).
   *
   * @param {Object} linkData - The data of the link to be calculated. It should include a `type` property that indicates the type of link.
   * @returns {*} The result of the calculation based on the link type.
   *
   * @example
   * // Assuming linkData is an object with a type property
   * const result = calculateLink({ type: 0, ...otherLinkProperties });
   * // This will call the calculateFS method with the linkData and return the result
   *
   * @throws {Error} If the link type is not recognized.
   */
  calculateLink(linkData) {
    const calculationMapping = {
      0: () => this.calculateFS(linkData),
      1: () => this.calculateSS(linkData),
      2: () => this.calculateFF(linkData),
      3: () => this.calculateSF(linkData)
    };

    return calculationMapping[linkData.type]();
  }
}

export { LinksConstraintCalculator };
