import { log } from '../../../../../monitor/monitor';
import filters from '../../helpers/filters';

/**
 * Identifies second-level activities that can be calculated.
 * @param {Object} params - The parameters object.
 * @param {Object} params.gantt - The Gantt instance.
 * @param {Set<number>} params.pendingParentsWithNoLinks - Set of parent IDs with no links.
 * @param {Map<number, any>} params.calculations - Map of calculated activities.
 * @param {string} params.linkProperty - The link property ('$source' or '$target').
 * @param {Function} params.getArrayOfLinkedActivities - Function to get array of linked activities.
 * @param {Object} params.filters - Object containing filter functions.
 * @returns {Set<number>} Set of second-level activity IDs that can be calculated.
 */
export function identifySecondLevelActivities(params) {
  const { gantt, pendingParentsWithNoLinks, calculations, linkProperty } =
    params;

  try {
    const subprojectsWithNoLinks = getSubprojectsWithNoLinks({
      gantt,
      pendingParentsWithNoLinks
    });

    if (subprojectsWithNoLinks.length === 0) {
      return new Set();
    }

    const allChildrenFromParents = getAllChildrenFromParents({
      gantt,
      subprojectsWithNoLinks
    });

    const childrenThatCanBeCalculated = filterChildrenThatCanBeCalculated({
      gantt,
      childrenIds: allChildrenFromParents,
      linkProperty,
      getArrayOfLinkedActivities,
      calculations
    });

    return new Set(childrenThatCanBeCalculated.map((activity) => activity.id));
  } catch (e) {
    log('Critical Path', 'Error in identifySecondLevelActivities');
    throw e;
  }
}

/**
 * Retrieves subprojects (parent activities) with no links.
 * @param {Object} params - The parameters object.
 * @param {Object} params.gantt - The Gantt instance.
 * @param {Set<number>} params.pendingParentsWithNoLinks - Set of parent IDs with no links.
 * @returns {Array<number>} Array of subproject IDs with no links.
 */
function getSubprojectsWithNoLinks(params) {
  const { gantt, pendingParentsWithNoLinks } = params;

  return gantt
    .getTaskByTime()
    .filter(filters.filterByParentType)
    .filter((activity) => pendingParentsWithNoLinks.has(activity.id))
    .map((activity) => activity.id);
}

/**
 * Retrieves all children from the given parent subprojects.
 * @param {Object} params - The parameters object.
 * @param {Object} params.gantt - The Gantt instance.
 * @param {Array<number>} params.subprojectsWithNoLinks - Array of subproject IDs.
 * @returns {Set<number>} Set of child activity IDs.
 */
function getAllChildrenFromParents(params) {
  const { gantt, subprojectsWithNoLinks } = params;

  const allChildrenSet = new Set();

  for (const subprojectId of subprojectsWithNoLinks) {
    const childrenIds = gantt.getChildren(subprojectId);

    const validChildren = childrenIds.filter((childId) => {
      const childActivity = gantt.getTask(childId);
      const isTaskOrMilestone = filters.byTaskTypeAndMilestone(childActivity);
      const hasProgressLessThan100 = Number(childActivity.progress) < 100;
      return isTaskOrMilestone && hasProgressLessThan100;
    });

    validChildren.forEach((childId) => allChildrenSet.add(childId));
  }

  return [...allChildrenSet];
}

/**
 * Filters children activities that can be calculated.
 * @param {Object} params - The parameters object.
 * @param {Object} params.gantt - The Gantt instance.
 * @param {Array<number>} params.childrenIds - Array of child activity IDs.
 * @param {string} params.linkProperty - The link property ('$source' or '$target').
 * @param {Function} params.getArrayOfLinkedActivities - Function to get array of linked activities.
 * @param {Map<number, any>} params.calculations - Map of calculated activities.
 * @returns {Array<Object>} Array of child activities that can be calculated.
 */
function filterChildrenThatCanBeCalculated(params) {
  const {
    gantt,
    childrenIds,
    linkProperty,
    getArrayOfLinkedActivities,
    calculations
  } = params;

  const calculableChildren = [];

  for (const childId of childrenIds) {
    const activity = gantt.getTask(childId);
    const links = activity[linkProperty] || [];
    const linkedActivities = getArrayOfLinkedActivities({
      gantt,
      links,
      linkDirection: linkProperty === '$source' ? 'target' : 'source'
    });

    const isLinkedWithCalculatedOrSibling = linkedActivities.every(
      (linkedActivityId) => calculations.has(linkedActivityId)
    );

    if (isLinkedWithCalculatedOrSibling) {
      calculableChildren.push(activity);
    }
  }

  return calculableChildren;
}

/**
 * Retrieves an array of linked activity IDs.
 * @param {Object} params - The parameters object.
 * @param {Object} params.gantt - The Gantt instance.
 * @param {Array<number>} params.links - Array of link IDs.
 * @param {string} params.linkDirection - The link direction ('source' or 'target').
 * @returns {Array<number>} Array of linked activity IDs.
 */
function getArrayOfLinkedActivities(params) {
  const { gantt, links, linkDirection } = params;

  const linkedActivityIds = [];

  for (const linkId of links) {
    const link = gantt.getLink(linkId);
    if (!link) {
      log('Critical Path', `Link data not found with id ${linkId}`);
      continue;
    }
    const linkedActivityId = Number(link[linkDirection]);
    linkedActivityIds.push(linkedActivityId);
  }

  return linkedActivityIds;
}
