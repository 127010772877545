import React from 'react';
import FormatPage from '../../atoms/FormatPage/FormatPage';
import FitOnePage from '../../atoms/FitOnePage/FitOnePage';
import TrimpParent from '../../atoms/TrimpParent/TrimpParent';
import './PageTab.scss';

export default function PageTab({
  t,
  isSchedule,
  formatExport,
  setFormatExport,
  isFitOnePage,
  setIsFitOnePage,
  isTrimParent,
  setIsTrimParent
}) {
  const marginTop = isSchedule ? 20 : 0;

  return (
    <div className="tab--page" style={{ marginTop }}>
      <div className="tab--page__title">
        <h5>{t('export_pdf_modal.tabs_page_subtitle')}</h5>
      </div>
      <div className="tab--page__format">
        <label>{t('export_pdf_modal.select_format')}</label>
        <div className="format__dropdown">
          <FormatPage
            t={t}
            formatExport={formatExport}
            setFormatExport={setFormatExport}
            setIsFitOnePage={setIsFitOnePage}
          />
        </div>
      </div>
      <div className="tab--page__scaling">
        <label>{t('export_pdf_modal.scaling_options')}</label>
        <FitOnePage
          t={t}
          formatExport={formatExport}
          isFitOnePage={isFitOnePage}
          setIsFitOnePage={setIsFitOnePage}
        />
      </div>
      <div className="tab--page__trim">
        <TrimpParent
          t={t}
          isSchedule={isSchedule}
          isTrimParent={isTrimParent}
          setIsTrimParent={setIsTrimParent}
        />
      </div>
    </div>
  );
}
