import { activityPdfService } from '../../../../../services';
import { checkBackendAccess } from '../../../../../utils/checkBackendAccess';
import {
  getCurrentProjectState,
  getCurrentSectorState,
  getSignedUser
} from '../../../../../utils/userUtils';
import { ALL_CONTENT } from './constants';
import { getPageDimensions, langExportPDF } from './helpers/configPages';
import {
  getGanttToExport,
  getZoomLevel,
  setupGanttAfterPDFGeneration,
  setupGanttBeforePDFGeneration
} from './helpers/updateGantt';
import {
  showExportPDFAlert,
  showNetworkBlockAlert
} from './helpers/notifyUser';
import {
  createFileExportToPDF,
  getExportPDFName,
  handleEventResultExportPDF
} from './helpers/exportPDF';
import {
  trackingEventExportPDFResult,
  trackingEventUnknown
} from './helpers/trackingEvent';
import * as Sentry from '@sentry/react';

/**
 * Generates and exports the Gantt chart as a PDF file.
 *
 * @param {Object} gantt - The Gantt chart instance to be exported.
 * @param {Function} t - Translation function for localization.
 * @param {Object} config - Configuration settings for the PDF export.
 * @param {string} config.formatToExport - The format of the export (e.g., all content, visible content).
 * @param {boolean} isSchedule - Determines if the export is for a schedule or lookahead.
 *
 * @returns {Promise<Object>} A promise that resolves with the response from the PDF export service.
 */
const createPDFExport = async ({
  gantt,
  t,
  projectState,
  config,
  isSchedule
}) => {
  const lang = langExportPDF();

  const { formatToExport } = config;

  setupGanttBeforePDFGeneration({ gantt, isSchedule });
  const pageDimensions = getPageDimensions({ gantt, formatToExport });

  const fileExportToPDF = createFileExportToPDF({
    gantt,
    t,
    lang,
    pageDimensions,
    isLookahead: !isSchedule,
    config
  });

  const currentUser = getSignedUser();
  const jsonData = JSON.stringify(fileExportToPDF);

  const { id: projectId, name: projectName } =
    getCurrentProjectState(projectState);
  const { id: sectorId, name: sectorName } =
    getCurrentSectorState(projectState);

  const exportName = getExportPDFName({
    gantt,
    isSchedule,
    projectName,
    sectorName,
    formatToExport
  });

  const exportPDFData = {
    data: jsonData,
    name: exportName,
    current_user: currentUser,
    lang,
    project_id: projectId,
    project: projectName,
    sector_id: sectorId,
    sector: sectorName,
    is_schedule: isSchedule
  };

  const response = await activityPdfService.exportToPDF(exportPDFData);

  setupGanttAfterPDFGeneration({
    gantt,
    isSchedule
  });

  return response;
};

/**
 * Initiates the process to export the Gantt chart as a PDF file.
 *
 * @param {Object} params - The parameters for the export process.
 * @param {boolean} [params.isSchedule=true] - Indicates if the export is for a schedule (default is true).
 * @param {Function} params.t - Translation function for localization (required).
 * @param {Function} [params.handleCloseModal] - Function to handle closing the modal (optional).
 * @param {Function} [params.openFitToOnePageModal] - Function to handle opening the fit-to-one-page modal (optional).
 * @param {Object} params.projectState - The current state of the project (required).
 * @param {Object} [params.config={}] - Configuration settings for the export (optional).
 * @param {boolean} [params.config.isTrimParent=false] - Whether to trim the parent task in the export (default is false).
 * @param {boolean} [params.config.isFitOnePage=false] - Whether to fit the export to a single page (default is false).
 * @param {string} [params.config.formatToExport=ALL_CONTENT] - The format of the export (default is ALL_CONTENT).
 * @param {boolean} [params.config.isShowTodayLinePDF=true] - Whether to show the "today" line in the PDF (default is true).
 * @param {Date} [params.config.printedOn=new Date()] - The print date for the export (default is current date).
 * @param {Date} [params.config.alignLineDate=new Date()] - The date to align the line for export (default is current date).
 *
 * @returns {Promise<void>} A promise that resolves when the PDF export process is complete.
 *
 * @throws {Error} If an error occurs during the export process.
 */
export const exportPDFGantt = async ({
  isSchedule = true,
  t,
  handleCloseModal = (_) => {},
  openFitToOnePageModal = (_) => {},
  projectState,
  config = {}
}) => {
  try {
    const gantt = getGanttToExport(isSchedule);

    if (!gantt) {
      return;
    }

    const statusServer = await checkBackendAccess(activityPdfService);
    if (!statusServer) {
      showNetworkBlockAlert({ t, isSchedule });
      return;
    }

    const zoomLevel = getZoomLevel(gantt);

    const now = new Date();
    const {
      isTrimParent = false,
      isFitOnePage = false,
      formatToExport = ALL_CONTENT,
      isShowTodayLinePDF = true,
      printedOn = now,
      alignLineDate = now
    } = config;

    const configPage = {
      isFitOnePage,
      isTrimParent,
      formatToExport,
      isShowTodayLinePDF,
      printedOn,
      alignLineDate
    };

    const exportResult = await createPDFExport({
      gantt,
      t,
      projectState,
      config: configPage,
      isSchedule,
      zoomLevel
    });

    const eventResult = showExportPDFAlert({ isSchedule, t, exportResult });

    handleEventResultExportPDF({ exportResult, gantt, openFitToOnePageModal });
    trackingEventExportPDFResult({
      gantt,
      isSchedule,
      zoomLevel,
      configPage,
      eventResult
    });
  } catch (error) {
    trackingEventUnknown({ t, isSchedule });
    Sentry.captureException(error);
  } finally {
    handleCloseModal();
  }
};
