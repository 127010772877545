class ActivityCalculator {
  constructor(options) {
    this.gantt = options.gantt;
    this.direction = options.direction;
    this.pendingParentsWithNoLinks = options.pendingParentsWithNoLinks;
    this.pendingParentsWithLinks = options.pendingParentsWithLinks;
    this.calculationOfParentsFromLinks = options.calculationOfParentsFromLinks;
    this.singleParents = options.singleParents;
    this.calculations = options.calculations;
    this.activitiesStartChainButChildrenOfLinkedParents =
      options.activitiesStartChainButChildrenOfLinkedParents;
    this.linkProperty = options.linkProperty;
    this.linkDirection = options.linkDirection;
  }

  /**
   * Checks which activities can now be calculated based on their dependencies.
   * @param {Set<number>} pendingToCalculate - Set of activity IDs pending calculation.
   * @returns {Object} An object containing activities that can be calculated and remaining pending activities.
   */
  checkActivitiesThatNowCanBeCalculated(pendingToCalculate) {
    const activitiesThatCanBeCalculated = new Set();
    const pendingActivities = new Set(pendingToCalculate);

    for (const activityId of pendingToCalculate) {
      const activityData = this.gantt.getTask(activityId);
      if (activityData.type === 'project') {
        this.handleProjectActivity(
          activityId,
          activityData,
          activitiesThatCanBeCalculated,
          pendingActivities
        );
      } else {
        this.handleNonProjectActivity(
          activityId,
          activityData,
          activitiesThatCanBeCalculated,
          pendingActivities
        );
      }
    }

    return {
      activitiesThatCanBeCalculated,
      pendingActivities
    };
  }

  /**
   * Handles logic for project-type activities.
   */
  handleProjectActivity(
    activityId,
    activityData,
    activitiesThatCanBeCalculated,
    pendingActivities
  ) {
    const isPendingParentWithNoLinks =
      this.pendingParentsWithNoLinks.has(activityId);
    const isPendingParentWithLinks =
      this.pendingParentsWithLinks.has(activityId);
    const hasCalculationFromLinks =
      this.calculationOfParentsFromLinks.has(activityId);

    if (isPendingParentWithNoLinks) {
      const children = this.singleParents.get(activityId)?.childrens;
      if (!children) {
        return;
      }
      const areChildrenCalculated = children.every((childId) =>
        this.calculations.has(childId)
      );

      if (areChildrenCalculated) {
        activitiesThatCanBeCalculated.add(activityId);
        pendingActivities.delete(activityId);
      }
      return;
    }

    if (isPendingParentWithLinks && hasCalculationFromLinks) {
      const children = this.singleParents.get(activityId)?.childrens;
      if (!children) {
        return;
      }
      const areChildrenCalculated = children.every((childId) =>
        this.calculations.has(childId)
      );

      if (areChildrenCalculated) {
        activitiesThatCanBeCalculated.add(activityId);
        pendingActivities.delete(activityId);
      }
      return;
    }

    if (isPendingParentWithLinks && !hasCalculationFromLinks) {
      const canBeCalculated = this.canCalculateParentActivity(activityData);

      if (canBeCalculated) {
        activitiesThatCanBeCalculated.add(activityId);
        pendingActivities.delete(activityId);
      }
      return;
    }
  }

  /**
   * Determines if a parent activity can be calculated.
   */
  canCalculateParentActivity(activityData) {
    const hasNoLinks = (activityData[this.linkProperty] || []).length === 0;
    const constraintType = activityData.constraint_type;
    const isSpecialConstraintType =
      this.direction === 'forward'
        ? constraintType === 'fnlt' || constraintType === 'snlt'
        : constraintType === 'fnlt';

    if (isSpecialConstraintType && hasNoLinks) {
      return true;
    }

    const linkedActivities = this.getArrayOfLinkedActivities(
      activityData[this.linkProperty]
    );
    return linkedActivities.every((activityId) =>
      this.calculations.has(activityId)
    );
  }

  /**
   * Handles logic for non-project activities.
   */
  handleNonProjectActivity(
    activityId,
    activityData,
    activitiesThatCanBeCalculated,
    pendingActivities
  ) {
    const parentId = Number(activityData.parent);
    const isParentPendingWithLinks = this.pendingParentsWithLinks.has(parentId);
    const hasParentCalculationFromLinks =
      this.calculationOfParentsFromLinks.has(parentId);
    if (isParentPendingWithLinks && !hasParentCalculationFromLinks) {
      return;
    }

    if (this.direction === 'backward') {
      const isStartChainChildOfLinkedParent =
        this.activitiesStartChainButChildrenOfLinkedParents.has(activityId);
      if (isStartChainChildOfLinkedParent && !hasParentCalculationFromLinks) {
        return;
      }
    }

    if (
      activityData['$target'].length === 0 &&
      activityData['$source'].length === 0
    ) {
      activitiesThatCanBeCalculated.add(activityId);
      pendingActivities.delete(activityId);
      return;
    }

    const linkedActivities = this.getLinkedActivities(
      activityData[this.linkProperty]
    );
    const arePredecessorsCalculated = linkedActivities.every((act) =>
      this.calculations.has(act.id)
    );

    if (this.direction === 'backward') {
      const hasNoLinkedActivities = linkedActivities.length === 0;

      if (
        (hasNoLinkedActivities || arePredecessorsCalculated) &&
        hasParentCalculationFromLinks
      ) {
        activitiesThatCanBeCalculated.add(activityId);
        pendingActivities.delete(activityId);
        return;
      }
    }

    if (arePredecessorsCalculated) {
      activitiesThatCanBeCalculated.add(activityId);
      pendingActivities.delete(activityId);
    }
  }

  /**
   * Retrieves linked activities based on link property.
   */
  getLinkedActivities(predecessors) {
    if (!Array.isArray(predecessors)) {
      return [];
    }

    const linkedActivities = [];

    for (const linkId of predecessors) {
      const linkData = this.gantt.getLink(linkId);

      if (!linkData) {
        continue;
      }

      const linkedActivityId = linkData[this.linkDirection];
      const linkedActivity = this.gantt.getTask(linkedActivityId);

      if (linkedActivity) {
        linkedActivities.push(linkedActivity);
      }
    }

    return linkedActivities;
  }

  /**
   * Retrieves an array of linked activity IDs.
   */
  getArrayOfLinkedActivities(links) {
    if (!Array.isArray(links)) {
      return [];
    }
    const linkedActivityIds = [];

    for (const linkId of links) {
      const linkData = this.gantt.getLink(linkId);

      if (!linkData) {
        continue;
      }

      linkedActivityIds.push(Number(linkData[this.linkDirection]));
    }

    return linkedActivityIds;
  }
}

export default ActivityCalculator;
