export const BASE_RESOURCES = {
  training: {
    icon: 'trainingIcon',
    labelKey: 'helpcenter.resources.training',
    urls: {
      es: 'https://proplannertrainings.thinkific.com/collections/es',
      en: 'https://knowledgebase.outbuild.com/en/knowledge/getting-started'
    }
  },
  knowledge: {
    icon: 'knowledgeIcon',
    labelKey: 'helpcenter.resources.knowledge',
    urls: {
      es: 'https://knowledgebase.outbuild.com/es/knowledge',
      en: 'https://knowledgebase.outbuild.com/en/knowledge'
    }
  },
  community: {
    icon: 'communityIcon',
    labelKey: 'helpcenter.resources.community',
    urls: {
      es: 'https://outbuild-community.mn.co/',
      en: 'https://outbuild-community.mn.co/'
    }
  },
  webinars: {
    icon: 'webinarsIcon',
    labelKey: 'helpcenter.resources.webinars',
    urls: {
      es: 'https://www.outbuild.com/webinars-trailer-talk',
      en: 'https://www.outbuild.com/webinars-trailer-talk'
    }
  }
};
