export const helpCenterQuestions = [
  {
    name: 'ganttchart',
    questions: [
      {
        title: 'ganttchart_first_import',
        url: 'https://knowledgebase.outbuild.com/knowledge/primera-importaci%C3%B3n-de-diagrama-gantt',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/first-gantt-chart-import'
      },
      {
        title: 'ganttchart_configure_calendar',
        url: 'https://knowledgebase.outbuild.com/knowledge/cómo-configurar-un-calendario',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/how-to-set-up-a-calendar'
      },
      {
        title: 'ganttchart_how_create_gantt',
        url: 'https://knowledgebase.outbuild.com/knowledge/cómo-crear-una-gantt-desde-cero-en-proplanner',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/how-to-create-a-gantt-from-scratch-in-proplanner'
      },
      {
        title: 'ganttchart_shortcuts',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/funcionalidades-en-la-gantt-atajos',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/functionalities-in-the-gantt-shortcuts'
      },
      {
        title: 'ganttchart_btns',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/funcionalidades-en-gantt-botones',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/functionalities-in-the-gantt-buttons'
      }
    ]
  },
  {
    name: 'gantt_resources',
    questions: [
      {
        title: 'gantt_resources_how_add',
        url: 'https://knowledgebase.outbuild.com/knowledge/cómo-agregar-recursos',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/how-to-add-resources'
      }
    ]
  },
  {
    name: 'lookahead_planification',
    questions: [
      {
        title: 'lookahead_planification_create_constraint',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/cómo-crear-restricciones',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/how-to-create-roadblocks'
      },
      {
        title: 'lookahead_planification_use_massive',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/c%C3%B3mo-usar-la-barra-masiva-de-tareas',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/how-to-use-bulk-edit'
      },
      {
        title: 'lookahead_planification_modification_request',
        url: 'https://knowledgebase.outbuild.com/knowledge/solicitud-de-modificaci%C3%B3n',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/modification-request'
      },
      {
        title: 'lookahead_planification_lean_is',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/que-es-el-estado-lean',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/what-is-the-lean-state'
      }
    ]
  },
  {
    name: 'lookahead_resources',
    questions: [
      {
        title: 'lookahead_resources_assign',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/cómo-asignar-recursos-a-mis-tareas',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/how-to-assign-resources-to-my-tasks'
      }
    ]
  },
  {
    name: 'lookahead_constraints',
    questions: [
      {
        title: 'lookahead_constraints_release',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/cómo-gestionar-y-liberar-restricciones',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/how-to-manage-and-release-roadblocks'
      }
    ]
  },
  {
    name: 'weekly_planning',
    questions: [
      {
        title: 'weekly_plan_commitment',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/como-comprometer-mi-plan-semanal',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/how-to-commit-my-weekly-plan'
      },
      {
        title: 'weekly_plan_report',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/reporte-de-cierre-de-la-semana',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/weekly-plan/weekly-plan'
      },
      {
        title: 'weekly_plan_close',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/reporte-de-cierre-de-la-semana',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/weekly-plan/weekly-plan'
      },
      {
        title: 'weekly_plan_partial',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/puedo-definir-compromisos-parciales-por-semana',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/weekly-plan/weekly-plan'
      }
    ]
  },
  {
    name: 'weekly_cncs',
    questions: [
      {
        title: 'weekly_register_cnc',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/cómo-registrar-causas-de-no-cumplimiento',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/how-to-register-reasons-for-variance-1'
      }
    ]
  },
  {
    name: 'analytics',
    questions: [
      {
        title: 'analytics_curve_s',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/curva-s',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge/s-curve'
      },
      {
        title: 'analytics_ppc',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/gráfico-ppc',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge/ppc-chart-1'
      },
      {
        title: 'analytics_pcr',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/gráfico-pcr',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge/prr-graph-1'
      },
      {
        title: 'analytics_cnc',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/gráfico-cnc',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/reasons-for-variance-chart'
      },
      {
        title: 'analytics_curve_speed',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/curva-de-velocidad',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge/speed-curve'
      }
    ]
  },
  {
    name: 'settings_user',
    questions: [
      {
        title: 'settings_user_config_user',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/configuración-de-usuario',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge/user-settings'
      },
      {
        title: 'settings_user_change_pass',
        url: 'https://knowledgebase.outbuild.com/knowledge/cómo-cambio-mi-contraseña',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/how-do-i-change-my-password'
      }
    ]
  },
  {
    name: 'settings_project',
    questions: [
      {
        title: 'settings_project_general',
        url: 'https://knowledgebase.outbuild.com/knowledge/configuración-de-proyecto-generales',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/project-settings-general'
      },
      {
        title: 'settings_project_constraints_cnc',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/categorías-de-restricciones-y-cnc',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/roadblock-categories-and-rfv'
      },
      {
        title: 'settings_project_create_assign_users',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/creación-y-asignación-de-usuarios',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/user-creation-and-assignment'
      },
      {
        title: 'settings_project_create_assign_subcontracts',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/creación-y-asignación-de-subcontratos',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/creation-and-assignment-of-companies'
      },
      {
        title: 'settings_project_user_roles',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/roles-de-usuario',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge/user-roles'
      }
    ]
  },
  {
    name: 'settings_company',
    questions: [
      {
        title: 'settings_company_creation_users',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/creación-y-asignación-de-usuarios',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/user-creation-and-assignment'
      },
      {
        title: 'settings_company_creation_subcontract',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/creación-y-asignación-de-subcontratos',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/creation-and-assignment-of-companies'
      },
      {
        title: 'settings_company_roles',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/roles-de-usuario',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge/user-roles'
      }
    ]
  },
  {
    name: 'projects_home',
    questions: [
      {
        title: 'projects_home_stages',
        url: 'https://knowledgebase.outbuild.com/knowledge/etapas-de-mi-proyecto',
        urlEng:
          'https://knowledgebase.outbuild.com/en/knowledge/stages-of-my-project-1'
      }
    ]
  },
  {
    name: 'takt_units',
    questions: [
      {
        title: 'takt_create',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/cómo-crear-una-estructura-rítmica',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge'
      },
      {
        title: 'takt_create_auto',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/creación-automática-de-estructuras-rítmicas',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge'
      }
    ]
  },
  {
    name: 'takt_linking',
    questions: [
      {
        title: 'takt_assign_structure',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/cómo-asignar-una-estructura-rítmica',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge'
      },
      {
        title: 'takt_deallocate',
        url: 'https://knowledgebase.outbuild.com/es/knowledge/cómo-desasignar-una-estructura-rítmica',
        urlEng: 'https://knowledgebase.outbuild.com/en/knowledge'
      }
    ]
  }
];
