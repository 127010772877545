import otherIcon from '../../../../../../assets/img/helpcenter/other_icon.png';
import trainingIcon from '../../../../../../assets/img/helpcenter/training.png';
import knowledgeIcon from '../../../../../../assets/img/helpcenter/knowledge.png';
import communityIcon from '../../../../../../assets/img/helpcenter/community.png';
import webinarsIcon from '../../../../../../assets/img/helpcenter/webinars.png';

export const ICONS = {
  trainingIcon,
  knowledgeIcon,
  communityIcon,
  webinarsIcon
};

export { otherIcon };
