import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { searchIcon } from '../assets';

const SearchBar = memo(
  ({
    visibilitySearchIcon,
    handleSearch,
    questions,
    questionsConfig = { offset: 2, marginTop: 15, span: 20 },
    t
  }) => (
    <>
      <Row>
        <Col style={{ marginTop: 15 }}>
          <div style={{ textAlign: 'center' }}>
            <img
              src={searchIcon}
              width={16}
              alt="search"
              style={{
                position: 'relative',
                left: 37,
                visibility: visibilitySearchIcon ? 'visible' : 'hidden'
              }}
            />
            <input
              onChange={(e) => handleSearch(e.target.value)}
              className="search-help-center"
              placeholder={t('takt_search')}
            />
          </div>
        </Col>
      </Row>

      <Row className="container-questions">
        <Col
          offset={questionsConfig.offset}
          style={{ marginTop: questionsConfig.marginTop }}
          span={questionsConfig.span}>
          {questions}
        </Col>
      </Row>
    </>
  )
);

SearchBar.propTypes = {
  visibilitySearchIcon: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  questions: PropTypes.node,
  questionsConfig: PropTypes.shape({
    offset: PropTypes.number,
    marginTop: PropTypes.number,
    span: PropTypes.number
  }),
  t: PropTypes.func.isRequired
};

export default SearchBar;
