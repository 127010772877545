import React from 'react';
import { useDependenciesLinkFloat } from './useGetLinkFreeFloat';

const DependenciesLinkFloat = ({ activity, activityLink, direction }) => {
  const dependenciesLinkFloat = useDependenciesLinkFloat(
    activity,
    activityLink,
    direction
  );

  return <span>{dependenciesLinkFloat}</span>;
};

export default DependenciesLinkFloat;
