import CalculationsGenericMethods from '../base/CalculationsGenericMethods';
import { addDurationToDate } from '../../helpers/addDurationToDate';
import { CONSTRAINT_TYPES } from '../../constants/index';
import CriticalPathHelpers from '../../base/generic-calculations';

class LinksConstraintCalculator extends CalculationsGenericMethods {
  constructor(calculationObject) {
    super(calculationObject);
    this.constraint = calculationObject.constraint || CONSTRAINT_TYPES.ASAP;
    this.parentsWithFullProgress = calculationObject.parentsWithFullProgress;
    this.parentsThatImpactChildrens =
      calculationObject.parentsThatImpactChildrens;
    this.projectDates = this.gantt.getSubtaskDates();
  }
  /**
   * Calculates the latest start (LS) and latest finish (LF) times for an activity.
   *
   * This function calculates the LS and LF times for an activity based on its progress and the links associated with it.
   * It takes into account various conditions and constraints to determine the final LS and LF times.
   *
   * @returns {Object} An object containing the calculated latest start (LS) and latest finish (LF) times.
   * @property {Date} ls - The calculated latest start time.
   * @property {Date} lf - The calculated latest finish time.
   *
   * @example
   * // Assuming this function is part of a class with access to the required properties and methods
   * const result = calculate();
 // { ls: Date, lf: Date }
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  calculate() {
    if (this.links.length === 0) {
      return new CriticalPathHelpers(
        this.gantt,
        'backward'
      ).calculateStartAndFinishOfChainOrigin(this.activity);
    }

    let activityProgressMajorThanZeroButLessThanOneHundred =
      this.activity.progress > 0 && this.activity.progress < 100;
    const activityParent = this.gantt.getTask(this.activity.parent);
    let shouldTakeIntoAccountTheParent = false;

    if (activityParent) {
      shouldTakeIntoAccountTheParent = this.parentsThatImpactChildrens.has(
        Number(this.activity.parent)
      );
    }

    const allLinksCalculations = this.links
      .map((link) => {
        const linkData = this.gantt.getLink(link);

        if (!linkData) {
          throw new Error(`Link not found with id ${link}`);
        }

        if (this.parentsWithFullProgress.has(Number(linkData.target))) {
          return;
        }
        return this.calculateLink(linkData);
      })
      .filter((link) => link);

    if (allLinksCalculations.length !== 0) {
      const minStartValuesFromLinks =
        this.getMinLfFromLinks(allLinksCalculations);
      allLinksCalculations.push(this.calculateTheLsAndLfForTheActivity());

      if (shouldTakeIntoAccountTheParent) {
        const parentValues = this.parentsThatImpactChildrens.get(
          Number(this.activity.parent)
        );
        allLinksCalculations.push(
          this.calculateTheLsAndLfForTheActivity(parentValues)
        );
      }

      let minStartValues = this.getMinDateFromLinks(allLinksCalculations);

      let finalLs = minStartValues.ls;
      let finalLf = minStartValues.lf;

      if (activityProgressMajorThanZeroButLessThanOneHundred) {
        finalLs = this.activity.start_date;
        finalLf = this.getMinCalculationLogic(minStartValues);
      }

      return {
        ls: finalLs,
        lf: finalLf,
        minFromLinks: minStartValuesFromLinks
      };
    }

    if (shouldTakeIntoAccountTheParent) {
      allLinksCalculations.push(this.calculateTheLsAndLfForTheActivity());
      const parentValues = this.parentsThatImpactChildrens.get(
        Number(this.activity.parent)
      );
      let finalLf = parentValues.lf;
      let finalLs = addDurationToDate(
        this.gantt.getCalendar(this.activity.calendar_id),
        finalLf,
        -this.activity.duration,
        this.activity
      );
      allLinksCalculations.push({ ls: finalLs, lf: finalLf });

      let minStartValues = this.getMinDateFromLinks(allLinksCalculations);

      return {
        ls: minStartValues.ls,
        lf: minStartValues.lf,
        minFromLinks: minStartValues
      };
    }

    const calculation = this.calculateTheLsAndLfForTheActivity();
    return {
      ls: calculation.ls,
      lf: calculation.lf,
      minFromLinks: calculation
    };
  }
  /**
   * Calculates the Late Start (LS) and Late Finish (LF) for the current activity.
   * @returns {Object} An object containing the Late Start (LS) and Late Finish (LF) dates.
   * @throws {Error} If there's an issue with retrieving the calendar or calculating the dates.
   */
  calculateTheLsAndLfForTheActivity(parentDates = null) {
    try {
      const duration = this.activity.duration;
      const activityCalendar = this.gantt.getCalendar(
        this.activity.calendar_id
      );
      if (!activityCalendar) {
        throw new Error(
          `Calendar not found with id ${this.activity.calendar_id}`
        );
      }
      const lf = parentDates ? parentDates.lf : this.projectDates.end_date;
      if (!lf) {
        throw new Error(`End date not found for activity ${this.activity.id}`);
      }
      const ls = addDurationToDate(
        activityCalendar,
        lf,
        -duration,
        this.activity
      );

      return { ls, lf };
    } catch (error) {
      throw Error(error.message);
    }
  }
  /**
   * Calculates the minimum between the minimum date from links and the end date of the project
   * or the end date of the activity, depending on the constraint type.
   * @param {Object} minCalculatedDateFromLinks - The minimum calculated date from links.
   * @returns {Date} The minimum date between the calculated date from links and the end date.
   */
  SnetSnltMsoMinDate(minCalculatedDateFromLinks) {
    const minDateOfEndOfProjectAndLinks = Math.min(
      minCalculatedDateFromLinks.lf,
      this.projectDates.end_date
    );

    return Math.max(minDateOfEndOfProjectAndLinks, this.activity.end_date);
  }
  /**
   * Calculates the successor and/or predecessor times based on the link type and constraints.
   * @param {Object} linkData - The link data object containing information about the link.
   * @returns {void} Returns nothing if the calculation is avoided based on the progress of the target activity.
   * @throws {Error} Throws an error if activity data is not found or if an error occurs during calculation.
   */
  calculateLink(linkData) {
    const activityFromLink = this.gantt.getTask(linkData.target);
    if (!activityFromLink) {
      return;
    }
    if (activityFromLink.progress > 0 && activityFromLink.type !== 'project') {
      return;
    }

    const specialParamForFS =
      this.getSpecialCalculationParamForConstraintLink();
    const specialParamForSf = this.getSpecialParamForSf();
    const specialParamForFF = this.getSpecialParamForFF();
    const calculationMapping = {
      0: () => this.calculateFS(linkData, specialParamForFS),
      1: () => this.calculateSS(linkData),
      2: () => this.calculateFF(linkData, specialParamForFF),
      3: () => this.calculateSF(linkData, specialParamForSf)
    };

    return calculationMapping[linkData.type]();
  }
  /**
   * Determines the special calculation parameter based on the constraint type.
   * @returns {string|undefined} Returns a special parameter for specific constraint types, or undefined if no special parameter is needed.
   */
  getSpecialCalculationParamForConstraintLink() {
    if (this.constraint === CONSTRAINT_TYPES.ALAP) return CONSTRAINT_TYPES.ALAP;
    if (
      this.constraint === CONSTRAINT_TYPES.FNET ||
      this.constraint === CONSTRAINT_TYPES.SNET
    )
      return 'snet-fnet';
    if (this.constraint === CONSTRAINT_TYPES.FNLT) return CONSTRAINT_TYPES.FNLT;
  }
  /**
   * Determines the special calculation parameter based on the constraint type.
   * @returns {string|undefined} Returns a special parameter for specific constraint types, or undefined if no special parameter is needed.
   */
  getSpecialParamForSf() {
    const constriantThatNeedSpecialParam = [CONSTRAINT_TYPES.ALAP];
    if (constriantThatNeedSpecialParam.includes(this.constraint)) return true;

    return false;
  }
  /**
   * Determines the special calculation parameter based on the constraint type.
   * @returns {string|undefined} Returns a special parameter for specific constraint types, or undefined if no special parameter is needed.
   */
  getSpecialParamForFF() {
    const constraintsThatNeedSpecialFFParam = [
      CONSTRAINT_TYPES.MFO,
      CONSTRAINT_TYPES.MSO
    ];
    if (constraintsThatNeedSpecialFFParam.includes(this.constraint))
      return true;

    return false;
  }
  /**
   * Determines the minimum calculation logic based on the constraint type.
   * @param {Object} minCalculateFromLinks - The minimum calculation data from links.
   * @returns {Date} Returns the minimum calculation date based on the constraint type.
   */
  getMinCalculationLogic(minCalculateFromLinks) {
    if (this.constraint === CONSTRAINT_TYPES.ALAP) {
      return this.getMinDateBasedOnConstraint(minCalculateFromLinks);
    }
    if (this.constraint === CONSTRAINT_TYPES.FNET) {
      return this.getMinDateBasedOnConstraint(minCalculateFromLinks);
    }
    if (this.constraint === CONSTRAINT_TYPES.FNLT) {
      return this.getMinDateBasedOnConstraint(minCalculateFromLinks);
    }
    if (this.constraint === CONSTRAINT_TYPES.MFO) {
      return this.getMinDateBasedOnConstraint(minCalculateFromLinks);
    }

    if (
      this.constraint === CONSTRAINT_TYPES.SNET ||
      this.constraint === CONSTRAINT_TYPES.SNLT ||
      this.constraint === CONSTRAINT_TYPES.MSO
    ) {
      return this.SnetSnltMsoMinDate(minCalculateFromLinks);
    }

    if (this.constraint === CONSTRAINT_TYPES.ASAP) {
      return this.getMinDateBasedOnConstraint(minCalculateFromLinks);
    }
  }
  /**
   * Calculates the minimum date from the list of link calculations based on the constraint type.
   * @param {Array} allLinksCalculations - The list of all link calculations.
   * @returns {Object} Returns the minimum date from the link calculations.
   */
  getMinDateFromLinks(allLinksCalculations) {
    try {
      if (
        this.constraint === CONSTRAINT_TYPES.ALAP ||
        this.constraint === CONSTRAINT_TYPES.ASAP
      ) {
        const min = allLinksCalculations.reduce((min, activity) =>
          activity.lf < min.lf ? activity : min
        );

        return min;
      }

      return allLinksCalculations.reduce((min, activity) =>
        activity.ls < min.ls ? activity : min
      );
    } catch (error) {
      throw new Error(
        `Function: getMinDateFromLinks in LinksConstraintCalculator - ${error.message}`
      );
    }
  }

  getMinLfFromLinks(allLinksCalculations) {
    return allLinksCalculations.reduce((min, activity) =>
      activity.lf < min.lf ? activity : min
    );
  }

  /**
   * Gets the minimum date between the links' minimum date and the end of the project based on the constraint type.
   *
   * @param {Object} minCalculatedDateFromLinks - The minimum calculated date from links.
   * @param {Date} minCalculatedDateFromLinks.lf - The latest finish date from links.
   * @param {string} constraintType - The constraint type of the activity.
   * @returns {Date} The calculated minimum date based on the constraint type.
   *
   * @throws {Error} If an error occurs during the calculation process.
   */
  getMinDateBasedOnConstraint(
    minCalculatedDateFromLinks,
    constraintType = this.constraint
  ) {
    const minDateOfEndOfProjectAndLinks = Math.min(
      minCalculatedDateFromLinks.lf,
      this.projectDates.end_date
    );

    const activityEndDate = this.activity.end_date;
    const constraintDate = this.activity.constraint_date;

    if (constraintType === CONSTRAINT_TYPES.ASAP) {
      return Math.max(minDateOfEndOfProjectAndLinks, activityEndDate);
    }

    if (
      constraintType === CONSTRAINT_TYPES.FNET ||
      constraintType === CONSTRAINT_TYPES.FNLT
    ) {
      const minDateBetweenConstraintDateAndLinks = Math.min(
        minDateOfEndOfProjectAndLinks,
        constraintDate
      );
      return Math.max(
        minDateOfEndOfProjectAndLinks,
        minDateBetweenConstraintDateAndLinks
      );
    }

    if (constraintType === CONSTRAINT_TYPES.ALAP) {
      return Math.min(minDateOfEndOfProjectAndLinks, activityEndDate);
    }
  }
}

export default LinksConstraintCalculator;
