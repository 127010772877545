import {
  TYPE_INVALID_FORMAT,
  TYPE_NULL,
  TYPE_NUMBER,
  TYPE_UNDEFINED,
  activityAttributes,
  ignoreAttributesInAmplitude,
  TYPE_DATE
} from '../../../../../constants/validateActivity.constants';
import { breadcrumbValidateActivity } from './sentry.helper';
import moment from 'moment';

/**
 * Validates if a given date is valid.
 *
 * @param {string} date - The date string to validate.
 * @returns {boolean} - Returns true if the date is valid, false otherwise.
 */
export const isDateValid = (date) => {
  const DATE_FORMAT = moment.ISO_8601;
  const STRICT_MODE = true;
  return moment(date, DATE_FORMAT, STRICT_MODE).isValid();
};

/**
 * Validates the value of an activity's attribute based on the given type.
 *
 * @param {*} value - The value to validate.
 * @param {string} type - The expected type.
 * @returns {{ isValid: boolean, typeError?: string }}
 */
export const validateActivityAttributes = (value, type) => {
  const errorResponse = (typeError) => ({ isValid: false, typeError });

  if (value === null) {
    return errorResponse(TYPE_NULL);
  }
  if (value === undefined) {
    return errorResponse(TYPE_UNDEFINED);
  }

  const validationRules = {
    [TYPE_NUMBER]: () => isNaN(value),
    [TYPE_DATE]: () => !isDateValid(value)
  };

  if (validationRules[type] && validationRules[type]()) {
    return errorResponse(TYPE_INVALID_FORMAT);
  }

  return { isValid: true };
};
/**
 * Validates an individual attribute and returns an invalid attribute object
 * or null if the attribute is valid or should be ignored.
 *
 * @param {Object} activity - The activity object being validated.
 * @param {string} key - The key of the attribute to validate.
 * @param {Object} updatedActivity - The updated activity object to store default values.
 * @returns {Object|null} - The invalid attribute object or null if valid.
 */
export const validateAttribute = (activity, key, updatedActivity) => {
  const value = activity[key];
  const { defaultValue, type } = activityAttributes[key];
  const { isValid, typeError } = validateActivityAttributes(value, type);

  if (isValid) {
    return null;
  }

  updatedActivity[key] = defaultValue;
  breadcrumbValidateActivity({
    attribute: key,
    originalValue: value,
    defaultValue,
    typeError
  });

  if (ignoreAttributesInAmplitude.includes(key)) {
    return null;
  }

  return {
    attribute: key,
    error_source: typeError,
    originalValue: value,
    replacedValue: defaultValue
  };
};

/**
 * Validates activity attributes, replaces invalid values with defaults,
 * and collects invalid attributes for further tracking or reporting.
 *
 * @param {Object} activity - The activity object to validate.
 * @returns {Object} - An object containing the updated activity and a list of invalid attributes.
 * @returns {Array<Object>} invalidAttributes - List of invalid attributes with error details.
 * @returns {Object} updatedActivity - The updated activity with replaced default values.
 */
export const getInvalidAttributes = (activity) => {
  const updatedActivity = { ...activity };
  const attributesToValidate = Object.keys(activityAttributes);

  const invalidAttributes = attributesToValidate
    .map((key) => validateAttribute(activity, key, updatedActivity))
    .filter(Boolean);

  return { invalidAttributes, updatedActivity };
};
