/**
 * Function to find the set with the highest Early Start (ES).
 * @param {Array<Object>} arrayCalculatedLinks - Array of objects containing earlyFinish (EF) and earlyStart (ES).
 * @returns {Object|boolean} - The set with the highest Early Start (ES) or `false` if the array is empty.
 */

function getMaxEarlyStartSet(arrayCalculatedLinks) {
  if (!arrayCalculatedLinks.length) {
    return false;
  }
  return arrayCalculatedLinks.reduce((maxObj, currentObj) =>
    currentObj.ef > maxObj.ef ? currentObj : maxObj
  );
}

export { getMaxEarlyStartSet };
