import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createBrowserHistory } from 'history';

import './index.css';
import App from './App';
import { store } from './redux/store';
import { start } from './monitor/monitor';
import * as serviceWorkerRegistration from './serviceworkers/serviceWorkerRegistration';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      cacheTime: 1000 * 60 * 0.5, // 30 segundos
      staleTime: 1000 * 60 * 0.5, // 30 segundos
      refetchOnWindowFocus: false
    }
  }
});

if (window.location.hostname === 'app.outbuild.com') {
  console.log = () => {};
}

console.warn = () => {};

const history = createBrowserHistory();

start(history);

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
