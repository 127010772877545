import React, { useState, useEffect } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import './index.css';

import { companyService } from '../../../../../services/company.service';
import { projectService } from '../../../../../services/project.service';
import { userService } from '../../../../../services/user.service';
// REDUX
import * as projectActions from '../../../../../redux/slices/projectSlice';
import { useSelector, useDispatch } from 'react-redux';

/** components */
import ItemCard from '../../../../../components/Projects/ListProjects/ItemCard';
import { dynamicSort } from '../../../../../utils';
import { useSelectProject } from '../../../../../hooks/useSelectProject';

function CardsGeneral(props) {
  const stateCompany = useSelector((state) => state.companyState);
  const projectState = useSelector((state) => state.projectState);
  const idCurrentCompany = stateCompany.currentCompany?.id;
  const { onClickProject } = useSelectProject();

  useEffect(() => {
    const abortController = new AbortController();
    companyService.show(idCurrentCompany).catch((err) => console.log(err));

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <div className="cards-setting-user">
      {projectState.allProjects
        .sort(dynamicSort('name'))
        .map((project, index) => (
          <ItemCard
            key={index}
            {...props}
            project={project}
            showActionsMenu={false}
            caller="settings"
            onClickProject={onClickProject}
          />
        ))}
    </div>
  );
}

export default CardsGeneral;
