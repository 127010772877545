import React from 'react';
import CheckSystem from '../../../../DesignSystem/CheckSystem';
import { isDateNotToday } from '../../../helpers';
import { getGanttToExport } from '../../../../../assets/gantt/ext/Export/ExportPDFGantt/helpers/updateGantt';

export default function AlignLineCheck({
  t,
  isSchedule,
  isAlignLine,
  setIsAlignLine,
  dateSelected
}) {
  const gantt = getGanttToExport(isSchedule);
  if (!gantt) return <></>;

  const { show_todayline } = gantt.config;
  const isVisible = show_todayline && isDateNotToday(dateSelected);

  if (!isVisible) {
    return <></>;
  }

  return (
    <>
      <CheckSystem
        checked={isAlignLine}
        withLabel={true}
        label={t('export_pdf_modal.today_line_aligned')}
        onClickFunction={(value) => setIsAlignLine(value)}
      />
    </>
  );
}
