/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalSystem from '../../DesignSystem/ModalSystem';
import { PLUS_ICON, MODAL_WIDTH } from './constants';
import InputSystem from '../../DesignSystem/InputSystem';
import { getBase64 } from '../../../utils';
import { useFormProject } from '../../../hooks/useFormProject';
import ProjectDetails from './components/ProjectDetails';
import useSaveProject from '../../../hooks/useSaveProject';
import { Icon, Spin } from 'antd';
import { getCompaniesLocal } from '../../../views/selectCompany/companyOptions/companyOptions.helpers';
import { getSignedUser } from '../../../utils/userUtils';

const antIcon = <Icon type="loading" style={{ fontSize: 20 }} spin />;
export const initialState = {
  projectName: '',
  projectType: 'commercial',
  location: 'US',
  currency: 'USD',
  budget: '0',
  unit: 'ft2',
  size: '0',
  projectImage: false,
  projectImageURL: '',
  isLoading: false,
  isExistChanges: false,
  moreDetailsExpanded: false
};

const AddProject = ({ t, visible, setVisible }) => {
  const { state, setField, setLoading, resetForm, toggleMoreDetails } =
    useFormProject(initialState);
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const [currentCompany, setCurrentCompany] = useState({});

  const user = getSignedUser();
  const [showModal, setShowModal] = useState(false);

  const { saveProject } = useSaveProject(
    state,
    setField,
    setLoading,
    resetForm,
    setVisible,
    t,
    user,
    currentCompany
  );

  useEffect(() => {
    if (visible) {
      setShowModal(true);
    }
  }, [visible]);

  useEffect(() => {
    const company = getCompaniesLocal();
    setCurrentCompany(company);
    setShowModal(false);
    setVisible(false);
  }, []);

  const handleChange = useCallback(
    (e) => {
      setField(e.target.name, e.target.value);
    },
    [setField]
  );

  const handleCloseModal = useCallback(() => {
    setVisible(false);
    setShowModal(false);
    setTimeout(() => {
      resetForm();
    }, 500);
  }, [setVisible, resetForm, dispatch, projectState.props]);

  const handleImageChange = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      await getBase64(info.file.originFileObj, (imageUrl) =>
        setField('projectImage', imageUrl)
      );

      setField('projectImageURL', info.file.response.name);
      setLoading(false);
    }
  };

  const modalContent = () => (
    <div className="modal--view--info">
      <div className="form__input_project_name_field">
        {InputSystem({
          label: `${t('add_project_modal.project_name_field')}*`,
          size: 'small',
          onChange: handleChange,
          name: 'projectName',
          autoComplete: 'off',
          theme: 'dark',
          required: true,
          value: state.projectName
        })}
      </div>

      {/* Toggle para más detalles */}
      <ProjectDetails
        t={t}
        state={state}
        setField={setField}
        toggleMoreDetails={toggleMoreDetails}
        handleChange={handleChange}
        handleImageChange={handleImageChange}
      />

      {/* Botones de cancelar y crear proyecto */}
      <div className="modal--view--info__footer">
        <button className="footer__cancel" onClick={handleCloseModal}>
          {t('add_project_modal.cancel_button')}
        </button>
        <button
          className="footer__save"
          disabled={
            !state.isExistChanges ||
            state.isLoading ||
            !state.projectName.trim()
          }
          onClick={saveProject}>
          {state.isLoading ? (
            <Spin indicator={antIcon} className="download__spinner" />
          ) : (
            t('add_project_modal.create_project_button')
          )}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: showModal,
    setVisible: setShowModal,
    children: modalContent(),
    theme: 'dark',
    width: MODAL_WIDTH,
    centered: true,
    showTitle: true,
    title: t('add_project_modal.add_project_title'),
    showIconTitle: true,
    iconTitle: PLUS_ICON,
    onCancelFunction: handleCloseModal
  });
};

export default AddProject;
