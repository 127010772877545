import { base } from './base';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import cloneDeep from 'lodash/cloneDeep';

export const save_single_task_lookahead = 'save_single_task_lookahead';
export const create_single_task_lookahead = 'create_single_task_lookahead';
export const save_massive_task_lookahead = 'save_massive_task_lookahead';
export const update_custom_commmitment_task_lookahead =
  'update_custom_commmitment_task_lookahead';
export const delete_old_cache = 'delete_old_cache';

class WorkerService {
  constructor(workerRef = null) {
    this.actions = this.buildActions();
    this.modules = {};
    this.modules.lookahead = {};
    this.modules.masterplan = {};
    this.workerAPI = new WorkerAPI(workerRef);

    const lookaheadActions = {
      [save_single_task_lookahead]: this.actions[save_single_task_lookahead],
      [save_massive_task_lookahead]: this.actions[save_massive_task_lookahead],
      [update_custom_commmitment_task_lookahead]:
        this.actions[update_custom_commmitment_task_lookahead]
    };

    this.modules.lookahead.actions = lookaheadActions;
  }

  buildActions() {
    const actions = {
      [delete_old_cache]: ({ data, commonService }) => {
        const res = this.workerAPI.postMessage(delete_old_cache, data);
        if (!res && commonService) commonService();
      },
      [save_single_task_lookahead]: ({ data, commonService }) => {
        delete data.activityReference;
        delete data.children;
        const res = this.workerAPI.postMessage(
          save_single_task_lookahead,
          data
        );
        if (!res && commonService) commonService();
      },
      [save_massive_task_lookahead]: ({ data, commonService }) => {
        const convertDates = (task) => {
          try {
            delete task.activityReference;
            delete task.children;
            task?.end_date && (task.end_date = moment(task.end_date).toDate());
            task?.start_date &&
              (task.start_date = moment(task.start_date).toDate());
          } catch (error) {
            Sentry.captureException(error);
          }
        };

        data && data.forEach(convertDates);
        const res = this.workerAPI.postMessage(
          save_massive_task_lookahead,
          data
        );
        if (!res && commonService) commonService();
      },
      [update_custom_commmitment_task_lookahead]: ({ data, commonService }) => {
        const res = this.workerAPI.postMessage(
          update_custom_commmitment_task_lookahead,
          {
            taskId: data.taskId,
            value: data.value
          }
        );

        if (!res && commonService) commonService();
      }
    };
    return actions;
  }

  setWorker(newWorker) {
    this.workerAPI.setInternalWorker(newWorker);
  }

  getWorker() {
    return this.workerAPI.internalWorker;
  }

  getAction(actionName) {
    return this.actions[actionName] &&
      this.actions[actionName] instanceof Function
      ? this.actions[actionName]
      : null;
  }

  callAction(actionName, data, commonService) {
    if (!actionName || !data) return;
    if (!this.workerAPI.internalWorker && typeof commonService === 'function') {
      commonService();
      return;
    }
    const action = this.getAction(actionName);
    data = cloneDeep(data);
    if (action) action({ data, commonService });
  }

  getWorkerAPI() {
    return this.workerAPI;
  }

  getLookaheadActions() {
    return this.modules.lookahead.actions;
  }

  getMasterplanActions() {
    return this.modules.masterplan.actions;
  }
}

class WorkerAPI {
  constructor(worker) {
    this.internalWorker = worker;
  }

  setInternalWorker(newWorker) {
    this.internalWorker = newWorker;
  }

  postMessage(cmd, data) {
    try {
      if (
        !this.internalWorker ||
        !this.internalWorker.postMessage ||
        !data ||
        !cmd
      )
        return;
      const { userToken, sector } = this.getTokenAndSector();
      if (userToken === null || sector === null) {
        Sentry.captureMessage(
          'Error when getting userToken and sector in the service worker',
          'warning'
        );
        return;
      }
      this.internalWorker.postMessage({
        cmd,
        metadata: {
          data,
          userToken,
          sector,
          base,
          appToken: base.masterKey
        }
      });
      return true;
    } catch (e) {
      Sentry.captureMessage(e.message, 'warning');
      return;
    }
  }

  getTokenAndSector() {
    const userToken = sessionStorage.getItem('sessionToken');
    const sector = sessionStorage.getItem('currentSector');
    return {
      userToken,
      sector
    };
  }
}

export const workerService = new WorkerService();
window.workerService = workerService;
