import { calculateGanttTableWidth } from '../helpers/updateGantt';

/**
 * Generates the CSS styles for the general feature and other custom Gantt chart styles.
 * @returns {string} The CSS styles as a string.
 */
const generalCSS = () => {
  return `
    @import '~antd/dist/antd.css';

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: #1890ff00 !important;
        opacity: 0.1;
    }

    /*
    .ant-input:focus {
        border-right-width: 0px !important;
    } */

    .ant-input:focus {
        border-color: #40a9ff;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
        box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
    }

    .ant-input-number-focused{
        border: 0px !important;
        border-color: #40a9ff;
        border-right-width: 0px !important;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
        box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
    }

    .ant-input-affix-wrapper:not(.search-box):hover .ant-input:not(.ant-input-disabled) {
        border-color: #40a9ff;
        border-right-width: 0px !important;
    }


    .custom-input-number-planification:hover{
        border: 0px !important;
        border-color: #40a9ff;
        border-right-width: 0px !important;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
        box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
    }

    .custom-input-number-planification:focus{
        border: 0px !important;
        border-color: #40a9ff;
        border-right-width: 0px !important;
        outline: 0;
        -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
        box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2) !important;
    }

    .gantt_drag_marker, .gantt_drag_marker .gantt_row.odd {
        background-color: transparent !important;
        color: transparent !important;
    }

    .new-gantt-chart .gantt_grid_data .gantt_cell{
        height: 100%!important;
    }

    .odd-col{
        background-color:rgba(239,239,239,0.5)!important;
        height: 100%!important;
    }

    .gantt_cell {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .gantt_drag_marker.gantt_grid_resize_area{
        background-color: hsla(0,0%,91%,.5) !important;
    }

    .gridHoverStyle,
    .gridSelection,
    .timelineSelection {
        background-color: #ECFFEE !important;
    }

    .gantt_grid_data{
        color: red!important;
        font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif!important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .gantt_grid_data .gantt_row.odd:hover,
    .gantt_grid_data .gantt_row:hover {
        background-color: #ECFFEE !important;
    }
    .gantt_grid_data .gantt_row.gantt_selected,
    .gantt_grid_data .gantt_row.odd.gantt_selected,
    .gantt_task_row.gantt_selected {
        background-color: #ECFFEE !important;
    }

    .gantt_task_row.gantt_selected .gantt_task_cell {
        background-color: #ECFFEE !important;
    }

    .gantt_tree_icon.gantt_blank{width:18px}.gantt_tree_icon.gantt_folder_open{
        width: 0% !important;
    }

    .gantt_tree_icon.gantt_file{
        width: 0% !important;
    }

    .add-responsable-new{
        float: left;
        position: relative;
        top: 0px;
        left: 5px;
        cursor: pointer;
    }

    .opacity-hover-style {
        opacity: 0;
    }
    .opacity-hover-style:hover {
        opacity: 0.65;
        transition: opacity 0.25s ease-in-out;
    }

    .opacity-hover-style:focus {
        opacity: 1;
    }

    .opacity-hover-style:active {
        opacity: 1;
    }

    .gantt_grid_data .gantt_cell {
        border-right: none;
        color: #454545;
        height: 30px !important;
    }

    .item-gantt{
        cursor: pointer;
        display: block;
        text-align: left;
        margin: 0;
        padding: 0px !important;
        height: 30px;
        font-size: 12px;
    }

    .custom-gantt-responsable-dropdown .select-search__option.is-selected {
        background: #2C3421 !important;
        color: #fff;
        height: 30px;
    }

    .custom-gantt-responsable-dropdown .select-search {
        width: 202px;
        position: relative;
        box-sizing: border-box;
    }

    .custom-gantt-responsable-dropdown .ant-popover-inner-content {
        border-radius: 20px !important;
    }

    .custom-gantt-responsable-dropdown .select-search__option.is-highlighted, .select-search__option:not(.is-selected):hover {
        background: #EFEFEF !important;
    }


    .custom-proplanner-tag .select-search__option.is-selected {
        background: #EFEFEF !important;
        color: inherit !important;
    }

    .custom-proplanner-tag .select-search {
        width: 150px;
        height: 150px !important;
        position: relative;
        font-family: 'Nunito Sans', sans-serif;
        box-sizing: border-box;
    }

    .custom-proplanner-tag .ant-popover-inner-content {
        border-radius: 20px !important;
    }

    .custom-proplanner-tag .select-search__option.is-highlighted, .select-search__option:not(.is-selected):hover {
        background: #EFEFEF !important;
    }

    .custom-proplanner-tag .select-search__options {
        align-items: center;
    }

    .custom-proplanner-tag .select-search__row{
        width: 89px !important;
        height: 29px !important;
    }

    .custom-proplanner-tag .select-search--multiple .select-search__option{
        width: 150px !important;
        height: 29px !important;
        text-align: center;

    }

    .custom-proplanner-tag .select-search--multiple .select-search__select{
        position: relative;
        overflow: auto;
        min-height: 1px !important;
        height: 114px !important;
        border-top: 1px solid #eee;
        border-radius: 0 0 3px 3px;
    }

    .custom-proplanner-tag .select-search__option{
        padding-left: 0px !important;
    }

    .custom-proplanner-tag .select-search__row:not(:first-child){
        border-top: 0px solid #eee !important;
    }

    .checked-for-gantt{
        opacity: 0.3;
    }

    .checked-for-gantt:hover{
        opacity: 0.6;
        transition: opacity 0.25s ease-in-out;
    }

    .checked-for-gantt-clicked{
        opacity: 0.8;
    }

    .tag-proplanner {
        display: none!important;
    }
    .tag-proplanner:before {
        display: none!important;
    }

    .tag-proplanner-content{
        display: none!important;
    }

    .pdf-fix-text{
        display: none!important;
    }

    .tag-class-span{
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
        color: #121212!important;
    }

    .custom-tags-proplanner{
        display: inline-flex;
        width: 100%;
        text-align: left;
        margin-left: 20px;
    }

    .custom-proplanner-tag .select-search__group-header{
        background: transparent;
        padding-bottom: 0px;
    }

    .custom-proplanner-tag .select-search__group{
        /* display: flex; */
        background: transparent;
    }

    .custom-create-tag-to-select-checkbox{
        align-content: center;
        text-align: center;
        position: relative;
        top: -6px;
        font-size: 8px;
        height: 22px;
        width: 53px;
        padding-top: 7px;
        margin: 0px;
        left: 28px;
    }

    .custom-proplanner-tag .select-search__value::after{
        background-image: url("./assets/img/gantt/search-new.png");
        background-repeat: no-repeat;
        background-size: 12.6px;
        background-position-x: 100%;
        background-position-y: 2px;
        width: 14px;
        height: 20px;
    }

    .custom-proplanner-tag .select-search__input{
        font-size: 12px;
    }

    .custom-gantt-subcontract .subcontracts-options{
        min-width: 148px;
    }
    .delete-task-gantt-btn{
        font-size: 15px; 
        color: #333333; 
        position: relative; 
        margin-right: 6px; 
        top: -3px; 
        font-weight: 400; 
        cursor: pointer;
    }

    .add-btn-task-gantt{
        font-size: 26px; 
        color: #333333;
        font-weight: 400; 
        cursor: pointer;
    }

    .uncomplete-btn-task-gantt{
        font-size: 15px; 
        color: #333333; 
        position: relative; 
        margin-right: 3px; 
        top: -4px; 
        left: -3px;
        font-weight: 400; 
        cursor: pointer;
    }

    .complete-btn-task-gantt{
        font-size: 13px; 
        color: #333333; 
        position: relative; 
        margin-right: 4px; 
        top: -4px; 
        font-weight: 400; 
        cursor: pointer;
    }


    .complete-btn-task-gantt:hover .complete-hover-gantt{
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    .complete-btn-task-gantt:hover .complete-empty-gantt{
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .uncomplete-btn-task-gantt:hover .uncomplete-hover-gantt{
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    .uncomplete-btn-task-gantt:hover .uncomplete-empty-gantt{
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .add-btn-task-gantt:hover .add-hover-gantt{
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    .add-btn-task-gantt:hover .add-empty-gantt{
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }


    .request-btn-task-gantt:hover .request-hover-gantt{
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    .request-btn-task-gantt:hover .request-empty-gantt{
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .delete-task-gantt-btn:hover .delete-hover-gantt{
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    .delete-task-gantt-btn:hover .delete-empty-gantt{
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }


    .delete-empty-gantt{
        opacity: 1;
        position: relative;
        left: -6px;
        top: -1px;

    }

    .delete-hover-gantt{
        opacity: 0;
        position: relative;
        left: -25px;
        top: -1px;
    }

    .add-empty-gantt{
        opacity: 1;
        position: relative;
        left: 30px;
        top: -1px;

    }

    .add-hover-gantt{
        opacity: 0;
        position: relative;
        left: 10px;
        top: -1px;
    }

    .request-hover-gantt{
        opacity: 0;
        position: relative;
        left: -19px;
        top: 0px;
    }

    .uncomplete-empty-gantt{
        opacity: 1;
        position: relative;
        left: 14px;
    }

    .uncomplete-hover-gantt{
        opacity: 0;
        left: -6px;
        position: relative;
    }

    .complete-empty-gantt{
        opacity: 1;
        position: relative;
        left: 11px;
    }

    .complete-hover-gantt{
        opacity: 0;
        left: -8px;
        position: relative;
    }


    /** NEW CHART GANTT STYLES**/

    .new-project-gantt-style:before{
        content: "";
        position: absolute;
        height: 0px;
        width: 0px;
        left: -1px;
        z-index: 0;
        top: -1px;
        border-top: 4px solid transparent;
        border-bottom: 10px solid transparent;
        
        border-left: 10px solid #0E5381 !important;
    }
    .new-project-gantt-style:after{
        content: "";
        height: 0px;
        position: relative;
        float: right;
        width: 0px;
        z-index: 0;
        top: -6px !important;
        left: 1px;
        border-top: 4px solid transparent;
        border-bottom: 10px solid transparent; 
        
        border-right:10px solid #0E5381 !important;  
    }


    .new-project-gantt-style{
        height: 7px !important;
        background: #0E5381 !important;
        border-color: #0E5381 !important;
        border-radius: 0px !important;
    }

    .new-project-gantt-style .gantt_task_progress{
        background: #0E5381 !important;
        border-color: #0E5381 !important;
    }



    .new-project-gantt-style .gantt_task_line.gantt_project{
        border-radius: 0px !important;
    }

    .new-links-gantt-style .gantt_link_line_right{
        background-color: #929292!important;
    }
    .new-links-gantt-style .gantt_link_line_down{
        background-color: #929292!important;
    }

    .new-links-gantt-style .gantt_link_line_left{
        background-color: #929292!important;
    }

    .new-links-gantt-style .gantt_link_line_up{
        background-color: #929292!important;
    }

    .new-links-gantt-style .gantt_link_arrow_right{
        border-left-color: #929292!important;
    }

    .new-links-gantt-style .gantt_link_arrow_left{
        border-right-color: #929292!important;
        margin-left: -1px;
    }

    .gantt_link_direction{
        border-color: #929292!important;
    }

    .gantt_task_cell {
        display: inline-block;
        height: 100%;
        border-right: 1px solid #c4c4c445 !important;
    }

    .new-links-gantt-style .gantt_link_arrow{
        border-width: 4px !important;
        margin-top: -2px !important
    }

    .new-parent-gantt-style{
        height: 22px !important;
        border-radius: 6px !important;
    }



    .gantt_task_line.gantt_milestone{
        background-color: #9E00A2;
        border: 0px solid #9E00A2 !important;
        border-radius: 0px;
    }
    .gantt_task_line.gantt_milestone .gantt_task_content{
        border-radius: 0px;
    }

    .gantt_marker{
        background-color: rgba(37, 168, 186, 1) !important;
    }

    .gantt_task_line {
        min-width: 3px !important;
    }

    .baseline {
        position: absolute;
        border-radius: 18px;
        opacity: 0.6;
        height: 2px;
        background: #25A8BAa1 !important;
        margin-top: 10px;
        z-index: 1;
    }

    .slack {
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        position: absolute;
        border-radius: 0;
        opacity: 0.7;

        border: none;
        border-right: 1px solid #b6b6b600;
        margin-left: -2px;
    }

    .weekend {
        background: #f4f7f4 !important;
    }


    .personalized-weekend-class{
        background-color: #E9E9E9 !important;
    }

    .gantt_marker_content::before{
        content: "";
        position: absolute;
        height: 0px;
        width: 0px;
        left: -2px;
        z-index: 0;
        top: -5px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #25A8BA !important;
        -webkit-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(91deg);
    }

    .gantt_task .gantt_task_scale .gantt_scale_cell{
        border-right: 1px solid #c4c4c47a !important;
        border-bottom: 1px solid #c4c4c47a;
        color: #121212 !important;
        font-size: 11px;
        font-weight: 600;
    }

    .gantt_grid_scale .gantt_grid_head_cell {
        color: #121212 !important;
        font-size: 12px;
        font-weight: 600;
        border-bottom: 1px solid #c4c4c47a;
    }

    .gantt_grid_scale, .gantt_task_scale{
        box-shadow: none !important;
    }

    .custom-gantt-subcontract .text-subcontract{
        max-width: 144px !important;
        font-size: 12px;
        color: #121212!important;
    }

    .new-project-gantt-style.top-fix-style{
        margin-top: 4px !important;
    }

    .gantt_marker_content{
        border-radius: 3px;
        font-weight: 500;
        background-color: rgba(37, 168, 186, 0.7) !important;
        position: relative;
        width: 85px;
        top: 7px;
    }

    .gantt_tree_icon.gantt_folder_closed{
        width: 0% !important;
    }

    .gantt_side_content.gantt_left {
        overflow: none !important;
    } 

    .gantt_side_content.gantt_right{
        overflow: visible !important;
        position: relative;
        top:-12px;
        width: fit-content !important;
    }

    .overdue-extra-text-gantt{
        color: #e50101d9;
        font-size: 13px;
    }

    .advancement-extra-text-gantt{
        color: #34af00d9;
        font-size: 13px;
    }

    .right-side-title-gantt{
        font-size: 13px;
        display: block;
        width: auto;
        max-width: 800px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .is_parent{
        position: relative!important;
        top: 2px!important;
    }

    .right-new-gantt{
        position: relative;
        top: -12px;
    }

    .on-lookahead-new-gantt-counter{
        background-color: white;
        border-radius: 100px;
        padding: 3px;
        font-size: 9px;
        position: relative;
        top: 3px;
        left: 41px;
        font-weight: bold;
    }

    .on-lookahead-new-gantt-counter-less{
        background-color: #fff;
        border-radius: 100px;
        padding: 2px;
        padding-left: 4px;
        padding-right: 5px;
        position: relative;
        top: 3px;
        left: 40px;
        font-weight: 700;
    }

    .solapate-responsable-gantt{
        padding: 0px !important;
    }

    .gantt_popup_title{
        background-color: #151F22 !important;
        color: #FFFFFF;
        font-weight: 500;
        border-bottom: 0px !important;
    }

    .gantt_modal_box{
        border: 0px solid white !important;
    }

    .fit-left-img-link{
        position: relative;
        top: 10px;
        left: 18px;
    }

    .title-fit-modal-link{
        float: left;
        position: relative;
        left: 8px;
        line-height: 36px;
        font-size: 16px;
        font-weight: 500;
    }

    .input-modal-link-new{
        padding-top: 10px;
        padding-bottom: 12px;
    }

    .arrow-link-description{
        position: relative;
        left: 11px;
    }

    .gantt_link-save-btn_button{
        float: right;
        left: 29%;
        position: relative;
        color: #FFFFFF;
        background-color: #7DFF8A;
        border: 0px solid white !important;
        font-weight: 600;
    }

    .gantt_link-delete-btn_button{
        float: left;
        color: #121212;
        border: 0px solid white !important;
        font-weight: 600;
    }

    .fit-right-img-link{
        float: right !important;
        position: relative;
        top: 10px;
        left: 4px;
        cursor: pointer;
    }

    .gantt_popup_controls.dhtmlx_popup_controls{
        display: flex;
    }

    .gantt_link-cancel-btn_button{
        position: relative;
        left: 28%;
        border: 0px solid white !important;
        font-weight: 600;
    }

    .gantt_popup_button.dhtmlx_popup_button.gantt_ok_button.dhtmlx_ok_button{
        background-color: #7DFF8A;
        color: white;
        text-shadow: 0 0px 0 #248a9f !important;
    }

    .gantt_popup_button.dhtmlx_popup_button.gantt_cancel_button.dhtmlx_cancel_button{
        background-color: #FFFFFF;
        color: #000000a6;
        text-shadow: 0 0px 0 #248a9f !important;
    }

    .middle-link-info{
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 12px;
        padding-bottom: 22px;
        color: white;
    }

    .gantt_popup_text{
        background-color: #25353A !important;
        margin: 0px !important;
        border-radius: 0px !important;
        padding: 5px;
    }

    .gantt_popup_controls.dhtmlx_popup_controls{
        background-color: #25353A !important;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        padding-bottom: 19px;
        padding-left: 19px;
        padding-right: 19px;

    }

    .plus-counter-responsable-gantt{
        margin-left: 7px;
        font-weight: 300;
        color: #121212;
    }

    .custom-gantt-responsable-dropdown .select-search__option{
        height: 30px !important;
    }

    .custom-gantt-responsable-dropdown .select-search__value::after{
        background-image: url("./assets/img/gantt/search-new.png");
        background-repeat: no-repeat;
        background-size: 12.6px;
        background-position-x: 100%;
        background-position-y: 2px;
        width: 14px;
        height: 20px;
    }

    .custom-gantt-responsable-dropdown .select-search__value{
        font-size: 12px;
    }

    .fix-name-at-new-gantt{
        display: inline-block;
        width: 27%;
    }

    .gantt_grid_scale{
        border-bottom: 1px solid #c4c4c47a !important;
    }
    .new-project-gantt-style .gantt_task_progress_wrapper{
        border: 0px solid rgba(48, 159, 233, 0.3) !important;
    }

    .gantt_task_content {
        display: none;
    }

    .gantt_task_line.gantt_milestone .gantt_task_content{
        display: block!important;
    }

    .img-responsable-lookahead {
        border-radius: 100%;
        height: 22px;
        width: 22px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-top: 4px;
    }

    .img-responsable-lookahead.no-img{
        background-color: #121212cf;
        color: #FFFFFF;
        font-size: 10px;
        position: relative;
        top: -8px;
    }

    .no-image-in-pdf{
        position: relative;
        top: 0px!important;
    }

    .gantt-header-titlemany, .gantt-header-titlelastpagemany{
        padding: 1px;
        color: #121212;
        position: relative;
        top: 0px;
        font-weight: 500;
        font-size: 22px!important;
    }

    .gantt-header-titlelastpage{
        padding: 1px;
        color: #121212;
        position: relative;
        top: 0px;
        font-weight: 500;
        font-size: 18px!important;
    }

    .img-pp{
        height: 25px;
        position: relative;
        top: 0px;
        margin-right: 35px;
    }


    .header-right{
        float: right;
        position: relative;
        top: 0px;
    }

    .printed-date{
        position: relative;
        top: 0px;
    }

    .position-noimage{
        position: relative;
        top: 0px;
    }

    /* ----------- CSS HEADER ----------- */

    .no-image-in-pdf{
        position: relative;
        top: 0px!important;
    }

    .wrapper-pdf{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 10px;
        width: calc(100% - 20px);
    }

    .wrapper-pdf-company, .header-right {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .wrapper-pdf .img-companymany {
        height: 50px;
    }
    .wrapper-pdf .header-right img{
        height: 22px;
    }

    .gantt-header-title-project{
        font-weight: 500;
        font-size: 22px;
    }
    .gantt-header-title-sector{
        font-weight: 300;
        font-size: 18px;
    }

    .img-company{
        height: calc(100% - 15px);
    }
    .img-owner{
        height: calc(100% - 15px);
    }


    /* ----------- END CSS HEADER ----------- */


    /* ----------- CSS FOOTER ----------- */

    .footer_container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        background-color: white;
        font-size: 18px;
        font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif!important;
        color:#121212;
        position:relative;
    }

    .footer_container_icons{
        display: flex;
        flex-direction: row;
    }

    .footer_container_icons_column{
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;

    }


    .footer_container_icons_column_title{
        font-weight: 600
    }
    .footer_container_items{
        display: flex;
        flex-direction: row;
    }
    .footer_container_icons_column_item span{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .footer_container_icons_column_item span div{
        width: 80px
    }

    .footer_container_icons_column_item span p{
        padding:0;
        margin:0;
        color: red
    }

    .footer-icon{
        height: 12px;
        margin-right:10px
    }

    .footer_container_info{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .gantt-footer-info{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding:0;
        height:40px;
        margin:0;
    }

    .gantt-footer-info span{
        margin:0;
        padding:0;
    }

    .gantt-footer-title{
        color:#747474;
        font-weight: 600;
        padding:0;
        margin:0;

    }
    .gantt-footer-data{
        font-weight: 600;
        padding:0;
        color: #121212;
        margin-left:5px
    }
    /* ----------- END CSS FOOTER ----------- */

    .subcontract-border{
        background-color: transparent!important;
    }

    .custom-gantt-subcontract .pre-text-subcontract{
        height: 10px;
        width: 10px;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
    }

    .custom-gantt-subcontract .subcontract-border{
        width: 100%;
        text-align: left;
        padding-left: 10px!important;
        max-width: 130px !important;   
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

    }

    .tag-class-pdf {
        margin-right: 5px;
        margin-left: 5px;
        position: relative;
        top: 4px;
    }

    .tag-class-span{
        margin:  0 3px 0 0;   
    }

    .name-responsable-pdf {
        margin: 0 5px;
    }

    .custom-gantt-responsable-dropdown {
        text-align: left;
        margin-left: 5px;
    }

    .span-img-resp{
        display: none;
    }

    .gantt_tree_icon.gantt_open {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAADACAQAAAD41aSMAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAxtJREFUeNrt3M1xE0EQhuHeBCAAiMM4AbLhyok/GQdAPDYhkAYJkAEHcGGMJO9Kuz3d+HmrOFD4QH3PxbZmZnobGtkEAAAAAQAgAAAEAIAAABAAAAIAQAAACAAAAQAgAAAEAIAAABAAAAIAQAAACAAAAQAgAAAEAIAAABAAAAIAQAAACAAAAQAgAAAEAIAAABAAAAIAQACeKMAUL+J7sSVejvkfjQCY4jrexOv4Vmj+y7iJL7F7CgBTXMe7iPhRiOAybuJZRFzlE2QD3M0fhQju5o8RBLkAU3yO9/f+XoHg/vwDCDIBHs5fgeDh/OkEeQD75h9NsG/+ZIIsgCmu4sOBfxtFcGj+VIIcgGPzjyI4Nn8iQQbAY/OPIHgVt0fnTyPYHmCKXXyc8XWZBHPmTyLYHuBq1vyZBHPnTyHYHmAXn2Z/bQbBkvn/C4BaBMXmz/ouqApBufnzfg6oQFBw/syfhEcTlJw/93dBIwmKzp/929BRBGXnz/88YARB4flHfCKWTXARX+vOP+Yz4UyC4vOPOhWRRVB+/nHngjIIGsw/8mDW1gQt5h97Mm5Lgibzjz6auBVBm/nHnw3dgqDR/BUO565N0Gr+Gqej1yS4iNt43mf+KsfT1yJoN3+d+wFrEDScv9IFjXMJWs5f64bMOQRN5692RelUgrbz17sjdgpB4/krXtJbSjB1nr/mLcllBNF5/qrXVJcQROf5694TXp+g5PyVL2qvS1B0/to35dcjKDt/9acK1iEoPH/9tyLOJyg9f4fHOs4jKD5/j9dSTicoP3+X52pOI2gwf5/3gpYTtJi/04NNywiazN/rxaz5BG3m7/Zk2TyCRvP3ezPucYJW83d8tO84QbP5e76aeJig3fxdn63cT9Bw/r7vhv5L0HL+zg+3/k3QdP7eL+f+IWg7f/eni38RNJ6//9vRu99/AAgAAAEAIAAABACAAAAQAAACAEAAAAgAAAEAIAAABACAAAAQAAAAbAAAgAAAEAAAAgBAAAAIAAABACAAAAQAgAAAEAAAAgBAAAAIAAABACAAAAQAgAAAEICO/QRQTDqQX8PqIQAAAABJRU5ErkJggg==);
        width: 18px;
        background-size: 20px;
        cursor: pointer;
    }

    .gantt_tree_icon.gantt_close{
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAOBJREFUeJzt08EJgFAMBcFgtZZkt9rBIl70ywy8ewjsDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEvaZ+a0Wzse/nh529sHwJcJBIJAIAgEgkAgCASCQCAIBIJAIAgEgkAgCASCQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOCvLjJfStGXVHE+AAAAAElFTkSuQmCC);
        width: 18px;
        background-size: 20px;
        cursor: pointer;
    }

    .gantt_tree_icon.gantt_close, .gantt_tree_icon.gantt_open {
        min-height: 18px;
        height: 100%;
    }

    .gantt_layout_content{
        margin-left: 10px!important;
        border: 1px solid #e9e9e9;
        margin-right: 10px!important;
        position: relative!important;
    }

    .gantt_layout_cell{
        border-top: 0px solid red!important;
    }

    .gantt_grid_head_cell{
        color: #121212!important;
    }

    .string-h{
        text-overflow: ellipsis!important;
        white-space: nowrap!important;
        overflow: hidden!important;
        display: inline-block;
    }

    .string-v{
        display: none;
    }

    .svg_tag_icon {
        fill: currentColor;
        display: inline-block;
        width: 14px;
        height: 14px;
        position: relative;
        top: 1px;   
    }

    .svg_company_icon {
        fill: currentColor;
        display: inline-block;
        width: 14px;
        height: 14px;
        position: relative;
        top: 1px;   
    }

    .open-mr-drawer-button{
        display: none;
    }

    .on-lookahead-new-gantt-counter, .on-lookahead-new-gantt-counter-less{
        display: none!important;
    }

    .checked-for-gantt{
        display: none!important;
    }

    .gantt-checkbox-column{
        display: none!important;
    }

    .new-gantt-chart{
        margin-top: 95px!important;
    }

    .gantt_data_area .is_milestone{
        position: relative!important;
        top: 13px!important;
    }

    .gantt_data_area .is_parent span{
        position: relative!important;
        top: -5px!important;
    }
`;
};

/**
 * Generates the CSS styles for the schedule feature and other custom Gantt chart styles.
 * @returns {string} The CSS styles as a string.
 */
const scheduleCss = () => {
  return `
        .new-child-gantt-style{
            height: 22px !important;
            border: 0px solid !important;
            border-radius: 6px !important;
        }

        .new-child-gantt-style .gantt_task_progress{
            background-color: inherit;
            opacity: 1 !important;
        }
    `;
};

/**
 * Generates the CSS styles for the lookahead feature and other custom Gantt chart styles.
 * @returns {string} The CSS styles as a string.
 */
const lookaheadCss = () => {
  return `

        .gantt_task_line.top-fix-style {
            min-width: 10px !important;
        }
        div .odd-col {
            background-color: transparent !important;
            height: 100%;
        }

        div .non-odd-col {
            background-color: #1212120a !important;
            height: 100%;
        }

        .custom-div-row-container {
            position: relative !important;
            left: 0px !important;
            top: 0px !important;
        }

        .gantt_side_content.gantt_right{
            position: relative;
            top: -25px !important;
            width: 189px;
            height: 30px;
        }

        .main-activity-route{
            position: absolute;
            top: 16px;
            left: 30px;
            font-weight: 100;
            font-size: 10px !important;
        }

        .main-custom-style {
            background-color: #f7f7f7 !important;
            height: initial;
            z-index: 10 !important;
            font-size: 12px !important;
        }

        .milestone-custom-style {
            background-color: #e0c9ed !important;
        }

        .act-correlative-id .main-activity-name,
        .act-correlative-id .main-activity-name .TooltipHTML .TooltipHTMLElement{
            font-weight: 600 !important;
        }
        .custom-div-row-container {
            position: relative !important;
            left: 0px !important;
            top: -6px !important;
        }

        .milestone-name-custom{
            font-weight: 600 !important;
            position: absolute;
            z-index: 100;
            margin-left: 10px;
            font-size: 12px;
        }

        .personalized-weekend-class-visua {
            background-color: #e9e9e9 !important;
        }
        .hide-parent-task-markers, .parent-task-style {
            height: 18px !important;
            border: 1px solid var(--borderMarkersColor) !important;
            border-bottom-left-radius: 0px  !important;
            border-bottom-right-radius: 0px  !important;
            z-index: 1;
        }

        .parent-task-style:before {
            content: '';
            position: absolute;
            height: 0px;
            width: 0px;
            left: 1px;
            z-index: 0;
            top: 18px;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid var(--borderMarkersColor) !important;
            transform: rotate(90deg);
        }

        .parent-task-style:after {
            content: '';
            height: 0px;
            position: relative;
            float: right;
            width: 0px;
            z-index: 0;
            top: -32px;
            left: -1px;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 5px solid var(--borderMarkersColor) !important;
            transform: rotate(270deg);
        }

        .hide-parent-task-markers:after, .hide-parent-task-markers:before {
            visibility: hidden;
        }
        .gantt_side_content.gantt_left{
            top: 0px !important;
            left: -26px;
            height: 24px;
        }

        .name-gantt-visualization{
            display: block;
            width: auto;
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .new-child-gantt-style{
            height: 22px !important;
            border: 0px solid;
            border-radius: 6px;
        }

        .new-child-gantt-style .gantt_task_progress{
            background-color: inherit;
            opacity: 1 !important;
        }

        .force-border-to-weekly-commited-range-task {
            border: 2px solid #7dff8a !important;
            border-radius: 6px;
            z-index: 1;
        }

        .force-border-to-weekly-restricted-task {
            border: 2px solid red !important;
            border-radius: 6px;
            z-index: 1;
        }

        .force-border-to-weekly-range-task {
            border: 2px solid #25a8ba !important;
            border-radius: 6px;
            z-index: 1;
        }
    `;
};

/**
 * Generates the CSS styles for the critical path feature.
 * @returns {string} The CSS styles as a string.
 */
const criticalCss = () => {
  return `
        .gantt_task_line.gantt_bar_task.gantt_critical_task{
            background-color: #e8a3a3 !important;
        }
        
        .gantt_task_line.gantt_bar_task.gantt_critical_task .gantt_task_progress_wrapper .gantt_task_progress{
            background-color: #e50101 !important;
        }
        
        .gantt_task_line.gantt_bar_task.gantt_critical_task .gantt_side_content .on-lookahead-new-gantt-counter-less{
            border-color: #e50101 !important;
            color:#e50101 !important;
        }
        .gantt_task_line.gantt_bar_task.gantt_selected.gantt_critical_task{
            background-color: #e8a3a3 !important;
        }
        
        .gantt_critical_link .gantt_line_wrapper>div {
            background-color: #e50101 !important;
        }
        
        .gantt_critical_link .gantt_link_arrow {
            border-color: #e50101 !important;
        }

        .gantt_critical_link .gantt_link_arrow_right{
            border-top-color: transparent!important;
            border-right-color: transparent!important;
            border-bottom-color: transparent!important;
        }
        
        .gantt_task_line.gantt_bar_task.gantt_no_critical_task{
            background-color: #C4C4C4 !important;
        }
        
        .gantt_task_line.gantt_bar_task.gantt_no_critical_task .gantt_task_progress_wrapper .gantt_task_progress{
            background-color: #a7a7a7 !important;
        }
        
        .gantt_task_line.gantt_bar_task.gantt_no_critical_task .gantt_side_content .on-lookahead-new-gantt-counter-less{
            border-color: #a7a7a7 !important;
            color:#a7a7a7 !important;
        }
        
        .gantt_task_line.gantt_bar_task.gantt_selected.gantt_no_critical_task{
            background-color: #C4C4C4 !important;
        }
        
        .gantt_no_critical_link .gantt_line_wrapper>div {
            background-color: #C4C4C4;
        }
        
        .gantt_no_critical_link .gantt_link_arrow {
            border-color: #C4C4C4;
        } 
    `;
};

/**
 * Generates the CSS styles for the slack feature.
 * @returns {string} The CSS styles as a string.
 */
const slackCss = () => {
  return `
            .slack {
                background: #b6b6b6;
                background: repeating-linear-gradient( 
                    45deg
                , #FFFFFF, #FFFFFF 5px, #b6b6b678 5px, #b6b6b669 10px );
            }

            .footer_container_icons_column_item__float{
                display:initial !important
            }
        `;
};

/**
 * Generates the CSS styles for the links feature.
 * @returns {string} The CSS styles as a string.
 */
const linksCss = () => {
  return `
            .gantt_links_area {
                display: none !important;
            }
            .footer_container_icons_column_item__links{
                display:none !important;
            }
        `;
};

/**
 * Generates the CSS styles for the baseline feature.
 * @returns {string} The CSS styles as a string.
 */
const baselineCss = () => {
  return `
        .baseline {
            opacity: 0;
        }
    `;
};

/**
 * Generates the CSS styles for the today line feature.
 * @returns {string} The CSS styles as a string.
 */
const todaylineCss = () => {
  return `
        .gantt_marker {
            opacity: 0;
          }
    `;
};

/**
 * Generates the CSS styles for exporting the Gantt chart based on the current configuration and conditions.
 *
 * @param {Object} gantt - The Gantt chart instance, containing the configuration settings.
 * @param {boolean} isLookahead - Whether to include the lookahead CSS.
 *
 * @returns {string} The CSS styles wrapped in an HTML `<style>` tag for the Gantt chart export.
 */
export const getCssToExport = ({ gantt, isLookahead }) => {
  const BLANK_CSS = '';

  const {
    config: {
      highlight_critical_path: showCritical,
      show_slack: showSlack,
      show_links: showLinks,
      show_baseline: showBaseline,
      show_todayline: showTodayline
    },
    isActiveColorWBS: showColorWBS,
    cssWBS
  } = gantt;

  const cssConfig = {
    general: generalCSS(),
    lookahead: isLookahead ? lookaheadCss() : scheduleCss(),
    critical: showCritical ? criticalCss() : BLANK_CSS,
    slack: showSlack ? slackCss() : BLANK_CSS,
    links: showLinks ? BLANK_CSS : linksCss(),
    baseline: showBaseline ? BLANK_CSS : baselineCss(),
    todayline: showTodayline ? BLANK_CSS : todaylineCss(),
    colorWBS: showColorWBS ? cssWBS : BLANK_CSS
  };

  const styleToExport = Object.values(cssConfig).join('');

  return `<style>${styleToExport}</style>`;
};

/**
 * Generates the CSS styles for adjusting the Gantt chart layout and positioning on the page.
 *
 * @param {number} customAdjust - The pixel value to adjust the position of the timeline cells.
 *
 * @returns {string} The CSS styles wrapped in an HTML `<style>` tag for the page layout adjustment.
 */
export const getCssForPage = () => {
  return `
        <style>
            .gantt_layout_cell.timeline_cell {
                left: 0px !important;
            }
        </style>
    `;
};

/**
 * Calculates the adjustment value for the page layout based on the Gantt chart width and page position.
 *
 * @param {Object} gantt - The Gantt chart instance used to calculate the table width.
 * @param {boolean} isAllContent - Indicates whether to include all content for the width calculation.
 * @param {number} pagePosition - The current horizontal position of the page.
 *
 * @returns {number} The adjustment value in pixels to be applied for the page layout.
 */

const calculateAdjustPage = ({ gantt, isAllContent, pagePosition }) => {
  const ADJUST_LEFT = 20;
  const ADJUST_DEFAULT = 0;

  const ganttTableWidth = calculateGanttTableWidth({ gantt, isAllContent });

  if (ganttTableWidth && pagePosition >= ganttTableWidth) {
    return ADJUST_LEFT;
  }

  return ADJUST_DEFAULT;
};

/**
 * Generates the CSS styles for exporting the Gantt chart to PDF, including adjustments for page layout.
 *
 * @param {Object} gantt - The Gantt chart instance used for extracting configuration and styles.
 * @param {boolean} isLookahead - Whether to include lookahead CSS.
 *
 * @returns {string} The combined CSS styles wrapped in an HTML `<style>` tag for the PDF export.
 */
export const getCSSPDF = ({ gantt, isLookahead }) => {
  const cssToPage = getCssForPage();
  const cssHtml = getCssToExport({ gantt, isLookahead });

  return cssToPage + cssHtml;
};
