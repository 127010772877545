import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import EventEmitter from 'react-native-eventemitter';
import { base } from '../../services/base';
import { Input } from 'antd';
import { withTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import NotificationSystem from '../../components/DesignSystem/NotificationSystem';
import SSOProcoreButton from './SSOProcoreButton';
import { useProperties } from '../../hooks/useProperties';
import './index.scss';
import LayoutLoginOutbuild from '../../components/LayoutLoginOutbuild';
import {
  redirectLogic,
  goToForgot,
  goToSignUp,
  validateEmail,
  validatePasswordLength,
  isValidForm
} from './login.helpers';
import { useAuth } from './hooks/useAuth';
import { useProcoreAuth } from './hooks/useProcoreAuth';
import {
  EMAIL_TYPE,
  PASSWORD_TYPE,
  ERROR_TYPE,
  WARNING_TYPE,
  FLOW_FREE_TRIAL,
  FLOW_FREE_TRIAL_ONLY_US
} from './constants';

function LoginView(props) {
  const { t } = props;
  const history = useHistory();
  const propertiesHook = useProperties();
  const [dataForm, setDataForm] = useState({ email: '', password: '' });
  const [emailIncorrectFormat, setEmailIncorrectFormat] = useState(false);
  const [passwordIncorrectFormat, setPasswordIncorrectFormat] = useState(false);
  const [configNotification, setConfigNotification] = useState({
    show: false,
    type: '',
    label: ''
  });
  const [showFlowFreeTrial, setShowFlowFreeTrial] = useState(false);
  const [beta, setBeta] = useState(false);
  const [env, setEnv] = useState('Local');
  const { isLoadingButton, onSubmit } = useAuth({
    t,
    dataForm,
    setConfigNotification,
    history
  });
  const { isLoadingProcoreButton, handleSSOProcore } = useProcoreAuth(
    t,
    beta,
    dataForm,
    setConfigNotification
  );

  useEffect(() => {
    window.Appcues.page();
  });

  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    const currentUrl = window.location.href;

    const redirectUrl = redirectLogic(currentUrl);

    if (redirectUrl !== currentUrl) {
      window.location.replace(redirectUrl);
    }

    if (
      window.location.href.includes('localhost') ||
      window.location.href.includes('dev.proplanner.app') ||
      window.location.href.includes('qa.proplanner.app') ||
      window.location.href.includes('preprod.proplanner.app')
    ) {
      setBeta(true);
      window.location.href.includes('qa.proplanner.app') && setEnv('QA');
      window.location.href.includes('dev.proplanner.app') && setEnv('Dev');
    }

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.agaveapi.com/init.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const flagFlowFreeTrial = propertiesHook?.properties?.find(
      (property) => property.name === FLOW_FREE_TRIAL
    );
    const flagFlowFreeTrialOnlyUS = propertiesHook?.properties?.find(
      (property) => property.name === FLOW_FREE_TRIAL_ONLY_US
    );

    if (!flagFlowFreeTrial || !flagFlowFreeTrialOnlyUS) return;
    if (!flagFlowFreeTrial.active) return;

    if (flagFlowFreeTrialOnlyUS.active) {
      setShowFlowFreeTrial(t('lang') === 'en');
      return;
    }

    setShowFlowFreeTrial(true);
  }, [propertiesHook]);

  const signed = localStorage.getItem('signed');
  const authToken = localStorage.getItem('authToken');
  const isValidUser = signed && authToken;
  if (isValidUser) {
    return <Redirect to="/selectCompany" />;
  }

  const handleInputChange = (event) => {
    setDataForm({
      ...dataForm,
      [event.target.name]: event.target.value
    });
  };

  const handleOnBlur = (type) => {
    if (type === EMAIL_TYPE)
      setEmailIncorrectFormat(!validateEmail(dataForm.email));
    if (type === PASSWORD_TYPE)
      setPasswordIncorrectFormat(!validatePasswordLength(dataForm.password));
  };

  const renderNotification = configNotification.show && (
    <div className="login__notification">
      <NotificationSystem
        type={configNotification.type}
        label={configNotification.label}
      />
    </div>
  );
  const renderForm = (
    <div className="login__form">
      <form onSubmit={onSubmit}>
        <div className="form__input">
          <label>{t('login.label_email')}</label>
          <Input
            value={dataForm.email}
            onChange={handleInputChange}
            onBlur={() => handleOnBlur(EMAIL_TYPE)}
            className={emailIncorrectFormat ? ERROR_TYPE : ''}
            type={EMAIL_TYPE}
            name={EMAIL_TYPE}
            autoComplete="off"
          />
          {emailIncorrectFormat && <small>{t('login.hint_email')}</small>}
        </div>
        <div className="form__input">
          <label>{t('login.label_password')}</label>
          <Input
            value={dataForm.password}
            onChange={handleInputChange}
            onBlur={() => handleOnBlur(PASSWORD_TYPE)}
            className={passwordIncorrectFormat ? ERROR_TYPE : ''}
            type={PASSWORD_TYPE}
            name={PASSWORD_TYPE}
          />
          {passwordIncorrectFormat && <small>{t('login.hint_password')}</small>}
        </div>
        <div className="form__input">
          <button type="submit" disabled={!isValidForm(dataForm)}>
            {isLoadingButton ? (
              <LoadingOutlined />
            ) : (
              t('login.button_sign_in_text')
            )}
          </button>
        </div>
        <div className="form__forgot">
          <a href="#" onClick={(e) => goToForgot(e, history)}>
            {t('login.button_forgot_password_text')}
          </a>
        </div>
        {showFlowFreeTrial && (
          <div className="form__signup">
            <span>
              {t('login.button_sign_up_label')}{' '}
              <a href="#" onClick={(e) => goToSignUp(e, history)}>
                {t('login.button_sign_up_link')}
              </a>
            </span>
          </div>
        )}
        <div className="form__line">
          <div className="line__separation" />
          <span>{t('login.other_login_text')}</span>
        </div>
        <div className="form__procore">
          <SSOProcoreButton
            t={t}
            isLoading={isLoadingProcoreButton}
            onClick={() => handleSSOProcore(history)}
          />
        </div>
        {beta && (
          <div className="form__beta">
            <NotificationSystem
              type={WARNING_TYPE}
              label={`Warning: ${env} version, please go to: app.outbuild.com`}
            />
          </div>
        )}
      </form>
    </div>
  );
  return (
    <div>
      <LayoutLoginOutbuild>
        <>
          {renderNotification}
          {renderForm}
        </>
      </LayoutLoginOutbuild>

      <div className="loggin-project-version">
        {base.version ? `v ${base.version}` : ''}
      </div>
    </div>
  );
}

export default withTranslation()(LoginView);
