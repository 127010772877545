import {
  hhEditor,
  costEditor,
  constraintTypeEditor,
  progressEditor,
  textEditor,
  dateStartEditor,
  dateEndEditor,
  durationEditor,
  constraintDateEditor,
  usedCostEditor,
  descriptionEditor
} from './editor-inline-gantt';
import moment from 'moment';
import SelectSearch from 'react-select-search';
import { Tooltip, Popover } from 'antd';

import plusIcon from '../../img/gantt/plusIcon.svg';

/** New gantt update icons */
import hasModification from '../../img/gantt/activity-has-modifications.png';
import hasNotModification from '../../img/gantt/activity-has-not-modifications.png';

import addResponsable from '../../img/gantt/assignee-new.png';
import removeIconNew from '../../img/gantt/X.png';
import addTagIcon from '../../img/gantt/add-tag.png';
import addIcon from '../../img/gantt/add-new.png';
import emptyIcon from '../../img/gantt/empty-new.png';
import hoverIcon from '../../img/gantt/hover-new.png';
import deleteIcon from '../../img/gantt/delete-new.png';
import completeIcon from '../../img/gantt/complete-new.png';
import uncompleteIcon from '../../img/gantt/uncomplete-new.png';
import deleteHover from '../../img/gantt/delete-hover.png';
import addHover from '../../img/gantt/add-hover.png';
import requestModificationHover from '../../img/gantt/request-new-hover.png';
import { TagIconExt } from '../../../icons';
import {
  tagIconSVG,
  companyIconSVG,
  getCompanyTextColor
} from '../../../utils';

import { capitalize } from 'lodash';

import { from_links_to_string_predecessor } from '../custom_gantt_fields/custom_predecessor';
import { from_links_to_string_sucessor } from '../custom_gantt_fields/custom_sucessor';

import {
  formatMoney,
  renderFriend,
  renderSubcontract,
  renderTag,
  replacePlainTextToLinksPattern,
  replacePlainTextToLinksTemplate
} from '../../../utils/lookahead-common';

import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';

/** New masterplan update imports */
import React, { useRef } from 'react';
import fakeAvatar from '../../../assets/img/fake_user.png';
import { getStringHidden } from '../../../views/ganttContainer/gantt/gantt.helper';
import { isFeatureOn } from '../../../utils/featureUtils';
import { FEATURE_FLAGS } from '../../../constants/featureFlags';
import { columnNameWBS, preColumnWBS } from '../gantt-general-config';
import useMasterplanPermissions from '../../../hooks/useMasterplanPermissions';
import { getSessionTokenData } from '../../../utils/userUtils';
import { earlyAccessCriticalPath } from '../../../utils/earlyAccessCriticalPath';

/** Permissions */
const userGanttPermissions = useMasterplanPermissions();
const customTypePermissions = ['SA', 'V', 'ACP'];
const fieldNoPermission = customTypePermissions.includes(
  userGanttPermissions.gantt
);
const triggerSetter = fieldNoPermission ? '' : 'click';
const cursorCustomStyle = {
  cursor: fieldNoPermission ? 'not-allowed' : 'pointer'
};

export const ganttColumnsSettingsVersion = 3;

const slackDaysFormatter = (activity, gantt) => {
  if (earlyAccessCriticalPath()) {
    const slack = activity.totalSlack;
    if (slack === undefined || slack === null || slack === '') {
      return { enFormatSlack: '', otherFormatSlack: '' };
    }
    const parseSlack = parseFloat(slack).toFixed(2);
    const enFormatSlack = parseSlack;
    const otherFormatSlack = parseSlack.replace('.', ',');

    return { enFormatSlack, otherFormatSlack };
  }

  return OldSlackDaysFormatter(activity, gantt);
};

const OldSlackDaysFormatter = (activity, gantt) => {
  const slack = activity.totalSlack;
  if (slack === undefined || slack === null || slack === '') {
    return { enFormatSlack: '', otherFormatSlack: '' };
  }
  const daysSlack = gantt.formatter.format(slack);
  const parseSlack = parseFloat(daysSlack);
  const enFormatSlack = formatMoney(parseSlack, '', 2, '.', ',');
  const otherFormatSlack = formatMoney(parseSlack, '', 2, ',', '.');
  return { enFormatSlack, otherFormatSlack };
};

/**
 * This function creates primary configuration object for DHTMLX gantt lib for all columns for grid side
 * @param {*} gantt Instance where user is actually using the gantt lib (dhtmlx) which gives access to their API
 * @param {*} t Translation object from i18 lib
 * @returns Return an object which many elements as columns we have at our grid UI
 */
const navigatorLang = navigator.language || navigator.userLanguage;
const totalSlackValuesByActivity = new Map();
export const table_grid_columns = (gantt, t, permissionGantt = '') => {
  let columns = [
    /* {
        name: 'id',
        label: 'UID',
        align: 'center',
        width: '*',
        resize: true,
        hide: false
    }, */

    {
      width: 30,
      name: preColumnWBS,
      label: '',
      hide: true,
      data_type: 'string',
      ignore_as_column: true,
      template: (activity) => ''
    },
    {
      name: columnNameWBS,
      label: '',
      hide: true,
      data_type: 'string',
      ignore_as_column: true,
      template: (activity) => {
        if (!activity) return;
        const taskLevel = activity?.$level;
        const childBeforeClass =
          'proplanner-wbs-color-' + taskLevel + '-child-before';
        const parentBeforeClass =
          'proplanner-wbs-color-' + taskLevel + '-before';
        const isParent = gantt.hasChild(activity.id);
        return `<div class="${isParent ? parentBeforeClass : childBeforeClass}"></div>`;
      }
    },
    {
      name: 'checked',
      align: 'center',
      label: 'Checked',
      template: (activity) => {
        const sessionTokenData = getSessionTokenData();
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.checked;
        }
        if (activity.readonly) return;
        let checked;
        if (activity.mustApplyVisibleChecked) {
          checked = activity.visibleChecked ? ' checked' : '';
        } else {
          checked = activity.checked ? ' checked' : '';
        }
        return `
           <div class="${isOdd ? 'odd-col' : 'non-odd-col'} checked-for-gantt ${checked ? 'checked-for-gantt-clicked' : ''}">
               <input class='gantt-checkbox-column' style="cursor: pointer; accent-color: #53C255;" onclick=window.to_use_react_gantt.runMultiSelectionMiddleware(${activity.id}) type='checkbox' name='test' id='test' value='1' ${checked}/>
           </div>
           ${
             ['superadmin', 'admin', 'planner', 'projectleader'].includes(
               sessionTokenData?.role
             )
               ? ` <div class="gantt_line_add_task_container">
                  <div class="gantt_line_add_task" onclick="window.to_use_react_gantt.lineAddHandler(${activity.id})">
                      <div class="gantt_line"></div>
                      <div class="gantt_add_task">
                          <img src="${plusIcon}" width="8px" height="8px" alt="Plus Icon" />
                      </div>
                  </div>
              </div>
              `
               : ''
           }
           `;
      },
      hide: false,
      width: 30,
      groupable: false,
      orderable: false,
      filterable: false,
      data_type: 'string',
      ignore_as_column: true
    },
    {
      name: 'correlative_id',
      label: 'ID',
      align: 'center',
      width: '45',
      resize: true,
      hide: false,
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.correlative_id;
        }
        const label = activity.correlative_id;
        // if (activity.activityModifications) {
        //     if (activity.activityModifications.length) {
        //         label += `
        //         <div class="gantt-dropdown" onclick="window.to_use_react_gantt.$showDropdown(this, ${activity.id})">
        //             <Tooltip placement='top' title='${t('lookahead_planification_modification_request')}'>
        //                 <img width="12" src="${ganttModificationIcon}"/>
        //             </Tooltip>
        //         </div>
        //         `
        //     }
        // }
        return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                ${label}
            </div>
            `;
      },
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true
    },
    /* {
        name: 'pp_id',
        label: 'ProPlanner ID',
        align: 'center',
        width: '*',
        resize: true,
        hide: false,
        template: (activity) => {
            return activity.proplannerId
        }
    }, */
    {
      name: 'text',
      label: 'Actividades',
      width: '270',
      resize: true,
      tree: true,
      editor: textEditor,
      hide: false,
      data_type: 'string',
      groupable: false,
      orderable: false,
      orderable_switch: ['A → Z', 'Z → A'],
      filterable: true,
      template: (activity) => {
        const hasChild = gantt.hasChild(activity.id);
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.text;
        }

        let iconToModifications;
        const onWaiting = activity.activityModifications.filter(
          (el) => el.state == 'waiting'
        );

        if (
          activity.activityModifications &&
          activity.activityModifications.length &&
          onWaiting.length
        ) {
          iconToModifications = hasModification;
        } else if (
          activity.activityModifications &&
          activity.activityModifications.length
        ) {
          iconToModifications = hasNotModification;
        }

        const level = activity.$level;
        const RIGHT_ALIGN = 15 * level;

        /** build string hidden for pdf */
        const htmlH = getStringHidden(activity.text, 'text', -27);

        const isMRDrawerFeatureEnabled = isFeatureOn(
          FEATURE_FLAGS.ENABLE_MODIFICATION_REQUESTS_DRAWER
        );
        const popoverOpeningHandler = isMRDrawerFeatureEnabled
          ? ''
          : `onclick="window.to_use_react_gantt.$showDropdown(this, ${activity.id})"`;

        let textToRender = `<span class="string-v">${activity.text}</span>`;
        const isSel = gantt.isSelectedTask(activity.id);
        /** check if is milestone, or is new activity */
        if (
          (['task', 'milestone'].includes(activity.type) ||
            (!activity.type && activity.proplannerId)) &&
          isSel
        ) {
          textToRender = `<span class="string-v">
                                        ${activity.text}
                                    </span>
                                </span>
                                `;
        }

        const toRender = `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'} request-btn-task-gantt" style="width: 88%;">
                <span  style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
                color: #121212;">
                    <span class="cut-text-and-dot ${iconToModifications ? 'fix-name-at-new-gantt' : ''}">
                        ${textToRender}
                        ${htmlH}
                    </span>
                    ${
                      iconToModifications
                        ? `
                    <span class="gantt-dropdown open-mr-drawer-button" style="left: -${RIGHT_ALIGN}px" data-activity-id="${activity.id}" ${popoverOpeningHandler}>
                        <Tooltip placement='top' title='${t('lookahead_planification_modification_request')}'>
                            <img class="request-empty-gantt"  width="15" src="${iconToModifications}"/>
                            <img class="request-hover-gantt"  width="15" src="${requestModificationHover}"/>
                        </Tooltip>
                    </span>`
                        : ''
                    }
                </span>
            </div>
            `;
        return toRender;
      }
    },
    {
      name: 'buttons',
      label:
        '<div style="font-size: 26px; color: #333333;font-weight: 400; cursor: pointer" onclick="window.to_use_react_gantt.createTask({ duration: 9 })"> + </div>',
      width: 90,
      align: 'center',
      resize: false,
      ignore_as_column: true,
      template: function (activity) {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.buttons;
        }
        const currentSector = JSON.parse(
          sessionStorage.getItem('currentSector')
        );
        if (!currentSector) return;
        /** Progress btn */
        let progressBtn;
        if (activity.progress <= 99.9) {
          let onClickStrProgressCompleted = `onclick="window.to_use_react_gantt.completeTask(${activity.id});setTimeout(() => {window.to_use_react_gantt.updateExpectedGlobal();window.to_use_react_gantt.executeAutoScheduleExternal()}, 500)"`;
          if (permissionGantt.gantt === 'V') {
            onClickStrProgressCompleted = '';
          }
          progressBtn = `
                    <span class="complete-btn-task-gantt" ${onClickStrProgressCompleted}>
                        <img class="complete-empty-gantt" width="15" src="${emptyIcon}" />
                        <img class="complete-hover-gantt" width="15" src="${hoverIcon}" />
                    </span>
                `;
        } else {
          let onClickStrProgressUncompleted = `onclick="window.to_use_react_gantt.uncompleteTask(${activity.id});setTimeout(() => {window.to_use_react_gantt.updateExpectedGlobal();window.to_use_react_gantt.executeAutoScheduleExternal()}, 500)"`;
          if (permissionGantt.gantt === 'V') {
            onClickStrProgressUncompleted = '';
          }
          progressBtn = `
                    <span class="uncomplete-btn-task-gantt" ${onClickStrProgressUncompleted}>
                        <img class="uncomplete-empty-gantt" width="15" src="${completeIcon}" />
                        <img class="uncomplete-hover-gantt" width="15" src="${uncompleteIcon}" />
                    </span>
                `;
        }

        /** Add btn */
        let addTaskBtn;
        const onClicStrAdd = `onclick="window.to_use_react_gantt.createTaskWithChild({ duration: ${parseInt(currentSector.hoursPerDay)} }, ${activity.id}, ${activity.proplannerId})"`;
        if (gantt.isDataFiltered || gantt.isDataOrdered) {
          addTaskBtn =
            '<span style="font-size: 26px; color: #33333300;font-weight: 400"> + </span>';
        } else {
          if (permissionGantt.gantt === 'V') {
            addTaskBtn = `
                        <Tooltip title="${t('not_permissions_actions')}">
                            <span class="add-btn-task-gantt" style="cursor: not-allowed">
                                <img class="add-empty-gantt" width="15" src="${addIcon}" />
                                <img class="add-hover-gantt" width="15" src="${addHover}" />
                            </span>
                        </Tooltip>
                    `;
          } else {
            addTaskBtn = `
                    <span class="add-btn-task-gantt" ${onClicStrAdd}>
                        <img class="add-empty-gantt" width="15" src="${addIcon}" />
                        <img class="add-hover-gantt" width="15" src="${addHover}" />
                    </span>
                `;
          }
        }

        /** Delete btn */
        let onclickStrDel = `onclick="window.to_use_react_gantt.trashTask(${activity.id})"`;
        if (permissionGantt.gantt === 'V') {
          onclickStrDel = '';
        }
        const deleteTaskBtn =
          activity.correlative_id === 0
            ? '<span class="delete-task-gantt-btn"></span>'
            : `
                <span class="delete-task-gantt-btn" ${onclickStrDel}>
                    <img class="delete-empty-gantt" width="15" src="${deleteIcon}" />
                    <img class="delete-hover-gantt" width="15" src="${deleteHover}" />
                </span>
            `;

        /** Build parent div with all others btns */
        const htmlPlain = `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                    <div style="position: relative; left: -22px">
                        ${addTaskBtn + progressBtn + deleteTaskBtn}
                    </div>
                </div>
            `;

        return htmlPlain;
      },
      groupable: false,
      orderable: false,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: false
    },
    {
      name: 'unique_correlative_id',
      label: 'UID',
      align: 'center',
      width: '*',
      resize: false,
      hide: true,
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.id;
        }
        const label = activity.unique_correlative_id;
        return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                ${label}
            </div>
            `;
      },
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: false
    },
    {
      name: 'status',
      label: 'Estado',
      width: '*',
      resize: true,
      hide: true,
      align: 'center',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.status;
        }
        switch (activity.status) {
          // Advancement = blue
          // Overdue = red
          // Done = green
          // Doing = yellow
          // Waiting = NO ICON

          case 'Advancement':
            return `<div class="${isOdd ? 'odd-col' : 'non-odd-col'}" title="${t('exportable.Advancement')}">
                <span>
                    <img style="width: 17px; height: 17px" src="https://criticapp.s3.sa-east-1.amazonaws.com/ontime-new.png" />
                </span>
                </div>`;
          case 'Overdue':
            return `<div class="${isOdd ? 'odd-col' : 'non-odd-col'}" title="${t('exportable.Overdue')}">
                <span>
                    <img style="width: 17px; height: 17px" src="https://criticapp.s3.sa-east-1.amazonaws.com/overdue-new.png" />
                </span>
                </div>`;
          case 'Waiting':
            return `<div class="${isOdd ? 'odd-col' : 'non-odd-col'}" title="${t('exportable.Waiting')}"> - </div>`;
          case 'Doing':
            return `<div class="${isOdd ? 'odd-col' : 'non-odd-col'}" title="${t('exportable.Doing')}">
                <span>
                    <img style="width: 17px; height: 17px" src="https://criticapp.s3.sa-east-1.amazonaws.com/doing-new.png" />
                </span>
                </div>`;
          case 'Done':
            return `<div class="${isOdd ? 'odd-col' : 'non-odd-col'}" title="${t('exportable.Done')}">
                <span>
                    <img style="width: 17px; height: 17px" src="https://criticapp.s3.sa-east-1.amazonaws.com/done-new.png" />
                </span>
                </div>`;
          default:
            return `
                    <div class="${isOdd ? 'odd-col' : 'non-odd-col'}"> </div> 
                `;
        }
      },
      data_type: 'array/icon',
      mixed: false,
      mixed_from: ['Overdue', 'Advancement'],
      from_values: [
        {
          icon: 'fas fa-clock',
          color: 'yellow',
          value: 'Advancement',
          label: t('exportable.Avancement'),
          weigth: 4
        },
        {
          icon: 'fas fa-clock',
          color: 'yellow',
          value: 'Overdue',
          label: t('exportable.Overdue'),
          weigth: 3
        },
        {
          icon: 'fas fa-clock',
          color: 'yellow',
          value: 'Doing',
          label: t('exportable.Doing'),
          weigth: 2
        },
        {
          icon: 'far fa-check-circle',
          color: 'green',
          value: 'Done',
          label: t('exportable.Done'),
          weigth: 1
        },
        {
          icon: 'fas fa-minus-circle',
          color: 'grey',
          value: 'Waiting',
          label: t('exportable.Waiting'),
          weigth: 0
        }
      ],
      groupable: false,
      groupable_switch: ['1 → N', 'N → 1'],
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true
    },
    {
      name: 'start_date',
      label: 'Inicio',
      align: 'center',
      resize: true,
      width: '75',
      editor: dateStartEditor,
      template: (activity) => {
        const hasChild = gantt.hasChild(activity.id);
        const momentDate = moment(activity.start_date);
        const momentDateStr = momentDate
          .format(gantt.currentDateFormat)
          .toString();
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.start_date;
        }
        const htmlH = getStringHidden(momentDateStr, 'start_date');

        return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
            color: #121212;">
                <span class="string-v">${momentDate.format(gantt?.currentDateFormat?.replace('hh', 'HH'))}</span>
                ${htmlH}
            </div>
            `;
      },
      hide: false,
      data_type: 'date',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true
    },
    {
      name: 'duration',
      label: 'Duración',
      align: 'center',
      width: '80',
      resize: true,
      editor: gantt ? durationEditor(gantt) : null,
      template: (activity) => {
        const hasChild = gantt.hasChild(activity.id);
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.duration;
        }
        const duration_string = ((activity) =>
          '<div class="' +
            (isOdd ? 'odd-col' : 'non-odd-col') +
            '" style="' +
            (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
            '"> ' +
            activity.for_disable_milestone_duration !==
          0
            ? gantt.formatter.format(activity.for_disable_milestone_duration)
            : '0 days' + ' </div>')(activity);
        return t('lang') === 'en'
          ? '<div class="' +
              (isOdd ? 'odd-col' : 'non-odd-col') +
              '" style="' +
              (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
              '">' +
              duration_string +
              '</div>'
          : '<div class="' +
              (isOdd ? 'odd-col' : 'non-odd-col') +
              '" style="' +
              (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
              '">' +
              duration_string.replace('.', ',') +
              '</div>';
      },
      hide: false,
      data_type: 'number',
      groupable: false,
      orderable: false,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true
    },
    {
      name: 'end_date',
      label: 'Fin',
      align: 'center',
      resize: true,
      width: '75',
      editor: dateEndEditor,
      hide: false,
      data_type: 'date',
      template: (activity) => {
        const hasChild = gantt.hasChild(activity.id);
        const momentDate = moment(activity.end_date);
        const momentDateStr = momentDate
          .format(gantt.currentDateFormat)
          .toString();
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.end_date;
        }
        const htmlH = getStringHidden(momentDateStr, 'end_date');

        return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
            color: #121212;">
                <span class="string-v">${momentDate.format(gantt?.currentDateFormat?.replace('hh', 'HH'))}</span>
                ${htmlH}
            </div>
            `;
      },
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true
    },
    {
      name: 'progress',
      label: 'Avance',
      align: 'center',
      resize: true,
      width: '70',
      editor: progressEditor,
      template: (activity) => {
        const hasChild = gantt.hasChild(activity.id);
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.progress;
        }
        let color;

        switch (activity.status) {
          case 'Advancement':
            color = '#155D77';
            break;
          case 'Overdue':
            color = '#E50101';
            break;
          case 'Waiting':
            color = '#121212';
            break;
          case 'Doing':
            color = '#F59D04';
            break;
          case 'Done':
            color = '#34AF00';
            break;
        }

        return t('lang') === 'en'
          ? `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
            color: ${color};">
                ${parseFloat(activity.progress).toFixed(2) + '%'}
            </div>
            `
          : `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
            color: ${color};">
                ${parseFloat(activity.progress).toFixed(2).replace('.', ',') + '%'}
            </div>
            `;
      },
      hide: false,
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true
    },
    {
      name: 'expected_progress',
      label: 'Esperado Seguimiento',
      align: 'center',
      resize: true,
      width: '*',
      template: (activity) => {
        const hasChild = gantt.hasChild(activity.id);
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.expected_progress;
        }
        const getOnClick = (id) => {
          return activity.type === 'project'
            ? `onclick="window.to_use_react_gantt.handleColumnClick(${id}, 'expected_progress')"`
            : '';
        };

        const getExpectedProgress = (progress, lang) => {
          if (progress.toFixed) {
            return lang === 'en'
              ? `${progress.toFixed(2)}%`
              : `${progress.toFixed(2).replace('.', ',')}%`;
          }
          return '-%';
        };

        const lang = t('lang');
        const progress =
          activity.expected_progress !== undefined
            ? activity.expected_progress
            : '-';

        return `
        <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" 
            style="font-weight: ${hasChild ? 600 : 100};" 
            ${getOnClick(activity.id)}>
          ${getExpectedProgress(progress, lang)}
        </div>
      `;
      },
      hide: true,
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: false
    },
    {
      name: 'freeSlack',
      align: 'center',
      resize: true,
      width: '*',
      label: 'Holgura Libre',
      template: function (activity) {
        const state = gantt.getState().drag_mode;
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.freeSlack;
        }

        if (gantt.isSummaryTask(activity)) {
          return (
            '<div class="' + (isOdd ? 'odd-col' : 'non-odd-col') + '"> </div>'
          );
        }
        const hasChild = gantt.hasChild(activity.id);

        return navigatorLang.includes('en')
          ? '<div class="' +
              (isOdd ? 'odd-col' : 'non-odd-col') +
              '" style="' +
              (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
              '">' +
              (earlyAccessCriticalPath()
                ? activity.freeSlack
                : formatMoney(
                    parseFloat(gantt.formatter.format(activity.freeSlack)),
                    '',
                    2,
                    '.',
                    ','
                  )) +
              '</div>'
          : '<div class="' +
              (isOdd ? 'odd-col' : 'non-odd-col') +
              '" style="' +
              (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
              '">' +
              (earlyAccessCriticalPath()
                ? activity.freeSlack
                : formatMoney(
                    parseFloat(gantt.formatter.format(activity.freeSlack)),
                    '',
                    2,
                    ',',
                    '.'
                  )) +
              '</div>';
      },
      hide: true,
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true
    },
    {
      name: 'totalSlack',
      align: 'center',
      resize: true,
      width: '*',
      template: function (activity) {
        const state = gantt.getState().drag_mode;
        let isOdd = false;
        const hasChild = gantt.hasChild(activity.id);
        const { enFormatSlack, otherFormatSlack } = slackDaysFormatter(
          activity,
          gantt
        );

        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.totalSlack;
        }

        if (gantt.isSummaryTask(activity)) {
          return (
            '<div class="' + (isOdd ? 'odd-col' : 'non-odd-col') + '"> </div>'
          );
        }

        if (
          state === 'resize' ||
          state === 'move' ||
          gantt.isEditingInline ||
          gantt.isEditingDrag
        ) {
          return '<div class="' +
            (isOdd ? 'odd-col' : 'non-odd-col') +
            '" style="' +
            (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
            '">' +
            navigatorLang.includes('en')
            ? enFormatSlack
            : otherFormatSlack + '</div>';
        }

        return '<div class="' +
          (isOdd ? 'odd-col' : 'non-odd-col') +
          '" style="' +
          (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
          '">' +
          navigatorLang.includes('en')
          ? enFormatSlack
          : otherFormatSlack + '</div>';
      },
      hide: true,
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true
    },
    {
      name: 'custom_predecessors',
      label: 'Predecesoras',
      width: '80',
      align: 'center',
      editor: { type: 'text', map_to: 'custom_predecessor' },
      resize: true,
      template: function (activity) {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.custom_predecessors;
        }
        try {
          const hasChild = gantt.hasChild(activity.id);
          return (
            '<div class="' +
            (isOdd ? 'odd-col' : 'non-odd-col') +
            '" style="' +
            (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
            '">' +
            from_links_to_string_predecessor(gantt)(activity) +
            '</div>'
          );
        } catch (e) {}
      },
      hide: false,
      data_type: 'string'
    },
    {
      name: 'custom_sucessors',
      label: 'Sucesoras',
      width: '80',
      align: 'center',
      editor: { type: 'text', map_to: 'custom_sucessors' },
      resize: true,
      template: function (activity) {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.custom_sucessors;
        }
        try {
          const hasChild = gantt.hasChild(activity.id);
          return (
            '<div class="' +
            (isOdd ? 'odd-col' : 'non-odd-col') +
            '" style="' +
            (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
            '">' +
            from_links_to_string_sucessor(gantt)(activity) +
            '</div>'
          );
        } catch (e) {
          console.log(e, 'e');
        }
      },
      hide: false,
      data_type: 'string'
    },
    {
      name: 'constraint_type',
      label: 'Tipo de Restricción',
      align: 'center',
      width: 122,
      template: (activity) => {
        let constraint_type_label = 'As Soon As Possible';
        let hasChild = false;
        let isOdd = false;
        if (gantt.oddColsConfig) isOdd = gantt.oddColsConfig.constraint_type;

        if (activity) {
          hasChild = gantt.hasChild(activity.id);
          constraint_type_label = gantt.locale.labels[activity.constraint_type]
            ? gantt.locale.labels[activity.constraint_type]
            : 'As Soon As Possible';
        }

        const htmlH = getStringHidden(constraint_type_label, 'constraint_type');

        let retStr = `
            <div 
                title="${constraint_type_label}" 
                class="constraint-type-container-style cut-text-and-dot ${isOdd ? 'odd-col' : 'non-odd-col'}" 
                style="font-weight: ${hasChild ? '600' : '100'}"> 
            `;

        retStr = `${retStr}
                <span class="string-v">${constraint_type_label}</span>
                ${htmlH}
            </div>`;

        return retStr;
      },
      resize: true,
      editor: gantt ? constraintTypeEditor(gantt) : null,
      hide: true,
      data_type: 'array/string',
      from_values: [
        {
          color: '#E50101',
          value: 'fnlt',
          label: 'Finish No Later Than',
          description: 'LEAN debit status means...',
          weigth: 7
        },
        {
          color: '#E50101',
          value: 'fnet',
          label: 'Finish No Earlier Than',
          description: 'LEAN debit status means...',
          weigth: 6
        },
        {
          color: '#E50101',
          value: 'asap',
          label: 'As soon As Possible',
          description: 'LEAN debit status means...',
          weigth: 5
        },
        {
          color: '#586666',
          value: 'alap',
          label: 'As late As Possible',
          description: 'LEAN debit status means...',
          weigth: 4
        },
        {
          color: '#34AF00',
          value: 'snet',
          label: 'Start No Earlier Than',
          description: 'LEAN can status means...',
          weigth: 3
        },
        {
          color: '#2C3421',
          value: 'snlt',
          label: 'Start No Later Than',
          description: 'LEAN debit status means...',
          weigth: 2
        },
        {
          color: '#F59D04',
          value: 'mso',
          label: 'Must Start On',
          description: 'LEAN debit status means...',
          weigth: 1
        },
        {
          color: '#F59D04',
          value: 'mfo',
          label: 'Must Finish On',
          description: 'LEAN debit status means...',
          weigth: 0
        }
      ],
      groupable: false,
      groupable_switch: ['1 → N', 'N → 1'],
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true,
      onrender: (task, node) => {
        const gantt = window.to_use_react_gantt;
        const from_values = [
          {
            color: '#586666',
            value: 'asap',
            label: 'As soon As Possible',
            description: 'LEAN debit status means...',
            weigth: 5
          },
          {
            color: '#586666',
            value: 'alap',
            label: 'As late As Possible',
            description: 'LEAN debit status means...',
            weigth: 4
          },
          {
            color: '#586666',
            value: 'snet',
            label: 'Start No Earlier Than',
            description: 'LEAN can status means...',
            weigth: 3
          },
          {
            color: '#586666',
            value: 'snlt',
            label: 'Start No Later Than',
            description: 'LEAN debit status means...',
            weigth: 2
          },
          {
            color: '#586666',
            value: 'fnlt',
            label: 'Finish No Later Than',
            description: 'LEAN debit status means...',
            weigth: 7
          },
          {
            color: '#586666',
            value: 'fnet',
            label: 'Finish No Earlier Than',
            description: 'LEAN debit status means...',
            weigth: 6
          },
          {
            color: '#586666',
            value: 'mso',
            label: 'Must Start On',
            description: 'LEAN debit status means...',
            weigth: 1
          },
          {
            color: '#586666',
            value: 'mfo',
            label: 'Must Finish On',
            description: 'LEAN debit status means...',
            weigth: 0
          }
        ];

        if (!gantt) return;
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.constraint_type;
        }
        const column = 'constraint_type';
        /** To avoid complex code, just use uncontrolled dom elements through a ref */
        let popRef = null;
        let statusObject = from_values?.find((e) => e.value === task[column]);
        if (!statusObject) {
          statusObject = from_values[0];
        }
        const colorObject = null;
        const disabled = task?.type === 'project' || task.progress === 100;
        const boldField = task?.type === 'project';

        const element_new = (
          <div className="opacity-hover-style">
            <span className="add-responsable-new">
              <img width="10" src={addResponsable} />
            </span>
          </div>
        );

        const element_actual = (
          <div className="subcontract-border cut-text-and-dot">
            <span
              style={{
                cursor:
                  disabled || fieldNoPermission ? 'not-allowed' : 'pointer',
                fontWeight: boldField ? 'bold' : 'normal'
              }}
              className="text-subcontract">
              <div style={{ display: 'none' }} className="pre-text-subcontract">
                {' '}
                &nbsp;
              </div>
              <span style={{ position: 'relative' }}>
                {statusObject?.label}
              </span>
            </span>
          </div>
        );

        const constraint_type = (
          <div>{statusObject ? element_actual : element_new}</div>
        );

        const changeOption = (option) => {
          if (popRef) {
            task.undo_constraint_type = task.constraint_type;
            task.undo_constraint_date = task.constraint_date;
            task.undo_start_date = task.start_date;
            task.undo_end_date = task.end_date;
            gantt.updateTask(task.id);
            task[column] = option.value;
            task.real_constraint_type = option.value;
            task.last_constraint = option.value;

            if (['asap', 'alap'].includes(option.value)) {
              task.constraint_date = null;
            }

            if (['snet', 'snlt', 'mso'].includes(option.value)) {
              task.constraint_date = task.start_date;
            }
            if (['fnet', 'fnlt', 'mfo'].includes(option.value)) {
              task.constraint_date = task.end_date;
            }

            gantt.updateTask(task.id);
            resetTotalSlackIfNeeded(option);
            gantt.autoSchedule();
            delete task.undo_constraint_type;
            popRef.tooltip.setState({ visible: false });

            gantt.resetLinksStateWithTimeout &&
              gantt.resetLinksStateWithTimeout();
          }
          trackingEvent(
            'activity_constraint_type_assignment',
            {
              ...getBasicAmplitudEventProperties(),
              activity_name: task?.text,
              activity_id: task?.id,
              activity_index: task?.correlative_id
            },
            AMPLITUDE_SERVICE
          );
        };

        const resetTotalSlackIfNeeded = (option) => {
          gantt.resetTotalSlackCache();
        };

        return (
          <div className={`react-dhtmlx ${isOdd ? 'odd-col' : 'non-odd-col'}`}>
            {!disabled ? (
              <Popover
                placement="bottom"
                ref={(r) => {
                  popRef = r;
                }}
                overlayClassName="popover-subcontract custom-gantt-subcontract"
                className="popover-subcontract react-dhtmlx custom-gantt-subcontract"
                content={
                  <div
                    className="subcontracts-options"
                    style={{
                      maxHeight: '240px'
                    }}>
                    {from_values.map((option) => (
                      <span
                        className="item-gantt"
                        key={option.value}
                        onClick={() => changeOption(option)}>
                        <div className="subcontract-border cut-text-and-dot  subcontract-border__hover">
                          <div className="text-subcontract cut-text-and-dot text-subcontract-item">
                            {option.label}
                          </div>
                        </div>
                      </span>
                    ))}
                  </div>
                }
                trigger={triggerSetter}>
                {constraint_type}
              </Popover>
            ) : (
              constraint_type
            )}
          </div>
        );
      }
    },
    {
      name: 'calendar_id',
      label: 'Calendario',
      align: 'center',
      width: '*',
      template: (activity) => {
        if (gantt.calendarOptions) {
          if (activity.calendar_id) {
            const hasChild = gantt.hasChild(activity.id);
            let isOdd = false;
            if (gantt.oddColsConfig) {
              isOdd = gantt.oddColsConfig.calendar_id;
            }

            const dCal = gantt.calendarOptions.find(
              (cal) => cal.key == activity.calendar_id
            );
            const htmlH = getStringHidden(
              dCal ? dCal.label : 'None',
              'calendar_id'
            );
            let retStr =
              '<div class="' +
              (isOdd ? 'odd-col' : 'non-odd-col') +
              '" style="' +
              (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
              '">';
            retStr = `${retStr}
                        <span class="string-v">${dCal ? dCal.label : 'None'}</span>
                        ${htmlH}
                    </div>
                    `;
            return retStr;
          }
        }
      },
      resize: true,
      editor: {
        type: 'select',
        map_to: 'calendar_id',
        options: []
      },
      hide: true,
      data_type: 'array/string',
      from_values: [],
      groupable: false,
      groupable_switch: ['1 → N', 'N → 1'],
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true
    },
    {
      name: 'constraint_date',
      label: 'Fecha de Restricción',
      align: 'center',
      width: '*',
      template: (activity) => {
        const constraintTypes = gantt.config.constraint_types;
        let isOdd = false;

        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.constraint_date;
        }

        if (
          activity.constraint_date &&
          activity.constraint_type != constraintTypes.ASAP &&
          activity.constraint_type != constraintTypes.ALAP
        ) {
          const hasChild = gantt.hasChild(activity.id);
          const momentDate = moment(activity.constraint_date);
          const disabled = activity.progress === 100;

          return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}"
                    style="
                        ${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
                        ${disabled ? 'cursor: not-allowed' : ''}; 
                        color: #121212;
                    ">
                    ${momentDate.format(gantt.currentDateFormat?.replace('hh', 'HH'))}
                </div>
                `;
        }
        return (
          '<div class="' + (isOdd ? 'odd-col' : 'non-odd-col') + '"> </div>'
        );
      },
      resize: true,
      editor: constraintDateEditor,
      hide: true,
      data_type: 'date',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true
    },
    {
      name: 'real_work',
      label: 'Trabajo Real',
      align: 'center',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.real_work;
        }

        const hasChild = gantt.hasChild(activity.id);
        const lang = t('lang');
        const value = hasChild
          ? activity.real_work
          : (activity.hhWorkTime * activity.progress) / 100;
        const formattedValue =
          lang === 'en'
            ? formatMoney(value, '', 2, '.', ',')
            : formatMoney(value, '', 2, ',', '.');
        return `
          <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" 
               style="font-weight: ${hasChild ? 600 : 100}; color: #121212;" 
               ${activity.type === 'project' ? `onclick="window.to_use_react_gantt.handleColumnClick(${activity.id}, 'earned_work')"` : ''}>
            ${formattedValue}
          </div>
        `;
      },
      data_type: 'number',
      hide: true,
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true,
      resize: true
    },
    {
      name: 'hhWorkTime',
      label: 'HH',
      align: 'center',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.hhWorkTime;
        }
        const hasChild = gantt.hasChild(activity.id);
        return t('lang') === 'en'
          ? `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
            color: #121212;">
                ${formatMoney(activity.hhWorkTime, '', 2, '.', ',')}
            </div>
            `
          : `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
            color: #121212;">
                ${formatMoney(activity.hhWorkTime, '', 2, ',', '.')}
            </div>
            `;
      },
      data_type: 'number',
      editor: hhEditor,
      hide: true,
      width: '*',
      resize: true,
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true
    },
    {
      name: 'cost',
      label: 'Costo',
      align: 'center',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.cost;
        }
        const hasChild = gantt.hasChild(activity.id);
        return t('lang') === 'en'
          ? `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
            color: #121212;">
                ${formatMoney(activity.cost, '$', 2, '.', ',')}
            </div>
            `
          : `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
            color: #121212;">
                ${formatMoney(activity.cost)}
            </div>
            `;
      },
      data_type: 'number',
      editor: costEditor,
      hide: true,
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true,
      resize: true
    },
    {
      name: 'used_cost',
      label: 'Costo Gastado',
      align: 'center',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.used_cost;
        }
        const hasChild = gantt.hasChild(activity.id);
        const lang = t('lang');
        const formattedCost =
          lang === 'en'
            ? formatMoney(activity.used_cost, '$', 2, '.', ',')
            : formatMoney(activity.used_cost);

        return `
      <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" 
           style="font-weight: ${hasChild ? 600 : 100}; color: #121212;">
        ${formattedCost}
      </div>
    `;
      },
      data_type: 'number',
      editor: usedCostEditor,
      hide: true,
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true,
      resize: true
    },
    {
      name: 'real_cost',
      label: 'Costo Ganado',
      align: 'center',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.real_cost;
        }
        const hasChild = gantt.hasChild(activity.id);
        const lang = t('lang');
        const formattedCost =
          lang === 'en'
            ? formatMoney(activity.real_cost, '$', 2, '.', ',')
            : formatMoney(activity.real_cost);

        return `
      <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" 
           style="font-weight: ${hasChild ? 600 : 100}; color: #121212;" 
           ${activity.type === 'project' ? `onclick="window.to_use_react_gantt.handleColumnClick(${activity.id}, 'earned_cost')"` : ''}>
        ${formattedCost}
      </div>
    `;
      },
      data_type: 'number',
      hide: true,
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true,
      resize: true
    },
    {
      name: 'ponderator',
      label: 'Ponderator',
      align: 'center',
      resize: true,
      width: '*',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.ponderator;
        }
        if (activity.correlative_id) {
          if (activity.ponderator) {
            const hasChild = gantt.hasChild(activity.id);
            return t('lang') === 'en'
              ? `
                    <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
                    color: #121212;">
                        ${parseFloat(activity.ponderator).toFixed(2)}
                    </div>
                    `
              : `
                    <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
                    color: #121212;">
                        ${parseFloat(activity.ponderator).toFixed(2).replace('.', ',')}
                    </div>
                    `;
          }
          return `
                    <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                    </div>
                    `;
        }
        return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
                `;
      },
      hide: true,
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true
    },
    {
      name: 'is_critical',
      label: 'Ruta Crítica',
      align: 'center',
      resize: true,
      width: '*',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.is_critical;
        }
        const state = gantt.getState().drag_mode;

        if (state == 'resize' || state == 'move' || gantt.isEditingDrag) {
          return '';
        }

        const hasChild = gantt.hasChild(activity.id);
        // activity.is_critical = gantt.isCriticalTask(activity) ? 'Si' : 'No'
        const render =
          activity.is_critical === 'Si' && t('lang') !== 'es'
            ? 'Yes'
            : activity.is_critical;
        return `
            <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
            color: #121212;">
                ${render}
            </div>
            `;
      },
      data_type: 'array/string',
      hide: true,
      from_values: [
        {
          color: 'lightgrey',
          value: 'No',
          label: 'No',
          weigth: 3
        },
        {
          color: 'darkturquoise',
          value: 'Si',
          label: t('lang') !== 'es' ? 'Yes' : 'Si',
          weigth: 2
        }
      ],
      groupable: false,
      orderable: false,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true
    },
    {
      name: 'start_base',
      label: 'Inicio Base',
      align: 'center',
      resize: true,
      width: '70',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.start_base;
        }
        if (activity.baseline_points && activity.baseline_points.length) {
          const activeBaseline = activity.baseline_points.find((base) => {
            if (base.sectorbaselineversion) {
              if (base.sectorbaselineversion.active) {
                return true;
              }
            }
          });
          const last_baseline = activeBaseline;
          if (last_baseline) {
            activity.start_base = last_baseline.start_date;

            const hasChild = gantt.hasChild(activity.id);
            const momentDate = moment(last_baseline.start_date);
            const momentDateStr = momentDate
              .format(gantt.currentDateFormat)
              .toString();
            const htmlH = getStringHidden(momentDateStr, 'start_base');
            return `
                        <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
                        color: #121212;">
                            <span class="string-v">${momentDate.format(gantt.currentDateFormat)}</span>
                            ${htmlH}
                        </div>
                        `;
          }
        } else {
          return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
                `;
        }
      },
      hide: true,
      data_type: 'date',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true
    },
    {
      name: 'end_base',
      label: 'Fin Base',
      align: 'center',
      resize: true,
      width: '100',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.end_base;
        }
        if (activity.baseline_points && activity.baseline_points.length) {
          const activeBaseline = activity.baseline_points.find((base) => {
            if (base.sectorbaselineversion) {
              if (base.sectorbaselineversion.active) {
                return true;
              }
            }
          });
          const last_baseline = activeBaseline;
          if (last_baseline) {
            activity.end_base = last_baseline.end_date;
            const hasChild = gantt.hasChild(activity.id);
            const momentDate = moment(last_baseline.end_date);
            const momentDateStr = momentDate
              .format(gantt.currentDateFormat)
              .toString();
            const htmlH = getStringHidden(momentDateStr, 'end_date');
            return `
                        <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
                        color: #121212;">
                            <span class="string-v">${momentDate.format(gantt?.currentDateFormat?.replace('hh', 'HH'))}</span>
                            ${htmlH}
                        </div>
                        `;
          }
        } else {
          return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
                `;
        }
      },
      hide: true,
      data_type: 'date',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true
    },
    {
      name: 'expected_progress_base',
      label: 'Esperado Base',
      align: 'center',
      resize: true,
      width: '*',
      template: (activity) => {
        const hasChild = gantt.hasChild(activity.id);
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.expected_progress_base;
        }
        if (
          activity.expected_progress_base ||
          activity.expected_progress_base == 0
        ) {
          if (activity.expected_progress_base.toFixed) {
            return t('lang') === 'en'
              ? '<div class="' +
                  (isOdd ? 'odd-col' : 'non-odd-col') +
                  '" style="' +
                  (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
                  '">' +
                  activity.expected_progress_base.toFixed(2) +
                  '%</div>'
              : '<div class="' +
                  (isOdd ? 'odd-col' : 'non-odd-col') +
                  '" style="' +
                  (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
                  '">' +
                  activity.expected_progress_base.toFixed(2).replace('.', ',') +
                  '%</div>';
          }
        } else {
          return (
            '<div class="' +
            (isOdd ? 'odd-col' : 'non-odd-col') +
            '" style="' +
            (hasChild ? 'font-weight: 600' : 'font-weight: 100') +
            '">-%</div>'
          );
        }
      },
      hide: true,
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true
    },
    {
      name: 'work_base',
      label: 'Trabajo Base',
      align: 'center',
      resize: true,
      width: '*',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.work_base;
        }
        if (activity.baseline_points && activity.baseline_points.length) {
          const activeBaseline = activity.baseline_points.find((base) => {
            if (base.sectorbaselineversion) {
              if (base.sectorbaselineversion.active) {
                return true;
              }
            }
          });
          const last_baseline = activeBaseline;
          if (last_baseline) {
            activity.work_base = last_baseline.hh_work;
            const hasChild = gantt.hasChild(activity.id);
            return t('lang') === 'en'
              ? `
                        <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
                        color: #121212;">
                            ${formatMoney(last_baseline.hh_work, '', 2, '.', ',')}
                        </div>
                        `
              : `
                        <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
                        color: #121212;">
                            ${formatMoney(last_baseline.hh_work, '', 2, ',', '')}
                        </div>
                        `;
          }
        } else {
          return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
                `;
        }
      },
      hide: true,
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true
    },
    {
      name: 'cost_base',
      label: 'Costo Base',
      align: 'center',
      resize: true,
      width: '*',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.cost_base;
        }
        if (activity.baseline_points && activity.baseline_points.length) {
          const activeBaseline = activity.baseline_points.find((base) => {
            if (base.sectorbaselineversion) {
              if (base.sectorbaselineversion.active) {
                return true;
              }
            }
          });
          const last_baseline = activeBaseline;
          if (last_baseline) {
            activity.cost_base = last_baseline.cost;
            const hasChild = gantt.hasChild(activity.id);
            return t('lang') === 'en'
              ? `
                        <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
                        color: #121212;">
                            ${formatMoney(last_baseline.cost, '$', 2, '.', ',')}
                        </div>
                        `
              : `
                        <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; 
                        color: #121212;">
                            ${formatMoney(last_baseline.cost)}
                        </div>
                        `;
          }
        } else {
          return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
                `;
        }
      },
      hide: true,
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true
    },
    {
      name: 'duration_base',
      label: 'Duración Base',
      align: 'center',
      resize: true,
      width: '*',
      template: (activity) => {
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.duration_base;
        }
        if (activity.baseline_points && activity.baseline_points.length) {
          const activeBaseline = activity.baseline_points.find((base) => {
            if (base.sectorbaselineversion) {
              if (base.sectorbaselineversion.active) {
                return true;
              }
            }
          });
          const last_baseline = activeBaseline;
          if (last_baseline) {
            activity.duration_base = last_baseline.duration;
            const duration = last_baseline.duration;
            const dayText = duration === 1 ? ' day' : ' days';
            const hasChild = gantt.hasChild(activity.id);

            const formattedDuration =
              t('lang') === 'en'
                ? duration
                : duration.toString().replace('.', ',');

            return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; color: #121212;">
                  ${formattedDuration + dayText}
                </div>
              `;
          }
        } else {
          return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                </div>
                `;
        }
      },
      hide: true,
      data_type: 'number',
      groupable: false,
      orderable: true,
      orderable_switch: ['1 → 9', '9 → 1'],
      filterable: true
    },
    /** Masterplan update new columns (responsable, subcontracts and tags) */
    {
      name: 'responsables',
      label: 'Responsable',
      data_type: 'array/images',
      visible: true,
      span: 2,
      offset: 0,
      align: 'center',
      mode: 'multiple',
      from_values: 'toSelectResponsables',
      el_to_extract_from: 'email',
      el_to_label_from: ['name', 'lastname'],
      hide: true,
      img_from: 'image',
      width: 125,
      groupable: false,
      groupable_switch: ['A → Z', 'Z → A'],
      orderable: false,
      orderable_switch: ['A → Z', 'Z → A'],
      filterable: true,
      resize: true,
      template: (task) => {
        const gantt = window.to_use_react_gantt;
        if (!gantt) return;
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.responsables;
        }

        let insideHtml = '';
        task.responsables.map((responsable, index) => {
          insideHtml += `<span className='name-responsable-pdf'>
                                ${responsable.name ? capitalize(responsable.name) : ''} ${responsable.lastname ? capitalize(responsable.lastname) : ''}${task.responsables.length > 1 && parseInt(task.responsables.length) - 1 !== index ? ', ' : ''}
                                </span>`;
        });

        const findColumn = window.to_use_react_gantt.config.columns.find(
          (el) => el.name === 'responsables'
        );
        const w1 = parseInt(findColumn.width);

        return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                    <span class="string-h" style="width: ${w1}px;">
                        ${insideHtml}
                    </span>
                </div>
            `;
      },
      onrender: (task, node) => {
        const gantt = window.to_use_react_gantt;
        if (!gantt) return;
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.responsables;
        }
        const column = 'responsables';
        const el_to_extract_from = 'email';
        const el_to_label_from = ['name', 'lastname'];
        const img_from = 'image';
        const from_values = gantt.toSelectResponsables;
        if (!from_values) return;

        const width = gantt.getGridColumn('responsables')?.width;

        if (!width) return;

        const friends = [];
        const selected = [];
        const dictionary = {};
        let with_image = 0; /** variable for responsable in pdf  */

        task[column].map((res) => {
          selected.push(res[el_to_extract_from]);
          if (res.image) with_image++;
        });

        from_values.map((user) => {
          if (user.is_active) {
            friends.push({
              name: user[el_to_label_from[0]] + ' ' + user[el_to_label_from[1]],
              value: user[el_to_extract_from],
              photo: user[img_from] || fakeAvatar,
              object: user
            });
            dictionary[user[el_to_extract_from]] = user;
          }
        });

        /** set class for responsable on pdf */
        let class_no_image_flag = '';
        if (with_image === 0) {
          class_no_image_flag = 'no-image-in-pdf';
        }

        /** 16x16 image size per total of friends */
        const totalWidthResponsables = task[column]?.length * 16;
        let applyPlusCounter;
        let maxElPerWidth;
        let restElements = 0;
        if (totalWidthResponsables > width) {
          applyPlusCounter = true;
          maxElPerWidth = width / 16;
        }

        const adjustPlus = 2;
        return (
          <div
            className={`custom-gantt-responsable-dropdown custom-multi-select-pop react-dhtmlx ${isOdd ? 'odd-col' : 'non-odd-col'}`}
            style={{
              width: column.width ? column.width : '100%',
              height: '100%',
              marginTop: 0,
              border: 0,
              ...cursorCustomStyle
            }}>
            <Popover
              placement="bottom"
              overlayClassName="container-image-custom custom-gantt-responsable-dropdown"
              className="popoverPriority"
              content={
                <SelectSearch
                  className="select-search select-search--multiple"
                  options={friends}
                  value={selected}
                  renderOption={renderFriend}
                  onChange={(val) => {
                    gantt.ext.undo.saveState(task.id, 'task');
                    task[column] = [];
                    trackingEvent(
                      'activity_responsible_entry',
                      {
                        ...getBasicAmplitudEventProperties(),
                        activity_name: task?.text,
                        activity_id: task?.id,
                        activity_index: task?.correlative_id
                      },
                      AMPLITUDE_SERVICE
                    );
                    val.map((op) => {
                      task[column].push(dictionary[op]);
                    });
                    gantt.updateTask(task.id);
                  }}
                  multiple
                  search
                  placeholder={t('search_responsable_placeholder')}
                />
              }
              trigger={triggerSetter}>
              <div style={{ height: '100%' }}>
                {task[column].length ? (
                  <div>
                    {task[column].map((responsable, index) => {
                      if (
                        applyPlusCounter &&
                        maxElPerWidth &&
                        index > maxElPerWidth - adjustPlus
                      ) {
                        restElements++;
                        return;
                      }

                      return (
                        <span
                          key={index}
                          className="solapate-responsable-gantt">
                          <Tooltip
                            title={`${responsable.name} ${responsable.lastname}`}
                            className="span-img-resp">
                            {responsable[img_from] ? (
                              <img
                                className="img-responsable-lookahead"
                                src={responsable[img_from]}
                              />
                            ) : (
                              <div
                                className={`img-responsable-lookahead no-img ${class_no_image_flag}`}>
                                {responsable.name ? responsable.name[0] : ''}
                                {responsable.lastname
                                  ? responsable.lastname[0]
                                  : ''}
                              </div>
                            )}
                          </Tooltip>
                        </span>
                      );
                    })}
                    {applyPlusCounter ? (
                      <span className="plus-counter-responsable-gantt">
                        +{restElements}
                      </span>
                    ) : null}
                  </div>
                ) : (
                  <div
                    className="opacity-hover-style"
                    style={{ width: '100%', height: '100%' }}>
                    <span
                      className="add-responsable-new"
                      style={cursorCustomStyle}>
                      <img width="10" src={addResponsable} />
                    </span>
                  </div>
                )}
              </div>
            </Popover>
          </div>
        );
      }
    },
    {
      name: 'subcontractId',
      label: 'Subcontrato',
      data_type: 'array/string',
      visible: true,
      hide: true,
      span: 2,
      offset: 0,
      align: 'center',
      mode: 'single',
      width: 180,
      groupable: false,
      from_values: 'subContracts',
      groupable_switch: ['1 → N', 'N → 1'],
      orderable: false,
      orderable_switch: ['1 → N', 'N → 1'],
      filterable: true,
      avoid_translate: true,
      resize: true,
      template: (task) => {
        const gantt = window.to_use_react_gantt;
        const column = 'subcontractId';
        if (!gantt) return;
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.subcontractId;
        }

        const statusObject = gantt.subContracts?.find(
          (e) => e.id === task[column]
        );
        if (!statusObject) return;
        const nameOut = statusObject.name;
        const colorOut = statusObject.color;
        const dotColorsvg = `
                    <span class="svg_company_icon" style="color: ${colorOut};!important;">
                        ${companyIconSVG}
                    </span>
                `;

        const insideHtml = `
                        <div
                            class="subcontract-border cut-text-and-dot"
                            style="height: 100%; padding: 0; position: relative;text-align: left; margin-left: 3px;"
                        >
                            ${dotColorsvg}
                            ${nameOut}
                        </div>
                `;
        return insideHtml;
      },
      onrender: (task, node) => {
        const gantt = window.to_use_react_gantt;
        if (!gantt) return;
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.subcontractId;
        }
        const column = 'subcontractId';
        /** To avoid complex code, just use uncontrolled dom elements through a ref */
        let popRef = null;
        // const subContractObject = gantt.subContracts.find(sub => sub.id == activity.subcontractId)
        const statusObject = gantt.subContracts?.find(
          (e) => e.id === task[column]
        );
        const colorObject = statusObject ? statusObject.color : null;
        return (
          <div
            className={`react-dhtmlx ${isOdd ? 'odd-col' : 'non-odd-col'}`}
            style={{ height: '100%', ...cursorCustomStyle }}>
            <Popover
              placement="bottom"
              ref={(r) => {
                popRef = r;
              }}
              overlayClassName="popover-subcontract custom-gantt-subcontract"
              className="popover-subcontract react-dhtmlx custom-gantt-subcontract"
              content={
                <div
                  className="subcontracts-options"
                  style={{
                    overflow: 'auto'
                  }}>
                  <div
                    style={{
                      alignContent: 'center',
                      textAlign: 'center',
                      position: 'relative',
                      top: 9,
                      height: 36
                    }}
                    className="add-subcontract-inline"
                    onClick={() => {
                      if (popRef) {
                        popRef.tooltip.setState({ visible: false });
                        window.to_use_react_gantt.render();
                      }
                      gantt.toHardAssignSubcontract = task.id;
                      gantt.setVisibleFormSubcontract(true);
                    }}>
                    {t('create_subcontract_label')}
                  </div>
                  {gantt.subContracts.map((option, index) => (
                    <span
                      className="item-gantt"
                      key={index}
                      onClick={() => {
                        if (popRef) {
                          task[column] = option.id;
                          trackingEvent(
                            'activity_company_entry',
                            {
                              ...getBasicAmplitudEventProperties(),
                              activity_name: task?.text,
                              activity_id: task?.id,
                              activity_index: task?.correlative_id
                            },
                            AMPLITUDE_SERVICE
                          );
                          popRef.tooltip.setState({ visible: false });
                          if (
                            window.to_use_react_gantt
                              .visualizationColorActive == 'subcontract'
                          ) {
                            window.to_use_react_gantt.defineColorBySubcontract(
                              task,
                              true
                            );
                          } else {
                            window.to_use_react_gantt.render();
                          }
                        }
                      }}>
                      <div
                        className="subcontract-border cut-text-and-dot"
                        style={{
                          height: '100%',
                          color: `${getCompanyTextColor(option.color)}`,
                          padding: 0,
                          backgroundColor: option.color
                        }}>
                        <div
                          className="text-subcontract cut-text-and-dot"
                          style={{
                            alignContent: 'center',
                            textAlign: 'center',
                            position: 'relative',
                            top: 7
                          }}>
                          {option.name}
                        </div>
                      </div>
                    </span>
                  ))}
                  <div
                    style={{
                      alignContent: 'center',
                      textAlign: 'center',
                      position: 'relative',
                      top: 7,
                      height: 36
                    }}
                    className="add-subcontract-inline"
                    onClick={() => {
                      gantt.ext.undo.saveState(task.id, 'task');
                      task[column] = null;
                      if (popRef) {
                        popRef.tooltip.setState({ visible: false });

                        if (
                          window.to_use_react_gantt.visualizationColorActive ==
                          'subcontract'
                        ) {
                          window.to_use_react_gantt.defineColorBySubcontract(
                            task,
                            true
                          );
                        } else {
                          gantt.updateTask(task.id);
                        }
                      }
                    }}>
                    <span
                      style={{
                        position: 'relative',
                        left: -7
                      }}>
                      <img width="10" src={removeIconNew} />
                    </span>
                    <span
                      style={{
                        color: '#121212'
                      }}>
                      {t('remove_subcontract_label')}
                    </span>
                  </div>
                </div>
              }
              trigger={triggerSetter}>
              <div style={{ height: '100%' }}>
                {statusObject ? (
                  <div
                    className="subcontract-border cut-text-and-dot"
                    style={{
                      height: '100%',
                      color: `${getCompanyTextColor(statusObject.color)}`,
                      padding: 0,
                      backgroundColor: colorObject
                    }}>
                    <Tooltip
                      title={capitalize(statusObject.name)}
                      placement="top">
                      <span className="text-subcontract">
                        <div
                          style={{
                            backgroundColor: colorObject
                          }}
                          className="pre-text-subcontract">
                          &nbsp;
                        </div>
                        {statusObject.name}
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    className="opacity-hover-style"
                    style={{ width: '100%', height: '100%' }}>
                    <span
                      className="add-responsable-new"
                      style={cursorCustomStyle}>
                      <img width="10" src={addResponsable} />
                    </span>
                  </div>
                )}
              </div>
            </Popover>
          </div>
        );
      }
    },
    {
      name: 'tags',
      label: 'Tags',
      data_type: 'array/images',
      visible: true,
      hide: true,
      span: 2,
      offset: 0,
      align: 'center',
      mode: 'multiple',
      from_values: 'toSelectTags',
      el_to_extract_from: 'id',
      el_to_label_from: ['name'],
      img_from: 'image',
      width: 125,
      groupable: false,
      groupable_switch: ['A → Z', 'Z → A'],
      orderable: false,
      orderable_switch: ['A → Z', 'Z → A'],
      filterable: true,
      resize: true,
      template: (task) => {
        const gantt = window.to_use_react_gantt;
        if (!gantt) return;
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.tags;
        }

        let insideHtml = '';
        task.tags.map((tag, index) => {
          const dotColorsvg = `
                    <span class="svg_tag_icon" style="color: ${tag.description};">
                        ${tagIconSVG}
                    </span>
                `;
          insideHtml += `
                        ${dotColorsvg}
                        ${tag.name}
                `;
        });

        return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                    <div key={index} style="height: 100%; padding: 0; position: relative;">
                        <div className='tag-class-span'>
                            ${insideHtml}
                        </div>
                    </div>
                </div>
            `;
      },
      onrender: (task, node) => {
        const gantt = window.to_use_react_gantt;
        if (!gantt) return;
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.tags;
        }
        const column = 'tags';
        const el_to_extract_from = 'id';
        const el_to_label_from = 'name';
        const from_values = gantt.toSelectTags;
        if (!from_values) return;

        const friends = [];
        const selected = [];
        const dictionary = {};

        task[column].map((res) => {
          const value = res[el_to_extract_from] + '-';
          selected.push(value);
        });
        let popRef = null;
        from_values.map((tag) => {
          const value = tag[el_to_extract_from] + '-';
          friends.push({
            name: tag[el_to_label_from],
            value,
            object: tag
          });
          dictionary[value] = tag;
        });

        const customOverlayClassName = gantt.toSelectTags.length
          ? 'container-image-custom custom-proplanner-tag'
          : 'container-image-custom custom-proplanner-tag-init';

        return (
          <div
            className={`custom-multi-select-pop react-dhtmlx ${isOdd ? 'odd-col' : 'non-odd-col'}`}
            style={{
              width: column.width ? column.width : '100%',
              height: '100%',
              marginTop: 0,
              border: 0,
              ...cursorCustomStyle
            }}>
            <Popover
              ref={(r) => {
                popRef = r;
              }}
              placement="bottom"
              overlayClassName={customOverlayClassName}
              className="popoverPriority custom-tags-proplanner"
              content={
                <>
                  <SelectSearch
                    renderGroupHeader={(name) => (
                      <div
                        className="add-subcontract-inline custom-create-tag-to-select-checkbox"
                        onClick={() => {
                          if (popRef) {
                            popRef.tooltip.setState({ visible: false });
                            window.to_use_react_gantt.render();
                          }
                          gantt.toHardAssignTag = task.id;
                          gantt.setVisibleFormTags(true);
                        }}>
                        {t('create_tag_label')}
                      </div>
                    )}
                    className="select-search select-search--multiple"
                    options={[
                      {
                        type: 'group',
                        name: 'Group name',
                        items: friends.length
                          ? friends
                          : [
                              {
                                name: 'NONE_TAG_EXIST',
                                value: 'NONE_TAG_EXIST'
                              }
                            ]
                      }
                    ]}
                    value={selected}
                    renderOption={renderTag}
                    onChange={(val) => {
                      gantt.ext.undo.saveState(task.id, 'task');
                      task[column] = [];
                      trackingEvent(
                        'activity_tag_entry',
                        {
                          ...getBasicAmplitudEventProperties(),
                          activity_name: task?.text,
                          activity_id: task?.id,
                          activity_index: task?.correlative_id
                        },
                        AMPLITUDE_SERVICE
                      );
                      val.map((op) => {
                        task[column].push(dictionary[op]);
                      });

                      if (
                        window.to_use_react_gantt.visualizationColorActive ==
                        'tags'
                      ) {
                        window.to_use_react_gantt.defineColorByTags(task, true);
                      } else {
                        window.to_use_react_gantt.render();
                      }
                    }}
                    multiple
                    search
                    placeholder={t('search_tags_placeholder')}
                  />
                  <div
                    style={{
                      alignContent: 'center',
                      textAlign: 'center',
                      position: 'relative',
                      height: 25
                    }}
                    className="add-subcontract-inline"
                    onClick={() => {
                      task[column] = [];
                      if (popRef) {
                        popRef.tooltip.setState({ visible: false });
                        if (
                          window.to_use_react_gantt.visualizationColorActive ==
                          'tags'
                        ) {
                          window.to_use_react_gantt.defineColorByTags(
                            task,
                            true
                          );
                        } else {
                          window.to_use_react_gantt.render();
                        }
                      }
                    }}>
                    <span
                      style={{
                        position: 'relative',
                        left: -7
                      }}>
                      <img width="10" src={removeIconNew} />
                    </span>
                    <span
                      style={{
                        color: '#121212',
                        fontSize: 12
                      }}>
                      {t('remove_tag_label')}
                    </span>
                  </div>
                </>
              }
              trigger={triggerSetter}>
              <div style={{ height: '100%' }}>
                {task[column].length ? (
                  task[column].map((tag, index) => {
                    const { description, name } = tag;
                    const textColor = getCompanyTextColor(description);
                    return (
                      <div key={index}>
                        <div
                          key={index}
                          className="tag-proplanner"
                          style={{
                            width: 70,
                            height: 17,
                            backgroundColor: description,
                            marginLeft: 16,
                            color: textColor
                          }}></div>
                        <div
                          className="tag-proplanner-content"
                          style={{
                            position: 'relative',
                            top: -11,
                            left: '19%',
                            display: 'inline-flex',
                            float: 'left'
                          }}>
                          <span
                            style={{
                              width: 5,
                              height: 5,
                              borderRadius: '50%',
                              backgroundColor: description,
                              border: `1px solid ${textColor}`,
                              position: 'relative',
                              zIndex: 1
                            }}></span>
                          <span
                            title={name}
                            className="cut-text-and-dot pdf-fix-text"
                            style={{
                              position: 'relative',
                              top: -12,
                              maxWidth: 60,
                              left: 4,
                              zIndex: 1,
                              color: textColor
                            }}>
                            {name}
                          </span>
                        </div>

                        {/* This div is for the pfd content */}
                        <div className="tag-class-span">
                          <TagIconExt
                            color={description}
                            className="tag-class-pdf"
                          />
                          {name}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="opacity-hover-style"
                    style={{ width: '100%', height: '100%' }}>
                    <span
                      className="add-responsable-new"
                      style={cursorCustomStyle}>
                      <img width="100" src={addTagIcon} />
                    </span>
                  </div>
                )}
              </div>
            </Popover>
          </div>
        );
      }
    },
    {
      name: 'description',
      label: 'Descripción',
      editor: descriptionEditor,
      offset: 0,
      align: 'center',
      ignore_as_column: false,
      template: function (activity) {
        let htmlH = '';
        if (activity?.description) {
          htmlH = getStringHidden(activity.description, 'description', 0);
        }
        let isOdd = false;
        if (gantt.oddColsConfig) {
          isOdd = gantt.oddColsConfig.description;
        }

        return `
                <div class="${isOdd ? 'odd-col' : 'non-odd-col'}">
                    <span class="string-v">${activity?.description?.replace(replacePlainTextToLinksPattern, replacePlainTextToLinksTemplate) ?? ''}</span>
                    ${htmlH}
                </div>
                `;
      },
      data_type: 'string',
      width: 200,
      groupable: false,
      hide: true,
      orderable: true,
      orderable_switch: ['A → Z', 'Z → A'],
      filterable: true,
      resize: true,
      editable: true
    }
  ];

  if (earlyAccessCriticalPath()) {
    columns.push(
      ...[
        {
          name: 'early_start',
          label: 'Early Start',
          align: 'center',
          resize: true,
          width: '75',
          template: function (activity) {
            return criticalPathDatesTemplate(
              activity,
              'earlyStart',
              'early_start',
              gantt
            );
          },
          hide: true,
          data_type: 'date',
          groupable: false,
          orderable: true,
          orderable_switch: ['1 → N', 'N → 1'],
          filterable: true
        },
        {
          name: 'early_finish',
          label: 'Early Finish',
          align: 'center',
          resize: true,
          width: '75',
          template: function (activity) {
            return criticalPathDatesTemplate(
              activity,
              'earlyFinish',
              'early_finish',
              gantt
            );
          },
          hide: true,
          data_type: 'date',
          groupable: false,
          orderable: true,
          orderable_switch: ['1 → N', 'N → 1'],
          filterable: true
        },
        {
          name: 'late_start',
          label: 'Late Start',
          align: 'center',
          resize: true,
          width: '75',
          template: function (activity) {
            return criticalPathDatesTemplate(
              activity,
              'lateStart',
              'late_start',
              gantt
            );
          },
          hide: true,
          data_type: 'date',
          groupable: false,
          orderable: true,
          orderable_switch: ['1 → N', 'N → 1'],
          filterable: true
        },
        {
          name: 'late_finish',
          label: 'Late Finish',
          align: 'center',
          resize: true,
          width: '75',
          template: function (activity) {
            return criticalPathDatesTemplate(
              activity,
              'lateFinish',
              'late_finish',
              gantt
            );
          },
          hide: true,
          data_type: 'date',
          groupable: false,
          orderable: true,
          orderable_switch: ['1 → N', 'N → 1'],
          filterable: true
        }
      ]
    );
  }
  return columns;
};
function criticalPathColumns(gantt) {
  if (JSON.parse(window.localStorage.getItem('user')).companyId !== '23')
    return {};

  return;
}

function criticalPathDatesTemplate(activity, property, columnName, gantt) {
  const hasChild = gantt.hasChild(activity.id);
  const date = moment(activity[property]);
  const dateString = date.format(gantt.currentDateFormat).toString();
  if (gantt.isSummaryTask(activity)) {
    return '';
  }
  let isOdd = false;
  if (gantt.oddColsConfig) {
    isOdd = gantt.oddColsConfig[columnName];
  }

  const htmlH = getStringHidden(dateString, columnName);

  return `
    <div class="${isOdd ? 'odd-col' : 'non-odd-col'}" style="${hasChild ? 'font-weight: 600' : 'font-weight: 100'}; color: #121212;">
      <span class="string-v">${date.format(gantt?.currentDateFormat?.replace('hh', 'HH'))}</span>
      ${htmlH}
    </div>`;
}
