import { log } from '../../../../../monitor/monitor';
import filters from '../../helpers/filters';

/**
 * Identifies the initial activities based on the direction and updates the chain start activities.
 * @param {Object} params - The parameters object.
 * @param {Object} params.gantt - The Gantt instance.
 * @param {string} params.linkProperty - The link property ('$target' or '$source').
 * @param {string} params.direction - The direction ('forward' or 'backward').
 * @returns {Object} An object containing the chain start activities and activities linked to linked parents.
 */
export function identifyInitialActivities({ gantt, linkProperty, direction }) {
  try {
    const chainStartActivities = getChainStartActivities(gantt, linkProperty);

    if (direction === 'forward') {
      return {
        chainStartActivities,
        activitiesStartChainButChildrenOfLinkedParents: null
      };
    }

    if (direction === 'backward') {
      return processBackwardActivities(
        chainStartActivities,
        gantt,
        linkProperty
      );
    }
  } catch (e) {
    console.log('Critical Path', e);
    log('Critical Path', 'Error in identifyInitialActivities');
    throw e;
  }
}

/**
 * Retrieves the chain start activities by filtering the tasks.
 * @param {Object} gantt - The Gantt instance.
 * @param {string} linkProperty - The link property ('$target' or '$source').
 * @returns {Array} An array of chain start activities.
 */
function getChainStartActivities(gantt, linkProperty) {
  return gantt
    .getTaskByTime()
    .filter(filters.avoidSubproject)
    .filter(filters.byTaskTypeAndMilestone)
    .filter((activity) => !activity[linkProperty].length);
}

/**
 * Processes backward activities to separate those with and without linked parents.
 * @param {Array} chainStartActivities - The initial chain start activities.
 * @param {Object} gantt - The Gantt instance.
 * @param {string} linkProperty - The link property ('$target' or '$source').
 * @returns {Object} An object containing activities without linked parents and a set of activities linked to linked parents.
 */
function processBackwardActivities(chainStartActivities, gantt, linkProperty) {
  const withoutLinkedParents = [];
  const activitiesStartChainButChildrenOfLinkedParents = new Set();

  chainStartActivities.forEach((activity) => {
    const parentId = Number(activity.parent);
    const parentActivity = gantt.getTask(parentId);

    if (!parentActivity) return;

    if (parentActivity[linkProperty].length > 0) {
      activitiesStartChainButChildrenOfLinkedParents.add(activity.id);
    } else if (parentActivity.constraint_type !== 'fnlt') {
      withoutLinkedParents.push(activity);
    }
  });

  return {
    chainStartActivities: withoutLinkedParents,
    activitiesStartChainButChildrenOfLinkedParents
  };
}
