import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import ResourceLink from './ResourceLink';

const ResourceRow = memo(({ resources, icons }) => (
  <Row style={{ marginTop: 13 }}>
    {resources.map((resource, index) => (
      <React.Fragment key={resource.key}>
        {index > 0 && <Col offset={3} />}
        <ResourceLink
          url={resource.url}
          icon={icons[resource.icon]}
          label={resource.label}
        />
      </React.Fragment>
    ))}
  </Row>
));

ResourceRow.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  icons: PropTypes.object.isRequired
};

export default ResourceRow;
