import React, { useState, useEffect } from 'react';
import CompanyImage from '../../../components/CompanyImage/CompanyImage';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCompaniesByUser, getSessionToken } from './companyOptions.helpers';
import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import useProjects from '../../project/hooks/useProjects';
import './index.scss';

export default function CompanyOptions(props) {
  const { dark, validateMP, closeModal } = props;
  const [companies, setCompanies] = useState([]);
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const history = useHistory();
  const { getProjectsLoad } = useProjects({
    dispatch,
    projectState
  });

  useEffect(() => {
    const getCompany = async () => {
      const response = await getCompaniesByUser();
      setCompanies(response);
    };

    getCompany();
  }, []);

  const handleRedirection = (currentPath, history) => {
    if (currentPath !== '/selectCompany') {
      window.location = '/projects';
      return;
    }
    history.push('/projects');
  };

  const handleProjectsPath = (currentPath, closeModal) => {
    if (currentPath.includes('/projects')) {
      closeModal();
      window.loader.show();
    }
  };

  const handleClick = async (companyId, companyName) => {
    const executeChangeCompany = async () => {
      const currentPath = history?.location?.pathname;
      handleProjectsPath(currentPath, closeModal);

      const eventSources = [
        { path: '/projects', source: 'Home' },
        { path: '/selectCompany', source: 'Company Selection page' }
      ];
      trackingEvent(
        'organization_selection',
        {
          ...getBasicAmplitudEventProperties(),
          event_source:
            eventSources.find((src) => currentPath?.includes(src.path))
              ?.source || 'Header',
          company_selected_id: companyId,
          company_selected_name: companyName
        },
        AMPLITUDE_SERVICE
      );

      await getSessionToken(companyId);
      await getProjectsLoad();
      handleRedirection(currentPath, history);
    };
    if (validateMP) {
      validateMP(executeChangeCompany);
      return;
    }
    await executeChangeCompany();
  };

  const renderCompanies = () => {
    if (!companies) return null;

    const countCompanies = companies.length;
    const isCorrectCompanies = countCompanies > 0;

    if (!isCorrectCompanies) return null;

    const MAX_VISIBLE_COMPANIES = 5;
    const isScroll =
      countCompanies >= MAX_VISIBLE_COMPANIES
        ? 'companyOption__layout-scroll'
        : '';
    const renderItems = () => {
      return companies.map((company) => {
        const { company: companyInfo, companyId } = company;
        const { name, image } = companyInfo;
        const item = (
          <div
            className={`companyOption__item ${dark ? 'companyOption__item--dark' : ''}`}
            key={`CompanyOptions__item-${name}`}
            onClick={() => handleClick(companyId, name)}>
            {CompanyImage(image, name)}
            <h2>{name}</h2>
          </div>
        );
        return item;
      });
    };

    return (
      <div className={`companyOption__layout ${isScroll}`}>{renderItems()}</div>
    );
  };
  return renderCompanies();
}
