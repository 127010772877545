import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { dotQuestionIcon } from '../assets';

const QuestionItem = memo(({ questionUrl, title }) => (
  <div
    className="single-question-style"
    onClick={() => window.open(questionUrl, '_blank')}>
    <span>
      <img src={dotQuestionIcon} width={8} alt="bullet" />
    </span>
    <span style={{ marginLeft: 8, fontSize: 11 }}>{title}</span>
  </div>
));

export default QuestionItem;
QuestionItem.propTypes = {
  questionUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
