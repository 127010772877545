/* eslint-disable quote-props */
import { base } from './base';
import axios from 'axios';

class UserCompanyService {
  constructor() {
    const loggedUser = localStorage.getItem('authToken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization:
        'Basic cHJvcGxhbm5lcjpiWGx0WVhOMFpYSnJaWGx0ZVcxaGMzUmxjbXRsZVcxNWJXRnpkR1Z5YTJWNU1USXpORFUyTnpnNU1UQmhZbU5rWldabmFHbHE=',
      'WWW-Authenticate': 'Bearer ' + loggedUser
    };
    const http = axios.create({ baseURL: base.api, headers: headers });
    this.http = http;
  }

  async getCompaniesByUser(userId) {
    try {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        this.http.defaults.headers['WWW-Authenticate'] = `Bearer ${authToken}`;
      }
      const { data } = await this.http.get(`usercompany/${userId}`);
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  }
}

export const userCompanyService = new UserCompanyService();
