import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { closeCenterIcon } from '../assets';

const CloseButton = memo(({ onClick }) => (
  <span
    style={{ position: 'relative', top: -5, cursor: 'pointer' }}
    onClick={onClick}>
    <img src={closeCenterIcon} width={17} alt="close" />
  </span>
));

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CloseButton;
