/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useUnsavedElementsAlerter from '../../hooks/useUnsavedElementsAlerter';
import CompanyOptions from '../../views/selectCompany/companyOptions';
import { getCompaniesLocal } from '../../views/selectCompany/companyOptions/companyOptions.helpers';
import ModalSystem from '../DesignSystem/ModalSystem';
import { OFFICE_ICON, MODAL_WIDTH } from './constants';
import './SelectCompanyModal.scss';

const SelectCompanyModal = ({ t, visible, setVisible }) => {
  const dispatch = useDispatch();
  const selectCompanyState = useSelector((state) => state.selectCompanyState);

  const { jsx, callback } = useUnsavedElementsAlerter(t);
  const [showModal, setShowModal] = useState(false);
  const [currentCompany, setCurrentCompany] = useState({});

  useEffect(() => {
    if (visible) {
      setShowModal(true);
    }
  }, [visible]);

  const closedModal = () => {
    setVisible(false);
    setShowModal(false);
  };

  useEffect(() => {
    const company = getCompaniesLocal();
    setCurrentCompany(company);
    closedModal();
  }, []);

  const handleClosedModal = useCallback(() => {
    closedModal();
  }, [setVisible, dispatch, selectCompanyState.props]);

  const validateMPMovementsAlert = (wishAction) => {
    const forceReturn = callback && callback(wishAction);
    if (forceReturn) return;
    wishAction();
  };

  const modalContent = () => (
    <div className="SelectCompanyModal__container">
      <div className="SelectCompanyModal__title">
        <h2>{t('selectOrganization.select_modal.title')}</h2>
      </div>
      <div className="SelectCompanyModal__description">
        {`${t('selectOrganization.select_modal.description')} ${currentCompany?.name}`}
      </div>

      <div>
        <CompanyOptions
          dark={true}
          validateMP={validateMPMovementsAlert}
          closeModal={handleClosedModal}
        />
      </div>

      <div className="SelectCompanyModal__footer">
        <button
          className="SelectCompanyModal__footer__cancel"
          onClick={handleClosedModal}>
          {t('selectOrganization.select_modal.cancel_button')}
        </button>
      </div>
      {jsx}
    </div>
  );

  return ModalSystem({
    visible: showModal,
    setVisible: setShowModal,
    children: modalContent(),
    theme: 'dark',
    width: MODAL_WIDTH,
    centered: true,
    showTitle: true,
    title: t('selectOrganization.select_modal.title_modal'),
    showIconTitle: true,
    iconTitle: OFFICE_ICON,
    onCancelFunction: handleClosedModal
  });
};

export default SelectCompanyModal;
