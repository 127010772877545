import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { companyActions } from '../../redux/actions/companyActions';
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Avatar } from 'antd';
import companyIcon from '../../assets/img/project_fake3.jpg';
import { companyService, userService } from '../../services';
import { getSumGroupBy } from '../../utils';
import TooltipSystem from '../DesignSystem/TooltipSystem/TooltipSystem';
import {
  ProjectInfoIcon,
  UserCircleSingleIcon,
  DollarCoinIcon
} from '../../icons';
import { ProjectStatus } from '../../constants/projectStatuses';
import * as Sentry from '@sentry/react';
import SelectCompanyModal from '../SelectCompanyModal/SelectCompanyModal';
import * as CompanySlice from '../../redux/slices/CompanySlice';
import { getCompaniesByUser } from '../../views/selectCompany/companyOptions/companyOptions.helpers';
import { getSignedUser } from '../../utils/userUtils';
import { addBreadcrumbUtil } from '../../monitor/monitor';

function CompanyInfo(props) {
  const { t } = props;

  const dispatch = useDispatch();
  const companyState = useSelector((state) => state.companyState);
  const selectCompanyState = useSelector((state) => state.selectCompanyState);
  const projectState = useSelector((state) => state.projectState);

  const [company, setCompany] = useState(companyState.currentCompany);
  const [statisticsCompany, setStatisticsCompany] = useState({
    totalActiveProjects: 0,
    totalActiveUsers: 0,
    totalBudget: []
  });

  const [companies, setCompanies] = useState([]);
  const user = getSignedUser();

  useEffect(() => {
    const getCompanies = async () => {
      const companies = await getCompaniesByUser();
      setCompanies(companies);
    };
    getCompanies();
  }, []);

  useEffect(() => {
    loadCompany();
  }, [projectState.allProjects.length, user.companyId]);

  const loadCompany = async () => {
    if (user) {
      try {
        addBreadcrumbUtil(
          'loadCompany',
          'Attempting to load company for user',
          'info',
          {
            userId: user.id
          }
        );

        const company = JSON.parse(sessionStorage.getItem('company'));

        if (!company) {
          throw new Error(`Company ${user.companyId} not found`);
        }

        const projects = projectState.allProjects || [];

        const projectsActive = projects.filter(
          (e) => e.stage !== ProjectStatus.PROJECT_STATUS_DELETED
        );
        dispatch(companyActions.setCurrentCompany(company));
        setCompany(company);

        const projectsActiveStatistics = projectsActive.filter(
          (e) =>
            e.stage !== ProjectStatus.PROJECT_STATUS_ARCHIVED &&
            e.stage !== ProjectStatus.PROJECT_STATUS_DELETED
        );

        const budget = getSumGroupBy(projectsActiveStatistics);
        const budgetGroupedbyCurrency =
          budget && budget.length > 0 ? budget : 0;

        const usersActiveByOrganization =
          await userService.usersActiveByOrganization();
        setStatisticsCompany({
          totalActiveProjects: projectsActiveStatistics.length,
          totalActiveUsers: usersActiveByOrganization.count,
          totalActiveBudget: budgetGroupedbyCurrency
        });

        addBreadcrumbUtil(
          'loadCompany',
          'Successfully loaded company',
          'info',
          {
            userId: user.id,
            companyId: user.companyId
          }
        );
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setContext('loadCompany', {
            user,
            companyId: user.companyId,
            errorMessage: err.message
          });
          Sentry.captureException(err);
        });
      } finally {
        if (window.loader) {
          window.loader.hide();
        }
      }
    }
  };

  const childrenTooltip = (icon, info) => (
    <div className="info__detail">
      <div>{icon}</div>
      <span>{info}</span>
    </div>
  );

  const getBudgets = (budgets) =>
    budgets &&
    budgets.map((e, index) => (
      <Fragment key={index}>
        {e.field}{' '}
        <NumberFormat
          value={e.sum}
          displayType={'text'}
          decimalSeparator={t('lang') === 'en' ? '.' : ','}
          thousandSeparator={t('lang') === 'en' ? ',' : '.'}
        />
        &nbsp;&nbsp;
      </Fragment>
    ));

  const changeCompany = () => {
    dispatch(
      CompanySlice.setProps({
        ...selectCompanyState.props,
        openSelectCompanyModal: true
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(
      CompanySlice.setProps({
        ...selectCompanyState.props,
        openSelectCompanyModal: false
      })
    );
  };

  return (
    <div id="toolbar_company" className="toolbar-company">
      <div className="company__logo">
        {company?.image ? (
          <img height="65" src={company?.image} />
        ) : (
          <Avatar shape="circle" src={companyIcon} size={65} />
        )}
      </div>
      <div className="company__container">
        <div className="company__name">
          <h2>{company?.name}</h2>
          {companies && companies.length > 1 && (
            <span onClick={() => changeCompany()}>
              {t('selectOrganization.change_organization')}
            </span>
          )}
        </div>
        <div className="company__info">
          {TooltipSystem({
            title: t('project_header.project'),
            placement: 'bottom',
            children: childrenTooltip(
              <ProjectInfoIcon color="#53C255" />,
              statisticsCompany.totalActiveProjects
            )
          })}
          {TooltipSystem({
            title: t('project_header.user'),
            placement: 'bottom',
            children: childrenTooltip(
              <UserCircleSingleIcon color="#53C255" />,
              statisticsCompany.totalActiveUsers
            )
          })}
          {TooltipSystem({
            title: t('project_header.monto'),
            placement: 'bottom',
            children: childrenTooltip(
              <DollarCoinIcon color="#53C255" />,
              getBudgets(statisticsCompany.totalActiveBudget)
            )
          })}
        </div>
      </div>

      <SelectCompanyModal
        t={t}
        visible={selectCompanyState.props?.openSelectCompanyModal}
        setVisible={handleCloseModal}
      />
    </div>
  );
}

export default withTranslation()(CompanyInfo);
