import {
  init,
  track,
  Identify,
  identify,
  setUserId,
  reset
} from '@amplitude/analytics-browser';
import { AMPLITUDE_MIN_LENGTH_USER_ID } from '../../constants';
import { getTokenDeploymentAmplitude, getTokenAmplitude } from '../../utils';
import {
  getCurrentProject,
  getSessionTokenData
} from '../../../utils/userUtils';
import { Experiment } from '@amplitude/experiment-js-client';

const initialVariants = {};

init(getTokenAmplitude());

const debug = window.location.hostname !== 'app.outbuild.com';

export const experiment = Experiment.initializeWithAmplitudeAnalytics(
  getTokenDeploymentAmplitude(),
  { debug, initialVariants }
);

export const amplitudeUserIdentify = (user) => {
  const event = new Identify();
  const sessionTokenData = getSessionTokenData();

  event.set('user_id', user?.id);
  event.set('name', user?.name);
  event.set('last_name', user?.lastname);
  event.set('email', user?.email);
  event.set('position', sessionTokenData?.position);
  event.set('role', sessionTokenData?.role);
  event.set('company_id', sessionTokenData?.companyId);
  event.set('company', user?.company);
  event.set('project_id', user?.project_id);

  identify(event);

  setUserId(user?.id.toString().padEnd(AMPLITUDE_MIN_LENGTH_USER_ID));
};

export const amplitudeEventTracking = (eventName, eventProperties) => {
  track(eventName, eventProperties);
};

export const resetPropertiesAmplitude = () => {
  reset();
};

export const isFeatureFlagActive = (feature) => {
  const featureFlag = experiment.variant(feature);

  return featureFlag?.key === 'on';
};
