import React from 'react';
import PrintDatePicker from '../../atoms/PrintDatePicker/PrintDatePicker';
import AlignLineCheck from '../../atoms/AlignLineCheck/AlignLineCheck';
import './PrintDateTab.scss';

export default function PrintDateTab({
  t,
  isSchedule,
  dateSelected,
  setDateSelected,
  isAlignLine,
  setIsAlignLine
}) {
  const marginTop = isSchedule ? 20 : 0;

  return (
    <div className="tab--print--date" style={{ marginTop }}>
      <div className="tab--print--date__title">
        <h5>{t('export_pdf_modal.tabs_print_date_subtitle')}</h5>
      </div>
      <div className="tab--print--date__select">
        <label>{t('export_pdf_modal.print_date')}</label>
        <div className="select__picker">
          <PrintDatePicker
            isSchedule={isSchedule}
            dateSelected={dateSelected}
            setDateSelected={setDateSelected}
          />
        </div>
      </div>
      <div className="tab--print--date__todayline">
        <AlignLineCheck
          t={t}
          isSchedule={isSchedule}
          dateSelected={dateSelected}
          isAlignLine={isAlignLine}
          setIsAlignLine={setIsAlignLine}
        />
      </div>
    </div>
  );
}
