import { getNextWorkingHour } from '../../helpers/getNextWorkingHour';
import { addDurationToDate } from '../../helpers/addDurationToDate';
import { LINK_TYPES } from '../../constants/index';

/**
 * Mutates a date based on the link type and lag provided. This function acts as an intermediary, deciding whether
 * to move the date to the future using `getNextWorkingHour` or to mutate it to the future or past using
 * `mutateTheDateToFutureOrPastInBaseRestriction`.
 *
 * @param {Object} params - The parameters for date mutation.
 * @param {string} params.linkType - The type of link (e.g., FS, SS) that determines the behavior of the date mutation.
 * @param {number} params.lag - The lag time that impacts how the date is mutated.
 * @param {Date} params.predecessorTime - The starting time from which the mutation begins.
 * @param {Object} params.activityCalendar - The calendar object used to calculate working hours.
 * @param {Object} params.activity - The activity object that may influence the date mutation logic.
 * @returns {Date} - The mutated date, adjusted according to the link type, lag, and activity calendar.
 */
function mutateDate({
  linkType,
  lag,
  predecessorTime,
  activityCalendar,
  activity
}) {
  if (linkType === LINK_TYPES.FS && lag === 0) {
    return getNextWorkingHour({
      predecessorTime,
      activityCalendar,
      direction: 'future',
      activity: activity
    });
  } else {
    return mutateTheDateToFutureOrPastInBaseRestriction(
      predecessorTime,
      linkType,
      activityCalendar,
      activity
    );
  }
}

function calculateRestriction({
  duration,
  constraintDate,
  activityCalendar,
  activity
}) {
  let ef = new Date(constraintDate);
  let es = addDurationToDate(activityCalendar, ef, duration, activity);

  return { es, ef };
}

function calculateRestrictionSnet({
  duration,
  constraintDate,
  activityCalendar,
  activity
}) {
  let es = new Date(constraintDate);
  let ef = addDurationToDate(activityCalendar, es, duration, activity);

  return { es, ef };
}

function mutateTheDateToFutureOrPastInBaseRestriction(
  dateBaseToCalculate,
  restrictionOfLink,
  activityCalendar,
  activity
) {
  if (
    ![LINK_TYPES.FS, LINK_TYPES.SS, LINK_TYPES.FF].includes(restrictionOfLink)
  ) {
    return dateBaseToCalculate;
  }

  let constraintThatGoToNextWorkingHour = [LINK_TYPES.FS, LINK_TYPES.SS];
  let direction = constraintThatGoToNextWorkingHour.includes(restrictionOfLink)
    ? 'future'
    : 'past';

  return getNextWorkingHour({
    dateBaseToCalculate,
    activityCalendar,
    direction,
    activity
  });
}

export {
  mutateDate,
  calculateRestriction,
  calculateRestrictionSnet,
  mutateTheDateToFutureOrPastInBaseRestriction
};
