import * as Sentry from '@sentry/react';
import { addBreadcrumbUtil } from '../../../../../monitor/monitor';

/**
 * Adds a breadcrumb to Sentry for attribute replacement.
 *
 * @param {string} attribute - The attribute key that was validated.
 * @param {*} originalValue - The original value of the attribute.
 * @param {*} defaultValue - The default value that replaced the original.
 */
export const breadcrumbValidateActivity = ({
  attribute,
  originalValue,
  defaultValue,
  typeError
}) => {
  const BREAD_CRUMB_CATEGORY = 'activity';
  const BREAD_CRUMB_MESSAGE = 'Validation of values in the activity';
  const BREAD_CRUMB_LEVEL = 'warning';

  addBreadcrumbUtil(
    BREAD_CRUMB_CATEGORY,
    BREAD_CRUMB_MESSAGE,
    BREAD_CRUMB_LEVEL,
    {
      attribute,
      originalValue,
      defaultValue,
      typeError
    }
  );
};

/**
 * Captures an exception in Sentry for activities with invalid fields.
 *
 * @param {number} id - The ID of the activity.
 * @param {Object} activity - The original activity object.
 * @param {Object} updatedActivity - The activity object with default values applied.
 * @param {Array} invalidAttributes - The list of invalid attributes in the activity.
 */
export const captureExceptionValidateActivity = ({
  id,
  activity,
  updatedActivity,
  invalidAttributes
}) => {
  Sentry.withScope((scope) => {
    scope.setContext('validateActivityProperties', {
      id,
      activity,
      updatedActivity,
      invalidAttributes: JSON.stringify(invalidAttributes)
    });
    Sentry.captureException(new Error('Activity with wrong fields'));
  });
};
