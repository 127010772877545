import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Header from './components/HeaderSection';
import SearchSection from './components/SearchSection';
import ResourcesSection from './components/Resources';
import './styles.scss';

const HelpCenter = memo(({ height, currentUser, drawer, onClose, t }) => {
  return (
    <Row style={{ height }}>
      <Col className="help-center__container" style={{ height }}>
        <Header currentUser={currentUser} onClose={onClose} t={t} />

        <div className="help-center__search-section">
          <SearchSection drawer={drawer} t={t} />
        </div>

        <div className="help-center__resources-section">
          <ResourcesSection height={height} t={t} />
        </div>
      </Col>
    </Row>
  );
});

HelpCenter.displayName = 'HelpCenter';

HelpCenter.propTypes = {
  height: PropTypes.number.isRequired,
  currentUser: PropTypes.object,
  drawer: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default HelpCenter;
