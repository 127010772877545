import { httpCodes } from '../../constants/httpCodes';
import { trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';

const setSession = (response) => {
  const { data } = response;
  if (!data) return;

  const { user, authToken } = data;
  if (!authToken) return;

  const { id } = user;
  localStorage.setItem('signed', true);
  localStorage.setItem('firsTimeLogin', true);
  localStorage.setItem('user', JSON.stringify({ id }));
  localStorage.setItem('authToken', JSON.stringify(authToken));
};

export const setSessionForFreeTrial = (response) => {
  setSession(response);
};

export const redirectSelectCompany = (response, history) => {
  setSession(response);
  history.push('/selectCompany');
};

export const goToForgot = (e, history) => {
  e.preventDefault();
  trackingEvent('forgot_password_option_selection', null, AMPLITUDE_SERVICE);
  history.push('/forgot');
};

export const goToSignUp = (e, history) => {
  e.preventDefault();
  history.push('/signup');
};

export function redirectLogic(currentUrl) {
  const EXCLUDED_SUBDOMAINS_FOR_REDIRECT = [
    'qa.proplanner.app',
    'dev.proplanner.app',
    'mobile-testing.proplanner.app',
    'preprod.proplanner.app'
  ];
  const MAIN_DOMAINS = ['proplanner.app'];
  const DEFAULT_URL = 'https://app.outbuild.com/';
  let url = currentUrl;
  const urlObj = new URL(currentUrl);
  const { hostname } = urlObj;
  const isInMainDomain = MAIN_DOMAINS.some((domain) =>
    hostname.endsWith(domain)
  );
  const isInExcludedSubdomain = EXCLUDED_SUBDOMAINS_FOR_REDIRECT.some(
    (domain) => hostname.endsWith(domain)
  );
  if (isInMainDomain && !isInExcludedSubdomain) {
    url = DEFAULT_URL;
  }
  return url;
}

export const sendSignInTrackingEvent = (email, status, errorType) => {
  const errorMessages = {
    user_not_confirm: 'Free Trial account not activated',
    user_not_exist: 'User email does not exist',
    password_not_valid: 'Incorrect user password',
    procore_error: 'Unable to get profile information from Procore'
  };
  let errorSource = null;
  let eventResult = 'Successful';
  if (status !== httpCodes.OK) {
    errorSource = errorMessages[errorType] ?? 'Login issues';
    eventResult = 'Failed';
  }
  trackingEvent(
    'sign_in',
    {
      user_email: email?.toLowerCase(),
      event_result: eventResult,
      error_source: errorSource
    },
    AMPLITUDE_SERVICE
  );
};

export const sendNetworkBlockedEvent = (email) => {
  const NETWORK_DISCONNECTION = 'Network disconnection';
  const NETWORK_BLOCKING = 'Network blocking login service';
  const userOnLine = navigator.onLine;
  const errorSource = userOnLine ? NETWORK_BLOCKING : NETWORK_DISCONNECTION;

  trackingEvent(
    'login_network_warning_alert',
    {
      email: email?.toLowerCase(),
      error_source: errorSource
    },
    AMPLITUDE_SERVICE
  );
};

export function validateEmail(email) {
  const REGEX_EMAIL =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return REGEX_EMAIL.test(email);
}

export function validatePasswordLength(password) {
  const MIN_LENGHT_PASSWORD = 6;
  return password.length >= MIN_LENGHT_PASSWORD;
}

export function isValidForm(data) {
  const { email, password } = data;
  return (
    validateEmail(email) &&
    password !== '' &&
    password !== null &&
    validatePasswordLength(password)
  );
}
