import pick from 'lodash/pick';

import colors from '../../stylesheets/variables.scss';
import i18n from '../../i18n';
import moment from 'moment';

const NUM_DECIMALS = 2;

/**
 * Gets the progress chart's color code based on the supplied completion amount
 *
 * @param {number} completion - A number that represents the current completion for a given activity
 * @return {string} - The color code to be used
 */
export const getChartColor = (completion) => {
  if (completion === 100) {
    return colors.green;
  }

  return completion === 0 ? colors.gray30 : colors.brandOrange40;
};

/**
 * Gets a formatted string with the amount of released roadblocks out of a given total
 *
 * @param {Object[]} roadblocks - An array of roadblock objects
 * @return {string} - The formatted roadblocks string
 */
export const getFormattedRoadblocks = (roadblocks) =>
  `${roadblocks?.filter((rb) => rb.status === 'released')?.length}/${roadblocks?.length}`;

/**
 * Returns a boolean indicating whether there are open roadblocks
 *
 * @param {Object[]} roadblocks - An array of roadblock objects
 * @return {boolean} - The boolean indicating whether there are open roadblocks
 */
export const hasOpenRoadblocks = (roadblocks) =>
  roadblocks?.some((rb) => rb.status !== 'released');

/**
 * Gets the progress chart's description label based on the supplied completion amount
 *
 * @param {number} completion - A number that represents the current completion for a given activity
 * @return {string} - The description label to be used
 */
export const getChartLabel = (completion) => {
  if (completion === 100 || completion > 99.9) {
    return i18n.t('takt_units.complete_op');
  }

  return i18n.t(
    completion === 0 ? 'takt_units.unstart_op' : 'takt_units.develope_op'
  );
};

/**
 * Sets a CSS variable/custom property with the current chart color.
 *
 * More about CSS Custom Properties:
 * https://medium.com/@alcidesqueiroz/vanilla-css-magic-with-custom-properties-5f2f145e3e07
 *
 * @param {number} color - The color code to be set
 * @return {undefined}
 */
export const setChartColorCustomProperty = (color) => {
  document.documentElement.style.setProperty(
    '--activity-card-chart-color',
    color
  );
};

/**
 * Maps an activity object to a friendlier format
 *
 * @param {Object} activity - The activity object to be mapped
 * @param {string} dateFormat - The format to be used when formatting dates
 * @param {Object} ganttInstance - The current gantt component instance
 * @return {Object} - The activity mapped to the new format
 */
export const mapActivityToNewFormat = (activity, dateFormat, ganttInstance) => {
  try {
    const baseline = getBaseline(activity);

    return {
      id: activity.id,
      uniqueId: Number(activity.unique_id),
      title: activity.name,
      cost: activity.cost,
      realCost: activity.real_cost,
      hasChilds: activity.has_childs,
      baseCost: baseline?.cost,
      work: activity.hhWorkTime,
      baseWork: baseline?.hh_work,
      hasChilds: false,
      realWork: activity.real_work,
      baseDuration: baseline?.duration,
      duration: activity.duration,
      baseStartDate: baseline?.start_date,
      startDate: activity.start_date,
      baseEndDate: baseline?.end_date,
      endDate: activity.end_date,
      completion: activity.progress,
      activityRoute: activity.activityRoute,
      subcontract: getSubcontract(activity),
      tags: getTags(activity),
      assignees: mapAssigneesToNewFormat(activity.responsables),
      resources: getResources(activity),
      dependencies: {
        predecessors: getPredecessors(activity, ganttInstance),
        successors: getSuccessors(activity, ganttInstance)
      },
      originalActivityObject: activity
    };
  } catch (error) {
    console.log(`Error in mapActivityToNewFormat: ${error}`);
  }
};

/**
 * Gets the ideal text color for a tag, based on its background color.
 *
 * @param {string} backgroundColor - The tag background color
 * @return {string} - The text color to be used
 */
export const getTagTextColor = (backgroundColor) => {
  // Using negative indexes here to accept colors with and without #
  const r = parseInt(backgroundColor.substr(-6, 2), 16);
  const g = parseInt(backgroundColor.substr(-4, 2), 16);
  const b = parseInt(backgroundColor.substr(-2, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.7)';
};

/**
 * Gets the formatted and localized text for the performed action of an activity log entry
 *
 * @param {Object} timeEntry - The log entry object to be used
 * @return {string} - The formatted and localized text
 */
export const getActionDescription = (timeEntry) => {
  const { type, field } = timeEntry;

  if (type === 'CHANGE') {
    return `${i18n.t('activity_card.changed')} ${field} ${i18n.t('activity_card.to')}`;
  }

  if (type === 'ADD') {
    return `${i18n.t('activity_card.added')} ${field}`;
  }
};

/**
 * Gets the formatted and localized text for the new value of an activity log entry
 *
 * @param {Object} timeEntry - The log entry object to be used
 * @return {string} - The formatted and localized text
 */
export const getNewValue = (timeEntry) => {
  const { newValue, newValueUnit } = timeEntry;

  if (newValueUnit === 'DAY') {
    return [
      newValue,
      i18n.t(`activity_card.day${newValue === 1 ? '' : 's'}`)
    ].join(' ');
  }

  return newValue;
};

export const parseActivityDateTime = (strDate) =>
  moment(strDate, 'YYYY/MM/DD H:mm').toDate();

export const formatActivityDateTime = (date) =>
  moment(date).format('YYYY/MM/DD H:mm');

export const formatDate = (date, format) =>
  date ? moment(parseActivityDateTime(date)).format(format) : '';

export const mapAssigneesToNewFormat = (assignees) =>
  assignees.length
    ? assignees.map((assignee) => ({
        email: assignee.email,
        thumbnail: assignee.image || assignee.thumbnail || null,
        name: [
          assignee.name || assignee.firstName,
          assignee.lastname || assignee.lastName
        ].join(' ')
      }))
    : [];

export function mapTasksToNewFormat(tasks, dateFormat) {
  return tasks.map((task) => ({
    id: task.id,
    correlative_number: task.correlative_number,
    title: task.name,
    duration: task.duration,
    startDate: formatDate(task.start_date, dateFormat),
    endDate: formatDate(task.end_date, dateFormat),
    completion: task.progress,
    assignees: mapAssigneesToNewFormat(task.responsables),
    originalTaskObject: task,
    roadblocks: getRoadblocks(task.constraints),
    is_parent: task.is_parent
  }));
}

function getSubcontract(activity) {
  const subcontract = activity.subcontract;
  return subcontract ? pick(subcontract, ['id', 'name', 'color']) : null;
}

function getTags(activity) {
  return activity.tags?.map((tag) => ({
    id: tag.id,
    name: tag.name,
    color: tag.description
  }));
}

function getResources(activity) {
  return activity.resources?.map((resource) => ({
    id: resource.id,
    name: resource.name,
    type: resource.type,
    quantity: resource.total,
    actualQuantity: resource.used,
    unit: resource.material_label,
    originalResourceObject: resource
  }));
}

function getDependencies(activityId, isSource, ganttInstance) {
  const linkIds =
    ganttInstance.getTask(activityId)[`$${isSource ? 'source' : 'target'}`];

  return linkIds
    .map((linkId) => {
      const linkInfo = ganttInstance.getLink(linkId);
      const depGanttActivityObject = ganttInstance.getTask(
        linkInfo[`${isSource ? 'target' : 'source'}`]
      );
      const depLagInHours = linkInfo.lag ?? 0;
      const depLagInDays = ganttInstance.formatter
        .format(depLagInHours)
        .split(' ')[0];
      if (depGanttActivityObject) {
        return {
          id: depGanttActivityObject.correlative_id,
          title: depGanttActivityObject.text,
          type: ['FS', 'SS', 'FF', 'SF'][Number(linkInfo.type)],
          lag: `${depLagInHours >= 0 ? '+' : ''}${depLagInDays}d`,
          linkId: linkId
        };
      }
    })
    .filter((link) => Boolean(link));
}

function getSuccessors(activity, ganttInstance) {
  return getDependencies(activity.unique_id, true, ganttInstance);
}

function getPredecessors(activity, ganttInstance) {
  return getDependencies(activity.unique_id, false, ganttInstance);
}

function getBreadcrumb(activity) {
  return [
    { title: i18n.t('master_plan.master_plan') },
    ...activity.activityRouteIds.map((activity) => ({
      id: activity.id,
      title: activity.name
    })),
    { id: activity.id, title: activity.name }
  ];
}

function getBaseline(activity) {
  return activity?.baseline_points?.[0] || [];

  /* const versionId = activity?.baseline_versions?.find((version) => version.active)?.id;
    const baseline = activity?.baseline_points?.find((baseline) => baseline.sectorbaselineversionId === versionId);

    return baseline ?? {}; */
}

function getRoadblocks(roadblocks) {
  return roadblocks?.map((rb) => ({
    name: rb.name,
    status: rb.status
  }));
}

/**
 * this function calculate te total quantity to show in act. card by resource
 * @param {*} resource current resource
 * @param {*} cardData reference to object cardData
 * @returns calculated actualQuantity
 */
export const getActualQuantity = (resource, cardData) => {
  /** calculate actual quantity (sum of resources assigned to task) */
  let actualQuantity = 0;
  let tasksToCalculate = [];
  if (resource.type === 'machinery') {
    tasksToCalculate = cardData.tasks.filter(
      (el) => el.originalTaskObject.machineId === resource.id
    );
    actualQuantity = tasksToCalculate.reduce((a, c) => {
      const spend_hm =
        (c.originalTaskObject.progress / 100) * c.originalTaskObject.total_hm;
      return a + spend_hm || 0;
    }, 0);
  } else if (resource.type === 'rrhh') {
    tasksToCalculate = cardData.tasks.filter(
      (el) => el.originalTaskObject.specialtyId === resource.id
    );
    actualQuantity = tasksToCalculate.reduce(
      (a, c) => a + c.originalTaskObject.spend_hh || 0,
      0
    );
  } else if (resource.type === 'material') {
    tasksToCalculate = cardData.tasks.filter(
      (el) => el.originalTaskObject.materialId === resource.id
    );
    actualQuantity = tasksToCalculate.reduce(
      (a, c) => a + c.originalTaskObject.actual_quantity || 0,
      0
    );
  }
  return actualQuantity;
};

/**
 * This function update gantt object
 * @param {*} cardData Activity object
 * @param {*} field Field to edit
 * @param {*} value Value to edit
 * @param {*} gantt Reference to gantt object
 */
export const updateGanttLocally = (cardData, field, value, gantt) => {
  if (gantt.isTaskExists(cardData.originalActivityObject.unique_id)) {
    const taskGantt = gantt.getTask(cardData.originalActivityObject.unique_id);
    if (Array.isArray(field)) {
      /** assign data by each field */
      field.forEach((nameField, i) => {
        taskGantt[nameField] = value[i];
      });
    } else {
      taskGantt[field] = value;
    }
    gantt.updateTask(taskGantt.id);
    return taskGantt;
  }
};

//  * This function evaluates if the progress of the activity can be edited.
//  * Depending on whether it has tasks and whether it has secondary activities
//  * @param {*} item activity object to be edited
//  * @returns boolean indicating whether or not the activity can be edited
//  */
export const canBeActivityEdited = (item) => {
  /** is object? */
  const isObject = Object.keys(item).length !== 0;
  let canBeEdit = false;
  if (isObject) {
    /** has no tasks */
    canBeEdit = !item?.tasks?.length;

    /** if has childs */
    canBeEdit = canBeEdit && !item.hasChilds;
  }
  return canBeEdit;
};

/**
 * Formats a number to display with or without decimals based on its nature.
 * @param {number|string} value - The value to be formatted.
 * @returns {string} - The formatted number.
 */
export const formatNumber = (value) => {
  const parsedValue = parseFloat(value);
  return Number.isInteger(parsedValue)
    ? parseInt(parsedValue)
    : parsedValue.toFixed(NUM_DECIMALS);
};
