import React from 'react';
import { findDeepGetActivityOfTask } from '../../../../views/lookahead/planification/index.helper';
import {
  findDeepGetTask,
  updateAsyncTaskGanttV2,
  defineRestrictedTaskByChilds
} from '../../GanttVisualization.helper';
import { capitalize } from 'lodash';
import {
  calculateLeanStatus,
  notifyMessage
} from '../../../../utils/lookahead-common';
import { Tooltip, Popover } from 'antd';
import { taskService } from '../../../../services';
import {
  save_single_task_lookahead,
  workerService
} from '../../../../services/serviceworker.service';

const RenderLean = ({ task, node, column, activities, gantt, t, onlyRead }) => {
  const isOnlyReadElement = onlyRead || task.readonly;
  if (!task.isTask) return null;
  if (task.is_milestone) return null;
  /** get activity reference */
  const findAct = findDeepGetActivityOfTask(window.activities, 'id', task.id);
  let activityReference;
  if (findAct) activityReference = findAct;
  if (!activityReference) return null;

  /** get reference task (taskFromLookahead) from activities */
  const taskFromLookahead = findDeepGetTask(activities, 'id', task.id);
  if (!taskFromLookahead) return null;

  /** We check if the task lean status is different to taskFromLookahead */
  if (taskFromLookahead.lean_status !== task.lean_status) {
    taskFromLookahead.lean_status = task.lean_status;

    workerService.callAction(
      save_single_task_lookahead,
      taskFromLookahead,
      () => {
        taskService.update(taskFromLookahead);
      }
    );
  }

  /** We check if the task was already loaded at checkIfTaskIsInsideOfWeekly gantt util function, and if it has other lean status but its inside the range */
  if (
    task.checkedIfInsideWeekly &&
    task.inside_weekly_other_lean &&
    !task.isInsideWeekly
  ) {
    task.inside_weekly_other_lean = false;
    taskFromLookahead.inside_weekly_other_lean = task.inside_weekly_other_lean;

    workerService.callAction(
      save_single_task_lookahead,
      taskFromLookahead,
      () => {
        taskService.update(taskFromLookahead);
      }
    );
  }
  /** disabled value for parent tasks, get parent task  */
  let parentTask = null;
  // let disabled = true
  if (taskFromLookahead) {
    if (taskFromLookahead.parent_id) {
      const doesExistAtReferenceParent = findDeepGetTask(
        activities,
        'id',
        taskFromLookahead.parent_id
      );
      parentTask = doesExistAtReferenceParent || null;
    }
  }

  const leanObject = column.from_values.find(
    (el) => el.value === task[column.name]
  );
  const background = leanObject?.color + 'cf';

  /** this function intercepts the save call, and adds data */
  // const updateData = (taskCallback) => {
  //     updateAsyncTaskGanttV2(taskCallback, column.name, null, gantt)
  // }

  if (isOnlyReadElement) {
    return (
      <div
        style={{
          backgroundColor: background,
          color: 'white',
          height: '100%'
        }}>
        <span className="vertical-center col-lean-status">
          {leanObject?.label}
        </span>
      </div>
    );
  }

  /** task is restricted */
  if (task?.is_restricted || task?.restricted) {
    return (
      <div
        style={{
          backgroundColor: '#E50101',
          color: 'white',
          height: '100%'
        }}>
        <span className="vertical-center">{t('restricted_label')}</span>
      </div>
    );
  }

  /** Status Lean Committed read only */
  if (task[column.name] === 'Committed') {
    return (
      <div
        style={{
          backgroundColor: background,
          color: 'white',
          height: '100%'
        }}>
        <span className="vertical-center col-lean-status">
          {leanObject?.label}
        </span>
      </div>
    );
  }

  const updateDataLeanStatus = (taskCallback) => {
    updateAsyncTaskGanttV2(taskCallback, 'lean_status', null, gantt);
  };

  let isOdd = false;
  if (gantt?.oddColsConfig && task.type === 'activitytask') {
    isOdd = gantt.oddColsConfig[column.name];
  }

  return (
    <div className={isOdd ? 'odd-col' : 'non-odd-col'}>
      <Tooltip placement="bottom">
        <Popover
          overlayClassName="popoverLeanStatus"
          className="popoverLeanStatus fix_lean_status react-dhtmlx"
          content={
            <div className="priorityOptions">
              {column.from_values.map((option, index) => {
                if (
                  option.value != 'Committed' &&
                  option.value != 'Restricted'
                ) {
                  return !task.isInsideWeekly &&
                    option.value == 'Will' ? null : (
                    <span
                      className="item"
                      key={index}
                      onClick={() => {
                        /** check if childs have lean_status = 'Will' */
                        const childsHaveLeanStatusWill =
                          taskFromLookahead.children.some(
                            (el) => el.lean_status === 'Will'
                          );
                        if (!childsHaveLeanStatusWill) {
                          task.inside_weekly_other_lean = false;

                          if (task.isInsideWeekly && option.value !== 'Will') {
                            task.inside_weekly_other_lean = true;
                          }
                          taskFromLookahead.inside_weekly_other_lean =
                            task.inside_weekly_other_lean;
                          updateAsyncTaskGanttV2(
                            taskFromLookahead,
                            column.name,
                            option.value,
                            gantt
                          );
                          calculateLeanStatus(
                            task,
                            parentTask || activityReference,
                            activityReference,
                            updateDataLeanStatus,
                            null
                          );
                        } else {
                          notifyMessage({
                            title: t('modules.weeklyplan'),
                            message: t('have_childs_will'),
                            type: 'warning'
                          });
                        }
                      }}>
                      <i
                        className="fas fa-circle"
                        style={{
                          fontSize: 11,
                          color: option.color,
                          position: 'relative',
                          top: -1
                        }}
                      />
                      <span style={{ marginLeft: 5 }}>
                        {capitalize(option.label)}
                      </span>
                    </span>
                  );
                }
              })}
            </div>
          }
          trigger="click">
          <div
            style={{
              backgroundColor: background,
              color: 'white',
              height: '100%'
            }}>
            <span className="vertical-center col-lean-status">
              {leanObject?.label}
            </span>
          </div>
        </Popover>
      </Tooltip>
    </div>
  );
};

export default RenderLean;
