import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import userSettingsService from '../services/userSettings.service';
import companySettingsService from '../services/companySettings.service';
import { permissionService } from '../services/permission.service';
import { companySettingsAction } from '../redux/actions/companySettingsAction';
import { getSignedUser } from '../utils/userUtils';
import { addBreadcrumbUtil } from '../monitor/monitor';

export const STORAGE_KEYS = {
  session: {
    PERMISSION_TABLE: 'permissiontable',
    COMPANY: 'company'
  },
  local: {
    CONSTRAINT_VALIDATION: 'constraintValidation'
  }
};

const QUERY_KEYS = {
  PERMISSIONS: 'userPermissions',
  CONSTRAINT_VALIDATION: 'constraintValidation',
  TRANSFER_RESPONSIBLE: 'transferResponsible'
};

const fetchPermissions = async () => {
  const { permissionTable } = await permissionService.getPermissionsByUsers();
  if (permissionTable) {
    sessionStorage.setItem(
      STORAGE_KEYS.session.PERMISSION_TABLE,
      JSON.stringify(permissionTable)
    );
  }
  return permissionTable;
};

const fetchConstraintValidation = async (userId) => {
  const { constraintValidation } =
    (await userSettingsService.getConstraintValidation(userId)) || {};

  if (constraintValidation !== undefined) {
    localStorage.setItem(
      STORAGE_KEYS.local.CONSTRAINT_VALIDATION,
      JSON.stringify(constraintValidation)
    );
  }
  return constraintValidation;
};

const fetchTransferResponsible = async (companyId, dispatch) => {
  try {
    const data =
      await companySettingsService.getTransferResponsibleSetting(companyId);
    const hasValidData = data && !data?.error;

    dispatch(
      companySettingsAction.setTransferResponsibles(hasValidData ? data : false)
    );
  } catch (error) {
    dispatch(companySettingsAction.setTransferResponsibles(false));
  }
};

const handleQueryError = (context, metadata = {}) => {
  addBreadcrumbUtil('App', context, 'warning', metadata);
};

export const useLoadUserSessionData = () => {
  const dispatch = useDispatch();
  const transferResponsibles = useSelector(
    (state) => state.companySettingsState.transferResponsibles
  );

  const currentUser = getSignedUser();
  const companyData = sessionStorage.getItem(STORAGE_KEYS.session.COMPANY);
  const company = companyData ? JSON.parse(companyData) : null;
  const isReady = Boolean(currentUser?.companyId && company?.id);

  useQuery({
    queryKey: [QUERY_KEYS.PERMISSIONS],
    queryFn: () => fetchPermissions(),
    enabled:
      isReady && !sessionStorage.getItem(STORAGE_KEYS.session.PERMISSION_TABLE),
    retry: 2,
    onError: (error) =>
      handleQueryError('permissions', {
        error,
        userId: currentUser?.id
      })
  });

  useQuery({
    queryKey: [QUERY_KEYS.CONSTRAINT_VALIDATION, currentUser?.id],
    queryFn: () => fetchConstraintValidation(currentUser.id),
    enabled:
      isReady &&
      !localStorage.getItem(STORAGE_KEYS.local.CONSTRAINT_VALIDATION),
    retry: 2,
    onError: (error) =>
      handleQueryError('constraint validation', {
        error,
        userId: currentUser?.id
      })
  });

  useQuery({
    queryKey: [QUERY_KEYS.TRANSFER_RESPONSIBLE, company?.id],
    queryFn: () => fetchTransferResponsible(company.id, dispatch),
    enabled: isReady && !transferResponsibles?.transferResponsible,
    retry: 2,
    onError: (error) => {
      handleQueryError('Error loading transfer responsible:', {
        error,
        userId: currentUser?.id,
        companyId: company?.id
      });
    }
  });
};
