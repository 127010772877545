import React, { useCallback } from 'react';
import QuestionItem from '../components/QuestionItem';

export const useQuestionsList = (drawer, helpCenterData, t) => {
  return useCallback(() => {
    if (!drawer?.data) return null;

    const currentModule = helpCenterData.find(
      (el) => el.name === drawer.data.module
    );
    if (!currentModule) return null;

    return currentModule.questions
      .filter((question) => !question.hide)
      .map((question, index) => {
        const questionUrl = t('lang') === 'es' ? question.url : question.urlEng;
        return (
          <QuestionItem
            key={index}
            questionUrl={questionUrl}
            title={t(question.title)}
          />
        );
      });
  }, [drawer?.data, helpCenterData, t]);
};
