import { CONSTRAINT_TYPES } from '../constants/index';
import { getLinkNameByCode } from '../constants/linkByCode';
class FreeFloat {
  constructor(paramsForCalculation) {
    this.gantt = paramsForCalculation.gantt;
    this.activity = paramsForCalculation.activity;
    this.forwardMap = paramsForCalculation.forwardMap;
    this.backwardMap = paramsForCalculation.backwardMap;
    this.projectDates = paramsForCalculation.projectDates;
    this.linksFreeFloatArray = [];
    this.linksFreeFloatObject = {};
  }

  getFreeFloat() {
    const PROGRESS = Number(this.activity.progress);
    const CONSTRAINT = this.getConstraintType();
    this.calculateFreeFloatOfLink(CONSTRAINT);
    const activityFreeFloat =
      PROGRESS === 100 ? 0 : this.getFreeFloatBaseConstraint(CONSTRAINT);
    return {
      activityFreeFloat,
      linksFreeFloat: this.linksFreeFloatObject
    };
  }

  getFreeFloatBaseConstraint(constraint) {
    if (
      constraint === CONSTRAINT_TYPES.MSO ||
      constraint === CONSTRAINT_TYPES.MFO
    ) {
      return this.calculateMsoMfo(constraint);
    } else {
      return this.calculateGeneral(constraint);
    }
  }

  calculateFreeFloatOfLink(constraint = CONSTRAINT_TYPES.ASAP) {
    const FREE_FLOAT_FROM_LINKS = this.activity['$source']
      .map((source) => {
        const { LINK, LINK_TYPE } = this.getLinkData(source);
        const sucessor = this.gantt.getTask(LINK.target);
        if (!sucessor) {
          return {
            linkId: LINK.id,
            freeFloat: null,
            hasProgress: false
          };
        }
        const hasProgress = Number(sucessor.progress) > 0;

        const { sucessorDateToCalculate, predecessorDateToCalculate } =
          this.getDatesFromSucessorAndPredecessor(
            LINK_TYPE,
            constraint,
            sucessor
          );

        const differenceBetweenDates = this.calculationWithCalendar(
          sucessorDateToCalculate,
          predecessorDateToCalculate
        );

        const finalResult =
          this.parseHoursToDays(differenceBetweenDates) -
          this.parseHoursToDays(LINK.lag);
        const nonNegativeResult = Math.max(0, finalResult);
        this.linksFreeFloatObject[LINK.id] = nonNegativeResult;

        return {
          linkId: LINK.id,
          freeFloat: nonNegativeResult,
          hasProgress
        };
      })
      .filter(
        (linkData) =>
          linkData.freeFloat !== null && linkData.freeFloat !== undefined
      );

    this.linksFreeFloatArray = FREE_FLOAT_FROM_LINKS;

    return FREE_FLOAT_FROM_LINKS;
  }

  calculateGeneral(constraint = CONSTRAINT_TYPES.ASAP) {
    const MIN_FLOAT_FROM_LINKS = this.getMinFloat(this.linksFreeFloatArray);

    const FREE_FLOAT_END_OF_PROJECT =
      this.calculateFreeFloatForEndOfProject(constraint);

    let totalMinusFloat = 0;

    if ([CONSTRAINT_TYPES.SNLT, CONSTRAINT_TYPES.FNLT].includes(constraint)) {
      totalMinusFloat = this.doCalculationForSnltFnlt(
        constraint,
        FREE_FLOAT_END_OF_PROJECT,
        MIN_FLOAT_FROM_LINKS
      );
    } else {
      totalMinusFloat = this.doGeneralCalculation(
        FREE_FLOAT_END_OF_PROJECT,
        MIN_FLOAT_FROM_LINKS
      );
    }

    return Math.max(0, totalMinusFloat);
  }

  getConstraintType() {
    let { constraint_type } = this.activity;

    if (!constraint_type) {
      constraint_type = CONSTRAINT_TYPES.ASAP;
    }

    return constraint_type;
  }

  getDatesFromSucessorAndPredecessor(linkType, constraint, sucessor) {
    const isSuscessorAlap = sucessor.constraint_type === CONSTRAINT_TYPES.ALAP;

    const constraintData = this.getSucessorAndPredecessorDates({
      constraint,
      isSuscessorAlap,
      sucessorId: sucessor.id,
      predecessorId: this.activity.id
    });

    let sucessorDateToCalculate = new Date();
    let predecessorDateToCalculate = new Date();

    sucessorDateToCalculate = constraintData[linkType].sucessor;
    predecessorDateToCalculate = constraintData[linkType].predecessor;

    return {
      sucessorDateToCalculate,
      predecessorDateToCalculate
    };
  }

  doGeneralCalculation(freeFloatEndOfProject, floatFromLinks) {
    if (floatFromLinks === null) {
      return freeFloatEndOfProject;
    }

    return this.getMinValue(floatFromLinks, freeFloatEndOfProject);
  }

  doCalculationForSnltFnlt(constraint, freeFloatEndOfProject, floatFromLinks) {
    const restrictionFreeFloat =
      this.calculateFreeFloatOfRestriction(constraint);

    return this.getMinValue(
      floatFromLinks,
      freeFloatEndOfProject,
      restrictionFreeFloat
    );
  }

  calculateFreeFloatForEndOfProject(constraint) {
    return this.parseHoursToDays(
      this.calculationWithCalendar(
        this.gantt.getSubtaskDates().end_date,
        constraint === CONSTRAINT_TYPES.ALAP
          ? this.backwardMap.get(this.activity.id).lf
          : this.forwardMap.get(this.activity.id).ef
      )
    );
  }

  calculateFreeFloatOfRestriction(constraint) {
    return this.parseHoursToDays(
      this.calculationWithCalendar(
        this.activity.constraint_date,
        constraint === CONSTRAINT_TYPES.FNLT
          ? this.forwardMap.get(this.activity.id).ef
          : this.forwardMap.get(this.activity.id).es
      )
    );
  }

  getLinkData(linkId) {
    const LINK = this.gantt.getLink(linkId) || {};
    const LINK_TYPE = getLinkNameByCode(LINK.type);

    return {
      LINK,
      LINK_TYPE
    };
  }

  getSucessorAndPredecessorDates({
    constraint,
    isSuscessorAlap,
    sucessorId,
    predecessorId
  }) {
    const getPredecessorDate = ({
      direction,
      constraint = CONSTRAINT_TYPES.ASAP
    }) => {
      if (constraint === CONSTRAINT_TYPES.ALAP) {
        return this.backwardMap.get(predecessorId)[direction];
      }
      return this.forwardMap.get(predecessorId)[direction];
    };

    if (
      constraint === CONSTRAINT_TYPES.ASAP ||
      constraint === CONSTRAINT_TYPES.SNET ||
      constraint === CONSTRAINT_TYPES.FNET ||
      constraint === CONSTRAINT_TYPES.MSO ||
      constraint === CONSTRAINT_TYPES.MFO
    ) {
      return {
        fs: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).ls
            : this.forwardMap.get(sucessorId).es,
          predecessor: getPredecessorDate({ direction: 'ef' })
        },
        ss: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).ls
            : this.forwardMap.get(sucessorId).es,
          predecessor: getPredecessorDate({ direction: 'es' })
        },
        ff: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).lf
            : this.forwardMap.get(sucessorId).ef,
          predecessor: getPredecessorDate({ direction: 'ef' })
        },
        sf: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).lf
            : this.forwardMap.get(sucessorId).ef,
          predecessor: getPredecessorDate({ direction: 'es' })
        }
      };
    }

    if (constraint === CONSTRAINT_TYPES.ALAP) {
      return {
        fs: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).ls
            : this.forwardMap.get(sucessorId).es,
          predecessor: getPredecessorDate({
            constraint: CONSTRAINT_TYPES.ALAP,
            direction: 'lf'
          })
        },
        ss: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).ls
            : this.forwardMap.get(sucessorId).es,
          predecessor: getPredecessorDate({
            constraint: CONSTRAINT_TYPES.ALAP,
            direction: 'ls'
          })
        },
        ff: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).lf
            : this.forwardMap.get(sucessorId).ef,
          predecessor: getPredecessorDate({
            constraint: CONSTRAINT_TYPES.ALAP,
            direction: 'lf'
          })
        },
        sf: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).lf
            : this.forwardMap.get(sucessorId).ef,
          predecessor: getPredecessorDate({
            constraint: CONSTRAINT_TYPES.ALAP,
            direction: 'ls'
          })
        }
      };
    }

    if (constraint === CONSTRAINT_TYPES.SNLT) {
      return {
        fs: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).ls
            : this.forwardMap.get(sucessorId).es,
          predecessor: getPredecessorDate({ direction: 'ef' })
        },
        ss: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).ls
            : this.forwardMap.get(sucessorId).es,
          predecessor: getPredecessorDate({ direction: 'es' })
        },
        ff: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).lf
            : this.forwardMap.get(sucessorId).ef,
          predecessor: getPredecessorDate({ direction: 'ef' })
        },
        sf: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).lf
            : this.forwardMap.get(sucessorId).ef,
          predecessor: getPredecessorDate({ direction: 'es' })
        }
      };
    }

    if (constraint === CONSTRAINT_TYPES.FNLT) {
      return {
        fs: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).ls
            : this.forwardMap.get(sucessorId).es,
          predecessor: getPredecessorDate({ direction: 'ef' })
        },
        ss: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).ls
            : this.forwardMap.get(sucessorId).es,
          predecessor: getPredecessorDate({ direction: 'es' })
        },
        ff: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).lf
            : this.forwardMap.get(sucessorId).ef,
          predecessor: getPredecessorDate({ direction: 'ef' })
        },
        sf: {
          sucessor: isSuscessorAlap
            ? this.backwardMap.get(sucessorId).lf
            : this.forwardMap.get(sucessorId).ef,
          predecessor: getPredecessorDate({ direction: 'es' })
        }
      };
    }
  }

  calculateMsoMfo(constraint) {
    const CONSTRAINT_DATE = this.activity.constraint_date || new Date();
    const DATE_TO_CALCULATE =
      constraint === CONSTRAINT_TYPES.MSO
        ? this.forwardMap.get(this.activity.id).es
        : this.forwardMap.get(this.activity.id).ef;

    const freeSlack = this.calculationWithCalendar(
      CONSTRAINT_DATE,
      DATE_TO_CALCULATE
    );

    return Math.max(0, this.parseHoursToDays(freeSlack));
  }

  getMinValue(...values) {
    const filterValues = values.filter(
      (value) => value !== undefined && value !== null
    );
    return filterValues.length ? Math.min(...filterValues) : 0;
  }

  calculationWithCalendar(dateOne, dateTwo) {
    let calendar = this.gantt.getCalendar(this.activity.calendar_id);
    return calendar.calculateDuration({
      start_date: new Date(dateTwo),
      end_date: new Date(dateOne)
    });
  }

  parseHoursToDays(date) {
    if (!date) {
      return 0;
    }
    return date / this.gantt.config.hoursPerDay;
  }

  getMinFloat(arrayCalculatedSlacks) {
    if (!arrayCalculatedSlacks?.length) {
      return null;
    }

    const slacksWithoutParents = arrayCalculatedSlacks
      .filter((linkData) => !linkData.hasProgress)
      .map((linkData) => linkData.freeFloat);

    if (slacksWithoutParents.length === 0) {
      return null;
    }

    return Math.min(...slacksWithoutParents);
  }
}
export default FreeFloat;
