import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function useDependenciesLinkFloat(
  activity,
  activityLink,
  direction = 'successor'
) {
  const criticalPathStatus = useSelector(
    (state) => state.ganttState.criticalPathStatus
  );
  const [dependenciesLinkFloat, setDependenciesLinkFloat] = useState(null);

  useEffect(() => {
    if (
      !activity ||
      !activityLink ||
      criticalPathStatus !== 'calculated' ||
      !window.to_use_react_gantt
    )
      return;
    const floatData = window.to_use_react_gantt.criticalPath.freeFloat.get(
      direction === 'successor' ? activity : activityLink.gantt_id
    )?.linksFreeFloat;

    const linkFloat = floatData ? floatData[activityLink.linkId] : 0;

    setDependenciesLinkFloat(linkFloat);
  }, [activity, activityLink, criticalPathStatus]);

  return dependenciesLinkFloat;
}

useDependenciesLinkFloat.propTypes = {
  activity: PropTypes.object.isRequired,
  activityLink: PropTypes.object.isRequired,
  direction: PropTypes.string
};

export { useDependenciesLinkFloat };
