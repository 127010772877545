import filters from '../../helpers/filters';
import { log } from '../../../../../monitor/monitor';

class ThirdLevelActivityIdentifier {
  /**
   * Constructs the ThirdLevelActivityIdentifier class.
   * @param {Object} params - The parameters object.
   * @param {Object} params.gantt - The Gantt chart instance.
   * @param {Object} params.filters - Filters for tasks.
   * @param {string} params.linkProperty - The property name for links.
   */
  constructor({ gantt, linkProperty }) {
    this.gantt = gantt;
    this.linkProperty = linkProperty;
    this.structureOfParents = new Map();
    this.singleParents = new Map();
  }

  /**
   * Identifies third-level activities and populates the structureOfParents map.
   */
  identifyThirdLevelActivities() {
    try {
      const parents = this.getFirstLevelParents();

      for (const parent of parents) {
        const processPayload = this.processEachActivity(parent);

        const { activitiesByLevel } = processPayload;

        const allLevels = this.getAllLevels(activitiesByLevel);
        const deepestLevel = this.getDeepestLevel(allLevels);

        const result = this.createResult(
          parent,
          processPayload,
          deepestLevel,
          allLevels
        );

        this.structureOfParents.set(parent.id, result);
      }

      return {
        structureOfParents: this.structureOfParents,
        singleParents: this.singleParents
      };
    } catch (e) {
      log('Critical Path', 'Error in ThirdLevelActivityIdentifier');
      throw e;
    }
  }

  /**
   * Retrieves the first-level parent tasks.
   * @returns {Array<Object>} Array of parent tasks.
   */
  getFirstLevelParents() {
    return this.gantt
      .getTaskByTime()
      .filter(filters.byFirstLevel)
      .filter(filters.filterByParentType);
  }

  /**
   * Processes each activity under a parent task.
   * @param {Object} parentActivity - The parent activity to process.
   * @returns {Object} Processed data including parentsIds, allTasks, activitiesByLevel.
   */
  processEachActivity(parentActivity) {
    const parentsIds = new Set();
    const allTasks = new Set();
    const activitiesByLevel = new Map();

    this.initializeParent(parentActivity);

    this.gantt.eachTask((activity) => {
      this.processActivity(activity, parentsIds, allTasks, activitiesByLevel);
    }, parentActivity.id);

    return {
      parentsIds,
      allTasks,
      activitiesByLevel
    };
  }

  /**
   * Initializes the parent activity in singleParents map.
   * @param {Object} parentActivity - The parent activity.
   */
  initializeParent(parentActivity) {
    const parentId = Number(parentActivity.id);
    const hasLink = Boolean(parentActivity[this.linkProperty]?.length);
    const level = parentActivity['$level'];

    this.singleParents.set(parentId, {
      level,
      hasLink,
      childrens: []
    });
  }

  /**
   * Processes a single activity.
   * @param {Object} activity - The activity to process.
   * @param {Set<number>} parentsIds - Set of parent IDs.
   * @param {Set<number>} allTasks - Set of all task IDs.
   * @param {Map<number, Map<number, Array<Object>>>} activitiesByLevel - Activities grouped by level.
   */
  processActivity(activity, parentsIds, allTasks, activitiesByLevel) {
    const activityId = Number(activity.id);
    const parentId = Number(activity.parent);
    allTasks.add(activityId);

    const isProjectActivity = this.isProjectActivity(activity);

    if (isProjectActivity) {
      this.addParentInfo(activity, parentsIds);
    }

    const parentExists = this.doesParentExist(parentId);
    if (parentId != null && parentExists) {
      this.addChildToParent(parentId, activityId);
    }

    this.addActivityToLevel(activity, activitiesByLevel);
  }

  /**
   * Checks if an activity is a project (parent activity).
   * @param {Object} activity - The activity to check.
   * @returns {boolean} True if the activity is a project.
   */
  isProjectActivity(activity) {
    return activity.type === 'project';
  }

  /**
   * Checks if an activity has a link.
   * @param {Object} activity - The activity to check.
   * @returns {boolean} True if the activity has a link.
   */
  hasLink(activity) {
    return Boolean(activity[this.linkProperty]?.length);
  }

  /**
   * Adds parent information to singleParents map.
   * @param {Object} activity - The parent activity.
   * @param {Set<number>} parentsIds - Set of parent IDs.
   */
  addParentInfo(activity, parentsIds) {
    const parentId = Number(activity.id);
    const level = activity['$level'];
    const hasLink = this.hasLink(activity);

    const parentInfo = {
      level,
      hasLink,
      childrens: []
    };

    parentsIds.add(parentId);
    this.singleParents.set(parentId, parentInfo);
  }

  /**
   * Checks if a parent exists in singleParents map.
   * @param {number} parentId - The parent ID to check.
   * @returns {boolean} True if the parent exists.
   */
  doesParentExist(parentId) {
    return this.singleParents.has(parentId);
  }

  /**
   * Adds a child activity to its parent in singleParents map.
   * @param {number} parentId - The parent ID.
   * @param {number} activityId - The child activity ID.
   */
  addChildToParent(parentId, activityId) {
    this.singleParents.get(parentId).childrens.push(activityId);
  }

  /**
   * Adds an activity to the activitiesByLevel map.
   * @param {Object} activity - The activity to add.
   * @param {Map<number, Map<number, Array<Object>>>} activitiesByLevel - Activities grouped by level.
   */
  addActivityToLevel(activity, activitiesByLevel) {
    const levelKey = activity['$level'];
    const parentKey = Number(activity.parent);

    if (!activitiesByLevel.has(levelKey)) {
      activitiesByLevel.set(levelKey, new Map());
    }

    const levelMap = activitiesByLevel.get(levelKey);

    if (!levelMap.has(parentKey)) {
      levelMap.set(parentKey, []);
    }

    levelMap.get(parentKey).push({
      id: Number(activity.id),
      isParent: this.isProjectActivity(activity),
      calculated: false
    });
  }

  /**
   * Retrieves all levels from activitiesByLevel map.
   * @param {Map<number, Map<number, Array<Object>>>} activitiesByLevel - Activities grouped by level.
   * @returns {Set<number>} Set of all levels.
   */
  getAllLevels(activitiesByLevel) {
    return new Set(activitiesByLevel.keys());
  }

  /**
   * Determines the deepest level from a set of levels.
   * @param {Set<number>} allLevels - Set of all levels.
   * @returns {number} The deepest level.
   */
  getDeepestLevel(allLevels) {
    if (allLevels.size === 0) {
      return 0;
    }
    return Math.max(...allLevels);
  }

  /**
   * Creates the result object for a parent activity.
   * @param {Object} parent - The parent activity.
   * @param {Object} processPayload - The processed data.
   * @param {number} deepestLevel - The deepest level found.
   * @param {Set<number>} allLevels - Set of all levels.
   * @returns {Object} The result object.
   */
  createResult(parent, processPayload, deepestLevel, allLevels) {
    const { activitiesByLevel, parentsIds, allTasks } = processPayload;

    return {
      parent: parent.id,
      isALinkedParent: this.hasLink(parent),
      levels: allLevels,
      allParentChildrens: parentsIds,
      allChildrens: allTasks,
      childrensByLevel: activitiesByLevel,
      currentResolutionLevel: deepestLevel,
      canBeCalculated: false
    };
  }
}

export default ThirdLevelActivityIdentifier;
