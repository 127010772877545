import React, { useState, useEffect } from 'react';
import EventEmitter from 'react-native-eventemitter';
import { Animated } from 'react-animated-css';
import useMasterplanPermissions from '../../hooks/useMasterplanPermissions';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom';
import { Button } from 'antd';
import { Spin, Icon } from 'antd';

import { executeNewToolbarCommand } from '../../components/GanttFilterHeader/GanttFilterHeaderUtils';
import { GanttSettings } from '../../components/GanttSettings';
import GanttChart from './gantt';
import Calendars from './calendar';
import Resources from './resources';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useSelector, useDispatch } from 'react-redux';
import * as ganttActions from '../../redux/slices/ganttSlice';
import { Redirect } from 'react-router-dom';
import { trackingEvent } from '../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../analytics/utils';
import { LinksStorage } from '../../assets/js/gantt_storage/links';
import { refreshCriticalPathIsNeeded } from '../../assets/js/events/onAfterAutoSchedule/refreshCriticalPathIsNeeded';

/** import library to scroll */
import scrollIntoView from 'scroll-into-view';

import './index.css';
import { withTranslation } from 'react-i18next';
import { sectorService } from '../../services';
import useUnsavedElementsAlerter from '../../hooks/useUnsavedElementsAlerter';
import useModalBasic from '../../hooks/useModalBasic';
import { getTypeNotification, notifyMessageCustom } from '../../utils';
import { TrashComp } from '../../components/GanttMassiveToolbar/icons';
import { useSubmittalsEvents } from '../../hooks/useSubmittalsEvents';
import { requestRender } from '../../assets/js/events/utils';
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect';
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
export const TestIds = {
  GANTT_CONTAINER_VIEW_CONTAINER: 'GANTT_CONTAINER_VIEW_CONTAINER'
};

function GanttContainerView({ t = () => {}, history = {} }) {
  const [loaded, setLoaded] = useState(false);
  const { height, width } = useWindowDimensions();
  const [gantt, setGantt] = useState(null);
  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  const requireResources = stateCompany?.currentCompany?.require_resources;
  const projectSelected = projectState.allProjects.find(
    (el) => el.id == projectState.projectSelected
  );
  const masterplanPermissions = useMasterplanPermissions();
  const match = useRouteMatch();
  const sectorId = projectState.sectorSelected;
  let lastEl = match.url.split('/');
  lastEl[lastEl.length - 1] = sectorId;
  lastEl = lastEl.join('/');
  const { jsx, callback } = useUnsavedElementsAlerter(t);
  const submittalState = useSelector((state) => state.submittalState);
  const latestSubmittalEvent = useSubmittalsEvents();

  /** Redux */
  const dispatch = useDispatch();

  /** use custom hook for circular links */
  const userLang = navigator.language || navigator.userLanguage;
  const lang = userLang.includes('es') ? 'es' : 'en';

  const link_es =
    'https://knowledgebase.outbuild.com/es/knowledge/manejo-de-v%C3%ADnculos-circulares';
  const link_en =
    'https://knowledgebase.outbuild.com/en/knowledge/outbuild-circular-link-handling';

  const modal_message_base = 'modals.gantt.circular_links.message';
  const detailsLinkText = 'modals.gantt.circular_links.details';
  const detailsLink = lang === 'es' ? link_es : link_en;

  const contentModalCircular = {
    message_i18: modal_message_base,
    question_i18: null,
    title_i18: null,
    text_ok_i18: 'ok_text',
    hideCancelButton: true,
    hideOkButton: false,
    linkRef: detailsLink,
    messageRef: detailsLinkText
  };
  const { jsxModal, callbackModal } = useModalBasic(t, contentModalCircular);

  /** use custom hook for massive delete */
  const contentModalDelMassive = {
    message:
      'default' /** the message is default because it is configured after */,
    question_i18: null,
    title_i18: null,
    text_cancel_i18: 'cancel',
    text_ok_i18: 'delete_only_label',
    hideCancelButton: false,
    hideOkButton: false,
    footerAlign: 'center',
    hideWarningIcon: true,
    hideCloseButton: true,
    customWidth: 300
  };
  const { jsxModalDelMass, callbackModalDelMass } = useModalBasic(
    t,
    contentModalDelMassive,
    'DelMass'
  );

  /** use custom hook for massive duplicate */
  const message = t('master_plan.dup_warning');
  const contentModalDupMassive = {
    message: message,
    question_i18: null,
    title_i18: null,
    text_ok_i18: 'ok_text',
    hideCancelButton: true,
    hideOkButton: false
  };
  const { jsxModalDupMass, callbackModalDupMass } = useModalBasic(
    t,
    contentModalDupMassive,
    'DupMass'
  );

  /** use custom hook for confirm massive duplicate */
  const messageDup = t('master_plan.dup_warning_confirm');
  const contentModalDupConfirmMassive = {
    message: messageDup,
    question_i18: null,
    title_i18: null,
    text_ok_i18: 'confirmation.continue',
    hideCancelButton: false,
    hideOkButton: false
  };
  const { jsxModalDupConfirmMass, callbackModalDupConfirmMass } = useModalBasic(
    t,
    contentModalDupConfirmMassive,
    'DupConfirmMass'
  );

  const [buttons, setButtons] = useState([]);
  const [btnLoaded, setBtnLoaded] = useState(false);
  const btnActiveStyle = 'gantt-main-container-btn-style';
  const btnDisabledStyle = `${btnActiveStyle} gantt-main-container-disabled-btn-style`;

  const updateSectorLocalStorage = async () => {
    const updatedSector = await sectorService.showByLooaheadWeekly(
      projectState.sectorSelected
    );
    if (updatedSector) {
      const copy_sector = { ...updatedSector.sector };
      copy_sector.cncs = [];
      copy_sector.constraints = [];
      copy_sector.weekcommitments = [];
      sessionStorage.setItem('currentSector', JSON.stringify(copy_sector));
    }
  };

  /**
   * This handler should be removed as soon as we replace legacy global Gantt utility functions
   * with a proper Redux-based approach. After this, this logic should be moved
   * back to the GanttToolbar component.
   */
  const handleCommandDispatched = (command, value) => {
    if (command === 'NAVIGATE_TO_CALENDARS') {
      const wishAction = (_) => history?.push(`${lastEl}/calendars`);
      const forceReturn = callback && callback(wishAction);
      if (forceReturn) return;
      wishAction();

      return;
    } else if (command?.includes('SET_DATE_FORMAT_')) {
      value = command?.split('SET_DATE_FORMAT_')[1];
      command = 'DATE_FORMAT';
    } else if (command?.includes('SET_CRITERIA_STATUS_')) {
      value = command?.split('SET_CRITERIA_STATUS_')[1];
      command = 'CRITERIA_STATUS';
    }

    executeNewToolbarCommand(command, value);
  };

  useEffect(() => {
    updateSectorLocalStorage();

    const displayedRoute = history?.location?.pathname;
    const calendarsSector = JSON.parse(
      sessionStorage.getItem('currentSector')
    )?.calendars;
    if (/calendars$/.test(displayedRoute) && Boolean(calendarsSector.length)) {
      trackingEvent(
        'calendar_page_visualization',
        {
          ...getBasicAmplitudEventProperties(),
          event_source: 'schedule_button'
        },
        AMPLITUDE_SERVICE
      );
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const buttonsJson = [
        {
          btnTitle: t('tabs.masterplan.gantt'),
          routeName: `${lastEl}/ganttchart`,
          onClick: (params) => {
            history.push && history.push(`${lastEl}/ganttchart`);
          },
          hide: masterplanPermissions.gantt === 'SA'
        },
        requireResources && {
          btnTitle: t('tabs.masterplan.resources'),
          routeName: `${lastEl}/resources`,
          onClick: (params) => {
            history.push && history.push(`${lastEl}/resources`);
          },
          hide: masterplanPermissions.resources === 'SA'
        }
      ].filter(Boolean);
      setButtons([...buttonsJson]);
      setBtnLoaded(true);
      setLoaded(true);
    }, 500);
  }, []);

  useEffect(() => {
    window.Appcues.page();
  });

  const handleClick = (e, btn, shouldGo = true) => {
    const wishAction = (_) => {
      if (shouldGo) btn.onClick();
    };

    const forceReturn = callback && callback(wishAction);
    if (forceReturn) return;
    wishAction();
  };

  /**
   * This function opens the modal that reports about circular links
   */
  const modalCircular = (arrActivities = null) => {
    let message = null;
    const msgArr = [];
    if (arrActivities) {
      const message_base = t('modals.gantt.circular_links.message');
      message =
        t('modals.gantt.circular_links.activities') +
        ': [' +
        arrActivities.join() +
        ']';
      /** matrix to give css to the number of activities (n) */
      msgArr.push(message_base);
      msgArr.push(message);
    }
    callbackModal && callbackModal(null, msgArr);
  };

  /**
   * This function opens the modal that displays the warning on bulk action of duplicate
   */
  const modalDupMass = (n) => {
    const message = t('master_plan.dup_warning').replace(
      '__n__',
      '<span>' + n + '</span>'
    );

    /** matrix to give css to the number of activities (n) */
    const msgArr = [];
    msgArr.push(message.split('(')[0]);
    msgArr.push(n);
    msgArr.push(message.split(')')[1]);
    callbackModalDupMass && callbackModalDupMass(null, msgArr);
  };

  /**
   * This function opens the modal that displays the warning on bulk action of duplicate
   */
  const modalDupMassAddActivities = (n) => {
    const message = t('master_plan.dup_warning_new_activities').replace(
      '__n__',
      '<span>' + n + '</span>'
    );

    /** matrix to give css to the number of activities (n) */
    const msgArr = [];
    msgArr.push(message.split('(')[0]);
    msgArr.push(n);
    msgArr.push(message.split(')')[1]);
    callbackModalDupMass && callbackModalDupMass(null, msgArr);
  };

  /**
   * This function opens the modal that displays the warning on bulk action of duplicate
   */
  const modalDelMass = (selectedActivities) => {
    const n = selectedActivities.length;
    const message = t('master_plan.del_warning').replace(
      '__n__',
      '<span>' + n + '</span>'
    );

    /** matrix to give css to the number of activities (n) */
    const msgArr = [];
    msgArr.push(message.split('(')[0]);
    msgArr.push(n);
    msgArr.push(message.split(')')[1]);
    callbackModalDelMass &&
      callbackModalDelMass(
        () => deleteMassiveActivities(selectedActivities),
        msgArr
      );
  };

  /**
   * This function delete the activities selected effectively
   * @param {*} selectedActivities Object of activitys to delete massively
   */
  const deleteMassiveActivities = (selectedActivities) => {
    const orderSelectedActivities = selectedActivities.sort(
      (a, b) => a.$index - b.$index
    );
    const atLeastOneActivityIsAlap = orderSelectedActivities.some(
      (act) => act.constraint_type === 'alap'
    );
    if (atLeastOneActivityIsAlap) {
      LinksStorage.updateShouldRecalculateAlap(true);
      LinksStorage.shouldRecalculateLinkedTasks(true);
    }
    if (orderSelectedActivities.length) {
      gantt.suspense_delete_update = false;
      let flagDelete = false;
      orderSelectedActivities.forEach(async (act) => {
        if (gantt.isTaskExists(act.id) && act.correlative_id) {
          gantt.deleteTask(act.id);
          flagDelete = true;
        }
      });
      if (flagDelete) {
        dispatch(ganttActions.setSelectedActivities([]));
        gantt.optimizedRender();
        notifyMessageCustom({
          type: getTypeNotification(TrashComp),
          description: t('massive_notif_delete')
        });
      }
      gantt.suspense_delete_update = true;
      refreshCriticalPathIsNeeded();
    }
  };

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(history);
    };

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  useEffect(() => {
    const url = window.location.href;
    if (buttons.length && btnLoaded) {
      setButtons(buttons.map((btn) => btn));
    }
  }, [match, btnLoaded]);

  const renderLoadedContent = () => {
    let message;
    if (projectSelected.activity_creter.toUpperCase() == 'DURATION') {
      message = t('info_criteria_activity_duration');
    } else if (projectSelected.activity_creter.toUpperCase() == 'COST') {
      message = t('info_criteria_activity_cost');
    } else if (projectSelected.activity_creter.toUpperCase() == 'HH') {
      message = t('info_criteria_activity_hh');
    }

    const displayedRoute = history?.location?.pathname;
    const activeButton = buttons.find(
      (btn) => btn.routeName === displayedRoute
    );
    return (
      <div className="gantt-container-header">
        {buttons.map((btn, index) => {
          if (btn.hide) return null;

          const isActive =
            activeButton && activeButton.btnTitle === btn.btnTitle;
          const buttonClassName = isActive ? btnActiveStyle : btnDisabledStyle;
          return (
            <Button
              key={index}
              className={buttonClassName}
              type="primary"
              onClick={(e) => handleClick(e, btn)}>
              {btn.btnTitle}
            </Button>
          );
        })}
        <GanttSettings
          t={t}
          onCommandDispatched={handleCommandDispatched}
          criteriaTooltip={message}
          ganttObject={gantt}
          projectState={projectState}
        />
      </div>
    );
  };

  const gotoTop = () => {
    scrollIntoView(document.querySelector('.ganttWrapp'), {
      align: {
        top: 0
      }
    });
  };

  useDeepCompareEffect(() => {
    // Validate that gantt exists and that its configuration allows for showing submittal icons
    if (!gantt || !gantt.config?.show_submittal_icon) {
      return;
    }

    // Validate that the submittal_icon_task_layer function exists on the gantt object
    if (typeof gantt.submittal_icon_task_layer !== 'function') {
      console.warn('gantt.submittal_icon_task_layer is not a function');
      return;
    }

    // Add task layer and keep its ID for future reference
    const taskLayerId = gantt.addTaskLayer(
      gantt.submittal_icon_task_layer(gantt)
    );

    if (taskLayerId) {
      // Keep track of the task layer IDs
      gantt.iconSubmittalsArray = gantt.iconSubmittalsArray || []; // Initialize if not already set
      gantt.iconSubmittalsArray.push(taskLayerId);

      // Optimize rendering
      requestRender();
    }
  }, [submittalState?.submittals]);

  const renderFinishPage = () => {
    if (
      masterplanPermissions.gantt == 'AC' ||
      masterplanPermissions.gantt == 'V'
    ) {
      return (
        <Animated
          className="ganttWrapp"
          animationIn="fadeIn"
          animationInDuration={500}
          isVisible={true}
          style={{ height: '100%' }}>
          {loaded ? (
            renderLoadedContent()
          ) : (
            <Spin className="loader-gantt-style" indicator={antIcon} />
          )}
          <Switch>
            <Route path={`${match.path}/ganttchart`}>
              <GanttChart
                t={t}
                history={history}
                permission={masterplanPermissions?.gantt}
                setParentGantt={setGantt}
                modalCircular={modalCircular}
                modalDupMass={modalDupMass}
                modalDupMassAddActivities={modalDupMassAddActivities}
                modalDelMass={modalDelMass}
                callbackModalDupConfirmMass={callbackModalDupConfirmMass}
              />
            </Route>
            <Route path={`${match.path}/calendars`}>
              <Calendars
                t={t}
                history={history}
                goToTop={gotoTop}
                permission={masterplanPermissions?.gantt}
              />
            </Route>
            <Route path={`${match.path}/resources`}>
              {requireResources ? (
                <Resources
                  t={t}
                  history={history}
                  permission={masterplanPermissions?.resources}
                />
              ) : (
                <Redirect to={`${match.path}/ganttchart`} />
              )}
            </Route>
          </Switch>
          {jsx}

          {/* Circular links */}
          {jsxModal}

          {/* Delete Massive */}
          {jsxModalDelMass}

          {/* Duplicate Massive */}
          {jsxModalDupConfirmMass}

          {/* Duplicate Massive */}
          {jsxModalDupMass}
        </Animated>
      );
    }
    return <div>{t('settings.not_permissions')}</div>;
  };

  return (
    <span data-testid={TestIds.GANTT_CONTAINER_VIEW_CONTAINER}>
      {renderFinishPage()}
    </span>
  );
}

export default withTranslation()(GanttContainerView);
