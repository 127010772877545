import { useState } from 'react';
import { authService } from '../../../services';
import { httpCodes } from '../../../constants/httpCodes';
import { checkBackendAccess } from '../../../utils/checkBackendAccess';
import * as Sentry from '@sentry/react';
import {
  sendSignInTrackingEvent,
  redirectSelectCompany,
  isValidForm,
  sendNetworkBlockedEvent
} from '../login.helpers';

export function useAuth({ t, dataForm, setConfigNotification, history }) {
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const checkNetworkAccess = async (email) => {
    const statusServer = await checkBackendAccess(authService);
    if (!statusServer) {
      sendNetworkBlockedEvent(email);
      setConfigNotification({
        show: true,
        type: 'error',
        label: t('login.network_blocking_alert')
      });
      return false;
    }
    return true;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingButton(true);

    if (isValidForm(dataForm) && !isLoadingButton) {
      const data = {
        user: dataForm?.email,
        password: dataForm?.password
      };
      try {
        const hasNetworkAccess = await checkNetworkAccess(data.email);
        if (!hasNetworkAccess) {
          setIsLoadingButton(false);
          return;
        }
        const response = await authService.index(data);
        if (response.status !== httpCodes.OK) {
          const isUnauthorized = response.status === httpCodes.UNAUTHORIZED;
          setConfigNotification({
            show: true,
            type: isUnauthorized ? 'warning' : 'error',
            label: isUnauthorized
              ? t('login.notification_warning_text')
              : t('login.notification_error_text')
          });
          sendSignInTrackingEvent(
            data.user,
            response.status,
            response.data.type
          );
          setIsLoadingButton(false);
          return;
        }
        sendSignInTrackingEvent(data.user, response.status, null);
        redirectSelectCompany(response, history);
      } catch (err) {
        setConfigNotification({
          show: true,
          type: 'error',
          label: t('login.notification_error_text')
        });
        Sentry.captureException(err);
        sendSignInTrackingEvent(
          data.user,
          httpCodes.INTERNAL_SERVER_ERROR,
          null
        );
      } finally {
        setIsLoadingButton(false);
      }
    } else {
      setIsLoadingButton(false);
    }
  };

  return {
    onSubmit,
    isLoadingButton
  };
}
