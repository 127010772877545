import React from 'react';
import { Icon } from 'antd';

export default function IconLoading() {
  const FONT_SIZE = 20;
  return (
    <>
      <Icon type="loading" style={{ fontSize: FONT_SIZE }} spin />
    </>
  );
}
