import { useQuery } from 'react-query';
import { userService } from '../../../services';
import { getSignedUser } from '../../../utils/userUtils';
import * as projectActions from '../../../redux/slices/projectSlice';

function useProjects({ dispatch }) {
  const user = getSignedUser();

  const getProjects = async () => {
    if (!user) return null;
    const response = await userService.projectsbyuserthrough(user.id);
    if (!response) return null;
    return response.projects;
  };

  const {
    data: projectsData,
    isLoading: loadingProjects,
    refetch: getProjectsLoad,
    isRefetching: isRefetchingProjects
  } = useQuery(['projects', user?.id], getProjects, {
    enabled: Boolean(user?.companyId),
    staleTime: 30 * 1000,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: 60000,
    onSuccess: (data) => {
      if (data) {
        updateProjectsList(data);
      }
    }
  });

  const updateProjectsList = (arr) => {
    dispatch(projectActions.setAllProjects(arr));
  };

  return {
    loadingProjects,
    getProjectsLoad,
    projectsData,
    isRefetchingProjects
  };
}

export default useProjects;
