export const ALL_CONTENT = 'ALL_CONTENT';
export const Tabloid = 'Tabloid';
export const A3 = 'A3';
export const ArchC = 'Arch_C';
export const ArchD = 'Arch_D';
export const NEW_TAB = '_blank';

export const PAPER_FORMAT = [
  [ALL_CONTENT, 'export_pdf_modal.format_option.one_page'],
  [Tabloid, 'Tabloid - 11 x 17 in'],
  [A3, 'A3 - 11.7 x 16.5 in'],
  [ArchC, 'Arch C - 18 x 24 in'],
  [ArchD, 'Arch D - 24 x 36 in']
];

export const formatOnPixels = {
  A3: {
    height: 1647,
    width: 2383,
    format_text: A3
  },
  Tabloid: {
    height: 1544.8,
    width: 2448,
    format_text: Tabloid
  },
  Arch_C: {
    height: 2527.8,
    width: 3370.4,
    format_text: ArchC
  },
  Arch_D: {
    height: 3370.4,
    width: 5055.6,
    format_text: ArchD
  }
};

export const ZOOM_LEVELS_GANTT = [
  'YEARS',
  'QUARTERS',
  'MONTH',
  'WEEKS',
  'DAYS'
];
export const zoomLevelConfig = {
  0: 50,
  1: 40,
  2: 50,
  3: 150,
  4: 20
};

export const HIDE_COLUMNS_SCHEDULE = ['buttons', 'checked'];
export const SHOW_COLUMNS_SCHEDULE = ['WBS_PRE_COL'];
export const HIDE_COLUMNS_LOOKAHEAD = ['actions'];
export const SHOW_COLUMNS_LOOKAHEAD = [];

export const MESSAGE_FIT_ONE_PAGE = 'message_fit_one_page';
export const SCHEDULE = 'Schedule';
export const LOOKEAHEAD = 'Lookeahead';
