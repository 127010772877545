import { useState, useCallback } from 'react';
import { helpCenterQuestions } from '../constants/helpCenterQuestions';

const SEARCH_STATES = {
  VISIBLE: true,
  HIDDEN: false
};

const useHelpCenterSearch = (currentModule, t) => {
  const [visibilitySearchIcon, setVisibilitySearchIcon] = useState(
    SEARCH_STATES.VISIBLE
  );
  const [helpCenterData, setHelpCenterData] = useState(helpCenterQuestions);

  const findModuleData = useCallback((data, moduleName) => {
    return data.find((el) => el.name === moduleName);
  }, []);

  const isMultiWordSearch = useCallback((searchTerms) => {
    return searchTerms.length > 1;
  }, []);

  const matchesSingleWordSearch = useCallback(
    (translatedTitle, searchValue) => {
      return translatedTitle.includes(searchValue.toLowerCase());
    },
    []
  );

  const matchesMultiWordSearch = useCallback((translatedTitle, searchTerms) => {
    return translatedTitle
      .split(' ')
      .some((word) => searchTerms.includes(word));
  }, []);

  const updateQuestionVisibility = useCallback(
    (question, searchValue, searchTerms) => {
      const translatedTitle = t(question.title).toLowerCase();

      question.hide = isMultiWordSearch(searchTerms)
        ? !matchesMultiWordSearch(translatedTitle, searchTerms)
        : !matchesSingleWordSearch(translatedTitle, searchValue);
    },
    [isMultiWordSearch, matchesMultiWordSearch, matchesSingleWordSearch, t]
  );

  const filterQuestions = useCallback(
    (moduleData, searchValue) => {
      if (!moduleData) return;

      const searchTerms = searchValue.toLowerCase().split(' ');
      moduleData.questions.forEach((question) =>
        updateQuestionVisibility(question, searchValue, searchTerms)
      );
    },
    [updateQuestionVisibility]
  );

  const handleSearch = useCallback(
    (searchValue) => {
      if (!currentModule) return;

      if (searchValue.length) {
        setVisibilitySearchIcon(SEARCH_STATES.HIDDEN);

        const updatedData = [...helpCenterData];
        const currentModuleData = findModuleData(updatedData, currentModule);

        filterQuestions(currentModuleData, searchValue);
        setHelpCenterData(updatedData);
      } else {
        setVisibilitySearchIcon(SEARCH_STATES.VISIBLE);
        resetSearch();
      }
    },
    [currentModule, helpCenterData, filterQuestions, findModuleData]
  );

  const resetSearch = useCallback(() => {
    const updatedData = [...helpCenterData];
    const currentModuleData = findModuleData(updatedData, currentModule);

    if (currentModuleData) {
      currentModuleData.questions.forEach((question) => {
        question.hide = false;
      });
      setHelpCenterData(updatedData);
    }
  }, [helpCenterData, currentModule, findModuleData]);

  return {
    visibilitySearchIcon,
    helpCenterData,
    handleSearch
  };
};

export default useHelpCenterSearch;
