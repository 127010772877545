const setColumnsFilter = (columns = [], updateCheckedColumns) => {
  if (!columns.length) return;
  const gantt = window.to_use_react_gantt;
  const visibleColumns = columns.filter((column) => !column.hide);
  updateCheckedColumns(visibleColumns);
  const mapOfOrderedColumns = orderColumnsByPosition(columns);
  const newColumnsOrder = combineColumns(mapOfOrderedColumns, gantt);
  gantt.config.columns = newColumnsOrder;
  setColumnsWidth(columns, gantt);
  setColumnsVisibliity(columns, gantt);
};

function orderColumnsByPosition(columns) {
  const sortedColumns = columns.sort((columnA, columnB) => {
    if (columnA.position < columnB.position) return -1;
    if (columnA.position > columnB.position) return 1;
    return 0;
  });

  const mapOfColumnsOrderedByPosition = new Map();
  sortedColumns.forEach((column, index) => {
    mapOfColumnsOrderedByPosition.set(column.name, column);
  });

  return mapOfColumnsOrderedByPosition;
}

function combineColumns(mapOfOrderedColumns, gantt) {
  const originalColumns = gantt.config.columns.slice();

  const getPosition = (element) => {
    const RANDOM_NUMBER_TO_ALLOW_SORT = 1000;
    return mapOfOrderedColumns.get(element.name) !== undefined
      ? mapOfOrderedColumns.get(element.name)
      : {
          position: RANDOM_NUMBER_TO_ALLOW_SORT
        };
  };

  originalColumns.sort(
    (columnA, columnB) =>
      getPosition(columnA).position - getPosition(columnB).position
  );

  return originalColumns;
}

function setColumnsWidth(columns, gantt) {
  columns.forEach((column) => {
    const gridColumn = gantt.getGridColumn(column.name);
    if (gridColumn) {
      gridColumn.width = column.width;
    }
  });
}

function setColumnsVisibliity(columns, gantt) {
  columns.forEach((column) => {
    const gridColumn = gantt.getGridColumn(column.name);
    if (gridColumn) {
      gridColumn.hide = column.hide;
    }
  });
}

export default setColumnsFilter;
