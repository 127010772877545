/* eslint-disable quote-props */
import * as Sentry from '@sentry/react';
import { createHttpClient } from '../utils/httpUtils';
import { sentryLogger } from '../monitor/monitor';

class UserService {
  constructor() {
    this.http = createHttpClient();
  }

  setFreeTrialHeader(data) {
    if (data?.isFreeTrial) {
      const loggedUser = localStorage.getItem('authToken');
      if (loggedUser) {
        this.http.defaults.headers['WWW-Authenticate'] = `Bearer ${loggedUser}`;
      }
    }
  }

  async show(userId) {
    const res = await this.http.get('users/' + userId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async destroy(userId) {
    const res = await this.http.delete('users/' + userId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async create(data) {
    if (data?.email) {
      data.email = data.email.toLowerCase();
    }
    const res = await this.http.post('users', data).catch((error) => {
      console.log(error.response);
      return error.response;
    });
    return res ? res.data : res;
  }

  async sendEmailCcloud(data) {
    const res = await this.http
      .post('users/email_ccloud_send', data)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http.put('users/' + data.id, data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async getbytoken(token) {
    const res = await this.http
      .get('users/get_user_by_token/' + token, {})
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getbyemail(email) {
    const res = await this.http
      .get('users/get_user_by_email/' + email, {})
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async inviteUsers(data) {
    data.users.map((user) => {
      user.email = user.email.toLowerCase();
    });
    const res = await this.http
      .post('users/inviteUsers', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async notifyCommitment(data) {
    const res = await this.http
      .post('users/notifyCommitments', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateProjectReport(data) {
    const res = await this.http
      .post('users/update_project_report', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getBySector(sectorId) {
    const data = { sector_id: sectorId };
    const res = await this.http.post('users/bysector', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async getByProject(projectId) {
    const res = await this.http
      .get('users/byprojectamind/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getByProjectThrough(projectId) {
    const res = await this.http
      .get('users/byprojectthrough/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async projectsbyuserthrough(userId) {
    const sessionToken = sessionStorage.getItem('sessionToken');
    if (sessionToken) {
      this.http.defaults.headers['WWW-Authenticate'] = `Bearer ${sessionToken}`;
    }
    const res = await this.http
      .get('users/projectsbyuserthrough/' + userId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getByCompany(userId) {
    const res = await this.http
      .get('users/company/' + userId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getProjectsByUser(userId) {
    const res = await this.http
      .get('users/projects_by_user/' + userId, {})
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getUserProjectsView(userId) {
    const res = await this.http
      .get(`users/${userId}/projectsView`, {})
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async setUserProjectsView(userId, projectsView) {
    const res = await this.http
      .put(`users/${userId}/projectsView`, { projectsView })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getUserProjectsViewFilters(userId) {
    const res = await this.http
      .get(`users/${userId}/projectsView/filters`)
      .catch((error) => {
        console.log(error.response);
        return {};
      });
    return res ? res.data : res;
  }

  async setUserProjectsViewFilters(userId, subcontractId) {
    const res = await this.http
      .put(`users/${userId}/projectsView/filters`, {
        filters: { subcontractId }
      })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async operationValidation(validation) {
    const res = await this.http
      .get('users/operation_validation/' + validation)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async checkPluginByName(name) {
    const res = await this.http
      .post('users/check_plugin/', { plugin_name: name })
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async inviteUserFreeTrial(data) {
    if (data?.email) {
      data.email = data.email.toLowerCase();
    }
    const res = await this.http
      .post('users/inviteUserFreeTrial', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getByTokenFreeTrial(token) {
    const res = await this.http
      .get('users/getByTokenFreeTrial/' + token, {})
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async updateUserFreeTrial(data) {
    try {
      this.setFreeTrialHeader(data);

      const res = await this.http.put('users/updateFreeTrial/' + data.id, data);

      return res ? res.data : res;
    } catch (err) {
      console.log(
        `Error in updateUserFreeTrial, error: ${err.response || err.message}`
      );
      return false;
    }
  }

  async createUsers(data) {
    const res = await this.http
      .post('users/createUsers', data)
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
    return res ? res.data : res;
  }

  async getUsersOrganizationSettings() {
    try {
      const res = await this.http.get('users/settings/organization');
      return res ? res.data : res;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('organizationSettings', { message: error.message });
        Sentry.captureException(error);
      });
      console.error(
        `Error in getUsersOrganizationSettings, error: ${error.response || error.message}`
      );
      throw error;
    }
  }

  async getUsersProjectSettings(projectId) {
    try {
      if (!projectId) {
        throw new Error('projectId is required');
      }
      const res = await this.http.get(`users/settings/project/${projectId}`);
      return res ? res.data : res;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('projectSettings', { message: error.message });
        Sentry.captureException(error);
      });
      console.error(
        `Error in getUsersProjectSettings, error: ${error.response || error.message}`
      );
      throw error;
    }
  }

  async manageUserAssignmentProjectSettings(
    projectId,
    usersToAssign = [],
    usersToUnassign = []
  ) {
    try {
      if (!projectId) {
        throw new Error('projectId is required');
      }

      const res = await this.http.post(
        `users/settings/project/${projectId}/manageUserAssignment`,
        {
          usersToAssign,
          usersToUnassign
        }
      );

      return res ? res.data : res;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setContext('projectSettings', { message: error.message });
        Sentry.captureException(error);
      });
      console.error(
        `Error in getUsersProjectSettings, error: ${error.response || error.message}`
      );
      throw error;
    }
  }

  async usersActiveByOrganization() {
    try {
      const res = await this.http.post('users/usersActiveByOrganization');
      return res ? res.data : res;
    } catch (error) {
      sentryLogger.error('usersActiveByOrganization', {
        error
      });
      return error;
    }
  }
}

export const userService = new UserService();
