import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SearchBar from './components/SearchBar';
import { useQuestionsList } from './hooks/useQuestionsList';
import useHelpCenterSearch from './hooks/useHelpCenterSearch';

const LAYOUT = {
  search: {
    iconWidth: 16,
    iconPosition: 37,
    marginTop: 15
  },
  questions: {
    offset: 2,
    marginTop: 15,
    span: 20
  },
  questionItem: {
    iconWidth: 8,
    textMargin: 8,
    fontSize: 11
  }
};

const SearchSection = memo(({ drawer, t }) => {
  const { visibilitySearchIcon, helpCenterData, handleSearch } =
    useHelpCenterSearch(drawer?.data?.module, t);

  const renderQuestions = useQuestionsList(drawer, helpCenterData, t);

  return (
    <SearchBar
      visibilitySearchIcon={visibilitySearchIcon}
      handleSearch={handleSearch}
      questions={renderQuestions()}
      questionsConfig={LAYOUT.questions}
      t={t}
    />
  );
});

SearchSection.propTypes = {
  drawer: PropTypes.object,
  t: PropTypes.func.isRequired
};

export default SearchSection;
