import { store } from '../../../../redux/store';
import { criticalPathRefresh, validateCriticalNeed } from '../utils/index';

function refreshCriticalPathIsNeeded() {
  const gantt = window.to_use_react_gantt;

  const columns_validate = gantt.getGridColumns();
  const isCriticalPathHighlightEnabled = gantt.config.highlight_critical_path;
  const isShowSlackRequest = gantt.config.show_slack;
  const isCriticalNeedValidColumnn = validateCriticalNeed(columns_validate);
  const isActivityCardOpen =
    store.getState()?.hoveringStack?.drawer?.status === 'OPEN';

  if (
    isCriticalPathHighlightEnabled ||
    isCriticalNeedValidColumnn ||
    isShowSlackRequest ||
    isActivityCardOpen
  ) {
    criticalPathRefresh();
  }
}

export { refreshCriticalPathIsNeeded };
