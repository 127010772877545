class LinksStorage {
  static links = new Map();
  static updatedLinks = new Map();
  static initialized = false;
  static linkedTasks = null;
  static autoSchedule = true;
  static shouldRecalculateAlap = true;
  static linkedActivitiesToAlap = null;
  static relationsAlap = null;

  static init(gantt) {
    if (this.initialized) {
      return;
    }
    const storage = gantt.getDatastore('link');
    this.initialized = true;

    storage.attachEvent('onStoreUpdated', (id, item, action) => {
      if (action === null || action === 'paint') {
        return;
      }

      if (action === 'delete') {
        this.links.delete(id);
      }

      if (this.linkedActivitiesToAlap?.has(id)) {
        this.updateShouldRecalculateAlap(true);
      }

      this.updatedLinks.set(id, item);
      this.updateAutoSchedule(true);
    });

    storage.attachEvent('onAfterAdd', (id, item) => {
      this.links.set(id, item);
      this.autoSchedule = true;
    });
  }

  static updateLinkedTasks(linkedTasks) {
    this.linkedTasks = linkedTasks;
    this.autoSchedule = false;
  }

  static updateAutoSchedule(autoSchedule = true) {
    this.autoSchedule = autoSchedule;
  }

  static getLinkedTasks() {
    return this.linkedTasks;
  }

  static shouldRecalculateLinkedTasks() {
    return this.autoSchedule;
  }

  static initializeLinksOfAllAlapActivities(allAlapActivities) {
    const setOfLinksInAlap = new Set();
    allAlapActivities.forEach((activity) => {
      activity['$target'].forEach((value) => setOfLinksInAlap.add(value));
      activity['$source'].forEach((value) => setOfLinksInAlap.add(value));
    });

    this.linkedActivitiesToAlap = setOfLinksInAlap;
  }

  static updateShouldRecalculateAlap(shouldRecalculateAlap) {
    this.shouldRecalculateAlap = shouldRecalculateAlap;
  }

  static getShouldRecalculateAlap() {
    return this.shouldRecalculateAlap;
  }

  static setRelationsAlap(relationsAlap) {
    this.relationsAlap = relationsAlap;
  }

  static getRelationsAlap() {
    return this.relationsAlap;
  }
}
window.LinksStorage = LinksStorage;
export { LinksStorage };
