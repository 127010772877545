import jwt from 'jsonwebtoken';
import * as Sentry from '@sentry/react';
import { addBreadcrumbUtil } from '../monitor/monitor';
import { NO_ORGANIZATION_ACCESS_ERROR_MSG } from '../constants/access';
import { authService } from '../services';
import SessionCreationError from '../entities/error/SessionCreationError';

/**
 * Maps a user object to a friendlier format
 *
 * @param {Object} user - The original user object
 * @return {Object} - The user mapped to the new format
 */
export const mapUserToNewFormat = (user) => {
  const mappingConfig = [
    ['is_active', 'isActive'],
    ['name', 'firstName'],
    ['lastname', 'lastName'],
    ['image', 'thumbnail'],
    ['activation_token', null],
    ['password', null],
    ['userproject', null]
  ];
  const newUser = { ...user };

  mappingConfig.forEach(([oldPropertyName, newPropertyName]) => {
    if (newPropertyName) {
      newUser[newPropertyName] = newUser[oldPropertyName];
    }

    delete newUser[oldPropertyName];
  });

  return newUser;
};

export const getSignedUser = () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const sessionData = getSessionTokenData();

    if (!sessionData) {
      addBreadcrumbUtil(
        'getSignedUser',
        'Session data is null or invalid',
        'warning'
      );
      return user;
    }

    const { companyId, role, position } = sessionData;

    addBreadcrumbUtil('getSignedUser', 'User signed in', 'info', {
      user,
      companyId,
      role
    });

    return {
      ...user,
      companyId,
      role,
      position
    };
  } catch (err) {
    addBreadcrumbUtil('getSignedUser', 'Error getting signed user', 'error', {
      message: err.message
    });
    return null;
  }
};

export const getCurrentUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (err) {
    return null;
  }
};

export const getFromSessionStorage = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (err) {
    return null;
  }
};

export const getCurrentSector = () => getFromSessionStorage('currentSector');
export const getCurrentProject = () => getFromSessionStorage('currentProject');
export const getCurrentCompany = () => getFromSessionStorage('company');
export const getSessionTokenData = () => {
  const sessionToken = getFromSessionStorage('sessionToken');

  if (!sessionToken) {
    addBreadcrumbUtil(
      'getSessionTokenData',
      'Session token is null or invalid',
      'warning',
      {
        sessionToken
      }
    );
    return null;
  }

  try {
    const { id: data } = jwt.decode(sessionToken);
    const { id, role, position, companyId, activation_token } = data;
    return { id, role, position, companyId, activation_token };
  } catch (error) {
    addBreadcrumbUtil(
      'getSessionTokenData',
      'Error getting session token data',
      'error',
      {
        message: error.message
      }
    );

    return null;
  }
};

export const setCurrentSector = (sector) =>
  sessionStorage.setItem('currentSector', JSON.stringify(sector));
export const setCurrentProject = (project) =>
  sessionStorage.setItem('currentProject', JSON.stringify(project));

export const initializeUserStorage = ({
  user,
  sessionToken,
  permissions,
  company
}) => {
  if (!sessionToken) {
    throw new Error(NO_ORGANIZATION_ACCESS_ERROR_MSG);
  }

  localStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('sessionToken', JSON.stringify(sessionToken));
  sessionStorage.setItem('permissiontable', JSON.stringify(permissions));
  sessionStorage.setItem('company', JSON.stringify(company));
};

export const getSessionToken = async (userId, companyId) => {
  try {
    const { data } = await authService.createSession({ userId, companyId });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw new SessionCreationError(error.message);
  }
};

export const clearSessionStorage = () => {
  const keys = [
    'sessionToken',
    'permissiontable',
    'company',
    'currentSector',
    'currentProject'
  ];

  keys.forEach((key) => sessionStorage.removeItem(key));
};

/**
 * Retrieves the translated user status label based on the provided settings and status.
 *
 * @param {Object} options - The function options.
 * @param {Function} options.t - Translation function for fetching status labels.
 * @param {string} [options.settings='project_settings'] - The settings context to retrieve the user status from.
 * @param {string} [options.status='invited'] - The user's status (default is 'invited').
 * @returns {string} - The translated user status label or the default label if no translation is found.
 */
export const getUserStatusSettings = ({
  t = () => {},
  settings = 'project_settings',
  status = 'invited'
}) => {
  const DEFAULT_STATUS_LABEL = 'Invited';
  const translationKey = `settings.${settings}.users_tab.user_status_${status}`;

  return t(translationKey, DEFAULT_STATUS_LABEL);
};

/**
 * Retrieves the current project from the project state.
 *
 * @param {Object} projectState - The project state object
 * @param {Array} projectState.allProjects - Array of all projects
 * @param {string} projectState.projectSelected - ID of the selected project
 * @returns {Object|null} - The current project or null if not found
 */
export const getCurrentProjectState = (projectState) => {
  const project = projectState.allProjects.find(
    ({ id }) => id === projectState.projectSelected
  );
  return project;
};

/**
 * Retrieves the current sector from the project state.
 *
 * @param {Object} projectState - The project state object
 * @param {Array} projectState.allSectors - Array of all sectors
 * @param {string} projectState.sectorSelected - ID of the selected sector
 * @returns {Object|null} - The current sector or null if not found
 */
export const getCurrentSectorState = (projectState) => {
  const sector = projectState.allSectors.find(
    ({ id }) => id === projectState.sectorSelected
  );

  return sector;
};
