import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as projectActions from '../redux/slices/projectSlice';
import { trackingEvent } from '../analytics';
import { AMPLITUDE_SERVICE } from '../analytics/constants';
import { getBasicAmplitudEventProperties } from '../analytics/utils';
import { setAllProjectsGlobal, openNotification } from '../utils';
import { userService } from '../services';
import { removeCurrencySeparators } from '../utils/currency';
import { log } from '../monitor/monitor';
import * as Sentry from '@sentry/react';
import { useCreateProject } from './useCreateProject';

const useSaveProject = (
  state,
  setField,
  setLoading,
  resetForm,
  setVisible,
  t,
  user,
  currentCompany
) => {
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const history = useHistory();
  const { createProject, isCreating } = useCreateProject();

  const saveProject = useCallback(async () => {
    if (!state.projectName.trim()) {
      openNotification(
        {
          title: t('add_project_modal.notification_title_error'),
          description: t('add_project_modal.notification_description_error'),
          type: 'error'
        },
        5
      );
      return;
    }

    setLoading(true);
    log(
      'save_project',
      `Starting project creation: ${state.projectName}, ${user.id}`
    );

    try {
      const isEnglish = t('lang').includes('en');
      const budget = removeCurrencySeparators(isEnglish, state.budget);
      const size = removeCurrencySeparators(isEnglish, state.size);

      const projectData = {
        projectName: state.projectName,
        location: state.location,
        projectType: state.projectType,
        currency: state.currency,
        budget,
        unit: state.unit,
        size,
        projectImageURL: state.projectImageURL
      };

      log(
        'save_project',
        `Creating project with data: ${JSON.stringify(projectData)}`
      );

      const { project, sector, gantt } = await createProject(projectData);

      log('save_project', `Project created successfully: ${project.id}`);

      dispatch(projectActions.setProject(project.id));
      sessionStorage.setItem('currentProject', JSON.stringify(project));
      dispatch(projectActions.setSector(sector.id));
      sessionStorage.setItem('currentSector', JSON.stringify(sector));

      const getProjects = await userService.projectsbyuserthrough(user.id);
      const projectsActive = getProjects.projects.filter(
        (e) => e.stage !== 'deleted'
      );
      setAllProjectsGlobal(
        projectsActive,
        dispatch,
        projectActions,
        projectState
      );

      openNotification(
        {
          title: t('add_project_modal.notification_title_success'),
          description: t('add_project_modal.notification_description_success'),
          type: 'success'
        },
        5
      );

      dispatch(
        projectActions.setProps({
          ...projectState.props,
          openAddProject: false
        })
      );
      trackingEvent(
        'add_project_click',
        {
          ...getBasicAmplitudEventProperties(),
          project_created: true,
          details_expanded: state.moreDetailsExpanded,
          user_id: user.id,
          project_id: project.id,
          project_name: state.projectName,
          organization_id: currentCompany.id
        },
        AMPLITUDE_SERVICE
      );

      log('save_project', `Redirecting to gantt: ${project.id}, ${sector.id}`);

      history.push({
        pathname: `/gantt/${gantt.sectorId}/ganttchart`,
        state: {}
      });
    } catch (error) {
      Sentry.captureException(error);
      const errorMessage = error.response?.data?.message || error.message;

      log(
        'creating_project',
        `Error creating project: ${errorMessage} - ${currentCompany.id} - ${state.projectName}, ${user.id}`
      );

      trackingEvent(
        'add_project_click',
        {
          ...getBasicAmplitudEventProperties(),
          project_created: false,
          details_expanded: state.moreDetailsExpanded,
          user_id: user.id,
          error: errorMessage
        },
        AMPLITUDE_SERVICE
      );

      openNotification(
        {
          title: t('add_project_modal.notification_title_error'),
          description: errorMessage,
          type: 'error'
        },
        5
      );
    } finally {
      setLoading(false);
      resetForm();
      dispatch(
        projectActions.setProps({
          ...projectState.props,
          openAddProject: false
        })
      );
    }
  }, [
    state,
    createProject,
    t,
    user,
    currentCompany,
    dispatch,
    history,
    projectState,
    setLoading,
    resetForm
  ]);

  return {
    saveProject,
    isCreating
  };
};

export default useSaveProject;
