import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Spin } from 'antd';
import * as Sentry from '@sentry/react';
import ModalSystem from '../../components/DesignSystem/ModalSystem';
import { PdfIcon } from '../../icons';
import { checkBackendAccess } from '../../utils/checkBackendAccess';
import { exportPDFGantt } from '../../assets/gantt/ext/Export/ExportPDFGantt';
import { ALL_CONTENT } from '../../assets/gantt/ext/Export/ExportPDFGantt/constants';
import { hideOpenDropdown } from './helpers';
import IconLoading from './components/atoms/IconLoading/IconLoading';
import PageTab from './components/molecules/PageTab/PageTab';
import { TABS } from './constants/index';
import PrintDateTab from './components/molecules/PrintDateTab/PrintDateTab';
import { getGanttToExport } from '../../assets/gantt/ext/Export/ExportPDFGantt/helpers/updateGantt';
import './index.scss';
import { activityPdfService } from '../../services';
import { showNetworkBlockAlert } from '../../assets/gantt/ext/Export/ExportPDFGantt/helpers/notifyUser';

const ExportPdfModal = ({
  t,
  isSchedule = true,
  isVisible,
  setIsVisible,
  handleClose,
  openFitToOnePageModal = () => {}
}) => {
  const [currentTab, setCurrentTab] = useState(TABS.PAGE);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);
  const [isFitOnePage, setIsFitOnePage] = useState(false);
  const [isAlignLine, setIsAlignLine] = useState(false);
  const [formatExport, setFormatExport] = useState(ALL_CONTENT);
  const [isTrimParent, setIsTrimParent] = useState(false);
  const projectState = useSelector((state) => state.projectState);

  useEffect(() => {
    if (isVisible) {
      hideOpenDropdown();
    }
  }, [isVisible]);

  const getCurrentTab = (tab = TABS.PAGE) => {
    const tabs = {
      [TABS.PAGE]: (
        <PageTab
          t={t}
          isSchedule={isSchedule}
          formatExport={formatExport}
          setFormatExport={setFormatExport}
          isFitOnePage={isFitOnePage}
          setIsFitOnePage={setIsFitOnePage}
          isTrimParent={isTrimParent}
          setIsTrimParent={setIsTrimParent}
        />
      ),
      [TABS.PRINT_DATE]: (
        <PrintDateTab
          t={t}
          isSchedule={isSchedule}
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
          isAlignLine={isAlignLine}
          setIsAlignLine={setIsAlignLine}
        />
      )
    };

    return tabs[tab];
  };

  const handleCloseModal = () => {
    setIsVisible(false);
    setCurrentTab(TABS.PAGE);
    setFormatExport(ALL_CONTENT);
    setIsFitOnePage(false);
    setIsTrimParent(false);
    setDateSelected(null);
    setIsAlignLine(false);
    handleClose();
  };

  const createPDFGanttExport = async () => {
    const gantt = getGanttToExport(isSchedule);
    if (!gantt) return;

    const now = new Date();
    const isShowTodayLinePDF = gantt.config.show_todayline;
    const printedOn = dateSelected || now;
    const alignLineDate = isAlignLine ? dateSelected : now;

    await exportPDFGantt({
      isSchedule,
      t,
      openFitToOnePageModal,
      projectState,
      config: {
        isTrimParent,
        isFitOnePage,
        formatToExport: formatExport,
        isShowTodayLinePDF,
        printedOn,
        alignLineDate
      }
    });
  };

  const handleExport = async () => {
    setLoadingPdf(true);
    try {
      await createPDFGanttExport();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingPdf(false);
      handleCloseModal();
    }
  };

  const contentModal = () => {
    return (
      <div className="modal--export--pdf">
        {isSchedule && (
          <div className="modal--export--pdf__tabs">
            <div
              className={`tabs__tab ${currentTab === TABS.PAGE ? 'active' : ''}`}
              onClick={() => setCurrentTab(TABS.PAGE)}>
              {t('export_pdf_modal.tabs_page_title')}
            </div>
            <div
              className={`tabs__tab ${currentTab === TABS.PRINT_DATE ? 'active' : ''}`}
              onClick={() => setCurrentTab(TABS.PRINT_DATE)}>
              {t('export_pdf_modal.tabs_print_date_title')}
            </div>
          </div>
        )}
        <div
          className="modal--export--pdf__content"
          style={{ paddingTop: isSchedule ? 30 : 0 }}>
          {getCurrentTab(currentTab)}
          <div className="content__footer">
            <button
              className="footer__cancel"
              onClick={() => handleCloseModal(false)}>
              {t('cancel')}
            </button>
            <button
              className="footer__download"
              disabled={loadingPdf}
              onClick={() => handleExport()}>
              {loadingPdf ? (
                <Spin indicator={IconLoading()} className="download__spinner" />
              ) : (
                t('export_pdf_modal.download')
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return ModalSystem({
    theme: 'dark',
    width: 350,
    title: t('export_pdf_modal.title'),
    showIconTitle: true,
    iconTitle: <PdfIcon color="#7DFF8A" />,
    visible: isVisible,
    setVisible: setIsVisible,
    centered: true,
    children: contentModal(),
    onCancelFunction: () => handleCloseModal()
  });
};

export default withTranslation()(ExportPdfModal);
