import { log } from '../../../../monitor/monitor';
import CriticalPathHelpers from '../base/generic-calculations';
import calculateRestriction from './helpers/restrictionCalculation';
import { LinksConstraintCalculator } from './calculators/LinksConstraintCalculator';
import { CONSTRAINT_TYPES } from '../constants/index';
import ActivityCalculator from '../base/getActivitiesToCalculate';

class ForwardPath extends CriticalPathHelpers {
  constructor(gantt) {
    super(gantt, 'forward');
    this.gantt = gantt;
    this.chainStartActivities = new Set();
    this.secondLevelActivities = new Set();
    this.pendingParentsWithLinks = new Set();
    this.pendingParentsWithNoLinks = new Set();
    this.structureOfParents = new Map();
    this.singleParents = new Map();
    this.calculations = new Map();
    this.calculationsOfParentThatImpactChildrens = new Map();
    this.alapMap = new Map();
    this.activitiesWaitingForCalculation = new Set();
    this.constraintsMap = new Map();
    this.totalFloatSnltFnlt = new Map();
    this.totalFloatForMsoMfo = new Map();
    this.totalFloatForAsap = new Map();
    this.calculationOfParentsFromLinks = new Set();
    this.parentCalculationFromChildren = new Set();
    this.forbiddenActivitiesLinkedToParentsWithLinks = new Set();
    this.activitiesStartChainButChildrenOfLinkedParents = new Set();
    this.identifyInitialActivities();
    this.initActivitiesWaitingForCalculation();
  }

  async calculate() {
    this.identifyParentsWithAndWithoutLinks();
    this.identifyForbiddenActivitiesLinkedToParentsWithLinks();
    this.initializeActivitiesWithFullProgress();
    this.calculateStartAndFinishOfActivitiesThatStartChain();
    this.identifySecondLevelActivities();
    this.calculateSecondLevelActivities();
    this.identifyThirdLevelActivities();
    this.recursiveCalculateEfAndEs();
  }

  /**
   * Recursively calculates the earliest start (ES) and earliest finish (EF) dates for activities.
   *
   * This function processes activities that can be calculated based on their predecessors and updates
   * the remaining pending activities until all calculations are complete. It recursively calls itself
   * until there are no more pending activities to be calculated.
   *
   * @param {Set<string|number>} [activitiesPending=this.activitiesWaitingForCalculation] - A set of activity IDs that are pending calculation.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming this.activitiesWaitingForCalculation is a Set containing activity IDs [1, 2, 3]
   * recursiveCalculateEfAndEs();
   * // This will recursively calculate ES and EF for all activities that can be calculated
   * // based on their predecessors and update the pending activities set until all are calculated.
   *
   * @throws {Error} If an error occurs during the process.
   */

  recursiveCalculateEfAndEs(
    activitiesPending = this.activitiesWaitingForCalculation,
    attempt = 0
  ) {
    // Verificamos si hemos alcanzado el límite de intentos
    if (attempt >= 25000) {
      log('critical_path', 'recursiveCalculateEfAndEs');
      throw new Error(
        'Error in recursiveCalculateEfAndEs: Maximum attempts reached'
      );
    }

    if (activitiesPending.size === 0) return;
    const activityCalculator = new ActivityCalculator({
      gantt: this.gantt,
      direction: this.direction,
      pendingParentsWithNoLinks: this.pendingParentsWithNoLinks,
      pendingParentsWithLinks: this.pendingParentsWithLinks,
      calculationOfParentsFromLinks: this.calculationOfParentsFromLinks,
      singleParents: this.singleParents,
      calculations: this.calculations,
      activitiesStartChainButChildrenOfLinkedParents:
        this.activitiesStartChainButChildrenOfLinkedParents,
      linkProperty: this.linkProperty,
      linkDirection: this.linkDirection
    });

    const { activitiesThatCanBeCalculated, pendingActivities } =
      activityCalculator.checkActivitiesThatNowCanBeCalculated(
        activitiesPending
      );

    this.calculateStartAndFinishTimes(activitiesThatCanBeCalculated);

    activitiesThatCanBeCalculated.forEach((activity) => {
      if (
        this.calculationOfParentsFromLinks.has(activity) &&
        !this.calculations.has(activity)
      ) {
        pendingActivities.add(activity);
      }
      this.activitiesWaitingForCalculation.delete(activity);
      if (this.calculations.has(activity)) {
        pendingActivities.delete(activity);
      }
    });

    if (pendingActivities.size > 0) {
      this.recursiveCalculateEfAndEs(pendingActivities, attempt + 1);
    }
  }

  /**
   * Calculates the earliest start (ES) and earliest finish (EF) dates for a list of activities.
   *
   * This function processes each activity in the `activitiesToCalculate` list, retrieves the necessary
   * information, and calculates the ES and EF dates based on the activity's progress and constraint type.
   * It handles various constraints and updates the calculations accordingly.
   * It also do calculation according to the activities links
   *
   * @param {Array<string|number>} activitiesToCalculate - A list of activity IDs for which to calculate ES and EF dates.
   * @returns {void} This function does not return a value.
   *
   * @example
   * // Assuming activitiesToCalculate is an array containing activity IDs [1, 2, 3]
   * calculateStartAndFinishTimes([1, 2, 3]);
   * // This will calculate the ES and EF dates for the activities and update the internal calculations map.
   *
   * @throws {Error} If an error occurs during the process.
   */

  calculateStartAndFinishTimes(activitiesToCalculate) {
    activitiesToCalculate.forEach((activity) => {
      let activityReference = this.gantt.getTask(activity);

      if (activityReference.type === 'project') {
        return this.doCalculationForParentType(activityReference);
      }

      if (!activityReference) {
        throw new Error(`Error trying to get activity ${activity}`);
      }
      let linksWithPredecessors = activityReference.$target;

      let constraintType =
        activityReference.constraint_type || CONSTRAINT_TYPES.ASAP;
      let constraintThatNeedToBeRecalculated = [
        CONSTRAINT_TYPES.SNET,
        CONSTRAINT_TYPES.SNLT,
        CONSTRAINT_TYPES.FNLT,
        CONSTRAINT_TYPES.FNET
      ];
      let recalculation = [
        CONSTRAINT_TYPES.ASAP,
        CONSTRAINT_TYPES.ALAP,
        CONSTRAINT_TYPES.SNET,
        CONSTRAINT_TYPES.FNET,
        CONSTRAINT_TYPES.SNLT,
        CONSTRAINT_TYPES.FNLT
      ];

      const activityCalendar = this.gantt.getCalendar(
        activityReference.calendar_id
      );
      if (!activityCalendar) {
        throw new Error(
          `Error trying to get calendar with id: ${activityReference.calendar_id}`
        );
      }
      const progress = Number(activityReference.progress);
      let es = null;
      let ef = null;
      let calculationsFromLinks = null;

      if (progress === 100) {
        this.setDatesForActivityWithFullProgress(activityReference);
        return;
      }

      if (progress > 0 && recalculation.includes(constraintType)) {
        ({ es, ef } =
          this.getDatesForActivityWithZeroProgress(activityReference));
        calculationsFromLinks = new LinksConstraintCalculator({
          links: linksWithPredecessors,
          activity: activityReference,
          calculatedActivitiesMap: this.calculations,
          calculatedAlapsMap: this.alapMap,
          constraint: constraintType,
          parentsWithLinks: this.pendingParentsWithLinks,
          parentsCalculations: this.calculationsOfParentThatImpactChildrens,
          ganttInstance: this.gantt
        }).calculate();

        if (constraintType === CONSTRAINT_TYPES.ASAP) {
          this.totalFloatForAsap.set(activity, {
            es: calculationsFromLinks.es,
            ef: calculationsFromLinks.ef,
            text: activityReference.text
          });
        }
      } else {
        ({ es, ef } = new LinksConstraintCalculator({
          links: linksWithPredecessors,
          activity: activityReference,
          calculatedActivitiesMap: this.calculations,
          calculatedAlapsMap: this.alapMap,
          constraint: constraintType,
          parentsWithLinks: this.pendingParentsWithLinks,
          parentsCalculations: this.calculationsOfParentThatImpactChildrens,
          ganttInstance: this.gantt
        }).calculate());
      }

      if (constraintType === CONSTRAINT_TYPES.ALAP) {
        this.alapMap.set(activity, {
          es: activityReference.start_date,
          ef: activityReference.end_date,
          text: activityReference.text
        });
      }
      let restriction = calculateRestriction(
        activityReference,
        constraintType,
        activityCalendar
      );

      if (constraintThatNeedToBeRecalculated.includes(constraintType)) {
        this.constraintsMap.set(activity, restriction);
        this.totalFloatSnltFnlt.set(activity, { es, ef });
        ({ es, ef } = this.recalculateInBaseConstraint(
          constraintType,
          restriction,
          { es, ef }
        ));
      }

      if (
        [CONSTRAINT_TYPES.MSO, CONSTRAINT_TYPES.MFO].includes(constraintType)
      ) {
        this.totalFloatForMsoMfo.set(activity, { es, ef });
        this.constraintsMap.set(activity, restriction);

        if (activityReference.progress === 0) {
          es = restriction.es;
          ef = restriction.ef;
        } else {
          let maxEfConstraint = activityReference.end_date;

          if (constraintType === CONSTRAINT_TYPES.MFO) {
            maxEfConstraint = Math.max(
              restriction.ef,
              activityReference.end_date
            );
          }

          es = activityReference.start_date;
          ef = maxEfConstraint;
        }
      }

      this.calculations.set(activity, {
        es,
        ef
      });

      this.activitiesWaitingForCalculation.delete(activity);
    });
  }

  /**
   * Gets the greatest date between a restriction and a link calculation.
   * @param {Object} restriction - The restriction object.
   * @param {Object} linkCalculation - The link calculation object.
   * @param {string} [comparisonType='greater'] - The type of comparison ('greater' or 'less').
   * @returns {Object} - The object with the greatest date.
   */
  getTheGreatesDateBetweenRestrictionAndEsEf(
    restriction,
    linkCalculation,
    comparisonType = 'greater'
  ) {
    if (comparisonType === 'greater') {
      return linkCalculation.ef > restriction.ef
        ? linkCalculation
        : restriction;
    }

    if (comparisonType === 'less') {
      return linkCalculation.ef < restriction.ef
        ? linkCalculation
        : restriction;
    }
  }

  /**
   * Set ES and EF dates for an activity with full progress.
   * @param {Object} activity - The activity object.
   */
  setDatesForActivityWithFullProgress(activity) {
    this.calculations.set(activity.id, {
      es: activity.start_date,
      ef: activity.end_date
    });
  }

  /**
   * Calculates ES and EF dates for an activity with progress greater than zero.
   * @param {Object} activity - The activity object.
   * @returns {Object} - The calculated ES and EF dates.
   */

  getDatesForActivityWithZeroProgress(activity) {
    return {
      es: activity.start_date,
      ef: activity.end_date
    };
  }

  /**
   * Recalculates ES and EF dates based on a constraint.
   * @param {string} constraintType - The type of constraint.
   * @param {Object} calculatedRestriction - The calculated restriction object.
   * @param {Object} calculatedEsEf - The calculated ES and EF object.
   * @returns {Object} - The recalculated ES and EF dates.
   */
  recalculateInBaseConstraint(
    constraintType,
    calculatedRestriction,
    calculatedEsEf
  ) {
    let comparisontype = [
      CONSTRAINT_TYPES.SNET,
      CONSTRAINT_TYPES.FNET
    ].includes(constraintType)
      ? 'greater'
      : 'less';
    let { es, ef } = this.getTheGreatesDateBetweenRestrictionAndEsEf(
      calculatedRestriction,
      calculatedEsEf,
      comparisontype
    );

    return { es, ef };
  }

  cleanAllActivityParentsFromLinks(links) {
    return links
      .map((link) => {
        const ganttActivityLink = this.gantt.getLink(link);
        if (!ganttActivityLink) {
          throw new Error(`Error trying to get link id: ${link}`);
        }
        let sourceId = ganttActivityLink.source;
        let targetId = ganttActivityLink.target;
        if (
          !this.gantt.getTask(sourceId) ||
          this.gantt.getTask(sourceId)?.type === 'project'
        )
          return;
        if (
          !this.gantt.getTask(targetId) ||
          this.gantt.getTask(targetId)?.type === 'project'
        )
          return;

        return link;
      })
      .filter(Boolean);
  }
}

export default ForwardPath;
