import { useMutation, useQueryClient } from 'react-query';
import { BreadcrumbCategory } from '../constants/breadcrumbTypes.constants';
import { sentryLogger } from '../monitor/monitor';
import { projectService } from '../services';

const PROJECT_QUERY_KEY = 'projects';

export const useCreateProject = () => {
  const queryClient = useQueryClient();

  const createProjectMutation = useMutation(
    (projectData) => projectService.createFromModal(projectData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_QUERY_KEY);
      },
      onError: (error) => {
        sentryLogger.error('Error creating project:', {
          error,
          entityName: BreadcrumbCategory.PROJECT
        });
      }
    }
  );

  return {
    createProject: createProjectMutation.mutateAsync,
    isCreating: createProjectMutation.isLoading,
    error: createProjectMutation.error
  };
};
