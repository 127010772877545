import moment from 'moment';
import { log } from '../../../../monitor/monitor';
import { addDurationToDate, filters } from '../helpers';
import { CONSTRAINT_TYPES } from '../constants/index';
import identifiers from './identifiers';
import CalculationOfParent from './calculationOfParent';
import ThirdLevelActivityIdentifier from './thirdLevelActivities';

class CriticalPathHelpers {
  constructor(ganttInstace, direction) {
    this.direction = direction;
    this.gantt = ganttInstace;
    this.linkProperty = this.direction === 'forward' ? '$target' : '$source';
    this.linkDirection = this.direction === 'forward' ? 'source' : 'target';
    this.projectDates = ganttInstace.getSubtaskDates();
  }

  tryToCalculateLastLevelChildrens() {
    this.structureOfParents.forEach((firstLevelParent) => {
      const { currentResolutionLevel, childrensByLevel, isALinkedParent } =
        firstLevelParent;
      if (isALinkedParent) return;
      const innerParents = childrensByLevel.get(currentResolutionLevel);

      for (let [parentId, childrens] of innerParents) {
        const childrensNotCalculated = childrens.filter((child) => {
          return !this.calculations.has(child.id);
        });

        if (childrensNotCalculated.length === 0) {
          let parent = this.gantt.getTask(parentId);
          this.calculateParentWithNoLinks(parent, childrens);
          return;
        }
      }
    });
  }

  initActivitiesWaitingForCalculation() {
    try {
      this.gantt
        .getTaskByTime()
        .filter(filters.avoidSubproject)
        .forEach((activity) => {
          this.activitiesWaitingForCalculation.add(activity.id);
        });
    } catch (e) {
      log('Critical Path', 'Cant init activities for calculation');
      throw e;
    }
  }

  identifyParentsWithAndWithoutLinks() {
    try {
      const {
        parentsWithFullProgress,
        pendingParentsWithNoLinks,
        pendingParentsWithLinks
      } = identifiers.identifyParentsWithAndWithoutLinks({
        gantt: this.gantt,
        direction: this.direction,
        linkProperty: this.linkProperty
      });

      this.parentsWithFullProgress = parentsWithFullProgress;
      this.pendingParentsWithNoLinks = pendingParentsWithNoLinks;
      this.pendingParentsWithLinks = pendingParentsWithLinks;
    } catch (e) {
      log('Critical Path', 'Cant identify parents');
      throw e;
    }
  }

  identifyForbiddenActivitiesLinkedToParentsWithLinks() {
    try {
      this.forbiddenActivitiesLinkedToParentsWithLinks =
        identifiers.identifyForbiddenActivities({
          pendingParentsWithLinks: this.pendingParentsWithLinks,
          gantt: this.gantt,
          direction: this.direction
        });
    } catch (e) {
      log('Critical Path', 'Cant identify activities linked to parent');
      throw e;
    }
  }

  getArrayOfIdOfLinkedActivities(links, direction = false) {
    return links.map((link) => {
      const linkData = this.gantt.getLink(link);
      const linkedActivity =
        linkData[direction ? direction : this.linkDirection];
      return Number(linkedActivity);
    });
  }

  async identifyThirdLevelActivities() {
    try {
      const { structureOfParents, singleParents } =
        new ThirdLevelActivityIdentifier({
          gantt: this.gantt,
          linkProperty: this.linkProperty
        }).identifyThirdLevelActivities();

      this.structureOfParents = structureOfParents;
      this.singleParents = singleParents;
    } catch (e) {
      log('Critical Path', 'Cant identify third level activities');
      throw e;
    }
  }

  processEachActivity(parentActivity) {
    let parentsIds = new Set();
    let allTasks = new Set();
    let activitiesByLevel = new Map();
    let singleParents = new Map();

    this.singleParents.set(Number(parentActivity.id), {
      level: parentActivity['$level'],
      hasLink: Boolean(parentActivity[this.linkProperty].length),
      childrens: []
    });

    return new Promise((resolve, reject) => {
      this.gantt.eachTask(
        function (activity) {
          const parentId = Number(activity.parent);
          allTasks.add(activity.id);

          if (activity.type === 'project') {
            const parentInfo = {
              level: activity['$level'],
              hasLink: Boolean(activity[this.linkProperty].length),
              childrens: []
            };
            parentsIds.add(activity.id);
            this.singleParents.set(Number(activity.id), parentInfo);
          }

          if (parentId && this.singleParents.has(parentId)) {
            this.singleParents.get(parentId).childrens.push(activity.id);
          }

          if (!activitiesByLevel.has(activity['$level'])) {
            activitiesByLevel.set(activity['$level'], new Map());
          }

          const level = activitiesByLevel.get(activity['$level']);

          if (!level.has(Number(activity.parent))) {
            level.set(Number(activity.parent), []);
          }

          level.get(Number(activity.parent)).push({
            id: activity.id,
            isParent: activity.type === 'project',
            calculated: false
          });
        }.bind(this),
        parentActivity.id
      );
      resolve({
        parentsIds,
        allTasks,
        activitiesByLevel,
        singleParents
      });
    });
  }

  identifySecondLevelActivities() {
    try {
      const secondLevelActivitiesSet =
        identifiers.identifySecondLevelActivities({
          gantt: this.gantt,
          pendingParentsWithNoLinks: this.pendingParentsWithNoLinks,
          calculations: this.calculations,
          linkProperty: this.linkProperty
        });

      this.secondLevelActivities = new Set([
        ...this.secondLevelActivities,
        ...secondLevelActivitiesSet
      ]);
    } catch (e) {
      log('Critical Path', 'Cant identify second level activities');
      throw e;
    }
  }

  /**
   * Identifies and sets the initial activities for the chain based on the specified direction.
   *
   * This function determines the starting activities of a chain by filtering activities from
   * the Gantt chart. The filtering is based on the specified direction (`forward` or `backward`).
   * - For `forward` direction, activities with an empty `$target` property are selected.
   * - For `backward` direction, activities with an empty `$source` property are selected.
   *
   * The function excludes activities of type `project` from the results.
   * The resulting activity IDs are stored in the `chainStartActivities` property.
   *
   * If an error occurs during the process, `chainStartActivities` is set to an empty array,
   * and the error is rethrown.
   *
   * @throws {Error} If an error occurs during the identification process.
   */
  identifyInitialActivities() {
    try {
      const {
        chainStartActivities,
        activitiesStartChainButChildrenOfLinkedParents
      } = identifiers.identifyInitialActivities({
        gantt: this.gantt,
        linkProperty: this.linkProperty,
        direction: this.direction
      });

      this.chainStartActivities = chainStartActivities;

      if (activitiesStartChainButChildrenOfLinkedParents) {
        this.activitiesStartChainButChildrenOfLinkedParents =
          activitiesStartChainButChildrenOfLinkedParents;
      }
    } catch (e) {
      log('Critical Path', 'Cant find initial activities');
      throw new Error(`Error in identifyInitialActivities: ${e.message}`);
    }
  }

  calculateSecondLevelActivities() {
    try {
      if (this.direction === 'forward') {
        this.recursiveCalculateEfAndEs(
          Array.from(this.secondLevelActivities),
          0,
          'secondLevel'
        );
      } else {
        this.recursiveCalculateLfAndLs(Array.from(this.secondLevelActivities));
      }
    } catch (e) {
      log('Critical Path', 'Cant calculate second level activities');
      throw e;
    }
  }

  calculateStartAndFinishOfActivitiesThatStartChain() {
    try {
      this.chainStartActivities.forEach((activity) => {
        if (!activity) return;
        if (activity.progress > 100) return;
        if (activity.type === 'project') return;

        const dates = this.calculateStartAndFinishOfChainOrigin(
          activity,
          this.direction
        );

        if (this.direction === 'forward') {
          let { es, ef } = dates;
          this.calculations.set(activity.id, { es, ef, text: activity.text });
          this.activitiesWaitingForCalculation.delete(activity.id);
        } else {
          let { ls, lf } = dates;
          this.calculations.set(activity.id, { ls, lf, text: activity.text });
          this.activitiesWaitingForCalculation.delete(activity.id);
        }
      });
    } catch (e) {
      log('Critical Path', 'Cant calculate activities that init chain');
      throw e;
    }
  }

  buildDependencyGraph(activitiesToBuild) {
    const graph = new Map();
    const calculatedActivities = new Set(this.calculations.keys());
    const activityMap = new Map();
    activitiesToBuild.forEach((activity) => {
      if (activity) {
        activityMap.set(activity.id, activity);
      }
    });

    activityMap.forEach((activity, activityId) => {
      if (calculatedActivities.has(activityId)) return;
      let dependencies = [];
      const linkIds = activity[this.linkDirection] || [];
      linkIds.forEach((linkId) => {
        const link = this.gantt.getLink(linkId);
        if (link) {
          const dependencyId = Number(link[this.linkProperty]);
          dependencies.push(dependencyId);
        }
      });
      dependencies = dependencies.filter(
        (depId) => activityMap.has(depId) || calculatedActivities.has(depId)
      );

      graph.set(activityId, dependencies);
    });

    return { graph, activityMap, calculatedActivities };
  }

  topologicalSort(graph, calculatedActivities) {
    const visited = new Set();
    const tempMarks = new Set();
    const sorted = [];

    const visit = (nodeId) => {
      if (tempMarks.has(nodeId)) {
        throw new Error(`Cyclical dependendi detected in id ${nodeId}`);
      }

      if (!visited.has(nodeId) && !calculatedActivities.has(nodeId)) {
        tempMarks.add(nodeId);

        const dependencies = graph.get(nodeId) || [];
        for (const depId of dependencies) {
          visit(depId);
        }

        tempMarks.delete(nodeId);
        visited.add(nodeId);
        sorted.push(nodeId);
      }
    };

    for (const nodeId of graph.keys()) {
      if (!visited.has(nodeId) && !calculatedActivities.has(nodeId)) {
        visit(nodeId);
      }
    }

    return sorted.reverse();
  }

  /**
   * Calculates the start and finish dates for the origin of a chain activity.
   *
   * This function calculates the start and finish dates for an activity based on the provided direction
   * (`forward` or `backward`). For forward direction, it calculates the earliest start (ES) and earliest
   * finish (EF). For backward direction, it calculates the latest start (LS) and latest finish (LF),
   * handling different progress states (0%, 100%, and between 0% and 100%).
   *
   * @param {Object} [activityFromLink=null] - The activity object to calculate dates for.
   * @param {string} [customDirection=null] - The direction for the calculation (`forward` or `backward`).
   * @returns {Object} An object containing the calculated dates (ES, EF, LS, LF) based on the direction and progress.
   *
   * @example
   * // Assuming activity is an object with start_date, end_date, progress, and calendar_id properties
   * // and direction is 'forward'
   * const dates = calculateStartAndFinishOfChainOrigin(activity, 'forward');
   * console.log()
 // { es: activity.start_date, ef: activity.end_date }
   *
   * @throws {Error} If the activity's calendar cannot be retrieved or other errors occur during calculation.
   */
  calculateStartAndFinishOfChainOrigin(
    activityFromLink = null,
    customDirection = null
  ) {
    try {
      let activity = activityFromLink;
      let progress = Number(activity.progress);
      if (progress === '0.00') {
        progress = 0;
      }
      let activityCalendar = this.gantt.getCalendar(activity.calendar_id);
      if (!activityCalendar) {
        throw new Error(
          `Error trying to get calendar with id: ${activity.calendar_id}`
        );
      }
      let direction = customDirection || this.direction;

      if (direction === 'forward') {
        let es = activity.start_date;
        let ef = activity.end_date;
        return {
          es,
          ef
        };
      } else {
        if (progress >= 100) {
          return {
            ls: moment(activity.start_date).clone(),
            lf: moment(activity.end_date).clone()
          };
        }

        if (progress === 0) {
          return this.calculateBackwardWhenZeroProgress(
            activity,
            activityCalendar
          );
        }

        if (progress < 100 && progress > 0) {
          return this.calculateWhenProgressIsBetweenZeroAndOneHundred(
            activity,
            activityCalendar
          );
        }
      }
    } catch (e) {
      throw e;
    }
  }
  /**
   * Calculates the latest start (LS) and latest finish (LF) dates for an activity with zero progress.
   *
   * This function determines the LS and LF dates based on the activity's constraint type and duration,
   * using the provided calendar. It handles different types of constraints, adjusting the start and finish
   * dates accordingly.
   *
   * @param {Object} activity - The activity object to calculate dates for, which should include start_date, end_date, duration, and constraint_type.
   * @param {Object} calendar - The calendar object used to adjust dates based on working days and hours.
   * @returns {Object} An object containing the calculated latest start (LS) and latest finish (LF) dates.
   *
   * @example
   * // Assuming activity is an object with the required properties and a calendar object is provided
   * const dates = calculateBackwardWhenZeroProgress(activity, calendar);
   * console.log()
 // { ls: moment(...), lf: moment(...) }
   *
   * @throws {Error} If an error occurs during the calculation.
   */
  calculateBackwardWhenZeroProgress(activity, calendar) {
    let lateStart = moment(activity.start_date).clone();
    let lateFinish = moment(activity.end_date).clone();
    let duration = activity.duration;
    let endDateProjectConstraints = [
      CONSTRAINT_TYPES.ASAP,
      CONSTRAINT_TYPES.ALAP,
      CONSTRAINT_TYPES.SNET,
      CONSTRAINT_TYPES.FNET
    ];
    let constraint = activity.constraint_type || CONSTRAINT_TYPES.ASAP;

    if (endDateProjectConstraints.includes(constraint)) {
      lateFinish = this.projectDates.end_date;
      lateStart = addDurationToDate(calendar, lateFinish, -duration, activity);
    } else {
      let plusDuration = [CONSTRAINT_TYPES.SNLT, CONSTRAINT_TYPES.MSO];
      let minusDuration = [CONSTRAINT_TYPES.FNLT, CONSTRAINT_TYPES.MFO];
      if (plusDuration.includes(constraint)) {
        lateStart = activity.constraint_date;
        lateFinish = addDurationToDate(calendar, lateStart, duration, activity);
      }

      if (minusDuration.includes(constraint)) {
        lateFinish = activity.constraint_date;
        lateStart = addDurationToDate(
          calendar,
          lateFinish,
          -duration,
          activity
        );
      }
    }

    return {
      ls: lateStart,
      lf: lateFinish
    };
  }
  /**
   * Calculates the latest start (LS) and latest finish (LF) dates for an activity with progress between 0% and 100%.
   *
   * This function determines the LS and LF dates based on the activity's constraint type and progress,
   * considering the end date of the project and various constraints.
   *
   * @param {Object} activity - The activity object to calculate dates for, which should include start_date, end_date, and constraint_type.
   * @returns {Object} An object containing the calculated latest start (LS) and latest finish (LF) dates.
   *
   * @example
   * // Assuming activity is an object with the required properties
   * const dates = calculateWhenProgressIsBetweenZeroAndOneHundred(activity);
   * console.log()
 // { ls: activity.start_date, lf: calculatedLateFinish }
   *
   * @throws {Error} If an error occurs during the calculation.
   */
  calculateWhenProgressIsBetweenZeroAndOneHundred(activity) {
    let lateStart = activity.start_date;
    let lateFinish = null;
    let constraint = activity.constraint_type || CONSTRAINT_TYPES.ASAP;
    let endDateOfTheProject = this.projectDates.end_date;
    let endDateActivityConstraints = [
      CONSTRAINT_TYPES.ASAP,
      CONSTRAINT_TYPES.ALAP,
      CONSTRAINT_TYPES.SNET,
      CONSTRAINT_TYPES.FNET
    ];
    let endDateActivityWithLagConstraints = [
      CONSTRAINT_TYPES.SNLT,
      CONSTRAINT_TYPES.FNLT
    ];

    if (endDateActivityConstraints.includes(constraint)) {
      lateFinish = Math.max(activity.end_date, endDateOfTheProject);
    } else if (constraint === CONSTRAINT_TYPES.MSO) {
      lateFinish = activity.end_date;
    } else if (
      constraint === CONSTRAINT_TYPES.MFO ||
      endDateActivityWithLagConstraints.includes(constraint)
    ) {
      const minDateBetween = Math.min(
        endDateOfTheProject,
        activity.constraint_date
      );

      lateFinish = Math.max(minDateBetween, activity.end_date);
    }

    return {
      ls: lateStart,
      lf: new Date(lateFinish)
    };
  }

  initializeActivitiesWithFullProgress() {
    try {
      const activitiesWithFullProgress = Array.from(
        this.activitiesWaitingForCalculation
      ).filter((activity) => {
        return Number(this.gantt.getTask(activity).progress) === 100;
      });
      const arrayOfActivitiesObject = activitiesWithFullProgress.map(
        (activity) => {
          return this.gantt.getTask(activity);
        }
      );
      arrayOfActivitiesObject.forEach((activity) => {
        const isAParent = activity.type === 'project';
        this.setDatesForActivityWithFullProgress(activity);
        this.activitiesWaitingForCalculation.delete(activity.id);
        if (isAParent && this.pendingParentsWithLinks.has(activity.id)) {
          this.parentCalculationFromChildren.add(activity.id);
          this.calculationsOfParentThatImpactChildrens.set(activity.id, {
            [this.direction === 'forward' ? 'es' : 'ls']: activity.start_date,
            [this.direction === 'forward' ? 'ef' : 'lf']: activity.end_date
          });
          this.calculationOfParentsFromLinks.add(activity.id);
        }
      });
    } catch (e) {
      log('Critical Path', 'Cant initialize activities with full progress');
      throw e;
    }
  }

  // Main function
  doCalculationForParentType(activity) {
    const calculationOfParent = new CalculationOfParent({
      activity: activity,
      direction: this.direction,
      calculationsOfParentThatImpactChildrens:
        this.calculationsOfParentThatImpactChildrens,
      calculations: this.calculations,
      calculationOfParentsFromLinks: this.calculationOfParentsFromLinks,
      singleParents: this.singleParents,
      parentCalculationFromChildren: this.parentCalculationFromChildren,
      gantt: this.gantt
    });
    if (this.pendingParentsWithLinks.has(activity.id)) {
      calculationOfParent.handleParentWithLinks();
    } else {
      calculationOfParent.handleParentWithNoLinks();
    }
  }
}

export default CriticalPathHelpers;
