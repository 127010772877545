export const TYPE_NUMBER = 'number';
export const TYPE_DATE = 'date';
const TYPE_BOOLEAN = false;

export const DEFAULT_NUMBER = 0;
const DEFAULT_DATE = new Date(new Date().setHours(0, 0, 0, 0));
const DEFAULT_BOOLEAN = false;

export const activityAttributes = {
  duration: { type: TYPE_NUMBER, defaultValue: DEFAULT_NUMBER },
  cost: { type: TYPE_NUMBER, defaultValue: DEFAULT_NUMBER },
  progress: { type: TYPE_NUMBER, defaultValue: DEFAULT_NUMBER },
  start_date: { type: TYPE_DATE, defaultValue: DEFAULT_DATE },
  end_date: { type: TYPE_DATE, defaultValue: DEFAULT_DATE },
  is_critical: { type: TYPE_BOOLEAN, defaultValue: DEFAULT_BOOLEAN },
  sumOfDurationRecursively: { type: TYPE_NUMBER, defaultValue: DEFAULT_NUMBER },
  used_cost: { type: TYPE_NUMBER, defaultValue: DEFAULT_NUMBER },
  isOnLookahead: { type: TYPE_BOOLEAN, defaultValue: DEFAULT_BOOLEAN },
  hasCustomPonderator: { type: TYPE_BOOLEAN, defaultValue: DEFAULT_BOOLEAN }
};

export const TYPE_UNDEFINED = 'Undefined property';
export const TYPE_NULL = 'Null property';
export const TYPE_INVALID_FORMAT = 'Invalid format';

export const ignoreAttributesInAmplitude = [
  'isOnLookahead',
  'hasCustomPonderator'
];
