import * as Sentry from '@sentry/react';
import { trackingEventValidateActivity } from './helpers/amplitude.helper';
import { captureExceptionValidateActivity } from './helpers/sentry.helper';
import { getInvalidAttributes } from './helpers/validateActivity.helper';

/**
 * Validates the properties of an activity, replaces invalid values with defaults,
 * and tracks any changes using Sentry and Amplitude.
 * If no invalid properties are found, the original activity is returned.
 *
 * @param {Object} activity - The activity object to validate.
 * @returns {Object} - The updated activity if invalid attributes were found, otherwise the original activity.
 */
export const validateActivityProperties = (activity = {}) => {
  try {
    const { invalidAttributes, updatedActivity } =
      getInvalidAttributes(activity);

    if (!invalidAttributes.length) {
      return activity;
    }

    const { unique_id: id, name } = activity;

    captureExceptionValidateActivity({
      id,
      activity,
      updatedActivity,
      invalidAttributes
    });

    trackingEventValidateActivity({
      activity_id: id,
      activity_name: name,
      invalid_attributes: invalidAttributes
    });

    return updatedActivity;
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setContext('validateActivityProperties', {
        activity,
        errorMessage: error.message
      });
      Sentry.captureException(error);
    });
    return activity;
  }
};
