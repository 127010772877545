import {
  amplitudeEventTracking,
  amplitudeUserIdentify,
  resetPropertiesAmplitude
} from './implements/amplitude';
import { totangoUserIdentify } from './implements/totango';
import { isEnabledEnviroment, isEnabledAnalyticsService } from './utils';
import { ALL_SERVICES } from './constants';
import { getSignedUser } from '../utils/userUtils';

export const userPropertiesAnalytics = (
  user,
  analyticsService = ALL_SERVICES
) => {
  if (isEnabledEnviroment() && isEnabledAnalyticsService(analyticsService)) {
    if (analyticsService === ALL_SERVICES) {
      amplitudeUserIdentify(user);
      return;
    }

    const userProperties = {
      amplitude: amplitudeUserIdentify(user),
      totango: totangoUserIdentify(user)
    };

    return userProperties[analyticsService];
  }
};

export const trackingEvent = (
  eventName,
  eventProperties,
  analyticsService = ALL_SERVICES
) => {
  if (isEnabledEnviroment() && isEnabledAnalyticsService(analyticsService)) {
    if (eventProperties?.project_id) {
      const user = getSignedUser();
      user.project_id = eventProperties.project_id;
      userPropertiesAnalytics(user);
    }
    if (analyticsService === ALL_SERVICES) {
      amplitudeEventTracking(eventName, eventProperties);
      return;
    }

    const services = {
      amplitude: amplitudeEventTracking(eventName, eventProperties)
    };

    return services[analyticsService];
  }
};

export const resetPropertiesAnalytics = (analyticsService = ALL_SERVICES) => {
  if (isEnabledEnviroment() && isEnabledAnalyticsService(analyticsService)) {
    if (analyticsService === ALL_SERVICES) {
      resetPropertiesAmplitude();
      return;
    }

    const services = {
      amplitude: resetPropertiesAmplitude()
    };

    return services[analyticsService];
  }
};
