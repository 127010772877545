import { useCallback } from 'react';
import { BASE_RESOURCES } from '../constants/resourceURLs';

export const useResourceUrls = (t) => {
  const getResourceUrls = useCallback((language) => {
    return Object.entries(BASE_RESOURCES).reduce((acc, [key, resource]) => {
      acc[key] = {
        url: resource.urls[language],
        icon: resource.icon,
        labelKey: resource.labelKey
      };
      return acc;
    }, {});
  }, []);

  const getResources = useCallback(() => {
    const language = t('lang') === 'es' ? 'es' : 'en';
    return Object.entries(getResourceUrls(language)).map(([key, resource]) => ({
      ...resource,
      key
    }));
  }, [t, getResourceUrls]);

  return { getResources };
};
