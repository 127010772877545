import React from 'react';
import CheckSystem from '../../../../DesignSystem/CheckSystem';

export default function TrimpParent({
  t,
  isSchedule,
  isTrimParent,
  setIsTrimParent
}) {
  if (!isSchedule) {
    return <></>;
  }

  return (
    <>
      <CheckSystem
        checked={isTrimParent}
        onClickFunction={(value) => setIsTrimParent(value)}
        withLabel={true}
        label={t('export_pdf_modal.tabs_page_trim_check_label')}
      />
    </>
  );
}
