import React, { useState, useRef, useEffect } from 'react';
import CompanyImage from '../CompanyImage/CompanyImage';
import PopoverSystemV2 from '../DesignSystem/PopoverSystemV2';
import { IMAGE_SIZE, BORDER } from './constants';
import * as CompanySlice from '../../redux/slices/CompanySlice';
import { useSelector, useDispatch } from 'react-redux';
import SelectCompanyModal from '../SelectCompanyModal/SelectCompanyModal';
import './CompanyPopover.scss';
import { getCompaniesByUser } from '../../views/selectCompany/companyOptions/companyOptions.helpers';

export default function CompanyPopover(props) {
  const { t } = props;
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const selectCompanyState = useSelector((state) => state.selectCompanyState);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const getCompanies = async () => {
      const companies = await getCompaniesByUser();
      setCompanies(companies);
    };
    getCompanies();
  }, []);

  const popoverRef = useRef(null);
  const treeDotsRef = useRef(null);

  const changeCompany = () => {
    setVisible(false);
    dispatch(
      CompanySlice.setProps({
        ...selectCompanyState.props,
        openSelectCompanyModal: true
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(
      CompanySlice.setProps({
        ...selectCompanyState.props,
        openSelectCompanyModal: false
      })
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const currentPopover = popoverRef.current;
      const currentTreeDots = treeDotsRef.current;
      if (
        currentPopover &&
        !currentPopover.contains(event.target) &&
        currentTreeDots &&
        !currentTreeDots.contains(event.target)
      ) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverRef, treeDotsRef]);

  const currentOrganization = useSelector(
    (state) => state.companyState.currentCompany
  );

  const { image, name } = currentOrganization;

  const contentPopover = () => {
    const IMAGE_SIZE = 20;
    return (
      <div className="CompanyPopover__content" ref={popoverRef}>
        <div className="CompanyPopover__currentCompany">
          <span>{t('selectOrganization.current_organization')}</span>
          <div className="CompanyPopover__currentCompany__line" />
        </div>
        <div className="CompanyPopover__item">
          <div className="CompanyPopover__item__company">
            {CompanyImage(image, name, IMAGE_SIZE)}
            <span> {name}</span>
          </div>
          {companies && companies.length > 1 && (
            <span
              className="CompanyPopover__item__buttonChange"
              onClick={() => changeCompany()}>
              {t('selectOrganization.change')}
            </span>
          )}
        </div>

        <SelectCompanyModal
          t={t}
          visible={selectCompanyState.props?.openSelectCompanyModal}
          setVisible={handleCloseModal}
        />
      </div>
    );
  };

  return (
    <div className="CompanyPopover-popover" ref={treeDotsRef}>
      <PopoverSystemV2
        trigger={'click'}
        visible={visible}
        placement="bottomLeft"
        key="CompanyPopover"
        theme="dark"
        content={contentPopover()}>
        <div onClick={() => setVisible((visible) => !visible)}>
          {CompanyImage(image, name, IMAGE_SIZE, BORDER)}
        </div>
      </PopoverSystemV2>
    </div>
  );
}
